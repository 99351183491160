import { uniq, difference, omit, get } from 'lodash';
import ContactUtils from './contact.utils';
import ContactAction from './contact.actions';
import UserAction from '../user/user.actions';
import SocketAction from '../socket/socket.action';
import { DEFAULT_CONTACT_FIELDS } from '../../enums/field/field';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CUSTOM_FIELDS_NAME } from '../../enums/modals/groups';

const INITIAL_STATE = {
  openContact: null,
  fetching: true,
  deleting: false,
  merging: false,
  unmerging: false,
  updating: false,
  duplicateContact: null,
  duplicateContactUpdatedFieldValue: null,
  data: {
    byId: {},
    allIds: [],
  },
  errorMessage: undefined,
  count: 0,
  fetchingPagination: false,
  isNewContactAdded: false,
  isAddingNewContact: false,
  filterColumnContact: [
    ...Object.values(DEFAULT_CONTACT_FIELDS)
      .filter((item) => item.custom_field_name !== CUSTOM_FIELDS_NAME.ipAddress)
      .map((item) => {
        const fieldName = get(item, 'custom_field_name');
        return { value: fieldName, label: fieldName };
      }),
    { label: 'Date Created', value: 'Date Created' },
  ],
};

const contactReducer = (state = INITIAL_STATE, action) => {
  if (action?.metadata?.contact) {
    return {
      ...state,
      fetching: false,
      data: ContactUtils.normalizeContact(state, action?.metadata?.contact),
    };
  }
  switch (action.type) {
    case ContactAction.TOGGLE_CONTACT: {
      return {
        ...state,
        openContact: action.payload ? action.payload._id : null,
      };
    }
    case ContactAction.SET_DUPLICATE_CONTACT_UPDATED_FIELD_VALUE:
      return {
        ...state,
        duplicateContactUpdatedFieldValue: action.payload,
      };

    case ContactAction.SET_DUPLICATE_CONTACT:
      return {
        ...state,
        duplicateContact: action.payload,
      };
    case ContactAction.FETCH_CONTACTS.START:
      return {
        ...state,
        fetching: action.loading,
      };
    case ContactAction.FETCH_CONTACTS.SUCCESS: {
      const prevCount = state.count;
      return {
        ...state,
        fetching: false,
        data: ContactUtils.normalizeContact(state, action.payload.data),
        count: action.payload.count ? action.payload.count : prevCount,
      };
    }
    case ContactAction.FETCH_CONTACTS.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case ContactAction.BULK_UPDATE_CONTACTS.START:
      return {
        ...state,
        updating: true,
      };
    case ContactAction.BULK_UPDATE_CONTACTS.SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case ContactAction.BULK_UPDATE_CONTACTS.FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
      };

    case ContactAction.BULK_DELETE_CONTACTS.START:
      return {
        ...state,
        deleting: true,
      };
    case ContactAction.BULK_DELETE_CONTACTS.SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case ContactAction.BULK_DELETE_CONTACTS.FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };
    case ContactAction.ADD_CONTACT: {
      return {
        ...state,
        isAddingNewContact: true,
      };
    }
    case ContactAction.ADD_CONTACT_FAILURE: {
      return {
        ...state,
        isAddingNewContact: false,
      };
    }
    case ContactAction.ADD_CONTACT_SUCCESS: {
      return {
        ...state,
        isAddingNewContact: false,
      };
    }
    // case ContactAction.ADD_CONTACT_SUCCESS: {
    //   const contact = action.payload;
    //   const contactId = contact.id;
    //   if (!contactId) return state;
    //   const allIds = uniq([...state.data.allIds, contactId]);
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       byId: {
    //         ...state.data.byId,
    //         [contactId]: contact,
    //       },
    //       allIds,
    //     },
    //   };
    // }
    case ContactAction.UPDATE_CONTACT_START:
    case ContactAction.FETCH_CONTACTS_BY_ID.START:
      return {
        ...state,
        fetching: action.loading,
      };

    case ContactAction.FETCH_CONTACTS_BY_ID.SUCCESS:
      const contacts = action.payload;
      return {
        ...state,
        fetching: false,
        data: ContactUtils.normalizeContact(state, contacts),
      };

    case ContactAction.FETCH_CONTACTS_BY_ID.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case ContactAction.UPDATE_CONTACT: {
      const contact = action.payload;
      const contactId = contact.id;
      if (!contactId) return state;
      const allIds = uniq([...state.data.allIds, contactId]);
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [contactId]: {
              ...state.data.byId[contactId],
              contact,
            },
          },
          allIds,
        },
      };
    }
    // case ContactAction.DELETE_CONTACT: {
    case SocketAction.SOCKET_EVENT.DELETE_CONTACT: {
      const { id: contactIds } = action.payload;
      if (!contactIds) return state;

      if (Array.isArray(contactIds)) {
        const byId = omit(state.data.byId, contactIds);
        return {
          ...state,
          data: {
            byId,
            allIds: Object.keys(byId),
          },
        };
      }

      const allIds = difference(state.data.allIds, [contactIds]);
      const byId = omit(state.data.byId, [contactIds]);
      return {
        ...state,
        data: {
          byId,
          allIds,
        },
      };
    }

    case ContactAction.DELETE_CONTACT_REDUX: {
      return {
        ...state,
        data: {
          byId: {},
          allIds: [],
        },
      };
    }

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    case ContactAction.UPDATE_CONTACT_CONFLICT:
      const { message, _oldContact, _newContact, mergeData } = action.payload;
      return { ...state, error: { message, _oldContact, _newContact, mergeData } };

    case ContactAction.MERGE_CONTACT_START:
      return {
        ...state,
        merging: true,
        error: null,
      };

    case ContactAction.MERGE_CONTACT_SUCCESS:
      return {
        ...state,
        merging: false,
        error: null,
      };
    case ContactAction.MERGE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        merging: false,
      };

    case ContactAction.UNMERGE_CONTACT_START:
      return {
        ...state,
        unmerging: true,
        error: null,
      };

    case ContactAction.UNMERGE_CONTACT_SUCCESS:
      return {
        ...state,
        unmerging: false,
        error: null,
      };
    case ContactAction.UNMERGE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        unmerging: false,
      };
    case ContactAction.SEARCH_CONTACTS_WITH_FILTER_PAGINATION.START:
    case ContactAction.FETCH_CONTACTS_WITH_PAGINATION.START:
    case ContactAction.SEARCH_CONTACTS_WITH_PAGINATION.START:
      return {
        ...state,
        fetchingPagination: true,
      };
    case ContactAction.FETCH_CONTACTS_WITH_PAGINATION.SUCCESS:
      return {
        ...state,
        fetchingPagination: false,
      };
    case ContactAction.FETCH_CONTACTS_WITH_PAGINATION.FAILURE:
    case ContactAction.SEARCH_CONTACTS_WITH_PAGINATION.FAILURE:
      return {
        ...state,
        fetchingPagination: false,
      };
    case ContactAction.SET_IS_NEW_CONTACT_ADDED:
      return {
        ...state,
        isNewContactAdded: action.payload,
      };
    case ContactAction.SET_FILTER_COLUMN:
      return {
        ...state,
        filterColumnContact: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;
