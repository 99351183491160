import { put, takeLatest } from 'redux-saga/effects';
import MergeLogAction from '../merge-logs/merge-logs.actions';

import SocketAction from '../socket/socket.action';

function* handleFetchMergeLogsByIds({ payload: mergeLogIds }) {
  try {
    yield put(MergeLogAction.fetchMergeLogsByIds(mergeLogIds));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeMergeLogFlow() {
  yield takeLatest(SocketAction.SOCKET_EVENT.FETCH_MERGE_LOG_BY_IDS, handleFetchMergeLogsByIds);
}
