export const LIST_ITEM_TYPE = {
  ALL_CONTACTS: 'ALL_CONTACTS',
  LIST: 'LIST',
  FILTER: 'FILTER',
};

export const LIST_CUSTOM_HEADER = {
  LATEST_RECEIVED_DATE: 'latestReceivedMessageDate',
  LATEST_SENT_DATE: 'latestSentMessageDate',
  CREATED_DATE: 'createdAt',
  ACTIVE_CAMPAIGN: 'activeCampaigns',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
};

export const LIST_CUSTOM_HEADER_NAME = {
  [LIST_CUSTOM_HEADER.LATEST_RECEIVED_DATE]: 'Last Received Date',
  [LIST_CUSTOM_HEADER.LATEST_SENT_DATE]: 'Last Sent Date',
  [LIST_CUSTOM_HEADER.CREATED_DATE]: 'Date Created',
  [LIST_CUSTOM_HEADER.ACTIVE_CAMPAIGN]: 'Active Campaigns',
  [LIST_CUSTOM_HEADER.ADDRESS_1]: 'Address 1',
  [LIST_CUSTOM_HEADER.ADDRESS_2]: 'Address 2',
};
