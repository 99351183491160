import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import MemberAdminAction from './member-admin.actions';
import { ADMINISTRATOR_ROLES } from '../../enums/member/member';

function* fetchMemberAdmins({ payload }) {
  try {
    const response = payload
      ? yield call(axios.get, `/member_admin/byOwner/${payload}`)
      : yield call(axios.get, `/member_admin`);
    if (response && response.data) {
      yield put(MemberAdminAction.fetchSuccess(response.data));
    } else {
      put(MemberAdminAction.fetchFailure('Fetch group members failed.'));
    }
  } catch (error) {
    yield put(MemberAdminAction.fetchFailure(error.message));
    throw error;
  }
}

function* updateMemberAdmins({ payload }) {
  try {
    const { data, removeAdmins } = payload;
    const addRolesAdmin = data.map((admin) => ({ ...admin, roles: Object.values(ADMINISTRATOR_ROLES) }));
    const response = yield call(axios.put, `/member_admin`, { data: addRolesAdmin, removeAdmins });
    if (response && response.data) {
      yield put(MemberAdminAction.updateSuccess(response.data));
    } else {
      put(MemberAdminAction.updateFailure('Fetch group members failed.'));
    }
  } catch (error) {
    yield put(MemberAdminAction.updateFailure(error.message));
    toast.error(get(error, 'response.data', error.message));
  }
}

function* acceptMemberAdmins({ payload }) {
  try {
    const response = yield call(axios.put, `/member_admin/accept`);
    if (response && response.data) {
      yield put(MemberAdminAction.updateSuccess(response.data));
    } else {
      put(MemberAdminAction.updateFailure('Fetch group members failed.'));
    }
  } catch (error) {
    yield put(MemberAdminAction.updateFailure(error.message));
    toast.error(get(error, 'response.data', error.message));
  }
}

function* rejectMemberAdmins({ payload, callback }) {
  try {
    const response = yield call(axios.delete, `/member_admin/reject`, payload);
    if (response && response.data) {
      yield put(MemberAdminAction.updateSuccess(response.data));
      callback(response.data);
    } else {
      put(MemberAdminAction.updateFailure('Fetch group members failed.'));
    }
  } catch (error) {
    yield put(MemberAdminAction.updateFailure(error.message));
    toast.error(get(error, 'response.data', error.message));
  }
}

export default function* memberAdminSagaFlow() {
  yield all([
    yield takeLatest(MemberAdminAction.FETCH_MEMBER_ADMIN.START, fetchMemberAdmins),
    yield takeLatest(MemberAdminAction.UPDATE_MEMBER_ADMIN.START, updateMemberAdmins),
    yield takeLatest(MemberAdminAction.MEMBER_ADMIN_ACCEPT, acceptMemberAdmins),
    yield takeLatest(MemberAdminAction.MEMBER_ADMIN_REJECT, rejectMemberAdmins),
  ]);
}
