export default class HomeAction {
  static SET_NAV_LINK = 'HomeAction/SET_NAV_LINK';

  static TOGGLE_OPEN_SIDEBAR = 'HomeAction/TOGGLE_OPEN_SIDEBAR';

  static TOGGLE_PROFILE_HIDDEN = 'HomeAction/TOGGLE_PROFILE_HIDDEN';

  static UPDATE_CONVERSATION_MODAL = 'HomeAction/UPDATE_CONVERSATION_MODAL';

  static UPDATE_NEW_DID_MODAL = 'HomeAction/UPDATE_NEW_DID_MODAL';

  static UPDATE_NEW_GROUP_MODAL = 'HomeAction/UPDATE_NEW_GROUP_MODAL';

  static MODIFY_GROUP_MODAL = 'HomeAction/MODIFY_GROUP_MODAL';

  static MODIFY_DID_MODAL = 'HomeAction/MODIFY_DID_MODAL';

  static DELETE_DID_MODAL = 'HomeAction/DELETE_DID_MODAL';

  static DELETE_GROUP_MODAL = 'HomeAction/DELETE_GROUP_MODAL';

  static FILTER_CONVERSATION = 'HomeAction/FILTER_CONVERSATION';

  static UPDATE_NEW_LIST_MODAL = 'HomeAction/UPDATE_NEW_LIST_MODAL';

  static SET_IMAGE_PREVIEW = 'HomeAction/SET_IMAGE_PREVIEW';

  static UPDATE_NEW_ADD_CONTACT_MODAL = 'HomeAction/UPDATE_NEW_ADD_CONTACT_MODAL';

  static setImagePreview = (src, alt = '') => ({
    type: this.SET_IMAGE_PREVIEW,
    payload: { src, alt },
  });

  static toggleProfileHidden = () => ({
    type: this.TOGGLE_PROFILE_HIDDEN,
  });

  static openSidebar = (openSidebar) => ({
    type: this.TOGGLE_OPEN_SIDEBAR,
    payload: openSidebar,
  });

  static setNavLink = (link) => ({
    type: this.SET_NAV_LINK,
    payload: link,
  });

  static updateConversationModal = (obj) => ({
    type: this.UPDATE_CONVERSATION_MODAL,
    payload: obj,
  });

  static updateNewSmsModal = (obj) => ({
    type: this.UPDATE_NEW_GROUP_MODAL,
    payload: obj,
  });
  static updateNewAddContactModal = (obj) => ({
    type: this.UPDATE_NEW_ADD_CONTACT_MODAL,
    payload: obj,
  });

  static updateNewListModal = (obj) => ({
    type: this.UPDATE_NEW_LIST_MODAL,
    payload: obj,
  });

  static updateModifyGroupModal = (obj) => ({
    type: this.MODIFY_GROUP_MODAL,
    payload: obj,
  });

  static updateModifyDidModal = (obj) => ({
    type: this.MODIFY_DID_MODAL,
    payload: obj,
  });

  static updateNewDidModal = (obj) => ({
    type: this.UPDATE_NEW_DID_MODAL,
    payload: obj,
  });

  static deleteDidModal = (obj) => ({
    type: this.DELETE_DID_MODAL,
    payload: obj,
  });

  static deleteGroupModal = (obj) => ({
    type: this.DELETE_GROUP_MODAL,
    payload: obj,
  });

  static updateFilterConversationModal = (obj) => ({
    type: this.FILTER_CONVERSATION,
    payload: obj,
  });
}
