import { groupBy } from 'lodash';

export default class MemberUtils {
  static normalizeMember(membersState, members) {
    const byId = groupBy(members, 'id');
    const allIds = Object.keys(byId);
    allIds.map((id) => {
      byId[id] = byId[id][0];
    });
    return {
      byId: { ...byId },
      allIds: [...membersState.allIds, ...allIds],
    };
  }
}
