import { groupBy } from 'lodash';

export default class WorkflowUtils {
  static normalizeWorkflow(data, workflows) {
    const byId = groupBy(workflows, 'id');
    const allIds = Object.keys(byId);
    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });
    return {
      byId: { ...data.byId, ...byId },
      allIds: [...data.allIds, ...allIds],
    };
  }
}
