import TemplateAction from './template.actions';
import UserAction from '../user/user.actions';
import { normalizeUpdatedData } from '../../util';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  error: null,
};

const templateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TemplateAction.ADD_TEMPLATE:
      return {
        ...state,
        data: normalizeUpdatedData(state.data, action.payload),
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default templateReducer;
