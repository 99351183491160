import axios from 'axios';
import { toast } from 'react-toastify';
import { all, takeLatest, call, put, debounce } from 'redux-saga/effects';
import SocketAction from '../socket/socket.action';
import ReportAction from './report.action';

const { SOCKET_EVENT } = SocketAction;

export function* fetchReport({ payload, callback }) {
  try {
    const { groupFilter } = payload;
    if (!groupFilter) return;
    const response = yield call(axios.get, '/report', { params: payload });
    if (response && response.data) {
      yield put(ReportAction.fetchReportSuccess(response.data));
      if (callback) callback();
    } else {
      yield put(ReportAction.fetchReportFail('Fetch report fail failed.'));
    }
  } catch (error) {
    yield put(ReportAction.fetchReportFail(error.message));
    console.error({ error });
  }
}
export function* fetchReportLogConversation(payload) {
  try {
    const response = yield call(axios.get, '/report/conversation-log');
    if (response && response.data) {
      yield put(ReportAction.fetchReportLogConversationSuccess(response.data));
    }
  } catch (error) {
    // yield put(ReportAction.fetchReportFail(error.message));
    console.error({ error });
  }
}

export function* fetchReportLogBulkUploadContact() {
  try {
    const response = yield call(axios.get, '/report/bulk-upload-contact-log');

    if (response && response.data) {
      yield put(ReportAction.fetchReportLogBulkUploadContactSuccess(response.data));
    }
  } catch (error) {
    // yield put(ReportAction.fetchReportFail(error.message));
    console.error({ error });
  }
}

export default function* reportSagaFlow() {
  yield all([
    debounce(3000, ReportAction.FETCH_REPORT.START, fetchReport),
    debounce(1000, ReportAction.FETCH_REPORT_LOG_CONVERSATION.START, fetchReportLogConversation),
    debounce(1000, ReportAction.FETCH_REPORT_LOG_BULK_UPLOAD_LOG.START, fetchReportLogBulkUploadContact),
    debounce(3000, SOCKET_EVENT.RECEIVE_MESSAGE, [fetchReport, fetchReportLogConversation]),
  ]);
}
