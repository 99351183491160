import axios from 'axios';
import { omit } from 'lodash';
import store from '../rootStore';
import 'webrtc-adapter';
import VoiceActions from './voice.actions';
import SIP, { UA } from 'sip.js';

const voiceApiDomain = import.meta.env.VITE_APP_NEW_VOICE_DOMAIN_API;

class VoiceService {
  constructor() {
    this.userAgent = null;
    this.sessions = {};
    this.callIds = {};

    this.timer = null;
    this.defaultProps = {};
    this.dtmfOpt = {
      duration: 160,
      interToneGap: 1200,
    };
  }

  setSession = (callId, sessionId = '') => {
    const rtcCallId = Object.keys(this.callIds).find((item) => item.includes(callId));
    console.log('🚀 ~ file: voice.service.js ~ line 24 ~ VoiceService ~ rtcCallId', rtcCallId);
    console.log(
      `%ccallId: ${callId} - sessionId: ${sessionId} 🐰`,
      'background: #000; color: #fff; padding: 8px; border-radius: 10px;',
    );

    if (rtcCallId) {
      this.sessions[sessionId] = this.callIds[rtcCallId];
    }
  };

  getRTCCall = (id) => {
    const session = this.sessions[id]; // id = sessionId
    if (session) {
      return session;
    }
    const RTCCallId = Object.keys(this.callIds).find((item) => item.includes(id)); // id = callId
    if (RTCCallId) {
      return this.callIds[RTCCallId];
    }
    return null;
  };

  updateStatus = (data, tState) => {
    console.log(`%c${tState} 🐶`, 'background: green; color: #fff; padding: 8px; border-radius: 10px;');
    if (tState === 'INCOMING_CALL') {
      console.log(`%cINBOUND 🐰`, 'background: #000; color: #fff; padding: 8px; border-radius: 10px;');
      console.log(
        `%cRTCId: ${data?.request.call_id} 👻`,
        'background: red; color: #fff; padding: 8px; border-radius: 10px;',
      );

      const RTCId = data.request.call_id;
      this.callIds[RTCId] = data;
      store.dispatch(VoiceActions.setSession());
      // Listen Session Events
      this.listenForSessionEvents(RTCId);
    }
  };

  // doConnect = (config) => {
  //   console.log('🚀 ~ file: voice.service.js:65 ~ VoiceService ~ config:', config);
  //   this.userAgent = new SIP.UA(config);
  //   console.log('🚀 ~ file: voice.service.js:66 ~ VoiceService ~ userAgent:', this.userAgent);
  //   this.userAgent.start();

  //   this.userAgent.on('connecting', () => store.dispatch(VoiceActions.apDialerStatus('CONNECTING')));
  //   this.userAgent.on('connected', () => store.dispatch(VoiceActions.apDialerStatus('CONNECTED')));
  //   this.userAgent.on('disconnected', () => store.dispatch(VoiceActions.apDialerStatus('DISCONNECTED')));
  //   this.userAgent.on('registered', () => {
  //     console.log(`%cREGISTERED 🐶`, 'background: green; color: #fff; padding: 8px; border-radius: 10px;');
  //     store.dispatch(VoiceActions.registered('REGISTERED'));
  //   });
  //   this.userAgent.on('unregistered', (res, cause) => this.updateStatus(cause, 'UNREGISTERED'));
  //   this.userAgent.on('registrationFailed', (res, cause) => {
  //     console.log("🚀 ~ file: voice.service.js:79 ~ VoiceService ~ this.userAgent.on ~ cause:", res)
  //     return this.updateStatus(cause, 'REGISTRATION_FAILED');
  //   });
  //   this.userAgent.on('invite', (session) => this.updateStatus(session, 'INCOMING_CALL'));
  //   this.userAgent.on('message', (msg) => this.updateStatus(msg, 'MESSAGE'));
  // };
  handleControlMuteCall = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/mute-member-conference`, payload);
      if (response?.status === 202) return response?.data;
      console.log('🚀 ~ file: voice.service.js:91 ~ VoiceService ~ handleControlMuteCall= ~ response:', response);
    } catch (error) {}
  };

  inviteExternalCall = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/make-invite-conference`, payload);
      if (response.status === 200) return response?.data;
    } catch (error) {}
  };

  hangupExternalCall = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/remove-member-conference`, payload);
      if (response?.status === 202) return response?.data;
    } catch (error) {}
  };

  holdParticipant = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/hold-conference`, payload);
      if (response?.status === 202) return response?.data;
    } catch (error) {}
  };

  recordConference = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/listen-conference`, payload);
      if (response?.status === 202) return response?.data;
    } catch (error) {}
  };

  requestToJoinCall = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/make-invite-conference`, payload);
      if (response.status === 200) return response?.data;
    } catch (error) {}
  };

  getCallLog = async (payload) => {
    try {
      const response = await axios.post(`${voiceApiDomain}/call-controller/get-call-log`, payload);
      if (response.status === 200) return response?.data;
    } catch (error) {}
  };

  doConnect = async (config) => {
    console.log('🚀 ~ file: voice.service.js:65 ~ VoiceService ~ config:', config);
    this.userAgent = new UA(config);
    this.userAgent.start();
    this.userAgent.on('connecting', (res, cause) => {
      console.log('🚀 ~ file: voice.service.js:90 ~ VoiceService ~ this.userAgent.on ~ res:', res);
      store.dispatch(VoiceActions.apDialerStatus('CONNECTING'));
    });
    this.userAgent.on('connected', (res, cause) => {
      console.log('🚀 ~ file: voice.service.js:94 ~ VoiceService ~ this.userAgent.on ~ res:', res);
      return store.dispatch(VoiceActions.apDialerStatus('CONNECTED'));
    });
    this.userAgent.on('disconnected', (res, cause) => {
      console.log('🚀 ~ file: voice.service.js:98 ~ VoiceService ~ this.userAgent.on ~ res:', res);
      return store.dispatch(VoiceActions.apDialerStatus('DISCONNECTED'));
    });
    this.userAgent.on('registered', (res, cause) => {
      console.log('🚀 ~ file: voice.service.js:93 ~ VoiceService ~ this.userAgent.on ~ res:', res);
      console.log(`%cREGISTERED 🐶`, 'background: green; color: #fff; padding: 8px; border-radius: 10px;');
      return store.dispatch(VoiceActions.registered('REGISTERED'));
    });
    this.userAgent.on('unregistered', (res, cause) => this.updateStatus(cause, 'UNREGISTERED'));
    this.userAgent.on('registrationFailed', (res, cause) => {
      console.log('🚀 ~ file: voice.service.js:79 ~ VoiceService ~ this.userAgent.on ~ cause:', res);
      return this.updateStatus(cause, 'REGISTRATION_FAILED');
    });
    this.userAgent.on('invite', (session) => this.updateStatus(session, 'INCOMING_CALL'));
    this.userAgent.on('message', (msg) => this.updateStatus(msg, 'MESSAGE'));
  };

  doRegister = () => {
    return;
    this.userAgent.register();
  };

  doUnRegister = () => {
    if (this.userAgent) this.userAgent.unregister();
  };

  doCall = (uri, options) => {
    store.dispatch(VoiceActions.apDialerStatus('TRYING'));
    const data = this.userAgent.invite(uri, {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
      ...options,
    });
    console.log(`%cOUTBOUND 🐰`, 'background: #000; color: #fff; padding: 8px; border-radius: 10px;');
    console.log(
      `%cRTCId: ${data?.request.call_id} 👻`,
      'background: red; color: #fff; padding: 8px; border-radius: 10px;',
    );

    const RTCId = data.request.call_id;
    this.callIds[RTCId] = data;
    store.dispatch(VoiceActions.setSession());

    // Listen Session Events
    this.listenForSessionEvents(RTCId);
  };

  // ======== SESSION ========
  listenForSessionEvents = (callId) => {
    this.callIds[callId].on('accepted', () => {
      console.log(`%cAccepted 🐶`, 'background: green; color: #fff; padding: 8px; border-radius: 10px;');
      store.dispatch(VoiceActions.apDialerStatus('TALKING'));
      const domElement = document.getElementById('remoteMedia');
      const pc = this.callIds[callId].sessionDescriptionHandler.peerConnection;
      const remoteStream = new MediaStream();
      pc.getReceivers().forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
      domElement.srcObject = remoteStream;
      domElement.play();
    });
    this.callIds[callId].on('progress', () => store.dispatch(VoiceActions.apDialerStatus('RINGING')));
    this.callIds[callId].on('rejected', (_, cause) => this.updateStatus(cause, 'REJECTED'));
    this.callIds[callId].on('failed', (_, cause) => this.updateStatus(cause, 'FAILED'));
    this.callIds[callId].on('terminated', (_, cause) => {
      this.callIds = omit(this.callIds, callId);
      Object.keys(this.sessions).forEach((sessionId) => {
        const session = this.sessions[sessionId];
        if (session.id === callId) {
          this.sessions = omit(this.sessions, sessionId);
        }
      });
      console.log('TERMINATED', callId);
      this.updateStatus(cause, 'TERMINATED');
    });
    this.callIds[callId].on('cancel', () => this.updateStatus(null, 'CANCELLED'));
    this.callIds[callId].on('refer', (req) => this.updateStatus(req, 'REFER'));
    this.callIds[callId].on('replaced', (session) => this.updateStatus(session, 'REPLACED'));
    this.callIds[callId].on('dtmf', (_, dtmf) => this.updateStatus(dtmf, 'DTMF'));
    this.callIds[callId].on('muted', () => this.updateStatus(null, 'MUTE'));
    this.callIds[callId].on('unmuted', () => this.updateStatus(null, 'UNMUTE'));
    this.callIds[callId].on('bye', (_) => {
      this.updateStatus(null, 'BYE');
      // Clear timer
      clearInterval(this.timer);
    });
  };

  // Cancel the call while calling outbound
  cancelCall = (sessionId) => {
    const session = this.sessions[sessionId];
    console.log('session endCall', session);

    if (session) {
      session.cancel();
    }
  };

  // Hang up the call
  endCall = (sessionId) => {
    const session = this.sessions[sessionId];
    if (session) {
      session.terminate();
    }
  };

  sendDtmf = (sessionId, dtmf) => {
    const session = this.sessions[sessionId];
    if (session) {
      session.dtmf(dtmf, this.dtmfOpt);
    }
  };

  changeMuteCallStatus = (sessionId, status) => {
    const session = this.sessions[sessionId];
    if (session) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach((sender) => {
        sender.track.enabled = !status;
      });
    }
  };

  muteCall = (sessionId) => {
    this.changeMuteCallStatus(sessionId, true);
  };

  unmuteCall = (sessionId) => {
    this.changeMuteCallStatus(sessionId, false);
  };

  acceptCall = (sessionId, config) => {
    const session = this.sessions[sessionId];
    if (session) {
      console.log('🚀 ~ file: voice.service.js ~ line 181 ~ VoiceService ~ session', session.status, session);
      session.accept({
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
        ...config,
      });
    }
  };

  stopStream = (sessionId) => {
    const session = this.sessions[sessionId];
    if (session) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach((sender) => {
        sender.track.stop();
      });
    }
  };

  stopConnect = () => {
    this.userAgent.stop();
  };

  fetchLogs = (userId) => axios.get(`/calling/logs/${userId}`);
}

const voiceService = new VoiceService();
export default voiceService;

window.voiceService = voiceService;
