import WorkflowAction from './workflow.actions';
import WorkflowUtils from './workflow.utils';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  executing: false,
  error: null,
};

const workflowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WorkflowAction.ADD_WORKFLOW:
      return {
        ...state,
        data: WorkflowUtils.normalizeWorkflow(state.data, action.payload),
      };
    case WorkflowAction.EXECUTE_WORKFLOW.START:
      return {
        ...state,
        executing: true,
        error: null,
      };
    case WorkflowAction.EXECUTE_WORKFLOW.SUCCESS:
      return {
        ...state,
        executing: false,
        error: null,
      };
    case WorkflowAction.EXECUTE_WORKFLOW.FAILURE:
      return {
        ...state,
        executing: false,
        error: action.payload,
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default workflowReducer;
