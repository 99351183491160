export default class UserAction {
  static UserActionTypes = {
    // fetch current user
    FETCH_CURRENT_USER_START: 'User/FETCH_CURRENT_USER_START',
    FETCH_USER: 'User/FETCH_CURRENT_USER_SUCCESS',

    // Update User
    UPDATE_USER_START: 'User/UPDATE_USER_START',
    UPDATE_USER_SUCCESS: 'User/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'User/UPDATE_USER_FAILURE',

    // Fetch User
    FETCH_USER_START: 'User/FETCH_USER_START',
    FETCH_USER_SUCCESS: 'User/FETCH_USER_SUCCESS',
    FETCH_USER_FAILURE: 'User/FETCH_USER_FAILURE',

    // Update plan
    UPDATE_PLAN: 'User/UPDATE_PLAN',
    // user logout
    USER_LOGOUT_START: 'User/USER_LOGOUT_START',
    USER_LOGOUT: 'User/USER_LOGOUT',
    // local register
    LOCAL_REGISTER_START: 'User/LOCAL_REGISTER_START',
    LOCAL_REGISTER: 'User/LOCAL_REGISTER',
    // local login
    LOCAL_LOGIN_START: 'User/LOCAL_LOGIN_START',
    LOCAL_LOGIN: 'User/LOCAL_LOGIN',
    // local login
    REQUEST_DEMO: 'User/REQUEST_DEMO',
    // fetch same domian email
    FETCH_SAME_DOMAIN_EMAILS_START: 'User/FETCH_SAME_DOMAIN_EMAILS_START',
    FETCH_SAME_DOMAIN_EMAILS: 'User/FETCH_SAME_DOMAIN_EMAILS',
    // user auth error
    USER_AUTH_ERROR: 'User/USER_AUTH_ERROR',
    // making a payment
    HANDLE_TOKEN_START: 'User/HANDLE_TOKEN_START',
    // update plan
    HANDLE_UPDATE_PLAN_START: 'User/HANDLE_UPDATE_PLAN_START',
    // resend confirmation email
    HANDLE_RESEND_CONFIRMATION_EMAIL_START: 'User/HANDLE_RESEND_CONFIRMATION_EMAIL_START',
    // forgot password email
    HANDLE_SEND_FORGOT_PASSWORD_EMAIL_START: 'User/HANDLE_SEND_FORGOT_PASSWORD_EMAIL_START',
    // reset password
    HANDLE_RESET_PASSWORD_START: 'User/HANDLE_RESET_PASSWORD_START',
    // get user list
    FETCH_USER_LIST_BY_MEMBERS_START: 'User/FETCH_USER_LIST_BY_MEMBERS_START',
    FETCH_USER_LIST_BY_MEMBERS_SUCCESS: 'User/FETCH_USER_LIST_BY_MEMBERS_SUCCESS',
    FETCH_USER_LIST_BY_MEMBERS_FAILURE: 'User/FETCH_USER_LIST_BY_MEMBERS_FAILURE',

    FETCH_USER_PLAN_START: 'User/FETCH_USER_PLAN_START',
    FETCH_USER_PLAN_SUCCESS: 'User/FETCH_USER_PLAN_SUCCESS',
    FETCH_USER_PLAN_FAILURE: 'User/FETCH_USER_PLAN_FAILURE',

    FETCH_USER_BY_IDS: 'User/FETCH_USER_BY_IDS',
    SET_TOKEN: 'User/SET_TOKEN',
    UPDATE_USER_FIELD: 'User/UPDATE_USER_FIELD',

    COUNT_PLAN: 'User/COUNT_PLAN',

    // Online User
    UPDATE_ONLINE_USER: 'User/UPDATE_ONLINE_USER',
    DELETE_ONLINE_USER: 'User/DELETE_ONLINE_USER',
    GET_ONLINE_USER: 'User/GET_ONLINE_USER',

    VERIFY_PHONE_NUMBER_START: 'User/VERIFY_PHONE_NUMBER_START',
    GET_VERIFY_PHONE_NUMBER_START: 'User/GET_VERIFY_PHONE_NUMBER_START',
    GET_VERIFY_PHONE_NUMBER_STATUS: 'User/GET_VERIFY_PHONE_NUMBER_STATUS',
    VERIFY_PHONE_GET_NEW_TOKEN: 'User/VERIFY_PHONE_GET_NEW_TOKEN',
  };

  static getOnlineUser() {
    return {
      type: UserAction.UserActionTypes.GET_ONLINE_USER,
    };
  }

  static updateOnlineUser(userIds) {
    return {
      type: UserAction.UserActionTypes.UPDATE_ONLINE_USER,
      payload: userIds,
    };
  }

  static deleteOnlineUser(userIds) {
    return {
      type: UserAction.UserActionTypes.DELETE_ONLINE_USER,
      payload: userIds,
    };
  }

  static countPlan(planFeatureName, inc, ownerId, callback) {
    return {
      type: UserAction.UserActionTypes.COUNT_PLAN,
      payload: { planFeatureName, inc, ownerId },
      callback,
    };
  }

  static fetchUserPlan(userId) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_PLAN_START,
      payload: userId,
    };
  }

  static fetchUserPlanSuccess(data) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_PLAN_SUCCESS,
      payload: data,
    };
  }

  static fetchUserPlanFailure(errorMessage) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_PLAN_FAILURE,
      payload: errorMessage,
    };
  }

  static fetchUserByIds(userIds) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_BY_IDS,
      payload: userIds,
    };
  }

  static setToken(token, sessionId) {
    return {
      type: UserAction.UserActionTypes.SET_TOKEN,
      payload: {
        token,
        sessionId,
      },
    };
  }

  static fetchUserList(users) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_START,
      payload: users,
    };
  }

  static fetchUserListSuccess(users) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_SUCCESS,
      payload: users,
    };
  }

  static updateUserField(id, key, value) {
    return {
      type: UserAction.UserActionTypes.UPDATE_USER_FIELD,
      payload: { id, key, value },
    };
  }

  static fetchUserListFailure(errorMessage) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_FAILURE,
      payload: errorMessage,
    };
  }

  // local register
  static initiateLocalRegisterStart(credentials, callback) {
    return {
      type: UserAction.UserActionTypes.LOCAL_REGISTER_START,
      payload: credentials,
      callback,
    };
  }

  // local login
  static initiateLocalLoginStart(credentials, callback) {
    return {
      type: UserAction.UserActionTypes.LOCAL_LOGIN_START,
      payload: credentials,
      callback,
    };
  }

  // local register
  static initiateRequestDemoStart(payload, callback) {
    return {
      type: UserAction.UserActionTypes.REQUEST_DEMO,
      payload,
      callback,
    };
  }

  static localLoginSuccess(currentUser, token, sessionId) {
    return {
      type: UserAction.UserActionTypes.LOCAL_LOGIN,
      payload: {
        currentUser,
        token,
        sessionId,
      },
    };
  }

  // making a payment ||  handle token
  static handleTokenStart(token, purchaseAmount) {
    return {
      type: UserAction.UserActionTypes.HANDLE_TOKEN_START,
      payload: { token, purchaseAmount },
    };
  }

  // update plan
  static updatePlanStart(token, purchaseAmount, plans) {
    return {
      type: UserAction.UserActionTypes.HANDLE_UPDATE_PLAN_START,
      payload: { token, purchaseAmount, plans },
    };
  }

  static updatePlanSuccess(plans) {
    return {
      type: UserAction.UserActionTypes.UPDATE_PLAN,
      payload: plans,
    };
  }

  // logout user
  static userLogoutStart() {
    return {
      type: UserAction.UserActionTypes.USER_LOGOUT_START,
    };
  }

  static userLogoutSuccess() {
    return {
      type: UserAction.UserActionTypes.USER_LOGOUT,
    };
  }

  // fetch current user
  static fetchCurrentUserStart(callback) {
    return {
      type: UserAction.UserActionTypes.FETCH_CURRENT_USER_START,
      payload: { callback },
    };
  }

  static fetchCurrentUserSuccess(data) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_SUCCESS,
      payload: data,
    };
  }

  // update user info
  static updateUserStart(payload) {
    return {
      type: UserAction.UserActionTypes.UPDATE_USER_START,
      payload,
    };
  }

  static updateUserSuccess(payload) {
    return {
      type: UserAction.UserActionTypes.UPDATE_USER_SUCCESS,
      payload,
    };
  }

  static updateUserFailure(payload) {
    return {
      type: UserAction.UserActionTypes.UPDATE_USER_FAILURE,
      payload,
    };
  }

  // fetch same domain user list or fetch same domian email
  static fetchSameDomainUsersListStart() {
    return {
      type: UserAction.UserActionTypes.FETCH_SAME_DOMAIN_EMAILS_START,
    };
  }

  static fetchSameDomainUsersListAsync(emailObj) {
    return {
      type: UserAction.UserActionTypes.FETCH_SAME_DOMAIN_EMAILS,
      payload: emailObj,
    };
  }

  // fetch user
  static fetchUserStart() {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_START,
    };
  }

  static fetchUserSuccess(userMap) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_SUCCESS,
      payload: userMap,
    };
  }

  static fetchUserFailure(errorMessage) {
    return {
      type: UserAction.UserActionTypes.FETCH_USER_FAILURE,
      payload: errorMessage,
    };
  }

  // update user auth error
  static updatingUserAuthError(error, email, cell_phone, id) {
    return {
      type: UserAction.UserActionTypes.USER_AUTH_ERROR,
      payload: [error, email, cell_phone, id],
    };
  }

  // resend confirmation email
  static resendConfirmationEmailStart(email) {
    return {
      type: UserAction.UserActionTypes.HANDLE_RESEND_CONFIRMATION_EMAIL_START,
      payload: email,
    };
  }

  // forgot password email
  static sendForgotPasswordEmailStart(email, callback) {
    return {
      type: UserAction.UserActionTypes.HANDLE_SEND_FORGOT_PASSWORD_EMAIL_START,
      payload: email,
      callback,
    };
  }

  // reset password
  static resetPasswordStart(token, password) {
    return {
      type: UserAction.UserActionTypes.HANDLE_RESET_PASSWORD_START,
      payload: { token, password },
    };
  }

  static verifyPhoneNumber(payload, callback) {
    return {
      type: UserAction.UserActionTypes.VERIFY_PHONE_NUMBER_START,
      payload,
      callback,
    };
  }

  static getVerifyPhoneNumber(payload, callback) {
    return {
      type: UserAction.UserActionTypes.GET_VERIFY_PHONE_NUMBER_START,
      payload,
      callback,
    };
  }
  static getVerifyPhoneNumberStatus(payload, callback) {
    return {
      type: UserAction.UserActionTypes.GET_VERIFY_PHONE_NUMBER_STATUS,
      payload,
      callback,
    };
  }
  static getNewPhoneVerifyToken(payload, callback) {
    return {
      type: UserAction.UserActionTypes.VERIFY_PHONE_GET_NEW_TOKEN,
      payload,
      callback,
    };
  }
}
