import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import broadcastReducer from './broadcast/broadcast.reducers';
import contactReducer from './contact/contact.reducer';
import conversationReducer from './conversation/conversation.reducers';
import fieldReducer from './field/field.reducer';
import didReducer from './did/did.reducer';
import groupsReducer from './group/group.reducers';
import homeReducer from './home/home.reducer';
import messageReducer from './message/message.reducer';
import listReducer from './list/list.reducer';
import planReducer from './plan/plan.reducers';
// import socketReducer from "../reducers/socket/socket.reducer";
import socketReducer from './socket/socket.reducer';
import userReducer from './user/user.reducers';
import memberReducer from './member/member.reducer';
import fieldValueReducer from './field-value/field-value.reducer';
import VoiceReducer from './voice/voice.reducers';
import dncReducer from './dnc/dnc.reducer';
import campaignReducer from './campaign/campaign.reducers';
import workflowReducer from './workflow/workflow.reducers';
import cdrReducer from './cdr/cdr.reducer';
import mergeLogReducer from './merge-logs/merge-logs.reducer';
import reportReducer from './report/report.reducer';
import sessionLogReducer from './session-log/session-log.reducer';
import fieldLogReducer from './field-log/field-log.reducer';
import templateReducer from './template/template.reducers';
import filterReducer from './filter/filter.reducer';
import membersAdminReducer from './member-admin/member-admin.reducer';
import brandReducer from './brand/brand.reducers';
import fileReducer from './file/file.reducer';
import identifyReducer from './identify/identify.reducer';

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['token', 'sessionId'],
};
const contactPersistConfig = {
  key: 'contacts',
  storage,
  whitelist: ['filterColumnContact'],
};

const rootReducer = combineReducers({
  brands: brandReducer,
  broadcasts: broadcastReducer,
  campaign: campaignReducer,
  cdrs: cdrReducer,
  contacts: persistReducer(contactPersistConfig, contactReducer),
  conversations: conversationReducer,
  dids: didReducer,
  dnc: dncReducer,
  fieldLogs: fieldLogReducer,
  fieldValues: fieldValueReducer,
  fields: fieldReducer,
  filters: filterReducer,
  groups: groupsReducer,
  home: homeReducer,
  lists: listReducer,
  memberAdmins: membersAdminReducer,
  members: memberReducer,
  mergeLogs: mergeLogReducer,
  messages: messageReducer,
  plans: planReducer,
  reports: reportReducer,
  sessionLogs: sessionLogReducer,
  socket: socketReducer,
  templates: templateReducer,
  user: persistReducer(userPersistConfig, userReducer),
  voice: VoiceReducer,
  workflows: workflowReducer,
  files: fileReducer,
  identify: identifyReducer,
});

export default rootReducer;
