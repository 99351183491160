import { groupBy, isEqual, omit, uniq } from 'lodash';

export default class FieldUtils {
  static updateField(fields, newField) {
    const { byId, allIds } = fields;
    const field = byId[newField.id];

    if (!field) return fields;
    return {
      byId: { ...byId, [newField.id]: newField },
      allIds,
    };
  }

  static normalizeFields(state, fields) {
    const byId = groupBy(fields, 'id');
    const allIds = Object.keys(byId);
    allIds.map((id) => {
      byId[id] = byId[id][0];
    });
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: uniq([...state.data.allIds, ...allIds]),
    };
  }

  static addField(fields, newField) {
    const { byId, allIds } = fields;
    const { id } = newField;
    return {
      byId: { ...byId, [id]: newField },
      allIds: [...allIds, id],
    };
  }

  static deleteField(fields, deletedField) {
    const { byId, allIds } = fields;
    const { id } = deletedField;
    return {
      byId: omit(byId, [id]),
      allIds: allIds.filter((fieldId) => !isEqual(fieldId, id)),
    };
  }
}
