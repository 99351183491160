export default class FilterAction {
  static FETCH_FILTERS = {
    START: 'FilterAction/FETCH_FILTERS_START',
    SUCCESS: 'FilterAction/FETCH_FILTERS_SUCCESS',
    FAILURE: 'FilterAction/FETCH_FILTERS_FAILURE',
  };

  static UPDATE_FILTER = {
    START: 'FilterAction/UPDATE_FILTER_START',
    SUCCESS: 'FilterAction/UPDATE_FILTER_SUCCESS',
    FAILURE: 'FilterAction/UPDATE_FILTER_FAILURE',
  };

  static DELETE_FILTER = {
    START: 'FilterAction/DELETE_FILTER_START',
    SUCCESS: 'FilterAction/DELETE_FILTER_SUCCESS',
    FAILURE: 'FilterAction/DELETE_FILTER_FAILURE',
  };

  static APPLY_FILTER = {
    START: 'FilterAction/APPLY_FILTER_START',
    SUCCESS: 'FilterAction/APPLY_FILTER_SUCCESS',
    FAILURE: 'FilterAction/APPLY_FILTER_FAILURE',
  };

  static SET_SELECTED_FILTER = 'FilterAction/SET_SELECTED_FILTER';

  static applyFilters = (contactIds, filter) => ({
    type: FilterAction.APPLY_FILTER.START,
    payload: { contactIds, filter },
  });

  static applyFiltersSuccess = (payload) => ({
    type: FilterAction.APPLY_FILTER.SUCCESS,
    payload,
  });

  static applyFiltersFailure = (errorMessage) => ({
    type: FilterAction.APPLY_FILTER.FAILURE,
    payload: errorMessage,
  });

  static setSelectedFilter = (payload) => ({
    type: FilterAction.SET_SELECTED_FILTER,
    payload,
  });

  static fetchFilters = () => ({
    type: FilterAction.FETCH_FILTERS.START,
  });

  static fetchFiltersSuccess = (payload) => ({
    type: FilterAction.FETCH_FILTERS.SUCCESS,
    payload,
  });

  static fetchFiltersFailure = (errorMessage) => ({
    type: FilterAction.FETCH_FILTERS.FAILURE,
    payload: errorMessage,
  });

  static createNewFilter = (body, callback) => ({
    type: FilterAction.CREATE_FILTER.START,
    payload: body,
    callback,
  });

  static createNewFilterSuccess = (data) => ({
    type: FilterAction.CREATE_FILTER.SUCCESS,
    payload: data,
  });

  static createNewFilterFailure = (errorMessage) => ({
    type: FilterAction.CREATE_FILTER.FAILURE,
    payload: errorMessage,
  });

  static updateFilter = (body, callback) => ({
    type: FilterAction.UPDATE_FILTER.START,
    payload: body,
    callback,
  });

  static updateFilterSuccess = (data) => ({
    type: FilterAction.UPDATE_FILTER.SUCCESS,
    payload: data,
  });

  static updateFilterFailure = (errorMessage) => ({
    type: FilterAction.UPDATE_FILTER.FAILURE,
    payload: errorMessage,
  });

  static deleteFilter = (body) => ({
    type: FilterAction.DELETE_FILTER.START,
    payload: body,
  });

  static deleteFilterSuccess = (filterIds) => ({
    type: FilterAction.DELETE_FILTER.SUCCESS,
    payload: filterIds,
  });

  static deleteFilterFailure = (errorMessage) => ({
    type: FilterAction.DELETE_FILTER.FAILURE,
    payload: errorMessage,
  });
}
