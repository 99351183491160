import FieldLogUtil from './field-log.utils';
import UserAction from '../user/user.actions';
import FieldLogAction from './field-log.actions';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  fetching: true,
  errorMessage: null,
};

const fieldLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.START:
      return {
        ...state,
        fetching: true,
        errorMessage: null,
      };
    case FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.SUCCESS:
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        data: FieldLogUtil.normalize(state, action.payload),
      };
    case FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default fieldLogReducer;
