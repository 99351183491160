import { groupBy } from 'lodash';

export default class BroadCastUtil {
  static getUpdatedReport = (report, payload, broadcast_id) => {
    if (!broadcast_id || !report || broadcast_id !== payload.broadcast_id) {
      return report;
    }

    let reportData = report.broadcasts;
    reportData = reportData.map((data) => {
      if (data.id !== payload.id) {
        return data;
      }
      return {
        ...data,
        status: payload.status,
      };
    });

    return {
      ...report,
      broadcasts: reportData,
    };
  };

  static normalizeBroadcast(broadcasts) {
    const byId = groupBy(broadcasts, 'id');
    const allIds = Object.keys(byId);
    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });
    return { byId, allIds };
  }
}
