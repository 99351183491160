import { takeLatest, put } from 'redux-saga/effects';
import HomeAction from './home.actions';
import GroupAction from '../group/group.actions';
import ConversationAction from '../conversation/conversation.actions';
import DidAction from '../did/did.actions';
import ContactAction from '../contact/contact.actions';
import ListAction from '../list/list.actions';
import MessageAction from '../message/message.actions';

export function* setNavLink({ payload }) {
  const link = payload;
  try {
    if (link === 'chats') {
      yield put(MessageAction.updateNewMessage(false));
      yield put(ConversationAction.fetchChatStart());
    } else if (link === 'groups') {
      yield put(GroupAction.fetchGroups());
    } else if (link === 'dids') {
      yield put(DidAction.fetchDids());
    } else if (link === 'contacts') {
      // yield put(ContactAction.fetchContacts());
    } else if (link === 'lists') {
      yield put(ListAction.fetchLists());
    }
  } catch (error) {
    yield put(GroupAction.fetchGroupFailure(error.message));
    console.log({ error });
  }
}

export default function* homeSagaFlow() {
  yield takeLatest(HomeAction.SET_NAV_LINK, setNavLink);
}
