import axios from 'axios';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { get, isEmpty, isEqual, pick, uniq } from 'lodash';
import { toast } from 'react-toastify';

import GroupAction from './group.actions';
import { allFieldCallSeting } from '../../constant/group';
import ConversationAction from '../conversation/conversation.actions';
import DidAction from '../did/did.actions';
import UserAction from '../user/user.actions';
import MemberAction from '../member/member.actions';
import FieldAction from '../field/field.actions';
import ContactAction from '../contact/contact.actions';
import TemplateAction from '../template/template.actions';
import WorkflowAction from '../workflow/workflow.actions';
import MessageAction from '../message/message.actions';

export function* fetchGroupById({ payload: groupId, callback = () => {} }) {
  try {
    const response = yield call(axios.get, `/group/${groupId}`);
    if (response && response.data) {
      const group = response.data;

      const ownedUsers = [group._user];
      const members = group.members || [];
      const fields = group.fields || [];
      const workflows = group.workflows || [];
      const templates = group.templates || [];

      yield put(TemplateAction.addTemplate(templates));
      yield put(WorkflowAction.addWorkflow(workflows));

      if (Array.isArray(members) && !isEqual(members.length, 0)) {
        const members_data = yield call(axios.get, '/member', { params: { memberIds: members } });
        const users_data = yield call(axios.get, '/user/member_ids', { params: { memberIds: members } });
        if (members_data.data && users_data.data) {
          yield put(UserAction.fetchUserListSuccess(users_data.data));
          yield put(MemberAction.fetchGroupMemberSuccess(members_data.data));
        }
      }

      if (Array.isArray(ownedUsers) && !isEqual(ownedUsers.length, 0)) {
        const ownerUsers_data = yield call(axios.get, '/user/user_ids', { params: { userIds: ownedUsers } });
        if (ownerUsers_data.data) {
          yield put(UserAction.fetchUserListSuccess(ownerUsers_data.data));
        }
      }

      if (Array.isArray(fields) && !isEqual(fields.length, 0)) {
        const fields_data = yield call(axios.post, '/field/field_ids', { fields });
        if (fields_data.data) {
          yield put(FieldAction.fetchFieldsSuccess(fields_data.data));
        }
      }
      yield put(GroupAction.fetchGroupByIdSuccess(group));
      callback(null, group);
    }
  } catch (error) {
    console.log('🚀 ~ file: group.saga.js:59 ~ function*fetchGroupById ~ error:', error);
    yield put(GroupAction.fetchGroupByIdFailure(error));
    callback(error, null);
  }
}

export function* fetchGroups({ payload, callback = () => {} }) {
  try {
    const response = yield call(axios.get, '/group');
    if (response && response.data) {
      const { member_groups = [], owned_groups = [], shared_groups = [] } = response.data;
      const groups = {};
      owned_groups.forEach((item) => {
        groups[item.id] = { ...item, role: 'owner' };
      });
      member_groups.forEach((item) => {
        if (!groups[item.id]) {
          groups[item.id] = { ...item, role: 'member' };
        }
      });
      shared_groups.forEach((item) => {
        if (!groups[item.id]) {
          groups[item.id] = { ...item, role: 'shared' };
        }
      });

      let ownedUsers = [];
      let members = [];
      let fields = [];
      let workflows = [];
      let templates = [];

      for (const group of Object.values(groups)) {
        ownedUsers = Array.isArray(ownedUsers) ? [...ownedUsers, group._user] : ownedUsers;
        members = Array.isArray(members) ? uniq([...members, ...group.members].filter(Boolean)) : members;
        fields = Array.isArray(fields) ? uniq([...fields, ...group.fields].filter(Boolean)) : fields;
        workflows = uniq([...workflows, ...group.workflows].filter(Boolean));
        templates = uniq([...templates, ...group.templates].filter(Boolean));
      }

      if (Array.isArray(members) && !isEqual(members.length, 0)) {
        const members_data = yield call(axios.get, '/member', { params: { memberIds: members } });
        const users_data = yield call(axios.get, '/user/member_ids', { params: { memberIds: members } });
        if (members_data.data && users_data.data) {
          yield put(UserAction.fetchUserListSuccess(users_data.data));
          yield put(MemberAction.fetchGroupMemberSuccess(members_data.data));
        }
      }

      if (Array.isArray(members) && isEqual(members.length, 0)) {
        yield put(UserAction.fetchUserListSuccess([]));
        yield put(MemberAction.fetchGroupMemberSuccess([]));
      }

      if (Array.isArray(ownedUsers) && !isEqual(ownedUsers.length, 0)) {
        const ownerUsers_data = yield call(axios.get, '/user/user_ids', { params: { userIds: ownedUsers } });
        if (ownerUsers_data.data) {
          yield put(UserAction.fetchUserListSuccess(ownerUsers_data.data));
        }
      }

      if (Array.isArray(fields) && !isEqual(fields.length, 0)) {
        const fields_data = yield call(axios.post, '/field/field_ids', { fields });
        if (fields_data.data) {
          yield put(FieldAction.fetchFieldsSuccess(fields_data.data));
        }
      }

      yield put(TemplateAction.addTemplate(templates));
      yield put(WorkflowAction.addWorkflow(workflows));

      yield put(GroupAction.fetchGroupSuccess(Object.values(groups)));
      callback(null, groups);
    }
  } catch (error) {
    console.log('🚀 ~ file: group.saga.js:134 ~ function*fetchGroups ~ error:', error);
    yield put(GroupAction.fetchGroupFailure(error.message));
    callback(error, null);
  }
}

export function* createNewGroup({ payload, callback }) {
  try {
    const {
      newGroupName,
      newGroupDescription,
      using_system_wide_dnc,
      newGroupNewMembers,
      newGroupDIDReRoute,
      memberRoles = [],
      addedMembers,
      livechat,
      fields,
      newUploadedAudios,
      workflows,
      templates,
      shared_message_history_with,
      color,
      roles,
      hideRecommendEmail,
    } = payload;

    const response = yield call(axios.post, '/group', {
      newGroupDIDReRoute,
      group_name: newGroupName,
      using_system_wide_dnc,
      memberRoles,
      group_description: newGroupDescription,
      members: newGroupNewMembers,
      addedMembers,
      // selected_fields,
      // checked_fields,
      fields,
      newUploadedAudios,
      workflows,
      livechat,
      templates,
      roles,
      ...pick(payload, allFieldCallSeting),
      shared_message_history_with,
      color,
      hideRecommendEmail,
    });
    yield put(UserAction.fetchCurrentUserStart());
    yield put(DidAction.fetchDids());
    if (fields) yield put(FieldAction.fetchFields());
    callback(null, response?.data);
  } catch (error) {
    callback(error);
    console.log('🚀 ~ file: group.saga.js:188 ~ function*createNewGroup ~ error:', error);
    yield put(GroupAction.fetchGroupFailure(error.message));
  }
}

export function* updateGroup({ payload }) {
  const { state, group_id, callback } = payload;
  try {
    const {
      newGroupName,
      using_system_wide_dnc,
      newGroupDescription,
      addedMembers,
      deletedMembers,
      memberRoles = [],
      modifiedUncheckedDIDGroup,
      newGroupDIDReRoute,
      // selected_fields,
      // checked_fields,
      fields,
      newUploadedAudios,
      workflows,
      templates,
      livechat,
      shared_message_history_with,
      color,
      roles,
      hideRecommendEmail,
      shareDataReport,
    } = state;

    const response = yield call(axios.patch, '/group', {
      modifiedUncheckedDIDGroup,
      newGroupDIDReRoute,
      memberRoles,
      group_id,
      group_name: newGroupName,
      using_system_wide_dnc,
      group_description: newGroupDescription,
      addedMembers,
      deletedMembers,
      fields,
      // fields: selected_fields,
      // checked_fields,
      templates,
      newUploadedAudios,
      workflows,
      livechat,
      roles,
      hideRecommendEmail,
      ...pick(state, allFieldCallSeting),
      shared_message_history_with,
      color,
      shareDataReport,
    });

    if (response.status === 200) {
      yield put(UserAction.fetchCurrentUserStart());
      // yield put(ContactAction.fetchContacts());
      yield put(DidAction.fetchDids());
      yield put(MessageAction.forceFetchMessage(true));
      if (fields) yield put(FieldAction.fetchFields());
      yield call(fetchGroups, true);
    }
    if (!isEmpty(response?.data?.cannotJoinGroupAsMember)) {
      callback(response?.data?.cannotJoinGroupAsMember);
    } else callback(null);
  } catch (error) {
    const message = error.response?.data?.msg || error.response?.data?.message || error.response?.data;
    callback(message);
    yield put(GroupAction.fetchGroupFailure(message));
    toast.error(error.response?.data?.msg || error.response?.data?.message);
  }
}

export function* acceptGroupRequest({ payload }) {
  try {
    yield call(axios.post, `/group/accept`, { id: payload });
    yield put(GroupAction.fetchGroups());
    yield put(ConversationAction.fetchChatStart());
    // yield put(ContactAction.fetchContacts());
    yield put(DidAction.fetchDids());
  } catch (error) {
    console.log('🚀 ~ file: group.saga.js:271 ~ function*acceptGroupRequest ~ error:', error);
    yield put(GroupAction.fetchGroupFailure(error.message));
  }
}

export function* rejectGroupRequest({ payload }) {
  try {
    yield call(axios.post, `/group/reject`, { id: payload });
    yield put(GroupAction.fetchGroups());
    yield put(ConversationAction.fetchChatStart());
    // yield put(ContactAction.fetchContacts());
    yield put(DidAction.fetchDids());
  } catch (error) {
    console.log('🚀 ~ file: group.saga.js:282 ~ function*rejectGroupRequest ~ error:', error);
    yield put(GroupAction.fetchGroupFailure(error.message));
  }
}

export function* deleteGroup({ payload, callback }) {
  try {
    yield call(axios.delete, `/group/${payload}`);
    yield put(DidAction.fetchDids());
    yield call(fetchGroups, true);
    callback(null);
    yield put(GroupAction.deleteGroupSuccess(payload));
  } catch (error) {
    yield put(GroupAction.fetchGroupFailure(error.message));
    if (get(error, 'response.data._conversation')) {
      yield put(
        ConversationAction.setConversationUsersStart({ conversationId: get(error, 'response.data._conversation') }),
      );
      yield put(MessageAction.fetchMessageStart({ key: get(error, 'response.data._conversation') }));
    }
    callback({
      message: get(error, 'response.data.message', error.message),
      close: !!get(error, 'response.data._conversation'),
    });
  }
}

export function* webhookTokenRequest({ payload, callback }) {
  try {
    const response = yield call(axios.get, `/group/webhook_token/${payload}`);
    yield put(GroupAction.fetchGroupByIdSuccess(response?.data?.dataValues));
    if (callback) callback(null, response);
  } catch (error) {
    if (callback)
      callback({
        message: get(error, 'response.data.message', error.message),
      });
  }
}

export function* getSuggestionForCampaign({ payload, callback }) {
  try {
    const voiceDomain = import.meta.env.VITE_APP_NEW_VOICE_DOMAIN_API;
    const response = yield call(axios.post, `${voiceDomain}/open-ai`, payload);
    if (response.status === 200) {
      const { data } = response;
      callback(null, data);
    }
  } catch (error) {
    if (callback)
      callback({
        message: get(error, 'response.data.message', error.message),
      });
  }
}

export default function* groupSagaFlow() {
  yield all([
    takeLatest(GroupAction.FETCH_GROUP.START, fetchGroups),
    takeLatest(GroupAction.FETCH_GROUP_BY_ID.START, fetchGroupById),
    takeLatest(GroupAction.CREATE_NEW_GROUP, createNewGroup),
    takeLatest(GroupAction.ACCEPT_GROUP_REQUEST, acceptGroupRequest),
    takeLatest(GroupAction.REJECT_GROUP_REQUEST, rejectGroupRequest),
    takeLatest(GroupAction.UPDATE_GROUP, updateGroup),
    takeLatest(GroupAction.DELETE_GROUP, deleteGroup),
    takeLatest(GroupAction.WEBHOOK_TOKEN_REQUEST, webhookTokenRequest),
    takeLatest(GroupAction.GET_CAMPAIGN_SUGGESTION.START, getSuggestionForCampaign),
  ]);
}
