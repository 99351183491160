import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import ListAction from './list.actions';

const defaultCallback = () => {};

export function* fetchLists() {
  try {
    const response = yield call(axios.get, '/list');
    if (response && response.data) {
      yield put(ListAction.fetchListsSuccess(response.data));
    } else {
      yield put(ListAction.fetchListsFailure('Fetch lists failed'));
    }
  } catch (error) {
    yield ListAction.fetchListsFailure(error.message);
    console.log({ error });
  }
}

export function* uploadList({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/list/uploadList', payload);
    if (response && response.data) {
      // yield put(ListAction.uploadListSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Upload List failed');
    }
  } catch (error) {
    console.log('TCL - file: list.saga.js:34 - function*uploadList - error:', error);
    yield ListAction.uploadListFailure(error.message);
    if (callback) {
      callback(error);
    }
  }
}

export function* replaceList({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/list/replaceList', payload);
    if (response && response.data) {
      yield put(ListAction.replaceListSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Replace List failed');
    }
  } catch (error) {
    console.log({ error });
    yield ListAction.replaceListFailure(error.message);
    if (callback) {
      callback(error);
    }
  }
}

export function* executeCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/list/executeCampaign', payload);
    if (response && response.data) {
      yield put(ListAction.executeCampaignOnListSuccess(response.data));
      if (callback) {
        callback(null);
      }
    } else {
      throw new Error('Execute campaign failed');
    }
  } catch (error) {
    console.log({ error });
    yield ListAction.executeCampaignOnListFailure(error.message);
    if (callback) {
      callback(error);
    }
  }
}

export function* stopCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/list/stopCampaign', payload);
    if (response && response.data) {
      yield put(ListAction.stopCampaignOnListSuccess(response.data));
      if (callback) {
        callback(null);
      }
    } else {
      throw new Error('Stop campaign failed');
    }
  } catch (error) {
    console.log({ error });
    yield ListAction.stopCampaignOnListFailure(error.message);
    if (callback) {
      callback(error);
    }
  }
}

export function* createNewList({ payload, callback = defaultCallback }) {
  try {
    const response = yield call(axios.post, '/list', payload);
    yield put(ListAction.createNewListSuccess());
    yield call(fetchLists);
    if (callback) {
      callback(null, response.data);
    }
  } catch (error) {
    yield put(ListAction.createNewListFailure(error.message));
    if (callback) {
      callback(error, null);
    }
  }
}

export function* deleteList({ payload }) {
  try {
    const response = yield call(axios.delete, `/list/${payload.id}`);
    if (response.data) {
      yield put(ListAction.deleteListSuccess(response.data));
    }
  } catch (error) {
    yield put(ListAction.deleteListFailure(error.message));
    console.log({ error });
  }
}

export function* updateList({ payload, callback = defaultCallback }) {
  try {
    const response = yield call(axios.put, '/list', payload);
    if (response.data) {
      yield put(ListAction.updateListSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    yield put(ListAction.updateListFailure(error.message));
    if (callback) {
      callback(error);
    }
  }
}

export default function* listSagaFlow() {
  yield takeLatest(ListAction.FETCH_LISTS.START, fetchLists);
  yield takeLatest(ListAction.CREATE_NEW_LIST.START, createNewList);
  yield takeLatest(ListAction.EXECUTE_CAMPAIGN_ON_LIST.START, executeCampaign);
  yield takeLatest(ListAction.STOP_CAMPAIGN_ON_LIST.START, stopCampaign);
  yield takeLatest(ListAction.UPDATE_LIST.START, updateList);
  yield takeLatest(ListAction.DELETE_LIST.START, deleteList);
  yield takeLatest(ListAction.UPLOAD_LIST.START, uploadList);
  yield takeLatest(ListAction.REPLACE_LIST.START, replaceList);
}
