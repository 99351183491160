import axios from 'axios';
import qs from 'qs';
import { all } from 'redux-saga/effects';
import { messageFlow } from './message/message.saga';
import { conversationFlow } from './conversation/conversation.saga';
import contactSagaFlow from './contact/contact.saga';
import fieldSagaFlow from './field/field.saga';
import { broadCastFlow } from './broadcast/broadcast.saga';
import listSagaFlow from './list/list.saga';
import planSagaFlow from './plan/plan.saga';
import didSagaFlow from './did/did.saga';
import groupSagaFlow from './group/group.saga';
import { userFlow } from './user/user.saga';
import homeSagaFlow from './home/home.saga';
import { socketFlow } from './socket/socket.saga';
import memberSagaFlow from './member/member.saga';
import memberAdminSagaFlow from './member-admin/member-admin.saga';
import voiceFlow from './voice/voice.saga';
import { dncFlow } from './dnc/dnc.saga';
import { campaignFlow } from './campaign/campaign.saga';
import fieldValueSagaFlow from './field-value/field-value.saga';
import workflowSagaFlow from './workflow/workflow.saga';
import cdrSagaFlow from './cdr/cdr.saga';
import identifySagaFlow from './identify/identify.saga';
import {
  realtimeUserFlow,
  realtimeConversationFlow,
  realtimeMessageFlow,
  realtimeCallingFlow,
  realtimeContactFlow,
  realtimeGroupFlow,
  realtimeDidFlow,
  realtimeFieldValueFlow,
  realtimeFieldFlow,
  realtimeCampaignFlow,
  realtimeDNCFlow,
  realtimeCdrFlow,
  realtimeMergeLogFlow,
  realtimeMemberFlow,
} from './realtime';
import store from './rootStore';
import UserSelector from './user/user.selectors';
import mergeLogSagaFlow from './merge-logs/merge-logs.saga';
import reportSagaFlow from './report/report.saga';
import sessionLogSagaFlow from './session-log/session-log.saga';
import fieldLogSagaFlow from './field-log/field-log.saga';
import templateSagaFlow from './template/template.saga';
import filterSagaFlow from './filter/filter.saga';
import { brandCastFlow } from './brand/brand.saga';
import fileSagaFlow from './file/file.saga';
import { appInfo } from '../enums/app';
import { USER_LOGIN_PLATFORM } from '../enums/user';
import UserAction from './user/user.actions';

// Add a request interceptor
axios.interceptors.request.use(
  function (request) {
    const state = store.getState();
    const token = UserSelector.getAccessToken(state);
    const sessionId = UserSelector.getSessionId(state);
    // const Authorization = sessionStorage.getItem('Authorization');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    } else {
      request.headers.Authorization = null;
    }
    request.headers.deviceid = sessionId;
    request.headers.ipaddress = appInfo.publicIP;
    request.headers.platform = USER_LOGIN_PLATFORM.WEB;
    request.baseURL = import.meta.env.VITE_APP_REACT_APP_BACKEND_URL;
    request.paramsSerializer = (params) => qs.stringify(params);

    return request;
  },
  function (error) {
    Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      store.dispatch(UserAction.userLogoutSuccess());
    }
    if (error?.response?.status === 403) {
      window.location.href = '/403';
    }
    return Promise.reject(error);
  },
);

export default function* rootSaga() {
  yield all([
    conversationFlow(),
    contactSagaFlow(),
    fieldSagaFlow(),
    broadCastFlow(),
    messageFlow(),
    listSagaFlow(),
    planSagaFlow(),
    didSagaFlow(),
    groupSagaFlow(),
    userFlow(),
    homeSagaFlow(),
    socketFlow(),
    memberSagaFlow(),
    voiceFlow(),
    dncFlow(),
    campaignFlow(),
    workflowSagaFlow(),
    fieldValueSagaFlow(),
    mergeLogSagaFlow(),
    reportSagaFlow(),
    cdrSagaFlow(),
    sessionLogSagaFlow(),
    fieldLogSagaFlow(),
    templateSagaFlow(),
    filterSagaFlow(),
    memberAdminSagaFlow(),
    fileSagaFlow(),
    identifySagaFlow(),
    // realtime
    realtimeMessageFlow(),
    realtimeConversationFlow(),
    realtimeUserFlow(),
    realtimeCallingFlow(),
    realtimeContactFlow(),
    realtimeGroupFlow(),
    realtimeDidFlow(),
    realtimeFieldValueFlow(),
    realtimeFieldFlow(),
    realtimeCampaignFlow(),
    realtimeDNCFlow(),
    realtimeCdrFlow(),
    realtimeMergeLogFlow(),
    realtimeMemberFlow(),
    brandCastFlow(),
  ]);
}
