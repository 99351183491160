export const createAsyncAction = (type) => {
  const START_TYPE = `${type}_START`;
  const SUCCESS_TYPE = `${type}_SUCCESS`;
  const FAILURE_TYPE = `${type}_FAILURE`;

  const start = (payload, callback) => ({
    type: START_TYPE,
    payload,
    callback,
  });

  const success = (payload, callback) => ({
    type: SUCCESS_TYPE,
    payload,
    callback,
  });

  const failure = (payload, callback) => ({
    type: FAILURE_TYPE,
    error: payload,
    callback,
  });

  return {
    ACTION_TYPE: {
      START: START_TYPE,
      SUCCESS: SUCCESS_TYPE,
      FAILURE: FAILURE_TYPE,
    },
    start,
    success,
    failure,
  };
};
