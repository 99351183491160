export default class FieldLogAction {
  static FETCH_FIELD_LOG_BY_DATE_RANGE = {
    START: 'FieldAction/FETCH_FIELD_LOG_BY_DATE_RANGE_START',
    SUCCESS: 'FieldAction/FETCH_FIELD_LOG_BY_DATE_RANGE_SUCCESS',
    FAILURE: 'FieldAction/FETCH_FIELD_LOG_BY_DATE_RANGE_FAILURE',
  };

  static fetchFieldLogByDateRange = (dateRange, userId) => ({
    type: FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.START,
    payload: { dateRange, userId },
  });

  static fetchFieldLogByDateRangeSuccess = (body) => ({
    type: FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.SUCCESS,
    payload: body,
  });

  static fetchFieldLogByDateRangeFailure = (errorMessage) => ({
    type: FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.FAILURE,
    payload: errorMessage,
  });
}
