export const PLAN_TYPE_NAME = {
  LOW_VOLUME: 'Low Volume',
  MEDIUM_VOLUME: 'Medium Volume',
  HIGH_VOLUME: 'High Volume',
};

export const PLAN_CYCLE = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};

export const PLAN_NAME = {
  VOICE: 'Voice',
  SMS: 'SMS',
  DID: 'DID',
};

export const PLAN_FEATURE_NAME = {
  VOICE: 'Voice',
  SMS: 'SMS',
  DID: 'Did',
  SEAT: 'Seat',
};

export const PLAN_TYPE = {
  [PLAN_TYPE_NAME.LOW_VOLUME]: 'Low Volume',
  [PLAN_TYPE_NAME.MEDIUM_VOLUME]: 'Medium Volume',
  [PLAN_TYPE_NAME.HIGH_VOLUME]: 'High Volume',
};

export const PLAN_VIEW = {
  SELECTION: 'SELECTION',
  PAYMENT: 'PAYMENT',
  A2P_CAMPAIGN_CREATION: 'A2P_CAMPAIGN_CREATION',
  PAYMENT_LOADING: 'PAYMENT_LOADING',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export const PLAN_VIEW_LABEL = {
  SELECTION: 'Plan Selection',
  PAYMENT: 'Business Information',
  A2P_CAMPAIGN_CREATION: 'Non-Refundable Registration Payment',
  PAYMENT_LOADING: 'Campaign Registration Complete',
  SUBSCRIPTION: 'Subscription Plan Successful',
};

export const PLAN_PAYMENT_STATUS = {
  PAID: 'paid',
};
