/* eslint-disable require-yield */
import { put, call, takeLatest, select, take, fork } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import axios from 'axios';
import { debounce, uniq } from 'lodash';

import SocketService, { ROOM_NAME } from './SocketIO';
import SocketAction from './socket.action';
import UserSelector from '../user/user.selectors';
import SocketSelector from './socket.selector';

function socketSubscriber(emitter) {
  const events = Object.values(SocketAction.SOCKET_EVENT);

  events.forEach((eventName) => {
    SocketService.on(eventName, (payload) => {
      console.info(`%c${eventName}`, 'background-color: black; padding: 10px; color: white; border-radius: 5px');
      emitter({ type: eventName, payload });
    });
  });

  return () => {};
}

function* socketEventChannel() {
  return eventChannel(socketSubscriber);
}

export function* onReceiveSocketEvent() {
  const channel = yield call(socketEventChannel);

  while (true) {
    try {
      const action = yield take(channel);
      yield put(action);
    } catch (error) {
      console.error(error);
    }
  }
}

export function* leaveAllRooms() {
  try {
    const currentUser = yield select(UserSelector.selectCurrentUser);
    const allRooms = yield select(SocketSelector.selectRooms);

    if (currentUser && Array.isArray(allRooms) && allRooms.length > 0) {
      for (const roomName of allRooms) {
        yield put(SocketAction.leaveRoom(roomName));
      }

      yield put(SocketAction.leaveConnectionRoom(currentUser.id));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* joinAllRooms(action) {
  try {
    yield call(leaveAllRooms);
    const currentUser = yield select(UserSelector.selectCurrentUser);

    if (currentUser.id) {
      const { id: userId } = currentUser;
      // USER Rooms
      yield put(SocketAction.joinConnectionRoom(userId));
      yield put(SocketAction.joinRoom(ROOM_NAME.USER({ userId })));

      // OWNER - GROUP Rooms
      const response = yield call(axios.get, '/user/socket_rooms');
      if (response.data) {
        const { groupRooms, ownerRooms } = response.data;
        if (groupRooms && ownerRooms) {
          yield put(SocketAction.updateRoom(response.data));
          const rooms = [...groupRooms, ...ownerRooms];

          for (const roomName of rooms) {
            yield put(SocketAction.joinRoom(roomName));
          }
          if (action?.callback) {
            const { callback } = action;
            callback(null, response.data);
          }
        }
      } else {
        throw new Error('No socket rooms found');
      }
    }
  } catch (error) {
    console.error(error);
    if (action?.callback) {
      const { callback } = action;
      callback(error, null);
    }
  }
}

export function* joinConnectionRoom({ payload: userId }) {
  try {
    yield call(SocketService.joinConnectionRoom, userId);
  } catch (error) {
    console.log({ error });
  }
}

export function* leaveConnectionRoom({ payload: userId }) {
  try {
    yield call(SocketService.leaveConnectionRoom, userId);
  } catch (error) {
    console.log({ error });
  }
}

export function* joinRoom({ payload: roomName }) {
  try {
    yield call(SocketService.joinRoom, roomName);
  } catch (error) {
    console.log({ error });
  }
}

export function* leaveRoom({ payload: roomName }) {
  try {
    yield call(SocketService.leaveRoom, roomName);
  } catch (error) {
    console.log({ error });
  }
}

export function* disconnect() {
  try {
    yield call(SocketService.disconnect);
  } catch (error) {
    console.log({ error });
  }
}

export function* joinAllRoomsAndReceiveSocketEvent() {
  try {
    const currentUser = yield select(UserSelector.selectCurrentUser);
    if (currentUser?.id) {
      yield call(joinAllRooms);
      yield fork(onReceiveSocketEvent);
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* debouceEndTypingEvent(payload) {
  const { groupId } = payload;

  try {
    debounce(
      () =>
        SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.AGENT_TYPING_EVENT, {
          ...payload,
          isTyping: false,
        }),
      1000,
    );
  } catch (error) {
    console.log({ error });
  }
}

export function* agentTypingEvent({ payload }) {
  const { groupId } = payload;

  try {
    SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.AGENT_TYPING_EVENT, payload);
  } catch (error) {
    console.log({ error });
  }
}

export function* callInboundSetState({ payload }) {
  const { groupId } = payload;

  try {
    SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND, payload);
  } catch (error) {
    console.log({ error });
  }
}

export function* callOutboundSetState({ payload }) {
  const { groupId } = payload;

  try {
    SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.CALL_MAKE_UPDATE_STATE_CALL_OUTBOUND, payload);
  } catch (error) {
    console.log({ error });
  }
}

export function* callUpdateListSessionList({ payload }) {
  const payloadData = Object.values(payload);
  let groupIds = payloadData.map((session) => session.groupId);
  groupIds = uniq(groupIds);
  try {
    groupIds.map((groupId) => {
      SocketService.emit(
        ROOM_NAME.GROUP({ groupId }),
        SocketAction.SOCKET_EVENT.CALL_UPDATE_SESSION_LIST_GROUP,
        payload,
      );
    });
  } catch (error) {
    console.log({ error });
  }
}

export function* callUpdateListInvitedExternalCall({ payload }) {
  const { externalCallList, groupId } = payload;
  try {
    SocketService.emit(
      ROOM_NAME.GROUP({ groupId }),
      SocketAction.SOCKET_EVENT.UPDATE_LIST_INVITED_EXTERNAL_CALL,
      externalCallList,
    );
  } catch (error) {
    console.log({ error });
  }
}
export function* callForceHungUpCall({ payload }) {
  const { conversationId, groupId, masterCallId } = payload;
  try {
    SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.SOCKET_EVENT.FORCE_HUNG_UP_CALL_CONVERSATION, {
      conversationId,
      masterCallId,
    });
  } catch (error) {
    console.log({ error });
  }
}
export function* requestSyncVoiceData({ payload }) {
  try {
    payload.map((groupId) => {
      SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.SOCKET_EVENT.REQUEST_SYNC_VOICE_DATA, { groupId });
    });
  } catch (error) {
    console.log({ error });
  }
}
// export function* debounceTyping({ payload }) {
//   yield debounce(1000, SocketAction.SOCKET_EVENT.AGENT_TYPING_EVENT, )
// }

export function* socketFlow() {
  yield takeLatest(SocketAction.JOIN_ROOM, joinRoom);
  yield takeLatest(SocketAction.DISCONNECT, disconnect);
  yield takeLatest(SocketAction.LEAVE_ROOM, leaveRoom);
  yield takeLatest(SocketAction.LEAVE_ALL_ROOM, leaveAllRooms);
  yield takeLatest(SocketAction.JOIN_CONNECTION_ROOM, joinConnectionRoom);
  yield takeLatest(SocketAction.LEAVE_CONNECTION_ROOM, leaveConnectionRoom);
  yield takeLatest(SocketAction.JOIN_ALL_ROOMS_AND_RECEIVE_SOCKET_EVENT, joinAllRoomsAndReceiveSocketEvent);
  yield takeLatest(SocketAction.SOCKET_EVENT.JOIN_ALL_ROOMS, joinAllRooms);
  yield takeLatest(SocketAction.AGENT_TYPING_EVENT, agentTypingEvent);
  yield takeLatest(SocketAction.CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND, callInboundSetState);
  yield takeLatest(SocketAction.CALL_MAKE_UPDATE_STATE_CALL_OUTBOUND, callOutboundSetState);
  yield takeLatest(SocketAction.CALL_UPDATE_SESSION_LIST_GROUP_ACTION, callUpdateListSessionList);
  yield takeLatest(SocketAction.UPDATE_LIST_INVITED_EXTERNAL_CALL_ACTION, callUpdateListInvitedExternalCall);
  yield takeLatest(SocketAction.FORCE_HUNG_UP_CALL_CONVERSATION_ACTION, callForceHungUpCall);
  yield takeLatest(SocketAction.REQUEST_SYNC_VOICE_DATA_ACTION, requestSyncVoiceData);
}
