import axios from 'axios';
import { takeLatest, call, put, select, fork, delay } from 'redux-saga/effects';
import { get, omitBy, pick } from 'lodash';
import { toast } from 'react-toastify';
import publicIP from 'public-ip';

import UserAction from './user.actions';
import SocketAction from '../socket/socket.action';
import { startConnectVoiceWithCurrentUser } from '../voice/voice.saga';
import voiceService from '../voice/voice.service';
import VoiceSelector from '../voice/voice.selectors';
import VoiceAction from '../voice/voice.actions';
import ConversationAction from '../conversation/conversation.actions';
import { appInfo } from '../../enums/app';
import { USER_LOGIN_PLATFORM } from '../../enums/user';

const { UserActionTypes } = UserAction;
const defaultCallback = () => {};

export function* fetchUserPlan({ payload: id }) {
  try {
    const response = yield call(axios.get, '');
    if (response.data) {
      yield put(UserAction.fetchUserPlanSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(UserAction.fetchUserPlanFailure(error));
  }
}

export function* fetchUserByIds({ payload, callback = defaultCallback }) {
  try {
    const response = yield call(axios.get, '/user/user_ids', { params: { userIds: payload } });
    yield put(UserAction.fetchUserListSuccess(response.data));
    callback(null, response.data);
  } catch (error) {
    callback(error, null);
    console.log({ error });
  }
}

// local Register
export function* initiateLocalRegister({ payload, callback = defaultCallback }) {
  const credentials = payload;
  try {
    const response = yield call(axios.post, `/authorization/register/local`, credentials);
    callback(null, response.data);
  } catch (error) {
    callback(error, null);
    console.log({ error });
  }
}

// local login
export function* initiateLocalLogin({ payload, callback }) {
  const credentials = payload;
  try {
    const deviceId = `${new Date().getTime()}`;
    const response = yield call(axios.post, `/authorization/local`, {
      ...credentials,
      ipAddress: appInfo.publicIP,
      platform: USER_LOGIN_PLATFORM.WEB,
      deviceId,
    });
    yield put(
      UserAction.updatingUserAuthError(
        response.data.authError,
        response.data.email,
        response.data.cell_phone,
        response.data.id,
      ),
    );
    if (!response.data.authError) {
      const token = response.data.Authorization;
      yield put(UserAction.localLoginSuccess(response.data.data, token, deviceId));
      yield put(UserAction.updateUserStart({ auto_assign: false }));
      callback(null, response.data);
    } else {
      callback(null);
    }
  } catch (error) {
    callback({ error }, null);
    console.log({ error });
  }
}
export function* initiateRequestDemo({ payload, callback }) {
  const credentials = payload;
  try {
    const response = yield call(axios.post, `/user/demo`, { ...credentials, ipAddress: appInfo.publicIP });
    yield put(UserAction.updatingUserAuthError(response.data.authError, response.data.email));
    callback(response.data);
  } catch (error) {
    callback(null, error);
    console.log({ error });
  }
}

// making a payment || handle token
export function* getOnlineUser() {
  try {
    const response = yield call(axios.get, `/user/getOnlineUser`);
    // Emit join socket
    yield put(UserAction.updateOnlineUser(response.data));
  } catch (error) {
    console.log({ error });
  }
}

// making a payment || handle token
export function* handleToken({ payload }) {
  const { token, purchaseAmount } = payload;
  try {
    const response = yield call(axios.post, `/charge/stripe/${purchaseAmount}`, token);
    // Emit join socket
    yield put(UserAction.fetchCurrentUserSuccess(response.data));
  } catch (error) {
    console.log({ error });
  }
}

// update plans
export function* updatePlans({ payload }) {
  // const { token, purchaseAmount, plans } = payload;
  const { token, plans } = payload;

  try {
    yield call(axios.post, `/plans/user`, { token, plans });
    yield put(UserAction.updatePlanSuccess(plans));
  } catch (error) {
    console.log({ error });
  }
}

// User Logout
export function* userLogout() {
  try {
    // yield put(VoiceAction.leaveCurrentCall());
    yield put(SocketAction.leaveAllRoom());

    yield call(() => new Promise((rs) => setTimeout(rs, 1000)));
    // yield call(voiceService.stopConnect);
    yield call(axios.put, '/user/current_user', { last_logged_in: new Date(), fromLogout: true });

    yield put(UserAction.userLogoutSuccess());
  } catch (error) {
    console.log({ error });
  }
}

// Fetch Current User
export function* fetchUser({ payload }) {
  try {
    const { callback } = payload;
    const response = yield call(axios.get, '/authorization/current_user');
    const userId = response.data.data.id;

    yield put(UserAction.fetchCurrentUserSuccess(response.data.data));
    const filterGroups = get(response, 'data.data.filterGroups');
    if (filterGroups) yield put(ConversationAction.updateFilterGroup(filterGroups));
    if (userId) {
      // yield put(SocketAction.joinAllRooms());
      // yield call(joinAllRooms);
      yield put(SocketAction.joinAllRoomsAndReceiveSocketEvent());
      yield put(UserAction.getOnlineUser());
    }
    yield call(startConnectVoiceWithCurrentUser, response.data.data);

    if (callback) {
      callback(response.data.data);
    }
  } catch (error) {
    console.log({ error });
  }
}

// update user info
export function* updateUser({ payload }) {
  const { auto_assign, forceCallAPI, filterGroups } = payload;

  try {
    // const currentUser = yield select(UserSelector.selectCurrentUser);
    const haveCall = yield select(VoiceSelector.selectGetUserHaveCallOrNot);
    let shouldCallAPI = true;

    const data = omitBy(
      pick(payload, ['picture', 'given_name', 'family_name', 'cell_phone', 'company', 'filterGroups', 'pbx_group']),
      (value) => !value,
    );
    if (payload.pbx_enable !== undefined) data.pbx_enable = payload.pbx_enable;

    if (filterGroups === 'ALL') data.filterGroups = null;
    if (typeof auto_assign === 'boolean') data.auto_assign = auto_assign;
    if (haveCall) {
      shouldCallAPI = false;
    }
    if (forceCallAPI) {
      shouldCallAPI = true;
    }
    if (shouldCallAPI) yield call(axios.put, '/user/current_user', data);
    if (forceCallAPI && haveCall && !auto_assign) {
      return;
    }
    yield put(UserAction.updateUserSuccess(data));
  } catch (error) {
    yield put(UserAction.updateUserFailure());
    console.log({ error });
  }
}

// fetch same domain user list
export function* fetchSameDomainUsersList() {
  try {
    const response = yield call(axios.post, '/group/domain');
    yield put(UserAction.fetchSameDomainUsersListAsync(response.data));
  } catch (error) {
    console.log({ error });
  }
}

// fetch user
export function* fetchUserStartAsync() {
  try {
    const currentUserResponse = yield call(axios.get, '/authorization/current_user');

    yield put(UserAction.fetchCurrentUserSuccess(currentUserResponse.data.data));
  } catch (error) {
    if (error && error.message) {
      yield put(UserAction.fetchUserFailure(error.message));
    }
    console.log({ error });
  }
}

// resend confirmation email
export function* resendConfirmationEmail({ payload }) {
  try {
    yield call(axios.post, '/authorization/email_confirmation_resend', {
      email: payload,
    });
  } catch (error) {
    yield put(UserAction.fetchUserFailure(error.message));
    yield put(UserAction.updatingUserAuthError(null, null));
    console.log({ error });
  }
}

// forgot password email
export function* sendForgotPasswordEmail({ payload, callback }) {
  const email = payload;
  try {
    const response = yield call(axios.post, '/authorization/forgot_password', {
      email,
    });
    callback(null, response.data);
    yield put(UserAction.updatingUserAuthError(response.data, email));
  } catch (error) {
    callback(error);
    if (error && error.message) {
      yield put(UserAction.fetchUserFailure(error.message));
    }
    console.log({ error });
  }
}

// reset password
export function* resetPassword({ payload }) {
  const { token, password } = payload;
  try {
    const response = yield call(axios.post, '/authorization/reset_password', {
      token,
      password,
    });
    return response.data.redirect;
    // window.location.href = response.data.redirect;
  } catch (error) {
    yield put(UserAction.fetchUserFailure(error.message));
    console.log({ error });
  }
}

export function* countPlan({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/user/count_plan', payload);
    if (response.data) {
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Reponse with no data.');
    }
  } catch (error) {
    console.log({ error });
    toast.error(error.message);
    callback(error, null);
  }
}

export function* getVerifyPhoneNumber({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/authorization/verify/get_code', payload);
    if (response.data && response.data.status === 'pending') {
      toast.info('Verification code has been sent');
      if (callback) {
        callback(null, response.data);
      }
    } else {
      callback(response.data, null);
    }
  } catch (error) {
    // toast.error(error.message);
    callback(error?.response?.data, null);
  }
}

export function* verifyPhoneNumber({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/authorization/verify', payload);
    console.log('TCL - file: user.saga.js:319 - function*verifyPhoneNumber - response:', response);
    if (response.data) {
      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    console.log({ error });
    callback(error?.response?.data, null);
  }
}
export function* verifyPhoneNumberStatus({ payload, callback }) {
  const { id } = payload;
  try {
    const response = yield call(axios.get, `/authorization/verify/status/${id}`);
    if (response.data) {
      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    console.log({ error });
    callback(error?.response?.data, null);
  }
}
export function* getNewToken({ payload, callback }) {
  try {
    const response = yield call(axios.post, `/authorization/verify/get_token`, payload);
    if (response.data) {
      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    console.log({ error });
    callback(error?.response?.data, null);
  }
}
// saga user flow
export function* userFlow() {
  yield takeLatest(UserActionTypes.LOCAL_LOGIN_START, initiateLocalLogin);
  yield takeLatest(UserActionTypes.HANDLE_TOKEN_START, handleToken);
  yield takeLatest(UserActionTypes.FETCH_USER_BY_IDS, fetchUserByIds);
  yield takeLatest(UserActionTypes.LOCAL_REGISTER_START, initiateLocalRegister);
  yield takeLatest(UserActionTypes.HANDLE_UPDATE_PLAN_START, updatePlans);
  yield takeLatest(UserActionTypes.USER_LOGOUT_START, userLogout);
  yield takeLatest(UserActionTypes.FETCH_CURRENT_USER_START, fetchUser);
  yield takeLatest(UserActionTypes.UPDATE_USER_START, updateUser);
  yield takeLatest(UserActionTypes.FETCH_SAME_DOMAIN_EMAILS_START, fetchSameDomainUsersList);
  yield takeLatest(UserActionTypes.FETCH_USER_START, fetchUserStartAsync);
  yield takeLatest(UserActionTypes.HANDLE_RESEND_CONFIRMATION_EMAIL_START, resendConfirmationEmail);
  yield takeLatest(UserActionTypes.HANDLE_SEND_FORGOT_PASSWORD_EMAIL_START, sendForgotPasswordEmail);
  yield takeLatest(UserActionTypes.HANDLE_RESET_PASSWORD_START, resetPassword);
  yield takeLatest(UserActionTypes.FETCH_USER_PLAN_START, fetchUserPlan);
  yield takeLatest(UserActionTypes.COUNT_PLAN, countPlan);
  yield takeLatest(UserActionTypes.GET_ONLINE_USER, getOnlineUser);
  yield takeLatest(UserActionTypes.REQUEST_DEMO, initiateRequestDemo);
  yield takeLatest(UserActionTypes.GET_VERIFY_PHONE_NUMBER_START, getVerifyPhoneNumber);
  yield takeLatest(UserActionTypes.GET_VERIFY_PHONE_NUMBER_STATUS, verifyPhoneNumberStatus);
  yield takeLatest(UserActionTypes.VERIFY_PHONE_GET_NEW_TOKEN, getNewToken);
  yield takeLatest(UserActionTypes.VERIFY_PHONE_NUMBER_START, verifyPhoneNumber);
}
