import { put, select, takeLatest, delay } from 'redux-saga/effects';
import SocketAction from '../socket/socket.action';
import ConversationAction from '../conversation/conversation.actions';
import UserSelector from '../user/user.selectors';
import ConversationSelector from '../conversation/conversation.selectors';
import MessageAction from '../message/message.actions';
import ReportAction from '../report/report.action';

const { SOCKET_EVENT } = SocketAction;

function* handleAddConversation({ payload: conversation }) {
  try {
    const currentUser = yield select(UserSelector.selectCurrentUser);

    if (!currentUser) return;

    yield put(ConversationAction.addConversation(conversation));
    yield put(ReportAction.fetchReportLogConversationStart());
  } catch (error) {
    console.log({ error });
  }
}

function* handleFetchConversationById({ payload: id }) {
  try {
    yield put(ConversationAction.fetchConversationById(id));
    yield put(ReportAction.fetchReportLogConversationStart());
  } catch (error) {
    console.log({ error });
  }
}

function* handleFetchConversations({ payload }) {
  try {
    yield put(ConversationAction.fetchChatStart());
    yield put(ReportAction.fetchReportLogConversationStart());
  } catch (error) {
    console.log({ error });
  }
}

function* handleRouteConversation({ payload: id }) {
  try {
    yield put(ConversationAction.chooseConversation(id));
  } catch (error) {
    console.log({ error });
  }
}

function* handleLiveChatTyping({ payload }) {
  const { conversationId, message, isTyping } = payload;
  try {
    const conversation = yield select(ConversationSelector.selectByConversationId(conversationId));

    if (conversation) {
      yield put(ConversationAction.addConversation({ ...conversation, isTyping, typingMessage: message }));

      // Prevent still typing lost socket connection
      if (isTyping) {
        yield delay(5000);
        yield put(ConversationAction.addConversation({ ...conversation, isTyping: false }));
      }
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeConversationFlow() {
  yield takeLatest(SOCKET_EVENT.FETCH_CONVERSATIONS, handleFetchConversations);
  yield takeLatest(SOCKET_EVENT.FETCH_CONVERSATION_BY_ID, handleFetchConversationById);
  yield takeLatest(SOCKET_EVENT.ADD_CONVERSATION, handleAddConversation);
  yield takeLatest(SOCKET_EVENT.ROUTE_CONVERSATION, handleRouteConversation);
  yield takeLatest(SOCKET_EVENT.LIVECHAT_TYPING_EVENT, handleLiveChatTyping);
}
