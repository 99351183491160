import { get, map, orderBy, uniq, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import ConversationSelector from '../conversation/conversation.selectors';
import ContactSelector from '../contact/contact.selectors';
import UserSelector from '../user/user.selectors';
import { SYSTEM_TYPES, MESSAGE_TYPES } from '../../enums/message/message';
import { CONVERSATION_TYPES } from '../../enums/conversation';
import GroupSelector from '../group/group.selectors';

const getFromTitle = (userInfo, isFromHookAPI) => {
  if (isFromHookAPI) {
    return 'API Message';
  }
  return userInfo.given_name || userInfo.family_name ? `${userInfo.given_name} ${userInfo.family_name}` : '';
};

const getMessageByConversation = (conversationId, messages, users) => {
  const idMessagesInConversation = messages.data.allIds.filter((id) => {
    const message = messages.data.byId[id];
    if (!message) return false;
    return message._conversation === conversationId;
  });
  const resultMessages = orderBy(
    map(idMessagesInConversation, (id) => {
      const message = messages.data.byId[id];
      const userInfo = message && message._from ? get(users, `byId.${message._from}`, {}) : {};
      return {
        ...message,
        from: getFromTitle(userInfo, message.is_from_hook_api),
        from_email: userInfo.email ? userInfo.email : '',
      };
    }),
    (con) => new Date(con.createdAt),
    ['desc'],
  );

  return resultMessages;
};

export default class MessageSelector {
  static selectMessages = (state) => state.messages;

  static selectConversationById = (state) => state.conversations.data.byId;

  static selectIdentify = (state) => state.identify.data.byId;

  static selectContactCurrentConversation = createSelector(
    ConversationSelector.selectConversation,
    this.selectMessages,
    ContactSelector.selectContact,
    this.selectIdentify,
    (conversations, messages, contacts, identifies) => {
      const conversation = get(conversations, `data.byId.${messages.key}`);
      const identifyId = get(conversation, '_identifier');
      const identifyData = identifies[identifyId];
      const contactId = get(identifyData, '_contact');
      return get(contacts, `data.byId.${contactId}`, {});
    },
  );

  static selectMessageData = createSelector(
    this.selectMessages,
    ConversationSelector.selectConversation,
    ContactSelector.selectContact,
    UserSelector.selectUsers,
    this.selectIdentify,
    GroupSelector.selectGroupsByIds,
    this.selectConversationById,
    (messages, conversations, contacts, users, identifies, groups, conversationById) => {
      const conversationList = Object.values(conversationById);
      const conversation = get(conversations, `data.byId.${messages.key}`);
      const identifyId = get(conversation, '_identifier');
      const identifyData = identifies[identifyId];
      const contactId = get(identifyData, '_contact');
      const contact = get(contacts, `data.byId.${contactId}`, {});
      const conversationWithSameIdentifyAndDiffDid = [];
      if (conversation?.type !== CONVERSATION_TYPES.LIVE_CHAT) {
        const messageList = Object.values(messages.data.byId);
        messageList.forEach((messageItem) => {
          if (
            messageItem?.conversation?._identifier === conversation?._identifier &&
            messageItem?.conversation?._did !== conversation?._did
          ) {
            conversationWithSameIdentifyAndDiffDid.push(messageItem?._conversation);
          }
        });
      }
      const conversationWithSameIdentifyAndDiffDidIds = uniq(conversationWithSameIdentifyAndDiffDid);
      const identifiesByContact = Object.values(identifies)
        .filter((iden) => iden._contact === contactId)
        .map((iden) => iden.id);

      const conversationsByIdentifies = conversationList.filter((conver) =>
        identifiesByContact.includes(conver._identifier),
      ); // select conversation inside list identify of contact
      const conversationsByIdentifiesIds = conversationsByIdentifies.map((it) => it.id);
      const finalConversationList = [...conversationsByIdentifiesIds, ...conversationWithSameIdentifyAndDiffDidIds];
      const idMessagesInConversation = messages.data.allIds.filter((id) => {
        const message = messages.data.byId[id];
        if (!message) return false;

        const isOpenClosedMessage =
          message.system_type === SYSTEM_TYPES.CLOSE_CONVERSATION || message?.type === SYSTEM_TYPES.OPEN_CONVERSATION;
        const { shared_message_history_with } = message?.conversation?.group ?? {};
        const isShareWithCurrentGroup =
          shared_message_history_with?.includes?.(conversation?._group) ||
          shared_message_history_with?.includes?.('ALL_GROUP');
        const isConversationRelate = finalConversationList.includes(message._conversation);

        return (
          message._conversation === messages.key || // message normal current conver
          (!isOpenClosedMessage && isConversationRelate) || // calling message
          (isShareWithCurrentGroup && message?.type !== MESSAGE_TYPES.SYSTEM && isConversationRelate) // message normal other conver
        );
      });

      const preData = map(idMessagesInConversation, (id) => {
        const message = messages.data.byId[id];
        if (!message) return undefined;
        const userInfo = message && message._from ? get(users, `byId.${message._from}`, {}) : {};
        const conversationData = conversationById[message?.conversation?.id];
        const groupData = groups[conversationData?._group];
        const { shared_message_history_with } = groupData ?? {};

        return {
          ...message,
          from: getFromTitle(userInfo, message.is_from_hook_api),
          from_email: userInfo.email ? userInfo.email : '',
          shared_message_history_with,
        };
      }).filter(Boolean);
      const data = orderBy(preData, ['createdAt'], ['desc']);
      const result = [];

      data.forEach((item) => {
        const conversationTmp = conversations.data.byId[item._conversation];
        if (conversationTmp?.type === CONVERSATION_TYPES.LIVE_CHAT) {
          result.push(item);
        }
        if (conversationTmp?.type !== CONVERSATION_TYPES.LIVE_CHAT) {
          result.push(item);
        }
      });
      return {
        ...messages,
        contact,
        data: result,
      };
    },
  );

  static selectMessageListByConversationId = (conversationId) =>
    createSelector(
      this.selectMessages,
      UserSelector.selectUsers,
      ConversationSelector.selectConversation,
      (messages, users, conversations) => {
        let result = [];
        const messageList = Object.values(messages.data.byId);

        if (Array.isArray(conversationId)) {
          let conversationMap = orderBy(
            conversationId.map((id) => {
              let cvs = conversations.data.byId[id];
              if (!cvs) {
                const messageWithConversationId = messageList.find((messageItem) => messageItem?._conversation === id);
                cvs = messageWithConversationId?.conversation;
              }
              return cvs || {};
            }),
            (con) => new Date(con?.createdAt),
            ['desc'],
          );
          conversationMap = uniqBy(conversationMap, 'id');
          conversationMap.forEach((conversation) => {
            result.push(...getMessageByConversation(conversation?.id, messages, users));
          });
        } else {
          result = getMessageByConversation(conversationId, messages, users);
        }

        return result;
      },
    );

  static selectMessageFetching = createSelector([this.selectMessages], (messages) => messages.fetching);

  static selectAllMessage = createSelector([this.selectMessages], (messages) => messages.data.byId);

  static messageSearchResult = createSelector([this.selectMessages], (messages) => messages.messageSearchResult);

  static isSearchingMessage = createSelector([this.selectMessages], (messages) => messages.isSearchingMessage);

  static messageSearchForReportResult = createSelector(
    [this.selectMessages],
    (messages) => messages.messageForReportResult,
  );

  static isFetchingMessageForReport = createSelector(
    [this.selectMessages],
    (messages) => messages.isSearchingForReport,
  );

  static selectLatestMessageByContact = createSelector(
    [this.selectMessages],
    (messages) => messages.latestMessageByContact,
  );

  static selectFetchingContactMessage = createSelector(
    [this.selectMessages],
    (messages) => messages.fetchingContactMessage,
  );

  static selectIsMessageLoaded = createSelector(
    // Not needed
    [this.selectMessages],
    (messages) => !!messages,
  );

  static selectMessageConversationId = createSelector([this.selectMessages], (messages) =>
    messages ? messages.key : null,
  );

  static selectMessageContactsData = createSelector(
    this.selectMessages,
    ConversationSelector.selectConversation,
    ContactSelector.selectContact,
    (messages, conversations, contacts) => {
      const conversation = get(conversations, `data.byId.${messages.key}`);
      const contactId = get(conversation, '_contact');
      const contact = get(contacts, `data.byId.${contactId}`, {});
      return contact;
    },
  );

  static selectMessageByCdrId = createSelector(this.selectMessages, (messages) => {
    const messageByCdrId = {};
    Object.values(messages.data.byId).forEach((item) => {
      if (item?._cdr) {
        messageByCdrId[item._cdr] = item;
      }
    });
    return messageByCdrId;
  });

  static selectGroupId = createSelector(
    (state) => state.conversations,
    (state) => state.messages,
    (conversations, messages) => get(conversations, ['data', 'byId', messages.key, '_group']),
  );

  static selectHasMore = createSelector(
    (state) => state.messages,
    (messages) => get(messages, ['hasMore']),
  );

  static selectSystemMessage = (state) => state.messages.systemMessage;

  static selectSystemMessageDataList = (state) => state.messages.systemMessageForReport;

  static selectGroupDataIds = (state) => state.groups.data.allIds;

  static selectSystemMessageForReport = () =>
    createSelector(this.selectSystemMessageDataList, this.selectGroupDataIds, (systemMessage, group) => {
      const data = systemMessage
        .filter((message) => group?.includes(message?.conversationGroup))
        .sort(function (x, y) {
          return x.createdAt - y.createdAt;
        });
      const listMessage = data.reduce((arr, cur) => {
        if (!arr[cur._conversation]) {
          arr[cur._conversation] = [cur];
        } else {
          const temp = arr[cur._conversation];
          temp.push(cur);
          arr[cur._conversation] = temp;
        }
        return arr;
      }, {});
      const finalRs = {};
      Object.keys(listMessage).forEach((conversationId) => {
        const filteredItem = listMessage[conversationId].filter(
          (messageItem, index) =>
            messageItem.system_type !== 'OPEN_CONVERSATION' || index === listMessage[conversationId].length - 1,
        );
        finalRs[conversationId] = filteredItem;
      });
      const listData = [];
      Object.values(finalRs).forEach((data) => {
        data.forEach((item) => listData.push(item));
      });
      return listData.sort(function (x, y) {
        return x.createdAt - y.createdAt;
      });
    });

  static selectForceFetchMessage = (state) => state.messages.forceFetchMessage;
}
