import BroadCastUtil from './broadcast.utils';
import BroadCastAction from './broadcast.actions';
import UserAction from '../user/user.actions';

const { broadcastActionTypes } = BroadCastAction;
const INITIAL_STATE = {
  fetching: true,
  data: {
    byId: {},
    allIds: [],
  },
  report: {},
  reportFetching: true,
  broadcast_id: undefined,
  errorMessage: undefined,
};

const broadcastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // fetch broadcasr
    case broadcastActionTypes.FETCH_BROADCAST_ASYNC_START:
      return {
        ...state,
        fetching: true,
      };
    case broadcastActionTypes.FETCH_BROADCAST_ASYNC_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: BroadCastUtil.normalizeBroadcast(action.payload),
      };
    case broadcastActionTypes.FETCH_BROADCAST_ASYNC_FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    // fetch broad cast report
    case broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_START:
      return {
        ...state,
        reportFetching: true,
      };
    case broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_SUCCSS:
      return {
        ...state,
        report: action.payload.report,
        broadcast_id: action.payload.broadcast_id,
        reportFetching: false,
      };
    // create broadcast
    case broadcastActionTypes.SET_BROADCAST_REPORT_STATUS_START_SUCCESS:
      return {
        ...state,
        report: BroadCastUtil.getUpdatedReport(state.report, action.payload, state.broadcast_id),
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default broadcastReducer;
