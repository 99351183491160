export default class ContactAction {
  static TOGGLE_CONTACT = 'ContactAction/TOGGLE_CONTACT';

  static UPDATE_FIELD = 'ContactAction/UPDATE_FIELD';

  static FETCH_CONTACTS = {
    START: 'ContactAction/FETCH_CONTACTS_START',
    SUCCESS: 'ContactAction/FETCH_CONTACTS_SUCCESS',
    FAILURE: 'ContactAction/FETCH_CONTACTS_FAILURE',
  };

  static FETCH_CONTACTS_BY_ID = {
    START: 'ContactAction/FETCH_CONTACTS_BY_ID_START',
    SUCCESS: 'ContactAction/FETCH_CONTACTS_BY_ID_SUCCESS',
    FAILURE: 'ContactAction/FETCH_CONTACTS_BY_ID_FAILURE',
  };

  static BULK_UPDATE_CONTACTS = {
    START: 'ContactAction/BULK_UPDATE_CONTACTS_START',
    SUCCESS: 'ContactAction/BULK_UPDATE_CONTACTS_SUCCESS',
    FAILURE: 'ContactAction/BULK_UPDATE_CONTACTS_FAILURE',
  };

  static BULK_DELETE_CONTACTS = {
    START: 'ContactAction/BULK_DELETE_CONTACTS_START',
    SUCCESS: 'ContactAction/BULK_DELETE_CONTACTS_SUCCESS',
    FAILURE: 'ContactAction/BULK_DELETE_CONTACTS_FAILURE',
  };

  static UPDATE_CONTACT_DNC = {
    START: 'ContactAction/UPDATE_CONTACT_DNC_START',
    SUCCESS: 'ContactAction/UPDATE_CONTACT_DNC_SUCCESS',
    FAILURE: 'ContactAction/UPDATE_CONTACT_DNC_FAILURE',
  };

  static FETCH_CONTACTS_BY_CONVERSATION_ID = 'ContactAction/FETCH_CONTACTS_BY_CONVERSATION_ID';

  static ADD_CONTACT = 'ContactAction/ADD_CONTACT';

  static ADD_CONTACT_SUCCESS = 'ContactAction/ADD_CONTACT_SUCCESS';

  static ADD_CONTACT_FAILURE = 'ContactAction/ADD_CONTACT_FAILURE';

  static UPDATE_CONTACT = 'ContactAction/UPDATE_CONTACT';

  static UPDATE_CONTACT_START = 'ContactAction/UPDATE_CONTACT_START';

  static UPDATE_CONTACT_CONFLICT = 'ContactAction/UPDATE_CONTACT_CONFLICT';

  static MERGE_CONTACT_START = 'ContactAction/MERGE_CONTACT_START';

  static MERGE_CONTACT_FAILURE = 'ContactAction/MERGE_CONTACT_FAILURE';

  static MERGE_CONTACT_SUCCESS = 'ContactAction/MERGE_CONTACT_SUCCESS';

  static UNMERGE_CONTACT_START = 'ContactAction/UNMERGE_CONTACT_START';

  static UNMERGE_CONTACT_FAILURE = 'ContactAction/UNMERGE_CONTACT_FAILURE';

  static UNMERGE_CONTACT_SUCCESS = 'ContactAction/UNMERGE_CONTACT_SUCCESS';

  static DELETE_CONTACT = 'ContactAction/DELETE_CONTACT';

  static DELETE_CONTACT_REDUX = 'ContactAction/DELETE_CONTACT_REDUX';

  static SET_DUPLICATE_CONTACT = 'ContactAction/SET_DUPLICATE_CONTACT';

  static SET_DUPLICATE_CONTACT_UPDATED_FIELD_VALUE = 'ContactAction/SET_DUPLICATE_CONTACT_UPDATED_FIELD_VALUE';

  static FETCH_CONTACTS_WITH_PAGINATION = {
    START: 'ContactAction/FETCH_CONTACTS_WITH_PAGINATION_START',
    SUCCESS: 'ContactAction/FETCH_CONTACTS_WITH_PAGINATION_SUCCESS',
    FAILURE: 'ContactAction/FETCH_CONTACTS_WITH_PAGINATION_FAILURE',
  };

  static SEARCH_CONTACTS_WITH_FILTER_PAGINATION = {
    START: 'ContactAction/SEARCH_CONTACTS_WITH_FILTER_PAGINATION_START',
    SUCCESS: 'ContactAction/SEARCH_CONTACTS_WITH_FILTER_PAGINATION_SUCCESS',
    FAILURE: 'ContactAction/SEARCH_CONTACTS_WITH_FILTER_PAGINATION_FAILURE',
  };

  static SEARCH_CONTACTS_WITH_PAGINATION = {
    START: 'ContactAction/SEARCH_CONTACTS_WITH_PAGINATION_START',
    SUCCESS: 'ContactAction/SEARCH_CONTACTS_WITH_PAGINATION_SUCCESS',
    FAILURE: 'ContactAction/SEARCH_CONTACTS_WITH_PAGINATION_FAILURE',
  };

  static SET_IS_NEW_CONTACT_ADDED = 'ContactAction/SET_IS_NEW_CONTACT_ADDED';

  static SET_FILTER_COLUMN = 'ContactAction/SET_FILTER_COLUMN';

  static setDuplicateContactUpdateField = (payload) => ({
    type: ContactAction.SET_DUPLICATE_CONTACT_UPDATED_FIELD_VALUE,
    payload,
  });

  static setDuplicateContact = (payload) => ({
    type: ContactAction.SET_DUPLICATE_CONTACT,
    payload,
  });

  static deleteContactsRedux = () => ({
    type: ContactAction.DELETE_CONTACT_REDUX,
  });

  static bulkDeleteContact = (contactIds, callback) => ({
    type: ContactAction.BULK_DELETE_CONTACTS.START,
    payload: contactIds,
    callback,
  });

  static bulkDeleteContactSuccess = (payload) => ({
    type: ContactAction.BULK_DELETE_CONTACTS.SUCCESS,
    payload,
  });

  static bulkDeleteContactFailure = (errorMessage) => ({
    type: ContactAction.BULK_DELETE_CONTACTS.FAILURE,
    payload: errorMessage,
  });

  static bulkUpdateContactByField = (contactIds, fieldId, value, callback) => ({
    type: ContactAction.BULK_UPDATE_CONTACTS.START,
    payload: { contactIds, fieldId, value },
    callback,
  });

  static bulkUpdateContactByFieldSuccess = (payload) => ({
    type: ContactAction.BULK_UPDATE_CONTACTS.SUCCESS,
    payload,
  });

  static bulkUpdateContactByFieldFailure = (errorMessage) => ({
    type: ContactAction.BULK_UPDATE_CONTACTS.FAILURE,
    payload: errorMessage,
  });

  static fetchContacts = (payload, loading = true) => ({
    type: ContactAction.FETCH_CONTACTS.START,
    payload,
    loading,
  });

  static fetchContactsSuccess = (payload, metadata) => ({
    type: ContactAction.FETCH_CONTACTS.SUCCESS,
    payload,
    metadata,
  });

  static fetchContactsFailure = (errorMessage) => ({
    type: ContactAction.FETCH_CONTACTS.FAILURE,
    payload: errorMessage,
  });

  static updateContactConflict = (payload) => ({
    type: ContactAction.UPDATE_CONTACT_CONFLICT,
    payload,
  });

  static toggleContact = (payload) => ({
    type: ContactAction.TOGGLE_CONTACT,
    payload,
  });

  static updateFieldValues = (payload) => ({
    type: ContactAction.UPDATE_FIELD,
    payload,
  });

  static addContactSuccess = () => ({
    type: ContactAction.ADD_CONTACT_SUCCESS,
  });

  static addContact = (payload, callback) => ({
    type: ContactAction.ADD_CONTACT,
    payload: { payload, callback },
  });

  static addContactFailure = (payload) => ({
    type: ContactAction.ADD_CONTACT_SUCCESS,
    payload,
  });

  static updateContact = (payload) => ({
    // old one use when update success
    type: ContactAction.UPDATE_CONTACT,
    payload,
  });

  static updateContactStart = (payload) => ({
    // new one use when start update
    type: ContactAction.UPDATE_CONTACT_START,
    payload,
  });

  static mergeContactSuccess = (payload) => ({
    // new one use when start update
    type: ContactAction.MERGE_CONTACT_SUCCESS,
    payload,
  });

  static mergeContactStart = (payload, callback) => ({
    // new one use when start update
    type: ContactAction.MERGE_CONTACT_START,
    payload,
    callback,
  });

  static mergeContactFailure = () => ({
    // new one use when start update
    type: ContactAction.MERGE_CONTACT_FAILURE,
  });

  static unmergeContact = (contactId, callback) => ({
    type: ContactAction.UNMERGE_CONTACT_START,
    payload: contactId,
    callback,
  });

  static unmergeContactSuccess = (payload) => ({
    type: ContactAction.UNMERGE_CONTACT_SUCCESS,
    payload,
  });

  static unmergeContactFailure = () => ({
    type: ContactAction.UNMERGE_CONTACT_FAILURE,
  });

  static deleteContact = (payload) => ({
    type: ContactAction.DELETE_CONTACT,
    payload,
  });

  static fetchContactById = (id, loading = true) => ({
    type: ContactAction.FETCH_CONTACTS_BY_ID.START,
    payload: id,
    loading,
  });

  static fetchContactByIdSuccess = (data) => ({
    type: ContactAction.FETCH_CONTACTS_BY_ID.SUCCESS,
    payload: [data],
  });

  static fetchContactByIdFailure = (errorMessage) => ({
    type: ContactAction.FETCH_CONTACTS_BY_ID.FAILURE,
    payload: errorMessage,
  });

  static fetchContactByConversationId = (conversationId) => ({
    type: ContactAction.FETCH_CONTACTS_BY_CONVERSATION_ID,
    payload: conversationId,
  });

  static updateContactDNC = (contactId, value, callback) => ({
    type: ContactAction.UPDATE_CONTACT_DNC.START,
    payload: { contactId, value },
    callback,
  });

  static fetchContactsWithPagination = (payload, callback) => ({
    type: ContactAction.FETCH_CONTACTS_WITH_PAGINATION.START,
    payload,
    callback,
  });

  static fetchContactsWithPaginationSuccess = () => ({
    type: ContactAction.FETCH_CONTACTS_WITH_PAGINATION.SUCCESS,
  });

  static fetchContactsWithPaginationFailure = () => ({
    type: ContactAction.FETCH_CONTACTS_WITH_PAGINATION.FAILURE,
  });

  static setIsNewContactAdded = (payload) => ({
    type: ContactAction.SET_IS_NEW_CONTACT_ADDED,
    payload,
  });

  static searchContactsWithFilterPagination = (payload, callback) => ({
    type: ContactAction.SEARCH_CONTACTS_WITH_FILTER_PAGINATION.START,
    payload,
    callback,
  });

  static searchContactsWithPagination = (payload, callback) => ({
    type: ContactAction.SEARCH_CONTACTS_WITH_PAGINATION.START,
    payload,
    callback,
  });

  static searchContactsWithPaginationFailure = () => ({
    type: ContactAction.SEARCH_CONTACTS_WITH_PAGINATION.FAILURE,
  });

  static setFilterColumn = (payload) => ({
    type: ContactAction.SET_FILTER_COLUMN,
    payload,
  });
}
