import { uniq } from 'lodash';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const datetimeFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export default class MessageUtil {
  static addMessageToChat = (messages, data) => {
    const message = data;

    const allIds = uniq([...messages.data.allIds, data.id]);
    const byId = {
      ...messages.data.byId,
      [data.id]: message,
    };

    return {
      ...messages,
      data: {
        allIds,
        byId,
      },
    };
  };

  static addReceviedMessageToChat = (messages, message) => {
    const allIds = uniq([...messages.data.allIds, message.id]);
    const byId = {
      ...messages.data.byId,
      [message.id]: message,
    };

    return {
      ...messages,
      data: {
        allIds,
        byId,
      },
    };
  };

  static updateMessageStatus = (messages, { messageId, status }) => {
    const { byId, allIds } = messages.data;
    const message = byId[messageId];
    if (status === 'sent') {
      if (message) {
        const newMessages = {
          ...message,
          sent: true,
        };

        return {
          ...messages,
          data: {
            ...messages.data,
            allIds: uniq(allIds),
            byId: { ...byId, [messageId]: newMessages },
          },
        };
      }
    }
    return messages;
  };

  static normalizeMessages(state, messages) {
    const byId = messages.reduce((initValue, message) => {
      return {
        ...initValue,
        [message.id]: message,
      };
    }, {});

    const allIds = Object.keys(byId);
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: uniq([...state.data.allIds, ...allIds]),
    };
  }

  static formatDate = (date) => {
    return dayjs(date).format('MM/DD/YYYY hh:mm:ss a z');
  };

  static contain_emoji_regex = /\p{Extended_Pictographic}/u;

  static only_emojis_regex =
    /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/gm;

  static single_emoji_regex =
    /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])$/gm;
}
