export default class ListAction {
  static FETCH_LISTS = {
    START: 'ListAction/FETCH_LISTS_START',
    SUCCESS: 'ListAction/FETCH_LISTS_SUCCESS',
    FAILURE: 'ListAction/FETCH_LISTS_FAILURE',
  };

  static CREATE_NEW_LIST = {
    START: 'ListAction/CREATE_NEW_LIST_START',
    SUCCESS: 'ListAction/CREATE_NEW_LIST_SUCCESS',
    FAILURE: 'ListAction/CREATE_NEW_LIST_FAILURE',
  };

  static UPDATE_LIST = {
    START: 'ListAction/UPDATE_LIST_START',
    SUCCESS: 'ListAction/UPDATE_LIST_SUCCESS',
    FAILURE: 'ListAction/UPDATE_LIST_FAILURE',
  };

  static DELETE_LIST = {
    START: 'ListAction/DELETE_LIST_START',
    SUCCESS: 'ListAction/DELETE_LIST_SUCCESS',
    FAILURE: 'ListAction/DELETE_LIST_FAILURE',
  };

  static EXECUTE_CAMPAIGN_ON_LIST = {
    START: 'ListAction/EXECUTE_CAMPAIGN_ON_LIST_START',
    SUCCESS: 'ListAction/EXECUTE_CAMPAIGN_ON_LIST_SUCCESS',
    FAILURE: 'ListAction/EXECUTE_CAMPAIGN_ON_LIST_FAILURE',
  };

  static STOP_CAMPAIGN_ON_LIST = {
    START: 'ListAction/STOP_CAMPAIGN_ON_LIST_START',
    SUCCESS: 'ListAction/STOP_CAMPAIGN_ON_LIST_SUCCESS',
    FAILURE: 'ListAction/STOP_CAMPAIGN_ON_LIST_FAILURE',
  };

  static UPLOAD_LIST = {
    START: 'ListAction/UPLOAD_LIST_START',
    SUCCESS: 'ListAction/UPLOAD_LIST_SUCCESS',
    FAILURE: 'ListAction/UPLOAD_LIST_FAILURE',
  };

  static REPLACE_LIST = {
    START: 'ListAction/REPLACE_LIST_START',
    SUCCESS: 'ListAction/REPLACE_LIST_SUCCESS',
    FAILURE: 'ListAction/REPLACE_LIST_FAILURE',
  };

  static SET_SELECTED_DATA = 'ListAction/SET_SELECTED_DATA';

  static SET_CONTACT_ID_LIST = 'ListAction/SET_CONTACT_ID_LIST';

  static SET_SELECTED_CONTACT_ID_LIST = 'ListAction/SET_SELECTED_CONTACT_ID_LIST';

  static SET_SELECTED_PAGE = 'ListAction/SET_SELECTED_PAGE';

  static replaceList = (payload, callback) => ({
    type: this.REPLACE_LIST.START,
    payload,
    callback,
  });

  static replaceListSuccess = (data) => ({
    type: this.REPLACE_LIST.SUCCESS,
    payload: data,
  });

  static replaceListFailure = (errorMessage) => ({
    type: this.REPLACE_LIST.FAILURE,
    payload: errorMessage,
  });

  static uploadList = (payload, callback) => ({
    type: this.UPLOAD_LIST.START,
    payload,
    callback,
  });

  static uploadListSuccess = (data) => ({
    type: this.UPLOAD_LIST.SUCCESS,
    payload: data,
  });

  static uploadListFailure = (errorMessage) => ({
    type: this.UPLOAD_LIST.FAILURE,
    payload: errorMessage,
  });

  static stopCampaignOnList = (contactIds, campaignId, callback) => ({
    type: this.STOP_CAMPAIGN_ON_LIST.START,
    payload: { contactIds, campaignId },
    callback,
  });

  static stopCampaignOnListSuccess = (data) => ({
    type: this.STOP_CAMPAIGN_ON_LIST.SUCCESS,
    payload: data,
  });

  static stopCampaignOnListFailure = (errorMessage) => ({
    type: this.STOP_CAMPAIGN_ON_LIST.FAILURE,
    payload: errorMessage,
  });

  static executeCampaignOnList = (contactIds, campaignId, callback) => ({
    type: this.EXECUTE_CAMPAIGN_ON_LIST.START,
    payload: { contactIds, campaignId },
    callback,
  });

  static executeCampaignOnListSuccess = (data) => ({
    type: this.EXECUTE_CAMPAIGN_ON_LIST.SUCCESS,
    payload: data,
  });

  static executeCampaignOnListFailure = (errorMessage) => ({
    type: this.EXECUTE_CAMPAIGN_ON_LIST.FAILURE,
    payload: errorMessage,
  });

  static setSelectedContactIdList = (contactIds) => ({
    type: ListAction.SET_SELECTED_CONTACT_ID_LIST,
    payload: contactIds,
  });

  static setContactIdList = (contactIds) => ({
    type: ListAction.SET_CONTACT_ID_LIST,
    payload: contactIds,
  });

  static setSelectedList = (type, id) => ({
    type: ListAction.SET_SELECTED_DATA,
    payload: { type, id },
  });

  static fetchLists = () => ({
    type: this.FETCH_LISTS.START,
  });

  static fetchListsSuccess = (payload) => ({
    type: this.FETCH_LISTS.SUCCESS,
    payload,
  });

  static fetchListsFailure = (errorMessage) => ({
    type: this.FETCH_LISTS.FAILURE,
    payload: errorMessage,
  });

  static createNewList = (body, callback) => ({
    type: this.CREATE_NEW_LIST.START,
    payload: body,
    callback,
  });

  static createNewListSuccess = (data) => ({
    type: this.CREATE_NEW_LIST.SUCCESS,
    payload: data,
  });

  static createNewListFailure = (errorMessage) => ({
    type: this.CREATE_NEW_LIST.FAILURE,
    payload: errorMessage,
  });

  static updateList = (body, callback) => ({
    type: this.UPDATE_LIST.START,
    payload: body,
    callback,
  });

  static updateListSuccess = (data) => ({
    type: this.UPDATE_LIST.SUCCESS,
    payload: data,
  });

  static updateListFailure = (errorMessage) => ({
    type: this.UPDATE_LIST.FAILURE,
    payload: errorMessage,
  });

  static deleteList = (body) => ({
    type: this.DELETE_LIST.START,
    payload: body,
  });

  static deleteListSuccess = (listIds) => ({
    type: this.DELETE_LIST.SUCCESS,
    payload: listIds,
  });

  static deleteListFailure = (errorMessage) => ({
    type: this.DELETE_LIST.FAILURE,
    payload: errorMessage,
  });

  static setSelectedPage = (payload) => ({
    type: this.SET_SELECTED_PAGE,
    payload,
  });
}
