import { groupBy, omit, uniq } from 'lodash';
// import qs from 'qs';

export default class GroupUtils {
  static removeMember(groups, { email, group_id }) {
    const newData = groups.data.byId;
    const { members } = newData[group_id];
    delete members[email];
    newData[group_id].members = members;

    return {
      byId: newData,
      allIds: groups.data.allIds,
    };
  }

  static addMember(group, { email, group_id }) {
    const newEmialObject = {};
    newEmialObject[email] = 'pending';
    const newData = group.data.byId;
    const members = { ...newData[group_id].members, ...newEmialObject };
    newData[group_id].members = members;

    return {
      byId: newData,
      allIds: group.data.allIds,
    };
  }

  static normalizeGroup(groups) {
    const byId = groupBy(groups, 'id');
    const allIds = Object.keys(byId);
    const allIdsWithGroupName = groups.map((item) => {
      return {
        value: item.id,
        label: item.group_name,
      };
    });
    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });
    return { byId, allIds, allIdsWithGroupName };
  }

  static addGroup(state, group) {
    let { byId, allIds } = state.data;
    byId = {
      ...byId,
      [group.id]: group,
    };
    allIds = uniq([...allIds, group.id]);
    return { byId, allIds };
  }

  static deleteGroup(state, groupId) {
    const { byId, allIds } = state.data;

    return {
      ...state,
      data: {
        byId: omit(byId, groupId),
        allIds: allIds.filter((id) => id !== groupId),
      },
    };
  }
}
