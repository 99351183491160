import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import MemberAction from './member.actions';

export function* fetchGroupMembers({ payload }) {
  try {
    const response = yield call(axios.get, '/member', payload);
    if (response && response.data) {
      yield put(MemberAction.fetchGroupMemberSuccess(response.data));
    } else {
      put(MemberAction.fetchGroupMemberFailure('Fetch group members failed.'));
    }
  } catch (error) {
    yield put(MemberAction.fetchGroupMemberFailure(error.message));
    throw error;
  }
}
export function* removeGroupMember(payload) {
  const payloadData = payload?.payload?.data;
  const callback = payload?.payload?.callback;
  try {
    const response = yield call(axios.post, '/member/remove', payloadData);
    callback(response, null);
  } catch (error) {
    callback(null, error);
  }
}

export default function* memberSagaFlow() {
  yield takeLatest(MemberAction.FETCH_GROUP_MEMBER.START, fetchGroupMembers);
  yield takeLatest(MemberAction.REMOVE_GROUP_MEMBER.START, removeGroupMember);
}
