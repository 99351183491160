import GroupUtils from './group.utils';
import GroupAction from './group.actions';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  fetching: true,
  data: {
    byId: {},
    allIds: [],
    allIdsWithGroupName: [],
  },
  errorMessage: null,
  isFetchingAllGroup: false,
};

const groupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GroupAction.FETCH_GROUP_BY_ID.START:
      return {
        ...state,
        fetching: true,
      };
    case GroupAction.FETCH_GROUP_BY_ID.SUCCESS: {
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        data: GroupUtils.addGroup(action.payload),
      };
    }
    case GroupAction.FETCH_GROUP_BY_ID.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case GroupAction.FETCH_GROUP.START:
      return {
        ...state,
        fetching: true,
        isFetchingAllGroup: true,
      };
    case GroupAction.FETCH_GROUP.SUCCESS: {
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        data: GroupUtils.normalizeGroup(action.payload),
        isFetchingAllGroup: false,
      };
    }
    case GroupAction.FETCH_GROUP.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
        isFetchingAllGroup: false,
      };
    case GroupAction.DELETE_GROUP_MEMBER: {
      const data = GroupUtils.removeMember(state, action.payload);
      return {
        ...state,
        data,
      };
    }
    case GroupAction.ADD_GROUP_MEMBER: {
      const data = GroupUtils.addMember(state, action.payload);
      return {
        ...state,
        data,
      };
    }
    case GroupAction.DELETE_GROUP_SUCCESS: {
      return GroupUtils.deleteGroup(state, action.payload);
    }
    case GroupAction.REMOVE_GROUP_REDUX: {
      return GroupUtils.deleteGroup(state, action.payload);
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default groupsReducer;
