import { CUSTOM_FIELDS_NAME, CUSTOM_FIELDS_TYPE } from '../modals/groups';

export const DEFAULT_CONTACT_FIELDS = [
  {
    custom_field_name: CUSTOM_FIELDS_NAME.email,
    custom_field_type: CUSTOM_FIELDS_TYPE.Email,
    order: 1,
    isMultiple: true,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.phone,
    custom_field_type: CUSTOM_FIELDS_TYPE.Phone,
    order: 2,
    isMultiple: true,
  },
  // {
  //   custom_field_name: CUSTOM_FIELDS_NAME.fingerPrint,
  //   custom_field_type: CUSTOM_FIELDS_TYPE.String,
  //   order: 3,
  //   isMultiple: true,
  // },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.firstName,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 3,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.lastName,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 4,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.address1,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 5,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.address2,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 6,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.city,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 7,
    isMultiple: false,
  },
  {
    custom_field_name: 'State',
    custom_field_type: CUSTOM_FIELDS_TYPE.Selectors,
    isMultiple: false,
    custom_field_options: [
      'AL',
      'AK',
      'AS',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FM',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MH',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'MP',
      'OH',
      'OK',
      'OR',
      'PW',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    order: 9,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.postalCode,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 10,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.ipAddress,
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 11,
    isMultiple: false,
  },
  {
    custom_field_name: CUSTOM_FIELDS_NAME.dnc,
    custom_field_type: CUSTOM_FIELDS_TYPE.Selectors,
    custom_field_options: ['Yes', 'No'],
    order: 12,
    isMultiple: false,
  },
  {
    custom_field_name: 'Owner',
    custom_field_type: CUSTOM_FIELDS_TYPE.String,
    order: 12,
    isMultiple: false,
  },
];

// eslint-disable-next-line no-unused-vars
const STATE = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
