export default class MemberAdminAction {
  static FETCH_MEMBER_ADMIN = {
    START: 'MemberAdminAction/FETCH_MEMBER_ADMIN_START',
    SUCCESS: 'MemberAdminAction/FETCH_MEMBER_ADMIN_SUCCESS',
    FAILURE: 'MemberAdminAction/FETCH_MEMBER_ADMIN_FAILURE',
  };

  static UPDATE_MEMBER_ADMIN = {
    START: 'MemberAdminAction/UPDATE_MEMBER_ADMIN_START',
    SUCCESS: 'MemberAdminAction/UPDATE_MEMBER_ADMIN_SUCCESS',
    FAILURE: 'MemberAdminAction/UPDATE_MEMBER_ADMIN_FAILURE',
  };

  static DELETE_MEMBER_REDUX_BY_IDS = 'MemberAdminAction/DELETE_MEMBER_REDUX_BY_IDS';

  static MEMBER_ADMIN_ACCEPT = 'MemberAdminAction/ACCEPT';

  static MEMBER_ADMIN_REJECT = 'MemberAdminAction/REJECT';

  static fetch = (ownerId) => ({
    type: MemberAdminAction.FETCH_MEMBER_ADMIN.START,
    payload: ownerId,
  });

  static fetchSuccess = (data) => ({
    type: MemberAdminAction.FETCH_MEMBER_ADMIN.SUCCESS,
    payload: data,
  });

  static fetchFailure = (errorMessage) => ({
    type: MemberAdminAction.FETCH_MEMBER_ADMIN.FAILURE,
    payload: errorMessage,
  });

  static updateMany = (payload) => {
    return {
      type: MemberAdminAction.UPDATE_MEMBER_ADMIN.START,
      payload,
    };
  };

  static updateSuccess = (data) => ({
    type: MemberAdminAction.UPDATE_MEMBER_ADMIN.SUCCESS,
    payload: data,
  });

  static updateFailure = (errorMessage) => ({
    type: MemberAdminAction.UPDATE_MEMBER_ADMIN.FAILURE,
    payload: errorMessage,
  });

  static deletedMemberAdminReduxByIds = (ids) => ({
    type: MemberAdminAction.DELETE_MEMBER_REDUX_BY_IDS,
    payload: ids,
  });

  static acceptRequest = (email) => ({
    type: MemberAdminAction.MEMBER_ADMIN_ACCEPT,
    payload: email,
  });

  static rejectRequest = (email, callback) => ({
    type: MemberAdminAction.MEMBER_ADMIN_REJECT,
    payload: email,
    callback,
  });
}
