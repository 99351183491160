import axios from 'axios';
import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';
import PlanAction from './plan.actions';
import UserAction from '../user/user.actions';

const { fetchUserPlan } = PlanAction;

export function* fetchDefaultPlans() {
  try {
    const response = yield call(axios.get, '/plans/associations');

    if (response && response.data) {
      yield put(PlanAction.fetchDefaultPlansSuccess(response.data));
    } else {
      yield put(PlanAction.fetchDefaultPlansFailure('Fetch plans failed.'));
    }
  } catch (error) {
    yield put(PlanAction.fetchDefaultPlansFailure(error.message));
    console.log({ error });
  }
}

export function* fetchPlanCountByDateRange({ payload }) {
  const { startDate, endDate, userIds } = payload;
  try {
    const response = yield call(axios.post, '/plan_count/users/byDateRange', { startDate, endDate, userIds });

    if (response && response.data) {
      yield put(PlanAction.fetchPlanCountByDateRangeSuccess(response.data));
    } else {
      yield put(PlanAction.fetchPlanCountByDateRangeFailure('Fetch plan count failed.'));
    }
  } catch (error) {
    yield put(PlanAction.fetchPlanCountByDateRangeFailure(error.message));
    console.log({ error });
  }
}

export function* addPaymentMethod({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/plans/subscribe', payload);

    if (response && response.data) {
      yield put(PlanAction.addPaymentMethodSuccess(response.data));
      yield put(UserAction.fetchCurrentUserStart());
      // const { hosted_invoice_url } = response.data;
      // if (hosted_invoice_url) {
      //   window.open(hosted_invoice_url);
      // }
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Payment failed.');
    }
  } catch (error) {
    yield put(PlanAction.addPaymentMethodFailure(error.message));
    toast.error(error.message);
    if (callback) {
      callback(error, null);
    }
    console.log({ error });
  }
}

export function* fetchUserPlans({ payload }) {
  const { userIds } = payload;
  try {
    const response = yield call(axios.post, '/plans/userPlans', { userIds });

    if (response && response.data) {
      yield put(fetchUserPlan.success(response.data));
    } else {
      throw new Error('Fetch plans failed.');
    }
  } catch (error) {
    yield put(fetchUserPlan.failure(error.message));
    console.log({ error });
  }
}

export default function* planSagaFlow() {
  yield takeLatest(PlanAction.FETCH_DEFAULT_PLANS.START, fetchDefaultPlans);
  yield takeLatest(PlanAction.ADD_PAYMENT_METHOD.START, addPaymentMethod);
  yield takeLatest(PlanAction.FETCH_PLAN_BY_DATERANGE.START, fetchPlanCountByDateRange);
  yield takeLatest(fetchUserPlan.ACTION_TYPE.START, fetchUserPlans);
}
