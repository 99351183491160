import { groupBy, uniq } from 'lodash';
// import qs from 'qs';

export default class ContactUtils {
  static toggle(openContacts, contactData) {
    // const openedContact = openContacts.find((contact) => contact === contactData._id);
    // if (openedContact) {
    //   return openContacts.filter((contact) => contact !== contactData._id);
    // }
    return contactData._id;
  }

  static normalizeContact(state, contacts) {
    const byId = Array.isArray(contacts)
      ? contacts.reduce(
          (initValue, value) => ({
            ...initValue,
            [value.id]: value,
          }),
          {},
        )
      : {};

    const allIds = Object.keys(byId);
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: uniq([...state.data.allIds, ...allIds]),
    };
  }
}
