import { uniq } from 'lodash';

export default class PlanUtils {
  static normalizeData(plans) {
    const byId = plans.reduce(
      (initValue, value) => ({
        ...initValue,
        [value.id]: value,
      }),
      {},
    );

    const allIds = Object.keys(byId);

    return { byId, allIds };
  }

  static normalizeUpdatedData(data, plans) {
    const byId = plans.reduce(
      (initValue, value) => ({
        ...initValue,
        [value.id]: value,
      }),
      data.byId,
    );

    const allIds = uniq([...data.allIds, ...Object.keys(byId)]);

    return { byId, allIds };
  }

  static normalizePlans(data) {
    return {
      // planType: this.normalizeData(data?.plan_types),
      // planFeature: this.normalizeData(data?.plan_features),
      data: this.normalizeData(data?.plans.filter((item) => !item.isDeleted)),
    };
  }
}
