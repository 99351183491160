import { groupBy, uniq } from 'lodash';
// import qs from 'qs';

export default class ConversationUtils {
  // static clearUnread = (chats, data) => {
  //   const { conversation, clear, message, } = data;
  //   const { byId } = chats.data;
  //   const conversationData = byId[conversation];

  //   if (conversationData) {
  //     const newConversation = clear ?
  //       {
  //         ...conversationData,
  //         unreadCount: 0,
  //         mentionUnreadCount: 0
  //       } :
  //       // {
  //       //   ...conversationData,
  //       //   unreadCount: conversationData.unreadCount + 1,
  //       //   unreplied: true,
  //       //   last_message: message || conversationData.last_message,
  //       // };

  //     return {
  //       ...chats,
  //       data: {
  //         ...chats.data,
  //         byId: { ...byId, [conversation]: newConversation }
  //       },
  //     };
  //   }
  //   return chats;
  // };

  static updateMessageUnrepliedStatus = (chats, data) => {
    const { conversation, status, message } = data;
    const { byId, allIds } = chats.data;
    const conversationData = byId[conversation];

    if (conversationData) {
      const newConversation = { ...conversationData, unreplied: status, last_message: message, is_close: false };
      return {
        ...chats,
        data: {
          byId: {
            ...byId,
            [conversation]: newConversation,
          },
          allIds,
        },
      };
    }

    return chats;
  };

  static updateCloseConversationStatus = (chats, data) => {
    const { conversation, status } = data;
    const { byId } = chats.data;
    const conversationData = byId[conversation];

    if (conversationData) {
      const newConversation = { ...conversationData, is_close: status };
      return {
        ...chats,
        data: {
          ...chats.data,
          byId: { ...byId, [conversation]: newConversation },
        },
      };
    }

    return chats;
  };

  static updateSeenStatus = (chats, { conversationId, id, purge }) => {
    const { byId, allIds } = chats.data;
    const conversationData = byId[conversationId];

    if (conversationData) {
      const tags = conversationData && conversationData.tags;

      if (tags[id] > 0) {
        conversationData.tags[id] = 0;
      }

      if (purge) {
        delete conversationData.tags[id];
      }

      return {
        ...chats,
        data: {
          byId: { ...byId, [conversationId]: conversationData },
          allIds,
        },
      };
    }

    return chats;
  };

  static updateConversation = (state, updateConversationParams) => {
    const conversationData = state.byId[updateConversationParams.id];
    const allIds = uniq([...state.allIds, updateConversationParams.id]);

    if (conversationData) {
      return {
        byId: {
          ...state.byId,
          [updateConversationParams.id]: {
            ...conversationData,
            ...updateConversationParams,
          },
        },
        allIds,
      };
    }
    return state;
  };

  static normalizeConversation = (conversations) => {
    const byId = groupBy(conversations, 'id');
    const allIds = Object.keys(byId);
    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });
    return { byId, allIds };
  };

  static addConversation = (state, conversation) => {
    let { byId, allIds } = state.data;
    byId = {
      ...byId,
      [conversation.id]: conversation,
    };
    allIds = uniq([...allIds, conversation.id]);
    return { byId, allIds };
  };
}
