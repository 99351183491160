import { takeLatest, put, call, all } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';
import DncAction from './dnc.action';
import { handleGetErrorMessage } from '../../util';
import ContactAction from '../contact/contact.actions';

const { DNC_ACTION_TYPE } = DncAction;

export function* handleGetAllDnc() {
  try {
    const response = yield call(axios.get, '/dnc');
    const dnc = get(response, 'data');
    if (dnc) {
      yield put(DncAction.fetchAllDncSuccess(dnc));
    }
  } catch (error) {
    yield put(DncAction.fetchAllDncFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

// delete dnc
export function* handleDeleteDnc({ payload, callback = () => {} }) {
  const idDnc = payload;
  try {
    const response = yield call(axios.delete, `/dnc/${idDnc}`);
    const idResponse = response.data;
    if (idResponse) {
      yield put(DncAction.deleteDncSuccess(idResponse));
      // yield put(ContactAction.fetchContacts());
      callback(null, idResponse);
    }
  } catch (error) {
    callback(error);
    yield put(DncAction.deleteDncFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* handleAddDnc({ payload, callback = () => {} }) {
  const { _contact, _group } = payload;
  try {
    const response = yield call(axios.post, `/dnc`, { _contact, _group });
    if (response.data) {
      yield put(DncAction.addDncSuccess(response.data));
      // yield put(ContactAction.fetchContacts());
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
    yield put(DncAction.addDncFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* refreshDNC() {
  try {
    const response_dnc = yield call(axios.get, '/dnc');
    const dnc = get(response_dnc, 'data');
    if (dnc) {
      yield put(DncAction.fetchAllDncSuccess(dnc));
    }
    // yield put(ContactAction.fetchContacts());
  } catch (error) {
    yield put(DncAction.fetchAllDncFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* handleFetchDncById({ payload }) {
  try {
    const response_dnc = yield call(axios.get, `/dnc/${payload}`);
    const dnc = get(response_dnc, 'data');
    if (dnc) {
      yield put(DncAction.fetchDNCByIdSuccess(dnc));
    }
  } catch (error) {
    yield put(DncAction.fetchDNCByIdFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

// saga dnc flow
export function* dncFlow() {
  yield takeLatest(DNC_ACTION_TYPE.HANDLE_DELETE_DNC_START, handleDeleteDnc);
  yield takeLatest(DNC_ACTION_TYPE.HANDLE_ADD_DNC_START, handleAddDnc);
  yield takeLatest(DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_START, handleGetAllDnc);
  yield takeLatest(DNC_ACTION_TYPE.FETCH_DNC_BY_ID_START, handleFetchDncById);
}
