export const DIRECTION_ENUM = {
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound',
};

export const CHANNEL_TYPE = {
  VOICE: 'VOICE',
  LIVECHAT: 'LIVECHAT',
};

export const ADVANCED_FILTER_CALL_LOG = {
  DID: 'DID',
  CONTACT_NUMBER: 'Contact Number',
  CALL_PARTICIPANTS: 'Call Participants',
};

export const SessionState = {
  Initial: 'Initial',
  Establishing: 'Establishing',
  Established: 'Established',
  Terminating: 'Terminating',
  Terminated: 'Terminated',
};

export const LegMemberStatus = {
  calling: 'CALLING',
  join: 'JOIN',
  end: 'END',
  leave: 'LEAVE',
  no_answer: 'NO_ANSWER',
  not_available: 'NOT_AVAILABLE',
};

export const CallerType = {
  user: 'user',
  phone: 'phone',
};

export const callingDirection = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
};
