import { put, takeLatest } from 'redux-saga/effects';
import SocketAction from '../socket/socket.action';
import CdrAction from '../cdr/cdr.actions';

const { SOCKET_EVENT } = SocketAction;

export function* handleUpdateCDR({ payload: cdr }) {
  yield put(CdrAction.addCdr([cdr]));
}

export function* realtimeCdrFlow() {
  yield takeLatest(SOCKET_EVENT.UPDATE_CDR, handleUpdateCDR);
}
