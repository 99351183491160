import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
import BroadCastAction from './broadcast.actions';

const { broadcastActionTypes } = BroadCastAction;

// fetch broadcast list
export function* fetchBroadcastStartAsync() {
  try {
    const broadcasts = yield call(axios.get, '/broadcast');
    if (broadcasts && broadcasts.data) {
      yield put(BroadCastAction.fetchBroadcastSuccess(broadcasts.data));
    }
  } catch (error) {
    if (error && error.message) {
      yield put(BroadCastAction.fetchBroadcastFailure(error.message));
    }
    console.log({ error });
  }
}

export function* watchFetchBroadcastStartAsync() {
  yield takeLatest(broadcastActionTypes.FETCH_BROADCAST_ASYNC_START, fetchBroadcastStartAsync);
}

// fetch broadcast report
export function* fetchBroadcastReport(payload) {
  const { id } = payload;
  console.log(payload);
  try {
    yield call(fetchBroadcastStartAsync());
    const broadcastReport = yield call(axios.get, `/broadcast/report?broadcast_id=${id}`);
    if (broadcastReport && broadcastReport.data) {
      yield put(BroadCastAction.fetchBroadcastReportSuccess({ report: broadcastReport.data, broadcast_id: id }));
    }
  } catch (error) {
    if (error && error.message) {
      yield put(BroadCastAction.fetchBroadcastReportFailure(error.message));
    }
    console.log({ error });
  }
}

export function* watchFetchBroadcastReport() {
  yield takeLatest(broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_START, fetchBroadcastReport);
}

// create broadcast
export function* startCreateBroadcast(payload) {
  const { group, numbers, message, list_id } = payload.payload;
  try {
    yield call(axios.post, '/broadcast', {
      group,
      numbers,
      message,
      list_id,
    });
    yield call(fetchBroadcastStartAsync);
  } catch (error) {
    if (error && error.message) {
      yield put(BroadCastAction.handleCreateBroadCasrFailure(error.message));
    }
    console.log({ error });
  }
}

export function* watchHandleCreateBroadCast() {
  yield takeLatest(broadcastActionTypes.FETCH_BROADCAST_CREATE_START, startCreateBroadcast);
}

// set report status

export function* setReportStatus(payload) {
  const { data } = payload;
  try {
    if (data) {
      yield put(BroadCastAction.handleSetStatusBroadCastSuccess(data));
    }
  } catch (error) {
    if (error && error.message) {
      yield put(BroadCastAction.handleSetStatusBroadCastFailure(error.message));
    }
    console.log({ error });
  }
}

export function* watchHandleSerReportStatus() {
  yield takeLatest(broadcastActionTypes.SET_BROADCAST_REPORT_STATUS_START, setReportStatus);
}

export function* broadCastFlow() {
  yield all([
    watchFetchBroadcastStartAsync(),
    watchFetchBroadcastReport(),
    watchHandleCreateBroadCast(),
    watchHandleSerReportStatus(),
  ]);
}
