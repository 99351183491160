export default class WorkflowAction {
  static ADD_WORKFLOW = 'WorkflowAction/ADD_WORKFLOW';

  static EXECUTE_WORKFLOW = {
    START: 'WorkflowAction/EXECUTE_WORKFLOW_START',
    SUCCESS: 'WorkflowAction/EXECUTE_WORKFLOW_SUCCESS',
    FAILURE: 'WorkflowAction/EXECUTE_WORKFLOW_FAILURE',
  };

  static addWorkflow = (payload) => ({
    type: this.ADD_WORKFLOW,
    payload,
  });

  static executeWorkflow = (payload) => ({
    type: this.EXECUTE_WORKFLOW.START,
    payload,
  });

  static executeWorkflowSuccess = () => ({
    type: this.EXECUTE_WORKFLOW.SUCCESS,
  });

  static executeWorkflowFailure = (error) => ({
    type: this.EXECUTE_WORKFLOW.FAILURE,
    payload: error,
  });
}
