import { omit } from 'lodash';
import DidAction from './did.actions';
import DidUtil from './did.utils';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  fetching: true,
  errorMessage: null,
  search_response: [],
  searching: false,
  data: {
    byId: {},
    allIds: [],
  },
};

const DidReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DidAction.SEARCH_NUMBER.START:
      return {
        ...state,
        searching: true,
      };
    case DidAction.SEARCH_NUMBER.SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        searching: false,
        search_response: payload,
      };
    }
    case DidAction.SEARCH_NUMBER.FAILURE:
      return {
        ...state,
        searching: false,
        errorMessage: action.payload,
      };
    case DidAction.FETCH_DID.START:
      return {
        ...state,
        fetching: true,
        errorMessage: null,
      };
    case DidAction.FETCH_DID.SUCCESS: {
      const { dids, isFetchAll } = action.payload;
      const { allIds, byId } = state.data;
      let newData = {};

      if (isFetchAll) {
        newData = DidUtil.normalizeDid(dids);
      } else {
        dids.forEach((did) => {
          let newAllIds = allIds;

          if (!allIds.includes(did.id)) {
            newAllIds = [...allIds, did.id];
          }

          newData = {
            allIds: newAllIds,
            byId: {
              ...byId,
              [did.id]: did,
            },
          };
        });
      }

      return {
        ...state,
        fetching: false,
        errorMessage: null,
        data: newData,
      };
    }
    case DidAction.FETCH_DID.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case DidAction.DELETE_DID: {
      const { payload } = action;
      const { id: deleteId } = payload;
      const { allIds, byId } = state.data;
      const newAllIds = allIds.filter((id) => id !== deleteId);
      const newById = omit(byId, deleteId);

      return { ...state, fetching: false, errorMessage: null, data: { allIds: newAllIds, byId: newById } };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default DidReducer;
