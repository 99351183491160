import MemberUtils from './member.utils';
import MemberAction from './member.actions';
import UserAction from '../user/user.actions';
import { omit } from 'lodash';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  isFetching: false,
  errorMessage: null,
};

const membersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MemberAction.FETCH_GROUP_MEMBER.START:
      return {
        ...state,
        isFetching: true,
      };
    case MemberAction.FETCH_GROUP_MEMBER.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: MemberUtils.normalizeMember(state.data, action.payload),
      };
    case MemberAction.FETCH_GROUP_MEMBER.FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case MemberAction.DELETE_MEMBER_REDUX_BY_IDS:
      const memberIds = action.payload;
      if (!Array.isArray(memberIds)) {
        return state;
      }

      const byId = omit(state.data.byId, memberIds);
      return {
        ...state,
        data: {
          byId,
          allIds: Object.keys(byId),
        },
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default membersReducer;
