import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import FieldLogAction from './field-log.actions';

export function* fetchFieldLogByDateRange({ payload }) {
  const { dateRange, userId } = payload;
  try {
    const response = yield call(axios.post, '/field_log/byDateRange', { dateRange, userId });
    if (response && response.data) {
      yield put(FieldLogAction.fetchFieldLogByDateRangeSuccess(response.data));
    }
  } catch (error) {
    yield put(FieldLogAction.fetchFieldLogByDateRangeFailure(error.message));
    console.log({ error });
  }
}

export default function* fieldLogSagaFlow() {
  yield takeLatest(FieldLogAction.FETCH_FIELD_LOG_BY_DATE_RANGE.START, fetchFieldLogByDateRange);
}
