export const MESSAGE_TYPES = {
  INTERNAL: 'INTERNAL',
  SMS: 'SMS',
  SYSTEM: 'SYSTEM',
  LIVE_CHAT: 'LIVE_CHAT',
};

export const SYSTEM_TYPES = {
  CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
  OPEN_CONVERSATION: 'OPEN_CONVERSATION',
  MISSED_CALL_VOICE: 'MISSED_CALL_VOICE',
  INBOUND_CALL_VOICE: 'INBOUND_CALL_VOICE',
  OUTBOUND_CALL_VOICE: 'OUTBOUND_CALL_VOICE',
  MERGE_LOG: 'MERGE_LOG',
  UNMERGE_LOG: 'UNMERGE_LOG',
  UPDATE_FIELD_VALUE_LOG: 'UPDATE_FIELD_VALUE_LOG',
};

export const SYSTEM_TYPE_LABEL = {
  CLOSE_CONVERSATION: 'Closed',
  OPEN_CONVERSATION: 'Re Opened',
};

export const TEMPLATES_MESSAGE = 'Start Message Template';
