import { useRef, useEffect } from 'react';
import { get, isString, isEqual, uniq, isNumber } from 'lodash';
import paginationFactory from 'react-bootstrap-table2-paginator';
import he from 'he';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

// Hook
export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const generateRandomString = () => Math.random().toString(36).substring(2) + Date.now().toString(36);

export const handleGetErrorMessage = (error) => {
  return get(error, 'message') || 'UNEXPECTED_ERROR_TEXT';
};

export const handleGetErrorMessageNew = (error) => {
  return get(error, 'message', get(error, 'response.data.message', 'Un expect error'));
};

export const handleDateFormat = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};

export const handleFormatDateMonthYear = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(date));
};

export const getMinArray = (arr) => {
  return arr.reduce((initValue, value) => {
    return initValue < value ? initValue : value;
  });
};

export const addPlusNumber = (number) => {
  return `+${number.replace(/\D/g, '')}`;
};

export const removePlusNumber = (number) => {
  return number.replace(/\D/g, '');
};

export const getFullPhoneNumber = (countryNumber, number, isPlus = true) => {
  if (!countryNumber) return number;
  const plus = isPlus ? '+' : '';
  return `${plus}${countryNumber.replace(/\D/g, '')}${number.replace(/\D/g, '')}`;
};

export const handleFormatStringDataCampaign = (data) => {
  const newData = {
    ...data,
    steps: data.steps.map((item, index) => {
      return {
        ...item,
        order: item.order || index + 1,
        http_body_form: JSON.stringify(item.http_body_form),
        response_variables: JSON.stringify(item.response_variables),
      };
    }),
  };
  return newData;
};

export const handleFormatDataParseCampaign = (data) => {
  const newData = {
    ...data,
    steps: data?.steps
      ? data.steps.map((item) => {
          return {
            ...item,
            http_body_form: JSON.parse(item.http_body_form),
            response_variables: JSON.parse(item.response_variables),
          };
        })
      : [],
  };
  return newData;
};

export const handleValidationJson = (json) => {
  if (!isString(json)) {
    return false;
  }
  const format = json.replace(/\${\w+}/g, '1');
  try {
    JSON.parse(format);
    return true;
  } catch (e) {
    return false;
  }
};
export const isValidPhone = (phoneNumber) => {
  // This pattern matches common North American phone number formats,
  // with or without a leading plus sign.
  var pattern = /^(\+?1-?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  // Use the test() method to check if the string matches the pattern.
  return pattern.test(phoneNumber);
};
export const formatPhoneNumber = (phoneNumber, isPlus = true) => {
  if (phoneNumber) {
    const phone = phoneNumber.toString().trim();
    const result = ['(', ')', '-', '+', ' '].reduce((initValue, char) => initValue.replaceAll(char, ''), phone);

    const { length } = result;

    const suffix = result.slice(length - 4);
    const middle = result.substr(length - 7, 3);
    const prefix = result.substr(length - 10, 3);

    if (isEqual(length, 10)) {
      return `(${prefix}) ${middle}-${suffix}`;
    }

    if (length > 10) {
      const regionNumber = result.substr(0, length - 10);
      return isPlus
        ? `+${regionNumber} (${prefix}) ${middle}-${suffix}`
        : `${regionNumber} (${prefix}) ${middle}-${suffix}`;
    }

    return result;
  }
  return phoneNumber;
};

export function getRandomInt(min, max) {
  const newMin = Math.ceil(min);
  const newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin) + newMin); // The maximum is exclusive and the minimum is inclusive
}

export const imageRegEx = /.+\.(gif|jpe?g|bmp|png)$/g;

export const emailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const decodeStripHTML = (htmlString = '') => {
  const stripedHtml = htmlString.replace(/<[^>]+>/g, ' ');
  return he.decode(stripedHtml);
};

export const handleMessage = (message, tags) => {
  let newMessage = message;
  for (const tag of tags) {
    let index = newMessage.indexOf(`key="${tag}"`);

    while (index !== -1) {
      const firstSpanIndex = index - 6;
      const lastSpanIndex = newMessage.indexOf('</span>', index);

      const prefix = newMessage.substr(0, firstSpanIndex);
      const suffix = newMessage.substr(lastSpanIndex + 7, newMessage.length - lastSpanIndex);
      newMessage = `${prefix}{{${tag}}}${suffix}`;

      index = newMessage.indexOf(`key="${tag}"`);
    }
  }

  return decodeStripHTML(newMessage);
};

export const getIndicesOf = (searchStr, str) => {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  var startIndex = 0,
    index,
    indices = [];

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

export const getMentionKey = (value) => {
  const message = value.toString();

  const keyIndexs = getIndicesOf('key=', message);
  const mentions = keyIndexs.map((keyIndex) => {
    const index = keyIndex + 5; // key="
    const lastIndex = message.indexOf(`"`, index);
    const key = message.substr(index, lastIndex - index);
    return key;
  });

  return uniq(mentions);
};

export const precision = (number) => {
  let e = 1;
  while (Math.round(number * e) / e !== number) e *= 10;
  return Math.log(e) / Math.LN10;
};

// ========= Encode Phone =========
export const privateKey = '@%@';

export const getNameOfEmail = (email) => {
  const regex = /^([^@]+)@/;
  var match = email?.match(regex);

  if (match) {
    return match[1];
  } else {
    return false;
  }
};

export const getNameOfSipEmail = (email) => {
  const regex = /^mobile-(.+)@/; // Matches "mobile-" followed by any characters (.+) until the "@" symbol
  const match = email.match(regex);

  if (match) {
    return match[1];
  }
  return false;
};

export const generateRandomConferenceName = () => {
  return `conference-${(Math.random() + 1).toString(36).substring(7)}`;
};

export const encodePhone = (region_number = 1, phone_number) => {
  return `${region_number}${privateKey}${phone_number}`;
};

export const decodePhone = (phone_number) => {
  if (!phone_number) return { region_number: null, phone_number: null };
  const phone = phone_number.split(privateKey);
  return { region_number: phone[0], phone_number: phone[1] };
};
export const isPhoneValid = (regionNumber, phoneNumber) => {
  const asYouType = new AsYouType({ defaultCallingCode: regionNumber });
  asYouType.input(regionNumber + phoneNumber);
  return asYouType.isValid();
};
export const decodePhoneToFormat = (phone_number) => {
  if (!phone_number) return '';
  const phone = phone_number.split(privateKey);
  return `+${phone[0]} (${phone[1].substr(0, 3)}) ${phone[1].substr(3, 3)}-${phone[1].substr(6, 10)}`;
};

export const getPaginationOptions = (totalSize, props = {}) =>
  paginationFactory({
    paginationSize: 25,
    sizePerPage: 25,
    pageStartIndex: 1,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize,
    ...props,
  });

export const addZero = (number) => (number <= 9 ? `0${number}` : `${number}`);

export const formatTime = (seconds) => {
  if (!seconds) {
    return '00:00:00';
  }

  const time = Math.ceil(seconds);

  if (time < 60) {
    return `00:00:${addZero(time)}`;
  }
  let ss = Math.floor(seconds % 60);
  let mm = Math.floor((seconds / 60) % 60);
  let hh = Math.floor(seconds / 3600) % 24;
  const dd = Math.floor(seconds / 86400);
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }

  return (dd ? `${dd} days ` : '') + `${hh}:${mm}:${ss}`;
};

export const handleGetPhoneNumber = (data) => data.substr(5, data.length - 5).replace(/\@[\W\D\S]+/g, '');

export const isValidPhoneNumber = (data) => data && data.match(/\+[\d]+/g);

export const removeVoiceDomain = (data) => data?.substr(0, data.indexOf('@'));

export const normalizeUpdatedData = (data, payload) => {
  if (!Array.isArray(payload)) {
    return {
      byId: { ...data.byId, [payload.id]: payload },
      allIds: uniq([...data.allIds, payload.id]),
    };
  }

  const byId = payload.reduce(
    (initValue, value) => ({
      ...initValue,
      [value.id]: value,
    }),
    {},
  );
  const allIds = uniq(Object.keys(byId));

  return {
    byId: { ...data.byId, ...byId },
    allIds: uniq([...data.allIds, ...allIds]),
  };
};

export const normalizeNewData = (payload) => {
  if (!Array.isArray(payload)) {
    return {
      byId: { [payload.id]: payload },
      allIds: uniq([payload.id]),
    };
  }

  const byId = payload.reduce(
    (initValue, value) => ({
      ...initValue,
      [value.id]: value,
    }),
    {},
  );
  const allIds = uniq(Object.keys(byId));

  return { byId, allIds };
};

export const countElementInArray = (array = []) => {
  const counts = {};
  array.forEach((item) => {
    const count = counts[item];
    counts[item] = isNumber(count) ? count + 1 : 1;
  });
  return counts;
};

export const checkDuplicateDataInArray = (array = []) => {
  const existedItems = [];
  for (const item of array) {
    if (existedItems.includes(item)) {
      return true;
    }
    existedItems.push(item);
  }
  return false;
};
