import DncUtil from './dnc.utils';
import DncAction from './dnc.action';
import UserAction from '../user/user.actions';

const { DNC_ACTION_TYPE } = DncAction;

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  fetching: false,
};

const dncReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DNC_ACTION_TYPE.FETCH_DNC_BY_ID_START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case DNC_ACTION_TYPE.FETCH_DNC_BY_ID_SUCCESS: {
      return {
        ...state,
        data: DncUtil.normalizeDnc(state, [action.payload]),
        fetching: false,
      };
    }
    case DNC_ACTION_TYPE.FETCH_DNC_BY_ID_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }
    case DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_SUCCESS: {
      return {
        ...state,
        data: DncUtil.normalizeDnc(state, action.payload),
        fetching: false,
      };
    }
    case DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }
    case DNC_ACTION_TYPE.HANDLE_DELETE_DNC_SUCCESS: {
      return {
        ...state,
        data: DncUtil.normalizeDeleteDnc(state, action.payload),
      };
    }
    case DNC_ACTION_TYPE.HANDLE_ADD_DNC_SUCCESS: {
      return {
        ...state,
        data: DncUtil.normalizeDnc(state, [action.payload]),
      };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default dncReducer;
