import { uniq, get, omit } from 'lodash';
import Immutable, { Record } from 'immutable';
// import Immutable from 'seamless-immutable';

import ConversationUtils from './conversation.utils';
import ConversationAction from './conversation.actions';
import SocketAction from '../socket/socket.action';
import MessageAction from '../message/message.actions';
import UserAction from '../user/user.actions';

const { MessageActionTypes } = MessageAction;
const { SOCKET_EVENT } = SocketAction;

const INITIAL_STATE = Record({
  fetching: true,
  filterGroups: [],
  users: [],
  errorMessage: null,
  data: {
    byId: {},
    allIds: [],
  },
  isOpenContactDetail: true,
})({
  fetching: true,
  filterGroups: [],
  users: [],
  errorMessage: null,
  data: {
    byId: {},
    allIds: [],
  },
  isOpenContactDetail: true,
});

const conversationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConversationAction.ConversationActionTypes.SET_OPEN_CONTACT_SIDEBAR: {
      return state.merge({
        isOpenContactDetail: action.payload,
      });
    }
    case ConversationAction.ConversationActionTypes.FETCH_CHAT_START: {
      return state.set('fetching', true);
    }
    case ConversationAction.ConversationActionTypes.FETCH_CHAT_SUCCESS: {
      return state.merge({
        fetching: false,
        errorMessage: null,
        data: ConversationUtils.normalizeConversation(action.payload),
      });
    }
    case ConversationAction.ConversationActionTypes.FETCH_CHAT_FAILURE: {
      return state.merge({
        fetching: false,
        errorMessage: action.payload,
      });
    }
    // case ConversationAction.ConversationActionTypes.FETCH_CHAT_BY_ID_START: {
    //   return state.set('fetching', true);
    // }
    case ConversationAction.ConversationActionTypes.FETCH_CHAT_BY_ID_SUCCESS: {
      return state.merge({
        fetching: false,
        errorMessage: null,
        data: ConversationUtils.addConversation(state, action.payload),
      });
    }
    case ConversationAction.ConversationActionTypes.FETCH_CHAT_BY_ID_FAILURE: {
      return state.merge({
        fetching: false,
        errorMessage: action.payload,
      });
    }
    case ConversationAction.ConversationActionTypes.UPDATE_UNREPLIED_STATUS: {
      const { data } = ConversationUtils.updateMessageUnrepliedStatus(state, action.payload);
      return state.merge({
        errorMessage: null,
        data,
      });
    }
    case ConversationAction.ConversationActionTypes.UPDATE_CLOSE_COVERSATION_STATUS: {
      const { data } = ConversationUtils.updateCloseConversationStatus(state, action.payload);
      return state.merge({
        errorMessage: null,
        data,
      });
    }
    case ConversationAction.ConversationActionTypes.UPDATE_SEEN_TO_TRUE: {
      const { data } = ConversationUtils.updateSeenStatus(state, action.payload);
      return state.merge({
        errorMessage: null,
        data,
      });
    }
    case ConversationAction.ConversationActionTypes.UPDATE_FILTER_GROUP:
      return state.merge({
        errorMessage: null,
        filterGroups: action.payload,
      });
    case ConversationAction.ConversationActionTypes.UPDATE_LAST_MESSAGE: {
      const { last_message, conversationId } = action.payload;
      return state.setIn(['data', 'byId', conversationId, 'last_message'], last_message);
    }
    case ConversationAction.ConversationActionTypes.SET_USERS:
      return state.merge({
        users: action.payload,
      });
    case ConversationAction.ConversationActionTypes.UPDATE_INDIVIDUAL_CONVERSATION:
      return state.merge({
        errorMessage: null,
        data: ConversationUtils.updateConversation(state.data, action.payload),
      });

    case MessageActionTypes.FETCH_MESSAGE_SUCCESS:
    case MessageActionTypes.FETCH_CONTACT_MESSAGE_SUCCESS: {
      const { payload } = action;
      const { key } = payload;
      if (!key) return state;
      return state.setIn(['data', 'byId', key, 'isFetched'], true);
    }

    case ConversationAction.ConversationActionTypes.ADD_CONVERSATION: {
      const conversation = action.payload;
      if (!conversation) return state;
      const conversationId = conversation.id;
      if (!conversationId) return state;
      const allIds = uniq([...state.data.allIds, conversationId]);
      return state
        .updateIn(['data', 'byId', conversationId], (oldConversation) => {
          return Immutable.merge(oldConversation, conversation);
        })
        .setIn(['data', 'allIds'], allIds);
    }

    case ConversationAction.ConversationActionTypes.UPDATE_VOICE_CONVERSATION: {
      const { id: conversationId, voice } = action.payload;
      const conversation = get(state, `data.byId.${conversationId}`);

      if (!conversation) return state;

      if (!voice) return state.setIn(['data', 'byId', conversationId, 'voice'], null);

      return state.updateIn(['data', 'byId', conversationId, 'voice'], (oldVoice = {}) => {
        if (!oldVoice) return voice;
        return Immutable.mergeDeep(oldVoice, voice);
      });
    }

    case ConversationAction.ConversationActionTypes.UPDATE_VOICE_LEGS_CONVERSATION: {
      const { id: conversationId, leg } = action.payload;
      const { id: userId, status } = leg;

      if (!userId || !conversationId) return state;

      return state.updateIn(['data', 'byId', conversationId, 'voice', 'legs'], (legs) => {
        if (!legs) return { [userId]: leg };
        return Immutable.merge(legs, { [userId]: leg });
      });
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
export default conversationReducer;
