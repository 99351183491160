import FieldUtils from './field.utils';
import FieldAction from './field.actions';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  fetching: true,
  deleting: false,
  updating: false,
  errorMessage: null,
};

const fieldReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FieldAction.FETCH_FIELDS.START:
      return {
        ...state,
        fetching: true,
        errorMessage: null,
      };
    case FieldAction.FETCH_FIELDS.SUCCESS:
      return {
        ...state,
        fetching: false,
        errorMessage: null,
        data: FieldUtils.normalizeFields(state, action.payload),
      };
    case FieldAction.FETCH_FIELDS.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case FieldAction.ADD_FIELD.SUCCESS:
      return {
        ...state,
        data: FieldUtils.addField(state.data, action.payload),
      };
    case FieldAction.ADD_FIELD.FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FieldAction.DELETE_FIELD.START:
      return {
        ...state,
        deleting: true,
      };
    case FieldAction.DELETE_FIELD.SUCCESS:
      return {
        ...state,
        deleting: false,
        data: FieldUtils.deleteField(state.data, action.payload),
      };
    case FieldAction.DELETE_FIELD.FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };
    case FieldAction.UPDATE_FIELD.START:
      return {
        ...state,
        updating: true,
      };
    case FieldAction.UPDATE_FIELD.SUCCESS:
      return {
        ...state,
        updating: false,
        data: FieldUtils.updateField(state.data, action.payload),
      };
    case FieldAction.UPDATE_FIELD.FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default fieldReducer;
