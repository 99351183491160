import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
import CampaignAction from './campaign.actions';
import { handleGetErrorMessage } from '../../util/index';
import { CampaignSession } from '../../enums/campaign';

const { CAMPAIGN_ACTION } = CampaignAction;
const voiceDomain = import.meta.env.VITE_APP_NEW_VOICE_DOMAIN_API;
// fetch campaign
export function* fetchCampaign() {
  try {
    const response = yield call(axios.get, '/campaign');
    yield put(CampaignAction.fetchCampaignSuccess(response.data));
  } catch (error) {
    yield put(CampaignAction.fetchCampaignFailure(handleGetErrorMessage(error)));
  }
}

export function* watchFetchCampaign() {
  yield takeLatest(CAMPAIGN_ACTION.FETCH_CAMPAIGN_START, fetchCampaign);
}

// create
export function* createCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/campaign', payload);
    yield put(CampaignAction.createCampaignSuccess(response.data));
    callback(null, response.data);
  } catch (error) {
    callback(error);
    yield put(CampaignAction.createCampaignFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* watchCreateCampaign() {
  yield takeLatest(CAMPAIGN_ACTION.HANDLE_CREATE_CAMPAIGN_START, createCampaign);
}

// update
export function* updateCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.put, `/campaign/${payload.id}`, payload);
    yield put(CampaignAction.updateCampaignSuccess(response.data));
    callback(null, response.data);
  } catch (error) {
    callback(error);
    yield put(CampaignAction.updateCampaignFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* watchUpdateCampaign() {
  yield takeLatest(CAMPAIGN_ACTION.HANDLE_UPDATE_CAMPAIGN_START, updateCampaign);
}

// delete
export function* deleteCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.delete, `/campaign/${payload}`);
    yield put(CampaignAction.deleteCampaignSuccess(response.data));
    callback(null, response.data);
  } catch (error) {
    callback(error);
    yield put(CampaignAction.deleteCampaignFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

export function* createDefaultCampaign({ payload, callback }) {
  try {
    const response = yield call(axios.post, `/campaign/createDefault`, payload);
    if (response.status === 200) callback(null, response.data.sid);
  } catch (error) {
    console.log('🚀 ~ function*createDefaultCampaign ~ error:', error);
    callback(error);
  }
}

export function* createSupportingDescriptionDefaultCampaign({ payload, callback }) {
  const { section } = payload;
  try {
    let response = yield call(axios.post, `${voiceDomain}/open-ai/description`, payload);
    if (response.status === 200) {
      const { data } = response;
      yield put(CampaignAction.createSupportingDataDefaultCampaignSuccess({ data, section }));
      callback(null, true);
    }
  } catch (error) {
    yield put(CampaignAction.createSupportingDataDefaultCampaignFailure({ section }));
    console.log('🚀 ~ function*createDefaultCampaign ~ error:', error);
    callback(error);
  }
}
export function* createSupportingMessageFlowDefaultCampaign({ payload, callback }) {
  const { section } = payload;
  try {
    let response = yield call(axios.post, `${voiceDomain}/open-ai/message-flow`, payload);

    if (response.status === 200) {
      const { data } = response;
      yield put(CampaignAction.createSupportingDataDefaultCampaignSuccess({ data, section }));
      callback(null, true);
    }
  } catch (error) {
    yield put(CampaignAction.createSupportingDataDefaultCampaignFailure({ section }));
    console.log('🚀 ~ function*createDefaultCampaign ~ error:', error);
    callback(error);
  }
}
export function* createSupportingMessageSampleDefaultCampaign({ payload, callback }) {
  const { section } = payload;
  try {
    let response = yield call(axios.post, `${voiceDomain}/open-ai/message-sample`, payload);

    if (response.status === 200) {
      const { data } = response;
      yield put(CampaignAction.createSupportingDataDefaultCampaignSuccess({ data, section }));
      callback(null, true);
    }
  } catch (error) {
    yield put(CampaignAction.createSupportingDataDefaultCampaignFailure({ section }));
    console.log('🚀 ~ function*createDefaultCampaign ~ error:', error);
    callback(error);
  }
}
export function* watchDeleteCampaign() {
  yield takeLatest(CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_START, deleteCampaign);
}

export function* createDefaultCampaignFlow() {
  yield takeLatest(CAMPAIGN_ACTION.CREATE_DEFAULT_CAMPAIGN_START, createDefaultCampaign);
}

export function* createSupportingDescriptionDefaultCampaignFlow() {
  yield takeLatest(
    CAMPAIGN_ACTION.CREATE_SUPPORTING_DESCRIPTION_FOR_DEFAULT_CAMPAIGN_START,
    createSupportingDescriptionDefaultCampaign,
  );
}
export function* createSupportingMessageFlowDefaultCampaignFlow() {
  yield takeLatest(
    CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_FLOW_FOR_DEFAULT_CAMPAIGN_START,
    createSupportingMessageFlowDefaultCampaign,
  );
}
export function* createSupportingMessageSampleDefaultCampaignFlow() {
  yield takeLatest(
    CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_SAMPLE_FOR_DEFAULT_CAMPAIGN_START,
    createSupportingMessageSampleDefaultCampaign,
  );
}
export function* campaignFlow() {
  yield all([
    watchFetchCampaign(),
    watchCreateCampaign(),
    watchUpdateCampaign(),
    watchDeleteCampaign(),
    createDefaultCampaignFlow(),
    createSupportingDescriptionDefaultCampaignFlow(),
    createSupportingMessageFlowDefaultCampaignFlow(),
    createSupportingMessageSampleDefaultCampaignFlow(),
  ]);
}
