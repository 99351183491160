import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import IdentifyAction from './identify.actions';

export function* updateIdentify({ payload }) {
  const { _contact, data, actionType, _user, callback } = payload;
  try {
    const response = yield call(axios.post, '/identify/updateIdentify', {
      _contact,
      data,
      actionType,
      _user,
    });
    if (response && response.data) {
      yield put(IdentifyAction.fetchIdentifyByContact(_contact));
      yield put(IdentifyAction.updateIdentifySuccess(response.data, _contact));
      if (callback) callback();
    } else {
      yield put(IdentifyAction.updateIdentifyFailure('Fail to update identify'));
    }
  } catch (error) {
    yield IdentifyAction.updateIdentifyFailure(error.message);
    console.log({ error });
  }
}
export function* fetchIdentifyByContact({ payload }) {
  const { _contact } = payload;
  try {
    const response = yield call(axios.get, `/identify/byContact/${_contact}`);
    if (response && response.data) {
      yield put(IdentifyAction.fetchIdentifyByContactSuccess(response.data, _contact));
    } else {
      yield put(IdentifyAction.fetchIdentifyByContactFailure('Fail to fetch identify'));
    }
  } catch (error) {
    yield IdentifyAction.fetchIdentifyByContactFailure(error.message);
    console.log({ error });
  }
}
export function* removeContactId({ payload, callback }) {
  const { identify_string, _contact } = payload;
  try {
    const response = yield call(axios.post, '/identify/removeContactId', {
      identify_string,
    });
    if (response && response.status === 200) {
      yield put(IdentifyAction.fetchIdentifyByContact(_contact));
      yield put(IdentifyAction.removeContactIdSuccess());
      if (callback) callback(null, response.status);
    } else {
      yield put(IdentifyAction.removeContactIdFailure('Fail to remove contactId'));
    }
  } catch (error) {
    yield IdentifyAction.removeContactIdFailure(error.message);
    console.log({ error });
  }
}
export function* fetchAllIdentify() {
  try {
    const response = yield call(axios.get, '/identify/');
    if (response && response.data) {
      yield put(IdentifyAction.fetchAllIdentifySuccess(response.data));
    } else {
      yield put(IdentifyAction.fetchAllIdentifyFailure('Fail to remove contactId'));
    }
  } catch (error) {
    yield IdentifyAction.fetchAllIdentifyFailure(error.message);
    console.log({ error });
  }
}
export function* checkDuplicate({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/identify/checkDuplicate', payload);
    if (response && response.status === 200) {
      if (callback) callback(null, response.data);
    }
  } catch (error) {
    if (callback) callback(error, null);
  }
}
export default function* identifySagaFlow() {
  yield takeLatest(IdentifyAction.UPDATE_IDENTIFY.START, updateIdentify);
  yield takeLatest(IdentifyAction.FETCH_IDENTIFY.START, fetchIdentifyByContact);
  yield takeLatest(IdentifyAction.REMOVE_CONTACT_ID.START, removeContactId);
  yield takeLatest(IdentifyAction.FETCH_ALL_IDENTIFY.START, fetchAllIdentify);
  yield takeLatest(IdentifyAction.CHECK_DUPLICATE.START, checkDuplicate);
}
