import { put, takeLatest } from 'redux-saga/effects';
import SocketAction from '../socket/socket.action';
import GroupAction from '../group/group.actions';
import CampaignAction from '../campaign/campaign.actions';

const { SOCKET_EVENT } = SocketAction;

export function* fetchGroupRealtime() {
  yield put(GroupAction.fetchGroups());
}

export function* fetchGroupByIdRealtime({ payload: id }) {
  yield put(GroupAction.fetchGroupById(id));
}

export function* deleteGroupRealtime({ payload: id }) {
  yield put(GroupAction.removeGroupRedux(id));
}

export function* realtimeGroupFlow() {
  yield takeLatest(SOCKET_EVENT.FETCH_GROUPS, fetchGroupRealtime);
  yield takeLatest(SOCKET_EVENT.FETCH_GROUP_BY_ID, fetchGroupRealtime);
  yield takeLatest(SOCKET_EVENT.DELETE_GROUP, deleteGroupRealtime);
}
