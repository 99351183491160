export default class BroadCastAction {
  // Action Types
  static broadcastActionTypes = {
    FETCH_BROADCAST_ASYNC_START: 'BroadCastAction/FETCH_BROADCAST_START',
    FETCH_BROADCAST_ASYNC_SUCCESS: 'BroadCastAction/FETCH_BROADCAST_SUCCESS',
    FETCH_BROADCAST_ASYNC_FAILURE: 'BroadCastAction/FETCH_BROADCAST_FAILURE',
    FETCH_INDIVIDUAL_BROADCAST_START: 'BroadCastAction/FETCH_INDIVIDUAL_BROADCAST_START',
    FETCH_INDIVIDUAL_BROADCAST_SUCCSS: 'BroadCastAction/FETCH_INDIVIDUAL_BROADCAST_SUCCSS',
    FETCH_INDIVIDUAL_BROADCAST_FAILURE: 'BroadCastAction/FETCH_INDIVIDUAL_BROADCAST_FAILURE',
    FETCH_BROADCAST_CREATE_START: 'BroadCastAction/FETCH_BROADCAST_CREATE_START',
    FETCH_BROADCAST_CREATE_SUCCESS: 'BroadCastAction/FETCH_BROADCAST_CREATE_SUCCESS',
    FETCH_BROADCAST_CREATE_FAILURE: 'BroadCastAction/FETCH_BROADCAST_CREATE_FAILURE',
    SET_BROADCAST_REPORT_STATUS_START: 'BroadCastAction/SET_BROADCAST_REPORT_STATUS_START',
    SET_BROADCAST_REPORT_STATUS_START_SUCCESS: 'BroadCastAction/SET_BROADCAST_REPORT_STATUS_START_SUCCESS',
    SET_BROADCAST_REPORT_STATUS_FAILURE: 'BroadCastAction/SET_BROADCAST_REPORT_STATUS_FAILURE',
  };

  // fetch borad
  static fetchBroadcastStart = (payload) => ({
    type: this.broadcastActionTypes.FETCH_BROADCAST_ASYNC_START,
    payload,
  });

  static fetchBroadcastSuccess = (payload) => ({
    type: this.broadcastActionTypes.FETCH_BROADCAST_ASYNC_SUCCESS,
    payload,
  });

  static fetchBroadcastFailure = (errorMessage) => ({
    type: this.broadcastActionTypes.FETCH_BROADCAST_ASYNC_FAILURE,
    payload: errorMessage,
  });

  // fetch broad report
  static fetchBroadcastReportStartAsync = (payload) => ({
    type: this.broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_START,
    payload,
  });

  static fetchBroadcastReportSuccess = (data) => ({
    type: this.broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_SUCCSS,
    payload: data,
  });

  static fetchBroadcastReportFailure = (errorMessage) => ({
    type: this.broadcastActionTypes.FETCH_INDIVIDUAL_BROADCAST_FAILURE,
    payload: errorMessage,
  });

  // create broad
  static handleCreatBroadCastStart = (payload) => ({
    type: this.broadcastActionTypes.FETCH_BROADCAST_CREATE_START,
    payload,
  });

  static handleCreateBroadCasrFailure = (errorMessage) => ({
    type: this.broadcastActionTypes.FETCH_BROADCAST_CREATE_FAILURE,
    payload: errorMessage,
  });

  // set status broadcast
  static handleSetStatusBroadCastStart = (payload) => ({
    type: this.broadcastActionTypes.SET_BROADCAST_REPORT_STATUS_START,
    payload,
  });

  static handleSetStatusBroadCastSuccess = (payload) => ({
    type: this.broadcastActionTypes.SET_BROADCAST_REPORT_STATUS_START_SUCCESS,
    payload,
  });

  static handleSetStatusBroadCastFailure = (errorMessage) => ({
    type: this.broadcastActionTypes.SET_BROADCAST_REPORT_STATUS_FAILURE,
    payload: errorMessage,
  });
}
