export default class SocketAction {
  static JOIN_ROOM = 'Socket/JOIN_ROOM';

  static JOIN_CONNECTION_ROOM = 'Socket/JOIN_CONNECTION_ROOM';

  static LEAVE_CONNECTION_ROOM = 'Socket/LEAVE_CONNECTION_ROOM';

  static DISCONNECT = 'Socket/DISCONNECT';

  static SET_IS_CONNECTED = 'Socket/SET_IS_CONNECTED';

  static LEAVE_ALL_ROOM = 'Socket/LEAVE_ALL_ROOM';

  static LEAVE_ROOM = 'Socket/LEAVE_ROOM';

  static UPDATE_ROOM = 'Socket/UPDATE_ROOM';

  static JOIN_ALL_ROOMS_AND_RECEIVE_SOCKET_EVENT = 'Socket/JOIN_ALL_ROOMS_AND_RECEIVE_SOCKET_EVENT';

  static SOCKET_EVENT = {
    JOIN_ALL_ROOMS: 'SocketEvent/JOIN_ALL_ROOMS',
    // Groups
    FETCH_GROUPS: 'SocketEvent/FETCH_GROUPS',
    FETCH_GROUP_BY_ID: 'SocketEvent/FETCH_GROUP_BY_ID',
    DELETE_GROUP: 'SocketEvent/DELETE_GROUP',

    // Message
    RECEIVE_MESSAGE: 'SocketEvent/RECEIVE_MESSAGE',
    RECEIVE_MESSAGE_STATUS: 'SocketEvent/RECEIVE_MESSAGE_STATUS',
    LIVECHAT_TYPING_EVENT: 'SocketEvent/LIVECHAT_TYPING_EVENT',

    // CDR
    UPDATE_CDR: 'SocketEvent/UPDATE_CDR',

    // Conversation
    ADD_CONVERSATION: 'SocketEvent/ADD_CONVERSATION',
    CREATE_CONVERSATION: 'SocketEvent/CREATE_CONVERSATION',
    UNREAD_CONVERSATION: 'SocketEvent/UNREAD_CONVERSATION',
    ROUTE_CONVERSATION: 'SocketEvent/ROUTE_CONVERSATION',
    RECEIVE_CLOSE_COVERSATION_STATUS: 'SocketEvent/RECEIVE_CLOSE_COVERSATION_STATUS',
    FETCH_CONVERSATION_BY_ID: 'SocketEvent/FETCH_CONVERSATION_BY_ID',
    FETCH_CONVERSATIONS: 'SocketEvent/FETCH_CONVERSATIONS',

    // FieldValues
    FETCH_FIELD_VALUES_BY_IDS: 'SocketEvent/FETCH_FIELD_VALUES_BY_IDS',
    DELETE_FIELD_VALUES_BY_IDS: 'SocketEvent/DELETE_FIELD_VALUES_BY_IDS',
    UPDATE_FIELD_VALUES: 'SocketEvent/UPDATE_FIELD_VALUES',

    // Field
    DELETE_FIELD: 'SocketEvent/DELETE_FIELD',

    // Contact
    CREATE_CONTACT: 'SocketEvent/CREATE_CONTACT',
    UPDATE_CONTACT: 'SocketEvent/UPDATE_CONTACT',
    DELETE_CONTACT: 'SocketEvent/DELETE_CONTACT',
    REFRESH_DNC: 'SocketEvent/REFRESH_DNC',
    FETCH_CONTACT_BY_ID: 'SocketEvent/FETCH_CONTACT_BY_ID',
    FETCH_CONTACTS: 'SocketEvent/FETCH_CONTACTS',
    DELETE_CONTACTS: 'SocketEvent/DELETE_CONTACTS',

    // DNC
    FETCH_DNC_BY_ID: 'SocketEvent/FETCH_DNC_BY_ID',
    DELETE_DNC: 'SocketEvent/DELETE_DNC',

    // Broadcast
    UPDATE_BROADCAST: 'SocketEvent/UPDATE_BROADCAST',

    // Campaign
    SHOW_IN_MEMBER_QUEUE: 'SocketEvent/SHOW_IN_MEMBER_QUEUE',
    UPDATE_CAMPAIGN: 'SocketEvent/UPDATE_CAMPAIGN',
    DELETE_CAMPAIGN: 'SocketEvent/DELETE_CAMPAIGN',
    REMOVE_CAMPAIGN_BY_OWNER: 'SocketEvent/REMOVE_CAMPAIGN_BY_OWNER',
    FETCH_CAMPAIGN_BY_OWNER: 'SocketEvent/FETCH_CAMPAIGN_BY_OWNER',

    // Voice
    VOICE_INIT: 'SocketEvent/VOICE_INIT',
    VOICE_INCALL: 'SocketEvent/VOICE_INCALL',
    VOICE_HUNGUP: 'SocketEvent/VOICE_HUNGUP',

    // DID
    FETCH_DID: 'SocketEvent/FETCH_DID',
    DELETE_DID: 'SocketEvent/DELETE_DID',

    // User
    UPDATE_ONLINE_USER: 'SocketEvent/UPDATE_ONLINE_USER',
    DELETE_ONLINE_USER: 'SocketEvent/DELETE_ONLINE_USER',
    UPDATE_USER: 'SocketEvent/UPDATE_USER',
    UPDATE_USER_FIELD: 'SocketEvent/UPDATE_USER_FIELD',
    UPDATE_CURRENT_USER: 'SocketEvent/UPDATE_CURRENT_USER',
    LOG_OUT: 'SocketEvent/LOG_OUT',

    // MergeLog
    FETCH_MERGE_LOG_BY_IDS: 'SocketEvent/FETCH_MERGE_LOG_BY_IDS',

    // Members
    DELETE_MEMBERS_BY_IDS: 'SocketEvent/DELETE_MEMBERS_BY_IDS',

    // Members Admin
    FETCH_MEMBER_ADMINS: 'SocketEvent/FETCH_MEMBER_ADMINS',
    FETCH_IDENTIFY: 'SocketEvent/FETCH_IDENTIFY',
    FETCH_BULK_UPLOAD_CONTACT_STATUS: 'SocketEvent/FETCH_BULK_UPLOAD_CONTACT_STATUS',

    CALL_UPDATE_SESSION_LIST_GROUP: 'SocketEvent/CALL_UPDATE_SESSION_LIST_GROUP',
    UPDATE_LIST_INVITED_EXTERNAL_CALL: 'SocketEvent/UPDATE_LIST_INVITED_EXTERNAL_CALL',
    UPDATE_CALL_LOG_CONFERENCE: 'SocketEvent/UPDATE_CALL_LOG_CONFERENCE',
    FORCE_HUNG_UP_CALL_CONVERSATION: 'SocketEvent/FORCE_HUNG_UP_CALL_CONVERSATION',
    REQUEST_SYNC_VOICE_DATA: 'SocketEvent/REQUEST_SYNC_VOICE_DATA',
    SYNC_VOICE_DATA: 'SocketEvent/SYNC_VOICE_DATA',
  };
  static CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND = 'SocketEvent/CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND';
  static CALL_MAKE_UPDATE_STATE_CALL_OUTBOUND = 'SocketEvent/CALL_MAKE_UPDATE_STATE_CALL_OUTBOUND';
  static CALL_UPDATE_SESSION_LIST_GROUP_ACTION = 'SocketEvent/CALL_UPDATE_SESSION_LIST_GROUP_ACTION';
  static UPDATE_LIST_INVITED_EXTERNAL_CALL_ACTION = 'SocketEvent/UPDATE_LIST_INVITED_EXTERNAL_CALL_ACTION';
  static AGENT_TYPING_EVENT = 'SocketEvent/AGENT_TYPING_EVENT';
  static FORCE_HUNG_UP_CALL_CONVERSATION_ACTION = 'SocketEvent/FORCE_HUNG_UP_CALL_CONVERSATION_ACTION';
  static REQUEST_SYNC_VOICE_DATA_ACTION = 'SocketEvent/REQUEST_SYNC_VOICE_DATA_ACTION';
  static SYNC_VOICE_DATA_ACTION = 'SocketEvent/SYNC_VOICE_DATA_ACTION';

  static requestSyncVoiceData = (payload) => {
    return {
      type: this.REQUEST_SYNC_VOICE_DATA_ACTION,
      payload,
    };
  };
  static syncVoiceData = (payload) => {
    return {
      type: this.SYNC_VOICE_DATA_ACTION,
      payload,
    };
  };

  static forceHungUpCallInConversation = (payload) => {
    return {
      type: this.FORCE_HUNG_UP_CALL_CONVERSATION_ACTION,
      payload,
    };
  };

  static callInboundReceiveOrUpdateState = (payload) => ({
    type: this.CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND,
    payload,
  });

  static callInboundReceiveOrUpdateState = (payload) => ({
    type: this.CALL_RECEIVE_UPDATE_STATE_CALL_INBOUND,
    payload,
  });

  static callOutboundMakeOrUpdateState = (payload) => ({
    type: this.CALL_MAKE_UPDATE_STATE_CALL_OUTBOUND,
    payload,
  });

  static callUpdateListSessionInGroup = (payload) => {
    return {
      type: this.CALL_UPDATE_SESSION_LIST_GROUP_ACTION,
      payload,
    };
  };

  static callUpdateListInvitedExternalCall = (payload) => {
    return {
      type: this.UPDATE_LIST_INVITED_EXTERNAL_CALL_ACTION,
      payload,
    };
  };

  static agentTypingEvent = (conversationId, groupId, userId, message, isTyping) => ({
    type: this.AGENT_TYPING_EVENT,
    payload: { conversationId, groupId, userId, message, isTyping },
  });

  static setConnected = (payload) => ({
    type: this.SET_IS_CONNECTED,
    payload,
  });

  static joinAllRooms = (callback = () => {}) => ({
    type: this.SOCKET_EVENT.JOIN_ALL_ROOMS,
    callback,
  });

  static joinRoom = (payload) => ({
    type: this.JOIN_ROOM,
    payload,
  });

  static leaveAllRoom = (payload) => ({
    type: this.LEAVE_ALL_ROOM,
    payload,
  });

  static joinConnectionRoom = (payload) => ({
    type: this.JOIN_CONNECTION_ROOM,
    payload,
  });

  static leaveConnectionRoom = (payload) => ({
    type: this.LEAVE_CONNECTION_ROOM,
    payload,
  });

  static leaveRoom = (payload) => ({
    type: this.LEAVE_ROOM,
    payload,
  });

  static leaveAllRoom = (payload) => ({
    type: this.LEAVE_ALL_ROOM,
    payload,
  });

  static updateRoom = (rooms) => ({
    type: this.UPDATE_ROOM,
    payload: rooms,
  });

  static disconnect = (payload) => ({
    type: this.DISCONNECT,
    payload,
  });

  static joinAllRoomsAndReceiveSocketEvent = () => ({
    type: this.JOIN_ALL_ROOMS_AND_RECEIVE_SOCKET_EVENT,
  });

  static fetchBulkUploadContactStatus = () => ({
    type: this.SOCKET_EVENT.FETCH_BULK_UPLOAD_CONTACT_STATUS,
  });
}
