import { put, takeLatest } from 'redux-saga/effects';
import FieldAction from '../field/field.actions';

import SocketAction from '../socket/socket.action';

function* handleDeleteField({ payload: id }) {
  try {
    yield put(FieldAction.deleteFieldSuccess(id));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeFieldFlow() {
  yield takeLatest(SocketAction.SOCKET_EVENT.DELETE_FIELD, handleDeleteField);
}
