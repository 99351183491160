import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import WorkflowAction from './workflow.actions';

export function* executeWorkflow({ payload }) {
  try {
    const response = yield call(axios.post, '/workflow/execute', payload);
    if (response && response.data) {
      yield put(WorkflowAction.executeWorkflowSuccess());
    } else {
      yield put(WorkflowAction.executeWorkflowFailure('Execute failed'));
    }
  } catch (error) {
    yield put(WorkflowAction.executeWorkflowFailure(error.message));
    console.log({ error });
  }
}

export default function* workflowSagaFlow() {
  yield takeLatest(WorkflowAction.EXECUTE_WORKFLOW.START, executeWorkflow);
}
