import HomeAction from './home.actions';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  profile: false,
  openSidebar: false,
  active: 'chats',
  link: 'chats',
  messageErrorMessage: undefined,
  newMessage: false,
  newGroupModal: false,
  newListModal: false,
  modifyGroupModal: null,
  newDidModal: null,
  ModifyDidModal: null,
  newConversationModal: null,
  deleteDidModal: null,
  deleteGroupModal: null,
  filterConversationModal: null,
  imagePreviewData: null,
  newAddContactModal: false,
};

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeAction.SET_IMAGE_PREVIEW:
      return {
        ...state,
        imagePreviewData: action.payload.src ? action.payload : null,
      };

    case HomeAction.TOGGLE_PROFILE_HIDDEN:
      return {
        ...state,
        profile: !state.profile,
      };
    case HomeAction.TOGGLE_OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: action.payload,
      };
    case HomeAction.SET_NAV_LINK:
      return {
        ...state,
        link: action.payload,
        active: action.payload,
      };
    case HomeAction.UPDATE_CONVERSATION_MODAL:
      return {
        ...state,
        newConversationModal: action.payload,
      };

    case HomeAction.UPDATE_NEW_GROUP_MODAL:
      return {
        ...state,
        newGroupModal: action.payload,
      };

    case HomeAction.UPDATE_NEW_ADD_CONTACT_MODAL:
      return {
        ...state,
        newAddContactModal: action.payload,
      };

    case HomeAction.UPDATE_NEW_LIST_MODAL:
      return {
        ...state,
        newListModal: action.payload,
      };

    case HomeAction.UPDATE_NEW_DID_MODAL:
      return {
        ...state,
        newDidModal: action.payload,
      };

    case HomeAction.MODIFY_GROUP_MODAL:
      return {
        ...state,
        modifyGroupModal: action.payload,
      };

    case HomeAction.MODIFY_DID_MODAL:
      return {
        ...state,
        ModifyDidModal: action.payload,
      };

    case HomeAction.DELETE_DID_MODAL:
      return {
        ...state,
        deleteDidModal: action.payload,
      };
    case HomeAction.DELETE_GROUP_MODAL:
      return {
        ...state,
        deleteGroupModal: action.payload,
      };
    case HomeAction.FILTER_CONVERSATION: {
      return {
        ...state,
        filterConversationModal: action.payload,
      };
    }

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default homeReducer;
