import axios from 'axios';
import { get, isEmpty, omitBy } from 'lodash';
import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';
import { CUSTOM_FIELDS_NAME } from '../../enums/modals/groups';
import { decodePhone, decodePhoneToFormat } from '../../util';
import ContactAction from '../contact/contact.actions';
import FieldValueAction from './field-value.actions';

export function* updateFieldValues({ payload }) {
  const { data, newFieldValues, deletedFieldValues, contactId } = payload;
  try {
    const response = yield call(axios.put, '/field_values', {
      fieldValues: data,
      newFieldValues: omitBy(newFieldValues, (fv) => {
        if (fv.isPhoneFieldValue) {
          return isEmpty(decodePhone(fv.value)?.phone_number);
        }
        return isEmpty(fv.value);
      }),
      deletedFieldValues,
      _contact: contactId,
    });

    if (response && response.data) {
      const errorMessage = get(response, 'data.error.message');
      const isDuplicate = get(response, 'data.isDuplicate');
      const existFieldValue = get(response, 'data.existFieldValue.0');

      if (isDuplicate) {
        toast.error(errorMessage);
        yield put(ContactAction.setDuplicateContact([contactId, existFieldValue?._contact]));
        yield put(FieldValueAction.setDuplicateValue(existFieldValue));
        yield put(FieldValueAction.updateFieldValuesFailure(errorMessage));
      } else {
        yield put(FieldValueAction.setFieldValues(response.data));
      }
    }
  } catch (error) {
    const message = get(
      error,
      'response.data.error.message',
      get(error, 'response.data.message', get(error, 'message')),
    );
    if (error.response) {
      const isDuplicate = get(error, 'response.data.isDuplicate');
      const existFieldValue = get(error, 'response.data.existFieldValue.0');
      if (isDuplicate) {
        toast.error(message);
        yield put(ContactAction.setDuplicateContact([contactId, existFieldValue?._contact]));
        yield put(FieldValueAction.setDuplicateValue(existFieldValue));
      }
    } else {
      toast.error(message);
    }
    yield put(FieldValueAction.updateFieldValuesFailure(message));
  }
}

export function* fetchFieldValueByIds({ payload }) {
  try {
    const response = yield call(axios.post, '/field_values/byIds', { fieldValueIds: payload });
    if (response && response.data) {
      yield put(FieldValueAction.fetchFieldValueByIdsSuccess(response.data));
    }
  } catch (error) {
    yield put(FieldValueAction.fetchFieldValueByIdsFailure(error));
    console.error({ error });
  }
}

export default function* fieldValueSagaFlow() {
  yield takeLatest(FieldValueAction.UPDATE_FIELD_VALUES, updateFieldValues);
  yield takeLatest(FieldValueAction.FETCH_FIELD_VALUES_BY_IDS.START, fetchFieldValueByIds);
}
