export default class IdentifyAction {
  static UPDATE_IDENTIFY = {
    START: 'IdentifyAction/UPDATE_IDENTIFY_START',
    SUCCESS: 'IdentifyAction/UPDATE_IDENTIFY_SUCCESS',
    FAILURE: 'IdentifyAction/UPDATE_IDENTIFY_FAILURE',
  };

  static FETCH_IDENTIFY = {
    START: 'IdentifyAction/FETCH_IDENTIFY_START',
    SUCCESS: 'IdentifyAction/FETCH_IDENTIFY_SUCCESS',
    FAILURE: 'IdentifyAction/FETCH_IDENTIFY_FAILURE',
  };

  static REMOVE_CONTACT_ID = {
    START: 'IdentifyAction/REMOVE_CONTACT_ID_START',
    SUCCESS: 'IdentifyAction/REMOVE_CONTACT_ID_SUCCESS',
    FAILURE: 'IdentifyAction/REMOVE_CONTACT_ID_FAILURE',
  };

  static FETCH_ALL_IDENTIFY = {
    START: 'IdentifyAction/FETCH_ALL_IDENTIFY_START',
    SUCCESS: 'IdentifyAction/FETCH_ALL_IDENTIFY_SUCCESS',
    FAILURE: 'IdentifyAction/FETCH_ALL_IDENTIFY_FAILURE',
  };

  static CHECK_DUPLICATE = {
    START: 'IdentifyAction/CHECK_DUPLICATE_START',
  };

  static updateIdentify = (data, _contact, actionType, _user, callback) => ({
    type: IdentifyAction.UPDATE_IDENTIFY.START,
    payload: { data, _contact, actionType, _user, callback },
  });

  static updateIdentifySuccess = (data, _contact) => ({
    type: IdentifyAction.UPDATE_IDENTIFY.SUCCESS,
    payload: { data, _contact },
  });

  static updateIdentifyFailure = (errorMessage) => ({
    type: IdentifyAction.UPDATE_IDENTIFY.FAILURE,
    payload: errorMessage,
  });

  static fetchIdentifyByContact = (_contact) => ({
    type: IdentifyAction.FETCH_IDENTIFY.START,
    payload: { _contact },
  });

  static fetchIdentifyByContactSuccess = (data, _contact) => ({
    type: IdentifyAction.FETCH_IDENTIFY.SUCCESS,
    payload: { data, _contact },
  });

  static fetchIdentifyByContactFailure = (errorMessage) => ({
    type: IdentifyAction.FETCH_IDENTIFY.FAILURE,
    payload: errorMessage,
  });

  static removeContactId = (identify_string, _contact, callback) => ({
    type: IdentifyAction.REMOVE_CONTACT_ID.START,
    payload: { identify_string, _contact },
    callback,
  });

  static removeContactIdSuccess = () => ({
    type: IdentifyAction.REMOVE_CONTACT_ID.SUCCESS,
  });

  static removeContactIdFailure = (errorMessage) => ({
    type: IdentifyAction.REMOVE_CONTACT_ID.FAILURE,
    payload: errorMessage,
  });

  static fetchAllIdentify = () => ({
    type: IdentifyAction.FETCH_ALL_IDENTIFY.START,
    payload: {},
  });

  static fetchAllIdentifySuccess = (data) => ({
    type: IdentifyAction.FETCH_ALL_IDENTIFY.SUCCESS,
    payload: { data },
  });

  static fetchAllIdentifyFailure = (errorMessage) => ({
    type: IdentifyAction.FETCH_ALL_IDENTIFY.FAILURE,
    payload: errorMessage,
  });

  static checkAndFindDuplicate = (payload, callback) => ({
    type: IdentifyAction.CHECK_DUPLICATE.START,
    payload,
    callback,
  });
}
