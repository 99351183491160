import { put, all, takeEvery, select } from 'redux-saga/effects';
import { includes } from 'lodash';
import SocketAction from '../socket/socket.action';
import MessageAction from '../message/message.actions';
import ConversationAction from '../conversation/conversation.actions';
import UserSelector from '../user/user.selectors';
import MessageSelector from '../message/message.selectors';
import ConversationSelector from '../conversation/conversation.selectors';
import { MESSAGE_TYPES } from '../../enums/message/message';
import { Toast } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReportAction from '../report/report.action';

const { SOCKET_EVENT } = SocketAction;

function* handleReceivedMessage({ payload }) {
  const { message } = payload;
  try {
    yield put(MessageAction.receivedNewMessage(message));
  } catch (error) {
    console.log({ error });
  }
}

function* handleReceivedMessageStatus({ payload }) {
  try {
    const { error } = payload;
    if (error?.error === '21610') {
      // contact is on dnc
      toast.error(
        `The customer has requested to be put on the DNC List. In order to continue messaging this phone number, the customer would need to send in 'START'`,
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

// when receive new message
export function* handleUnreadConversation({ payload }) {
  const { message, conversation } = payload;
  const selectMessages = yield select(MessageSelector.selectMessages);
  const selectCurrentUser = yield select(UserSelector.selectCurrentUser);
  const selectConversation = yield select(ConversationSelector.selectByConversationId(conversation.id));

  // update unread + mentions
  if (selectMessages.key !== conversation.id && selectConversation && !(message.type === MESSAGE_TYPES.SYSTEM)) {
    const { mentions = [] } = message;
    const mentionUnreadCount =
      message.type === MESSAGE_TYPES.INTERNAL && includes(mentions, selectCurrentUser.id)
        ? selectConversation.mentionUnreadCount + 1
        : selectConversation.mentionUnreadCount;
    const unreadCount = !message._from ? selectConversation.unreadCount + 1 : selectConversation.unreadCount;

    yield put(
      ConversationAction.updateIndividualConversation({
        id: conversation.id,
        mentionUnreadCount,
        unreadCount,
      }),
    );
  }

  // update last message
  if (conversation && conversation.id) {
    // const { message: messageText } = message;
    yield put(
      ConversationAction.updateConversationLastMessage({
        conversationId: conversation.id,
        last_message: message,
      }),
    );
  }
  yield put(ReportAction.setFetchReport(true));
}

export function* realtimeMessageFlow() {
  yield all([
    takeEvery(SOCKET_EVENT.RECEIVE_MESSAGE, handleUnreadConversation),
    takeEvery(SOCKET_EVENT.RECEIVE_MESSAGE, handleReceivedMessage),
    takeEvery(SOCKET_EVENT.RECEIVE_MESSAGE_STATUS, handleReceivedMessageStatus),
  ]);
}
