import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import UserSelector from './redux/user/user.selectors';
import { PATH_APP } from './enums/path/app';
import LoadingIndicator from './components/WithSpinner/LoadingIndicator';
import loadable from './util/loadable';

import SocketAction from './redux/socket/socket.action';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.scss';

const NotFound = loadable(() => import('./pages/Other/NotFound'), { fallback: <LoadingIndicator /> });
const Forbidden = loadable(() => import('./pages/Other/Forbidden'), { fallback: <LoadingIndicator /> });
const Unauthorized = loadable(() => import('./pages/Other/Unauthorized'), { fallback: <LoadingIndicator /> });
const Landing = loadable(() => import('./pages/Landing/Landing'), { fallback: <LoadingIndicator /> });
const Login = loadable(() => import('./pages/Login/Login'), { fallback: <LoadingIndicator /> });
const Register = loadable(() => import('./pages/Register/Register'), { fallback: <LoadingIndicator /> });
const ForgotPassword = loadable(() => import('./pages/ForgotPassword/ForgotPassword'), {
  fallback: <LoadingIndicator />,
});
const ResetPassword = loadable(() => import('./pages/ResetPassword/ResetPassword'), {
  fallback: <LoadingIndicator />,
});
const Dashboard = loadable(() => import('./components/Dashboard/Dashboard'), {
  fallback: <LoadingIndicator />,
});
const Request = loadable(() => import('./pages/Request/Request'), { fallback: <LoadingIndicator /> });
const Privacy = loadable(() => import('./pages/Privacy/Privacy'), { fallback: <LoadingIndicator /> });
const Terms = loadable(() => import('./pages/Terms/Terms'), { fallback: <LoadingIndicator /> });
const Verify = loadable(() => import('./pages/Verify/Verify'), { fallback: <LoadingIndicator /> });
class App extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div style={{ flex: 1 }}>
        <BrowserRouter>
          <Switch>
            <Route exact path={PATH_APP.LANDING_PATH} component={Landing} />
            <Route exact path={PATH_APP.REQUEST_PATH} component={Request} />
            <Route exact path={PATH_APP.PRIVACY_PATH} component={Privacy} />
            <Route exact path={PATH_APP.TERM_PATH} component={Terms} />
            <Route exact path={PATH_APP.REGISTER_PATH} component={Register} />
            <Route path={PATH_APP.VERIFY_PATH} component={Verify} />
            <Route exact path={PATH_APP.FORGOT_PASSWORD_PATH} component={ForgotPassword} />
            <Route exact path={PATH_APP.RESET_PASSWORD_PATH} component={ResetPassword} />
            <Route path={PATH_APP.DASHBOARD_PATH} component={Dashboard} />
            <Route exact path={PATH_APP.LOGIN_PATH} component={Login} />
            <Route exact path={PATH_APP.FORBIDDEN_PATH} component={Forbidden} />
            <Route path={PATH_APP.UNAUTHORIZED_PATH} component={Unauthorized} />
            <Route path={PATH_APP.NOTFOUND_PATH} component={NotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer hideProgressBar autoClose={4000} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: UserSelector.selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  leaveSocketConnect: () => dispatch(SocketAction.leaveSocketConnect()),
  currentUser: UserSelector.selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
