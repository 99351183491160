export default class VoiceAction {
  static VoiceActionTypes = {
    CONNECT: 'Voice/CONNECT',
    REGISTER: 'Voice/REGISTER',
    CONNECTED: 'Voice/CONNECTED',
    DIALER_STATUS: 'Voice/DIALER_STATUS',
    TRYING: 'Voice/TRYING',
    RINGING: 'Voice/RINGING',
    TALKING: 'Voice/TALKING',
    REGISTERED: 'Voice/REGISTERED',
    TOGGLE_DIALPAD: 'Voice/TOGGLE_DIALPAD',
    TOGGLE_KEYPAD: 'Voice/TOGGLE_KEYPAD',
    DIALING_STATE: 'Voice/DIALING_STATE',
    DIALING: 'Voice/DIALING',
    ON_RECEIVE_MESSAGE: 'Voice/ON_RECEIVE_MESSAGE',
    ON_MESSAGE: 'Voice/ON_MESSAGE',
    ACCEPT_CALL: 'Voice/ACCEPT_CALL',
    QUICK_DIAL: 'Voice/QUICK_DIAL',
    HANGUP: 'Voice/HANGUP',
    INC_CALLTIMER: 'Voice/INC_CALLTIMER',
    RESET_CALLTIMER: 'Voice/RESET_CALLTIMER',
    MUTE: 'Voice/MUTE',
    UNMUTE: 'Voice/UNMUTE',
    MUTE_ID: 'Voice/MUTE_ID',
    UNMUTE_ID: 'Voice/UNMUTE_ID',
    HOLD_ID: 'Voice/HOLD_ID',
    UN_HOLD_ID: 'Voice/UN_HOLD_ID',
    CANCEL: 'Voice/CANCEL',
    UPDATE_VOICE_DATA: 'Voice/UPDATE_VOICE_DATA',
    REMOVE_CLOSED_SESSION: 'Voice/REMOVE_CLOSED_SESSION',
    JOIN_CALL: 'Voice/JOIN_CALL',
    FETCH_LOGS: 'Voice/FETCH_LOGS',
    FETCH_LOGS_SUCCESS: 'Voice/FETCH_LOGS_SUCCESS',
    FETCH_LOGS_FAILURE: 'Voice/FETCH_LOGS_FAILURE',
    SET_SESSION: 'Voice/SET_SESSION',
    UPDATE_SESSION_LIST: 'Voice/UPDATE_SESSION_LIST',
    LEAVE_CURRENT_CALL: 'Voice/LEAVE_CURRENT_CALL',
    INVITE_EXTERNAL_CALL: 'Voice/INVITE_EXTERNAL_CALL',
    HANGUP_EXTERNAL_CALL: 'Voice/HANGUP_EXTERNAL_CALL',
    SET_CURRENT_CALL: 'Voice/SET_CURRENT_CALL',
    REQUEST_JOIN_CALL: 'Voice/REQUEST_JOIN_CALL',
    UPDATE_LIST_INVITED_EXTERNAL_CALL: 'Voice/UPDATE_LIST_INVITED_EXTERNAL_CALL',
    GET_CALL_LOG: 'Voice/GET_CALL_LOG',
    SET_CALL_LOG: 'Voice/SET_CALL_LOG',
    MUTE_PARTICIPANT: 'Voice/MUTE_PARTICIPANT',
    GET_AND_REALTIME_SESSION_PAYLOAD: 'Voice/GET_AND_REALTIME_SESSION_PAYLOAD',
    FORCE_HUNG_UP_CALL_CONVERSATION: 'Voice/FORCE_HUNG_UP_CALL_CONVERSATION',
    SET_IN_CALL_STATUS: 'Voice/SET_IN_CALL_STATUS',
    SET_SESSION_ID: 'Voice/SET_SESSION_ID',
    ACCEPT_CALL_FROM_POPUP: 'Voice/ACCEPT_CALL_FROM_POPUP',
    HOLD_PARTICIPANT: 'Voice/HOLD_PARTICIPANT',
    RECORD_CALL: 'Voice/RECORD_CALL',
    SET_CURRENT_VOICE_STATE: 'Voice/SET_CURRENT_VOICE_STATE',
    GET_AND_SYNC_VOICE_DATA: 'Voice/GET_AND_SYNC_VOICE_DATA',
    SYNC_VOICE_DATA: 'Voice/SYNC_VOICE_DATA',
    SYNC_VOICE_DATA_TO_STORE: 'Voice/SYNC_VOICE_DATA_TO_STORE',
  };

  static syncVoiceDataToStore = (payload) => {
    return {
      type: this.VoiceActionTypes.SYNC_VOICE_DATA_TO_STORE,
      payload,
    };
  };

  static syncVoiceData = (payload) => {
    return {
      type: this.VoiceActionTypes.SYNC_VOICE_DATA,
      payload,
    };
  };

  static getAndSyncVoiceData = (payload) => {
    return {
      type: this.VoiceActionTypes.GET_AND_SYNC_VOICE_DATA,
      payload,
    };
  };

  static setCurrentVoiceState = (payload) => {
    return {
      type: this.VoiceActionTypes.SET_CURRENT_VOICE_STATE,
      payload,
    };
  };

  static setAcceptCallFromBackground = (payload) => {
    return {
      type: this.VoiceActionTypes.ACCEPT_CALL_FROM_POPUP,
      payload,
    };
  };

  static setAcceptCallFromBackground = (payload) => {
    return {
      type: this.VoiceActionTypes.ACCEPT_CALL_FROM_POPUP,
      payload,
    };
  };

  static setSessionId = (payload) => {
    return {
      type: this.VoiceActionTypes.SET_SESSION_ID,
      payload,
    };
  };
  static setForceHungUpCallConversation = (payload) => {
    return {
      type: this.VoiceActionTypes.FORCE_HUNG_UP_CALL_CONVERSATION,
      payload,
    };
  };

  static setInCallStatus = (payload) => {
    return {
      type: this.VoiceActionTypes.SET_IN_CALL_STATUS,
      payload,
    };
  };

  static getAndRealtimeSessionData = (payload) => {
    return {
      type: this.VoiceActionTypes.GET_AND_REALTIME_SESSION_PAYLOAD,
      payload,
    };
  };

  static handleMuteParticipants = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.MUTE_PARTICIPANT,
      payload,
      callback,
    };
  };

  static handleHoldParticipants = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.HOLD_PARTICIPANT,
      payload,
      callback,
    };
  };

  static handleRecordCall = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.RECORD_CALL,
      payload,
      callback,
    };
  };

  static callSetCallLogFromVoiceServer = (payload) => ({
    type: this.VoiceActionTypes.SET_CALL_LOG,
    payload,
  });

  static callGetCallLogFromVoiceServer = (payload) => ({
    type: this.VoiceActionTypes.GET_CALL_LOG,
    payload,
  });

  static updateListInvitedExternalCall = (payload) => {
    return {
      type: this.VoiceActionTypes.UPDATE_LIST_INVITED_EXTERNAL_CALL,
      payload,
    };
  };

  static requestToJoinCall = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.REQUEST_JOIN_CALL,
      payload,
      callback,
    };
  };

  static inviteExternalCall = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.INVITE_EXTERNAL_CALL,
      payload,
      callback,
    };
  };
  static hangupExternalCall = (payload, callback) => {
    return {
      type: this.VoiceActionTypes.HANGUP_EXTERNAL_CALL,
      payload,
      callback,
    };
  };
  static setCurrentCall = (payload) => {
    return {
      type: this.VoiceActionTypes.SET_CURRENT_CALL,
      payload,
    };
  };
  static updateSessionList = (payload) => {
    return {
      type: this.VoiceActionTypes.UPDATE_SESSION_LIST,
      payload,
    };
  };

  static setSession = (payload) => {
    return {
      type: this.VoiceActionTypes.SET_SESSION,
      payload,
    };
  };

  static updateVoiceData = (payload, latestSessionId) => {
    return {
      type: this.VoiceActionTypes.UPDATE_VOICE_DATA,
      payload,
      latestSessionId,
    };
  };

  static setJoinCall = (sessionId, isJoinCall) => {
    return {
      type: this.VoiceActionTypes.JOIN_CALL,
      payload: { sessionId, isJoinCall },
    };
  };

  static removeClosedSession = () => {
    return {
      type: this.VoiceActionTypes.REMOVE_CLOSED_SESSION,
    };
  };

  static apConnect = (payload) => {
    return {
      type: this.VoiceActionTypes.CONNECT,
      payload,
    };
  };

  static apRegister = (payload) => {
    return {
      type: this.VoiceActionTypes.REGISTER,
      payload,
    };
  };

  static registered = (payload) => {
    return {
      type: this.VoiceActionTypes.REGISTERED,
      payload,
    };
  };

  static connected = (payload) => {
    return {
      type: this.VoiceActionTypes.CONNECTED,
      payload,
    };
  };

  // uri, options, dtype, mType = '', mGateway = '', mConferenceId = '', mGroupId = ''
  static apDailing = (payload) => ({
    type: this.VoiceActionTypes.DIALING,
    payload,
  });

  static appDailingState = (payload) => ({
    type: this.VoiceActionTypes.DIALING_STATE,
    payload,
  });

  static hangup = (sessionId) => {
    return {
      type: this.VoiceActionTypes.HANGUP,
      payload: { sessionId },
    };
  };

  static acceptCall = (sessionId, config) => {
    return {
      type: this.VoiceActionTypes.ACCEPT_CALL,
      payload: { sessionId, config },
    };
  };

  static apDialerStatus = (status) => {
    return {
      type: this.VoiceActionTypes.DIALER_STATUS,
      payload: status,
    };
  };

  static toggleDialpad = (payload) => {
    return {
      type: this.VoiceActionTypes.TOGGLE_DIALPAD,
      payload,
    };
  };

  static toggleKeypad = (payload) => {
    return {
      type: this.VoiceActionTypes.TOGGLE_KEYPAD,
      payload,
    };
  };

  static incCalltimer = (payload) => {
    return {
      type: this.VoiceActionTypes.INC_CALLTIMER,
      payload,
    };
  };

  static resetCalltimer = (payload) => {
    return {
      type: this.VoiceActionTypes.RESET_CALLTIMER,
      payload,
    };
  };

  static muteCall = (sessionId) => {
    return {
      type: this.VoiceActionTypes.MUTE,
      payload: { sessionId },
    };
  };

  static unMuteCall = (sessionId) => {
    return {
      type: this.VoiceActionTypes.UNMUTE,
      payload: { sessionId },
    };
  };
  static muteCallUser({ sessionId, callId }, callback) {
    return {
      type: this.VoiceActionTypes.MUTE_ID,
      payload: {
        sessionId,
        callId,
      },
      callback,
    };
  }

  static unMuteCallUser({ sessionId, callId }, callback) {
    return {
      type: this.VoiceActionTypes.UNMUTE_ID,
      payload: {
        sessionId,
        callId,
      },
      callback,
    };
  }
  static holdCallUser({ sessionId, callId }, callback) {
    return {
      type: this.VoiceActionTypes.HOLD_ID,
      payload: {
        sessionId,
        callId,
      },
      callback,
    };
  }

  static unHoldCallUser({ sessionId, callId }, callback) {
    return {
      type: this.VoiceActionTypes.UN_HOLD_ID,
      payload: {
        sessionId,
        callId,
      },
      callback,
    };
  }
  static cancelCall = (sessionId) => {
    return {
      type: this.VoiceActionTypes.CANCEL,
      payload: { sessionId },
    };
  };

  // static messageFromSocketServer = (payload) => ({
  //   type: this.VoiceActionTypes.ON_MESSAGE,
  //   payload,
  // })
  static receiveMessageFromSocketServer = (payload) => ({
    type: this.VoiceActionTypes.ON_RECEIVE_MESSAGE,
    payload,
  });

  static fetchLogs = (payload) => ({ type: this.VoiceActionTypes.FETCH_LOGS, payload });

  static fetchLogsFailure = (error) => ({ type: this.VoiceActionTypes.FETCH_LOGS_FAILURE, payload: { error } });

  static leaveCurrentCall = () => {
    return {
      type: this.VoiceActionTypes.LEAVE_CURRENT_CALL,
    };
  };
}
