import axios from 'axios';
import { toast } from 'react-toastify';
import { debounce, call, put, takeLatest } from 'redux-saga/effects';
import SessionLogAction from './session-log.action';

export function* fetchSessionLogs({ payload }) {
  const { dateRange, ownerId } = payload;
  try {
    const response = yield call(axios.post, '/session_log/findByDateRange', { dateRange, ownerId });
    if (response && response.data) {
      yield put(SessionLogAction.fetchSessionLogByDateRangeSuccess(response.data));
    } else {
      yield put(SessionLogAction.fetchSessionLogByDateRangeFailure('Fetch session logs failed.'));
    }
  } catch (error) {
    yield put(SessionLogAction.fetchSessionLogByDateRangeFailure(error.message));
    toast.error(error.message);
    console.log({ error });
  }
}

export default function* sessionLogSagaFlow() {
  yield debounce(1000, SessionLogAction.FETCH_SESSION_LOG_BY_DATE_RANGE.START, fetchSessionLogs);
}
