import { groupBy, omit } from 'lodash';
import IdentifyAction from './identify.actions';
import { normalizeUpdatedData, normalizeNewData } from '../../util';
import { get, isString, isEqual, uniq, isNumber } from 'lodash';

const INITIAL_STATE = {
  fetching: false,
  fetchAll: false,
  updating: false,
  deleting: false,
  removingContactId: null,
  addingNewIdentify: null,
  data: {
    byId: {},
    allIds: [],
  },
  byContact: {
    byId: {},
    allIds: [],
  },
};
// const normalizeIdentify = (payload) => {
//   const rawData = {
//     byId: {},
//     allIds: [],
//   };
//   payload.forEach((identify) => {
//     rawData.byId[identify?.id] = identify;
//   });
//   rawData.allIds = payload.map((identify) => identify.id);
//   return rawData
// }
const normalizeIdentify = (state, payload) => {
  const byId = Array.isArray(payload)
    ? payload.reduce(
        (initValue, value) => ({
          ...initValue,
          [value?.id]: value,
        }),
        {},
      )
    : {};
  const newIdentifyList = Object.values(byId);
  const baseIdentifyList = Object.values(state.data.byId);
  const diff = baseIdentifyList.filter((base) => !byId[base.id] && newIdentifyList[0]?._contact === base?._contact);
  const allIds = Object.keys(byId);
  if (diff.length > 0) {
    const newById = {};
    const diffId = diff.map((item) => item?.id);
    baseIdentifyList.forEach((item) => {
      if (!diffId.includes(item?.id)) {
        newById[item?.id] = item;
      }
    });
    return {
      byId: newById,
      allIds: uniq(Object.keys(newById)),
    };
  }
  return {
    byId: { ...state.data.byId, ...byId },
    allIds: uniq([...state.data.allIds, ...allIds]),
  };
};

const identifyReducer = (state = INITIAL_STATE, action) => {
  if (action?.metadata?.identifies) {
    // const rawData = normalizeIdentify(action?.metadata?.identifies);
    return {
      ...state,
      fetching: false,
      data: normalizeIdentify(state, action?.metadata?.identifies),
    };
  }

  switch (action.type) {
    case IdentifyAction.UPDATE_IDENTIFY.START:
      return {
        ...state,
        addingNewIdentify: true,
      };
    case IdentifyAction.UPDATE_IDENTIFY.SUCCESS: {
      return {
        ...state,
        addingNewIdentify: false,
      };
    }
    case IdentifyAction.UPDATE_IDENTIFY.FAILURE:
      return {
        ...state,
        addingNewIdentify: false,
        errorMessage: action.payload,
      };
    case IdentifyAction.FETCH_IDENTIFY.START:
      return {
        ...state,
        fetching: true,
      };
    case IdentifyAction.FETCH_IDENTIFY.SUCCESS: {
      return {
        ...state,
        fetching: false,
        data: normalizeIdentify(state, action.payload.data),
      };
    }
    case IdentifyAction.FETCH_IDENTIFY.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case IdentifyAction.REMOVE_CONTACT_ID.START:
      return {
        ...state,
        removingContactId: true,
      };
    case IdentifyAction.REMOVE_CONTACT_ID.SUCCESS: {
      return {
        ...state,
        removingContactId: false,
      };
    }
    case IdentifyAction.REMOVE_CONTACT_ID.FAILURE:
      return {
        ...state,
        removingContactId: false,
        errorMessage: action.payload,
      };
    case IdentifyAction.FETCH_ALL_IDENTIFY.START:
      return {
        ...state,
        fetchAll: true,
      };
    case IdentifyAction.FETCH_ALL_IDENTIFY.SUCCESS: {
      // const rawData = normalizeIdentify(action?.metadata?.identifies);
      return {
        ...state,
        fetchAll: false,
        data: normalizeIdentify(state, action?.metadata?.identifies),
      };
    }
    case IdentifyAction.FETCH_ALL_IDENTIFY.FAILURE:
      return {
        ...state,
        fetchAll: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default identifyReducer;
