import { toast } from 'react-toastify';
import axios from 'axios';
import _, { compact, omit, flatten, uniqBy, get } from 'lodash';
import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import ContactAction from './contact.actions';
// import ContactUtils from "./contact.utils";
import FieldValueAction from '../field-value/field-value.actions';
import FieldAction from '../field/field.actions';
// import MessageAction from "../message/message.actions";
// import ConversationAction from "../conversation/conversation.actions";
import ConversationSelector from '../conversation/conversation.selectors';
import ContactSelector from './contact.selectors';

const ENUM_METADATA_CONTACT = {
  identifies: {
    path: 'identifies',
    defaultValues: [],
  },
  field_values: {
    path: 'field_values',
    defaultValues: [],
  },
};
const ENUM_METADATA_CONTACT_LABEL = {
  identifies: 'identifies',
  field_values: 'field_values',
};

const createMetadata = (dataItem) => {
  const identifies = [];

  dataItem.forEach((contact) => {
    Object.keys(ENUM_METADATA_CONTACT).forEach((meta) => {
      const rawData = _.get(contact, ENUM_METADATA_CONTACT[meta].path, ENUM_METADATA_CONTACT[meta].defaultValues);
      if (rawData && meta === ENUM_METADATA_CONTACT_LABEL.identifies) {
        rawData.forEach((item) => {
          identifies.push(item);
        });
      }
    });
  });
  return {
    identifies: _.uniqBy(identifies, 'id'),
  };
};
export function* bulkDeleteContacts({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/contact/deleteMany', { contactIds: payload });
    if (response && response.data) {
      yield put(ContactAction.bulkDeleteContactSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    } else {
      yield put(ContactAction.bulkDeleteContactFailure('Bulk delete failed.'));
    }
  } catch (error) {
    yield put(ContactAction.bulkDeleteContactFailure(error.message));
    if (callback) {
      callback(error);
    }
    console.log({ error });
  }
}

export function* bulkUpdateContactByField({ payload, callback }) {
  const { contactIds, fieldId, value } = payload;

  try {
    const response = yield call(axios.post, '/contact/updateMany', { contactIds, fieldId, value });
    if (response && response.data) {
      yield put(ContactAction.bulkUpdateContactByFieldSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    } else {
      yield put(ContactAction.bulkUpdateContactByFieldFailure('Bulk update failed.'));
    }
  } catch (error) {
    yield put(ContactAction.bulkUpdateContactByFieldFailure(error.message));
    if (callback) {
      callback(error);
    }
    console.log({ error });
  }
}

export function* fetchContacts() {
  try {
    const response = yield call(axios.get, '/contact');
    if (response && response.data) {
      const contacts = response.data;
      yield put(ContactAction.fetchContactsSuccess(contacts.map((contact) => omit(contact, 'field_values'))));
      const fieldValues = uniqBy(flatten(contacts.map((contact) => contact.field_values)), 'id');

      // if (fieldValueIds && Array.isArray(fieldValueIds) && fieldValueIds.length > 0) {
      //   const fieldValuesData = yield call(axios.get, '/field_values', { params: { fieldValueIds } });

      yield put(FieldValueAction.setFieldValues(fieldValues));
    } else {
      yield put(ContactAction.fetchContactsFailure('Fetch contacts failed.'));
    }
  } catch (error) {
    yield put(ContactAction.fetchContactsFailure(error.message));
    console.log({ error });
  }
}

export function* updateFieldValues({ payload }) {
  try {
    yield call(axios.put, '/contact/values', payload);
    // yield put(ContactAction.fetchContacts());
  } catch (error) {
    yield put(ContactAction.fetchContactsFailure(error.message));
    console.log({ error });
  }
}

export function* updateContact({ payload }) {
  try {
    const response = yield call(axios.put, '/contact', {
      id: payload.id,
      phone_number: payload.phone_number,
      region_number: payload.region_number,
      email: payload.email,
    });
    if (response.data) {
      yield put(ContactAction.fetchContactsSuccess([])); // Temporary for set Fetching to false
    }
  } catch (error) {
    const conflictData = get(error, ['response', 'data']);
    yield put(ContactAction.updateContactConflict(conflictData));
  }
}

export function* mergeContact({ payload, callback }) {
  const {
    mergeData,
    originalContactId,
    mergeContactId,
    identifyData,
    liveChatMergeFunction,
    conversationId,
    liveChatGroup,
    liveChatNewIdentify,
  } = payload;
  try {
    const response = yield call(axios.put, '/contact/merge', {
      mergeData,
      originalContactId,
      mergeContactId,
      identifyData,
      liveChatMergeFunction,
      conversationId,
      liveChatGroup,
      liveChatNewIdentify,
    });
    if (response.data) {
      yield put(ContactAction.mergeContactSuccess());
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Merged Failed');
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
    yield put(ContactAction.mergeContactFailure(error));
  }
}

export function* unmergeContact({ payload: contactId, callback }) {
  try {
    const response = yield call(axios.put, '/contact/unmerge', { contactId });
    if (response.data) {
      yield put(ContactAction.unmergeContactSuccess());
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('Unmerge Failed.');
    }
  } catch (error) {
    console.log(error);
    toast.error('For some reason this contact cannot be unmerge.');
    if (callback) {
      callback(error, null);
    }
    yield put(ContactAction.unmergeContactFailure(error));
  }
}

export function* fetchContactById(action) {
  const contactId = action.payload; // yield select(ConversationSelector.selectContactByConversationId(payload));
  try {
    if (contactId) {
      const response = yield call(axios.get, `/contact/${contactId}`);
      if (response.data) {
        const contact = response.data;
        if (contact.field_values) {
          const { field_values } = contact;
          const fields = field_values.map((item) => item.fields);
          yield put(FieldAction.fetchFieldsSuccess(fields));
          yield put(FieldValueAction.setFieldValues(field_values));
        }
        yield put(ContactAction.fetchContactByIdSuccess(contact));
      }
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchContactByConversationId({ payload }) {
  // const contactId = yield select(ConversationSelector.selectContactByConversationId(payload));
  const response = yield call(axios.get, `/identify/contactByConversation/${payload}`);
  const contactId = response.data;
  yield call(fetchContactById, { payload: contactId });
}

export function* createNewContact({ payload }) {
  const { callback } = payload;
  try {
    const response = yield call(axios.post, '/contact', payload.payload);
    if (response) {
      yield put(ContactAction.addContactSuccess());
      callback(null, response.data);
    }
  } catch (error) {
    yield put(ContactAction.addContactFailure());
    const { response } = error;
    callback(response.data);
    console.log({ error });
  }
}

export function* updateContactDNC(payload) {
  const { callback } = payload;
  try {
    const response = yield call(axios.put, '/contact/updateDNC', payload.payload);
    if (response) {
      callback(null, response);
    }
  } catch (error) {
    const { response } = error;
    callback(response.data);
    console.log({ error });
  }
}

export function* fetchContactsWithPagination(payload) {
  try {
    const { page, pageSize, currentUser } = payload.payload;
    const response = yield call(axios.get, '/contact/findAllWithPagination', {
      params: { page, pageSize, currentUser },
    });
    if (response && response.data) {
      const contacts = response.data.rows;
      if (contacts?.length > 0) {
        const metadata = createMetadata(contacts);
        const allIds = contacts?.map((item) => item.id);
        // const contactPayload = contacts.map((contact) => omit(contact, 'field_values'))
        yield put(ContactAction.fetchContactsSuccess({ data: contacts, count: response.data.count }, metadata));
        const fieldValues = uniqBy(flatten(contacts.map((contact) => contact.field_values)), 'id');
        yield put(FieldValueAction.setFieldValues(fieldValues));
        if (payload?.callback) {
          const { callback } = payload;
          callback(null, allIds);
        }
      }
      yield put(ContactAction.fetchContactsWithPaginationSuccess());
    } else {
      yield put(ContactAction.fetchContactsWithPaginationFailure());
    }
  } catch (error) {
    yield put(ContactAction.fetchContactsFailure(error.message));
    console.log({ error });
  }
}

export function* searchContactsWithFilterPagination(payload) {
  try {
    const { callback } = payload;
    const { currentUser, page, pageSize, contactFilter } = payload.payload;
    const response = yield call(axios.get, '/contact/searchAllWithFilterPagination', {
      params: { page, pageSize, currentUser, contactFilter },
    });
    if (response && response.data) {
      const contacts = response.data.rows;
      const metadata = createMetadata(contacts);
      const allIds = contacts.map((item) => item.id);
      // const contactPayload = contacts.map((contact) => omit(contact, 'field_values'))
      yield put(ContactAction.fetchContactsSuccess({ data: contacts, count: response.data.count }, metadata));
      const fieldValues = uniqBy(flatten(contacts.map((contact) => contact.field_values)), 'id');

      yield put(FieldValueAction.setFieldValues(fieldValues));
      yield put(ContactAction.fetchContactsWithPaginationSuccess());
      callback(null, allIds);
    } else {
      yield put(ContactAction.fetchContactsWithPaginationFailure());
    }
  } catch (error) {
    yield put(ContactAction.fetchContactsFailure(error.message));
    console.log({ error });
  }
}

export function* searchContactsWithPagination(payload) {
  const { callback } = payload;
  try {
    const { currentUser, page, pageSize, searchValue } = payload.payload;
    const response = yield call(axios.get, '/contact/searchAllWithPagination', {
      params: { page, pageSize, currentUser, searchValue },
    });
    if (response && response.data) {
      const contacts = response.data.rows;
      const metadata = createMetadata(contacts);
      const allIds = contacts.map((item) => item.id);
      // const contactPayload = contacts.map((contact) => omit(contact, 'field_values'))
      yield put(ContactAction.fetchContactsSuccess({ data: contacts, count: response.data.count }, metadata));
      const fieldValues = uniqBy(flatten(contacts.map((contact) => contact.field_values)), 'id');

      yield put(FieldValueAction.setFieldValues(fieldValues));
      yield put(ContactAction.fetchContactsWithPaginationSuccess());
      callback(null, allIds);
    }
  } catch (error) {
    yield put(ContactAction.searchContactsWithPaginationFailure());
    callback({ error }, null);
    console.log({ error });
  }
}

export default function* contactSagaFlow() {
  yield takeLatest([ContactAction.FETCH_CONTACTS.START, ContactAction.ADD_CONTACT_SUCCESS], fetchContacts);
  yield takeLatest(ContactAction.ADD_CONTACT, createNewContact);
  yield takeLatest(ContactAction.UPDATE_FIELD, updateFieldValues);
  yield takeLatest(ContactAction.UPDATE_CONTACT_START, updateContact);
  yield takeLatest(ContactAction.MERGE_CONTACT_START, mergeContact);
  yield takeLatest(ContactAction.UNMERGE_CONTACT_START, unmergeContact);
  yield takeEvery(ContactAction.FETCH_CONTACTS_BY_ID.START, fetchContactById);
  yield takeEvery(ContactAction.FETCH_CONTACTS_BY_CONVERSATION_ID, fetchContactByConversationId);
  yield takeLatest(ContactAction.BULK_UPDATE_CONTACTS.START, bulkUpdateContactByField);
  yield takeLatest(ContactAction.BULK_DELETE_CONTACTS.START, bulkDeleteContacts);
  yield takeLatest(ContactAction.UPDATE_CONTACT_DNC.START, updateContactDNC);
  yield takeLatest(ContactAction.FETCH_CONTACTS_WITH_PAGINATION.START, fetchContactsWithPagination);
  yield takeLatest(ContactAction.SEARCH_CONTACTS_WITH_FILTER_PAGINATION.START, searchContactsWithFilterPagination);
  yield takeLatest(ContactAction.SEARCH_CONTACTS_WITH_PAGINATION.START, searchContactsWithPagination);
}
