import { filter, get, isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { CAMPAIGN_TYPE } from '../../enums/campaign';
import IdentifySelector from '../identify/identify.selectors';

export default class ContactSelector {
  static selectContact = (state) => state.contacts;

  static selectFieldValue = (state) => state.fieldValues;

  static selectField = (state) => state.fields;

  static selectConversations = (state) => state.conversations.data.byId;

  static selectCampaigns = (state) => state.campaign.data.byId;

  static selectFieldValueByIds = (state) => state.fieldValues.data.byId;

  static selectOpenContact = createSelector([this.selectContact], (contact) => contact.openContact);

  static selectContactCount = createSelector([this.selectContact], (contact) => contact.count);

  static selectContactsData = createSelector(
    [this.selectContact, this.selectFieldValue, this.selectField],
    (contactSelector, fieldValueSelector, fieldSelector) => {
      const { byId, allIds } = contactSelector.data;
      const data = allIds
        .map((contactId) => {
          const contact = byId[contactId];
          if (!contact) return undefined;
          const fieldValueIds = fieldValueSelector.data.allIds.filter((fieldValueId) => {
            const fieldValue = fieldValueSelector.data.byId[fieldValueId];
            return !!fieldValue && fieldValue._contact === contactId;
          });
          const expandFieldValues = fieldValueIds
            .map((fieldValueId) => {
              const fieldValue = fieldValueSelector.data.byId[fieldValueId];
              if (!fieldValue) return undefined;
              return {
                ...fieldValue,
                field: fieldSelector.data.byId[fieldValue._field],
              };
            })
            .filter(Boolean);

          return {
            ...contact,
            field_values: expandFieldValues,
          };
        })
        .filter(Boolean);

      return {
        ...contactSelector,
        data,
      };
    },
  );

  static selectContactById = (id) => createSelector(this.selectContact, (contacts) => get(contacts, `data.byId.${id}`));

  // static selectContactByIds = createSelector([this.selectContact, this.selectFieldValueByIds], (contacts, field_values) => {
  //   const contactList = get(contacts, `data.byId`);
  //   const fieldValueList = Object.values(field_values);
  //   console.log('🚀 ~ file: contact.selectors.js:65 ~ ContactSelector ~ selectContactByIds=createSelector ~ fieldValueList:', fieldValueList)
  //   const result = {};
  //   Object.keys(contactList).forEach(contactId => {
  //     const listFieldValues = filter(fieldValueList, function (i) { return i?._contact === contactId; })
  //     const finalContact = contactList[contactId];
  //     finalContact.field_values = listFieldValues;
  //     result[contactId] = finalContact
  //   })
  //   return result;
  // });
  static selectContactByIds = createSelector(this.selectContact, (contacts) => get(contacts, `data.byId`));

  static selectInstanceCampaignByContactId = (contactId) =>
    createSelector(
      [this.selectConversations, this.selectCampaigns, IdentifySelector.allIdentify],
      (conversations, campaigns, identifies) => {
        const campaignData = Object.values(campaigns).filter((item) => {
          const conversation = conversations[item?._conversation];
          if (conversation) {
            const identify = identifies[conversation?._identifier];
            return conversation && identify?._contact === contactId && item?.type === CAMPAIGN_TYPE.INSTANCE;
          }
        });
        return campaignData;
      },
    );

  static selectContactsFetching = createSelector([this.selectContact], (contact) => contact.fetching);

  static selectDuplicateContact = createSelector([this.selectContact], (contact) => contact.duplicateContact);

  static selectDuplicateContactUpdatedFieldValue = createSelector(
    [this.selectContact],
    (contact) => contact.duplicateContactUpdatedFieldValue,
  );

  static selectContactNameById = (contactId) =>
    createSelector([this.selectContact, this.selectFieldValue, this.selectField], (contacts, fieldValues, fields) => {
      const contact = contacts.data.byId[contactId];
      let firstName = '';
      let lastName = '';
      let ip_Address = '';
      if (contact && fieldValues && fields) {
        for (const item of Object.values(fieldValues.data.byId)) {
          if (isEqual(item?._contact, contactId)) {
            const field = fields.data.byId[item?._field];
            if (field?.isDefault && isEqual(field?.custom_field_name, 'First Name')) {
              firstName = item?.value || '';
            }
            if (field?.isDefault && isEqual(field?.custom_field_name, 'Last Name')) {
              lastName = item?.value || '';
            }
            if (field?.isDefault && isEqual(field?.custom_field_name, 'IP Address')) {
              ip_Address = item?.value || '';
            }
          }
        }
      }
      return {
        firstName,
        lastName,
        ip_Address,
        fingerprint: contact ? contact.fingerprinting : null,
        email: contact ? contact.email : null,
      };
    });

  static selectContactIsFetchingWithPagination = createSelector(this.selectContact, (contacts) =>
    get(contacts, `fetchingPagination`, false),
  );

  static selectIsNewContactAdded = createSelector(this.selectContact, (contacts) =>
    get(contacts, `isNewContactAdded`, false),
  );

  static filterColumnContact = createSelector(this.selectContact, (contacts) => get(contacts, `filterColumnContact`));

  static selectIsAddingNewContact = createSelector(this.selectContact, (contacts) =>
    get(contacts, `isAddingNewContact`, false),
  );
}
