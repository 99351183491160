import { uniq } from 'lodash';

export default class CdrUtil {
  static normalizeCdr(state, cdrs) {
    const byId = cdrs.reduce(
      (initValue, value) => ({
        ...initValue,
        [value.id]: value,
      }),
      {},
    );
    const allIds = Object.keys(byId);

    return {
      byId: { ...state.data.byId, ...byId },
      allIds: uniq([...state.data.allIds, ...allIds]),
    };
  }
}
