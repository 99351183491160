export default class MemberAction {
  static FETCH_GROUP_MEMBER = {
    START: 'MemberAction/FETCH_GROUP_MEMBER_START',
    SUCCESS: 'MemberAction/FETCH_GROUP_MEMBER_SUCCESS',
    FAILURE: 'MemberAction/FETCH_GROUP_MEMBER_FAILURE',
  };
  static REMOVE_GROUP_MEMBER = {
    START: 'MemberAction/REMOVE_GROUP_MEMBER_START',
    FAILURE: 'MemberAction/REMOVE_GROUP_MEMBER_FAILURE',
  };

  static DELETE_MEMBER_REDUX_BY_IDS = 'MemberAction/DELETE_MEMBER_REDUX_BY_IDS';

  static fetchGroupMember = (memberIds) => ({
    type: MemberAction.FETCH_GROUP_MEMBER.START,
    payload: memberIds,
  });

  static fetchGroupMemberSuccess = (data) => ({
    type: MemberAction.FETCH_GROUP_MEMBER.SUCCESS,
    payload: data,
  });

  static fetchGroupMemberFailure = (errorMessage) => ({
    type: MemberAction.FETCH_GROUP_MEMBER.FAILURE,
    payload: errorMessage,
  });

  static deletedMemberReduxByIds = (ids) => ({
    type: MemberAction.DELETE_MEMBER_REDUX_BY_IDS,
    payload: ids,
  });
  static removeGroupMember = (data, callback) => ({
    type: MemberAction.REMOVE_GROUP_MEMBER.START,
    payload: { data, callback },
  });
}
