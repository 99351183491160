import { put, takeLatest } from 'redux-saga/effects';

import SocketAction from '../socket/socket.action';
import ContactAction from '../contact/contact.actions';
import IdentifyAction from '../identify/identify.actions';
import ReportAction from '../report/report.action';

function* handleFetchContactById({ payload: id }) {
  try {
    yield put(ContactAction.fetchContactById(id, false));
    yield put(IdentifyAction.fetchIdentifyByContact(id));
    // fetch contact page
    yield put(ContactAction.setIsNewContactAdded(true));
  } catch (error) {
    console.log({ error });
  }
}

function* handleFetchContacts() {
  try {
    yield put(ContactAction.deleteContactsRedux());
    yield put(ContactAction.fetchContacts(undefined, false));
  } catch (error) {
    console.log({ error });
  }
}

function* handleFetchBulkUploadContactStatus() {
  try {
    yield put(ReportAction.fetchReportLogBulkUploadContactStart());
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeContactFlow() {
  yield takeLatest(SocketAction.SOCKET_EVENT.FETCH_CONTACT_BY_ID, handleFetchContactById);
  yield takeLatest(SocketAction.SOCKET_EVENT.FETCH_CONTACTS, handleFetchContacts);
  yield takeLatest(SocketAction.SOCKET_EVENT.FETCH_BULK_UPLOAD_CONTACT_STATUS, handleFetchBulkUploadContactStatus);
}
