import axios from 'axios';
import { toast } from 'react-toastify';
import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';

import DidAction from './did.actions';
import GroupSelector from '../group/group.selectors';
import HomeAction from '../home/home.actions';
import UserAction from '../user/user.actions';
import { GROUP_KEY_TABS } from '../../enums/modals/groups';

const defaultCallback = () => {};

function* fetchDids({ payload }) {
  try {
    const ids = get(payload, 'ids');
    const config = ids && { params: { ids } };
    const url = ids ? `/dids/ids` : '/dids';

    const response = yield call(axios.get, url, config);

    if (response && response.data) {
      yield put(DidAction.fetchDidSuccess({ dids: response.data, isFetchAll: !ids }));
    } else {
      yield put(DidAction.fetchDidFailure('Fetch dids failed.'));
    }
  } catch (error) {
    yield put(DidAction.fetchDidFailure(error.message));
    console.log({ error });
  }
}

export function* searchDid({ payload, callback = defaultCallback }) {
  try {
    // const { searchedAreaCode, currentUser } = payload;
    const { searchedAreaCode } = payload;

    const response = yield call(axios.get, `/dids/listavailablenumbers?searchedAreaCode=${searchedAreaCode}`);

    yield put(DidAction.searchDidSuccess(response.data));
    callback(null, response.data);
  } catch (error) {
    yield put(DidAction.searchDidFailure(error.message));
    callback(error);
  }
}

function* updateDid({ payload, callback }) {
  try {
    const { modifiedDidDescription, modifiedDidGroup, modifiedDidId } = payload;
    yield call(axios.patch, '/dids', {
      id: modifiedDidId,
      description: modifiedDidDescription,
      _group: modifiedDidGroup,
    });

    callback(null);
  } catch (error) {
    callback(error);
    yield put(DidAction.fetchDidFailure(error.message));
    console.log({ error });
  }
}
function* setDid({ payload, callback }) {
  try {
    const { _group, number, region_number, description } = payload;
    const response = yield call(axios.post, '/dids/setToGroup', payload);
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
  }
}

function* confirmPurchaseDids({ payload, callback = defaultCallback }) {
  try {
    const { selectedDIDs, purchaseDIDDescription, purchaseDIDGroupRoute, carrier } = payload;

    for (const number of selectedDIDs) {
      const response = yield call(axios.post, '/dids/purchase', {
        number,
        description: purchaseDIDDescription,
        group: purchaseDIDGroupRoute,
        carrier,
        state: 'Active',
      });
      callback(null, response?.data);
    }
    yield put(DidAction.fetchDids());
  } catch (error) {
    yield put(DidAction.fetchDidFailure(error.message));
    callback(error);
  }
}

function* releaseDid({ payload }) {
  const { id, _group } = payload;
  try {
    yield call(axios.post, '/dids/release', {
      id,
    });
  } catch (error) {
    yield put(DidAction.fetchDidFailure(error.message));
    toast.error(error.response?.data?.msg || error.response?.data?.message);
    const { group_name, liveChat_requiredFields, status, using_system_wide_dnc, group_description, owner, _user } =
      yield select(GroupSelector.selectGroupById(_group));
    yield put(UserAction.fetchSameDomainUsersListStart());
    yield put(
      HomeAction.updateModifyGroupModal({
        group_name,
        liveChat_requiredFields,
        _id: _group,
        tab_name: GROUP_KEY_TABS.Dids.value,
        status,
        using_system_wide_dnc,
        group_description,
        owner,
        _user,
      }),
    );
  }
}

export default function* didSagaFlow() {
  yield takeLatest(DidAction.FETCH_DID.START, fetchDids);
  yield takeLatest(DidAction.SEARCH_NUMBER.START, searchDid);
  yield takeLatest(DidAction.UPDATE_DID, updateDid);
  yield takeLatest(DidAction.SET_DID, setDid);
  yield takeLatest(DidAction.RELEASE_DID, releaseDid);
  yield takeEvery(DidAction.CONFIRM_PURCHASE_DID, confirmPurchaseDids);
}
