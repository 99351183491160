import { includes, compact, isEqual, uniq, uniqBy } from 'lodash';

import { CALL_STATUS } from '../../enums/socket/voice';
import VoiceAction from './voice.actions';
import UserAction from '../user/user.actions';
import SocketAction from '../socket/socket.action';

const { VoiceActionTypes } = VoiceAction;

const INITIAL_STATE = {
  incall: null,
  wsState: null,
  calltrying: false,
  callringing: false,
  calltalking: false,
  regState: false,
  keypad: false,
  dialpad: false,
  message: null,
  arrMess: [],
  isJoinCall: false,
  sessionId: '',
  // In use
  elapsed: 0,
  data: {
    byId: {},
    allIds: [],
  },
  sessionList: {},
  currentInvitedExternalCall: {},
  currentCallLog: {},
  forceHungUpConversation: {},
  isInCall: false,
  acceptCallFromPopup: false,
  currentCallStatus: {},
};

const voiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VoiceActionTypes.JOIN_CALL: {
      const { sessionId, isJoinCall } = action.payload;
      const session = state.data.byId[sessionId];
      const byId = { ...state.data.byId, [sessionId]: { ...session, isJoinCall } };
      const allIds = uniq(Object.keys(byId));

      return {
        ...state,
        data: { byId, allIds },
      };
    }

    case VoiceActionTypes.UPDATE_VOICE_DATA: {
      const voiceData = action.payload;
      let { allIds } = state.data;
      let { byId } = state.data;

      if (Array.isArray(voiceData)) {
        voiceData.forEach((data) => {
          byId = {
            ...byId,
            [data.sessionId]: data,
          };
          allIds = uniq(compact([...allIds, data.sessionId]));
        });
      } else {
        const oldSession = state.data.byId[voiceData.sessionId];

        byId = {
          ...state.data.byId,
          [voiceData.sessionId]: oldSession ? { ...oldSession, ...voiceData } : voiceData,
        };
        allIds = uniq(compact([...state.data.allIds, voiceData.sessionId]));
      }

      return { ...state, data: { byId, allIds } };
    }

    case VoiceActionTypes.REMOVE_CLOSED_SESSION: {
      const voices = Object.values(state.data.byId);
      const byId = {};
      let { latestRTCSessionId } = state;

      for (const session of voices) {
        // const isSessionHungUp = isEqual(session.eventName, 'HUNGUP');
        const isAllHungUp = session.participantList.every((participant) =>
          isEqual(participant.state, CALL_STATUS.HUNGUP),
        );
        if (isAllHungUp && latestRTCSessionId === session.RTCSessionId) {
          latestRTCSessionId = '';
        }
        if (!isAllHungUp) {
          byId[session.sessionId] = session;
        }
      }
      const allIds = Object.keys(byId);
      return { ...state, latestRTCSessionId, data: { byId, allIds } };
    }

    case VoiceActionTypes.CONNECT: {
      return state;
    }
    case VoiceActionTypes.ACCEPT_CALL_FROM_POPUP: {
      return { ...state, acceptCallFromPopup: action.payload.fromPopup };
    }

    case VoiceActionTypes.SET_CURRENT_VOICE_STATE: {
      return { ...state, currentCallStatus: { ...state.currentCallStatus, ...action.payload } };
    }

    case VoiceActionTypes.REGISTER: {
      return state;
    }

    case VoiceActionTypes.DIALER_STATUS: {
      const incall = includes(['TALKING', 'RINGING'], action.payload);
      return {
        ...state,
        incall: incall || state.incall,
        wsState: action.payload,
      };
    }

    case VoiceActionTypes.TRYING: {
      return {
        ...state,
        calltrying: true,
      };
    }

    case VoiceActionTypes.RINGING: {
      return {
        ...state,
        callringing: true,
      };
    }

    case VoiceActionTypes.SET_SESSION_ID: {
      return {
        ...state,
        sessionId: action.payload.sessionId,
      };
    }

    case VoiceActionTypes.TALKING: {
      return {
        ...state,
        incall: true,
        calltalking: true,
      };
    }

    case VoiceActionTypes.REGISTERED: {
      return {
        ...state,
        regState: action.payload,
      };
    }

    case VoiceActionTypes.TOGGLE_DIALPAD: {
      return {
        ...state,
        dialpad: !state.dialpad,
      };
    }

    case VoiceActionTypes.TOGGLE_KEYPAD: {
      return {
        ...state,
        keypad: !state.keypad,
      };
    }

    case VoiceActionTypes.DIALING_STATE: {
      return {
        ...state,
        incall: true,
        dialpad: false,
      };
    }

    // case VoiceActionTypes.QUICK_DIAL: {
    //   return {
    //     ...state,
    //     incall: true,
    //   };
    // }

    case VoiceActionTypes.HANGUP: {
      return {
        ...state,
        incall: false,
        calltrying: false,
        callringing: false,
        calltalking: false,
      };
    }

    case VoiceActionTypes.INC_CALLTIMER: {
      return {
        ...state,
        elapsed: (state.elapsed ? state.elapsed : 1) + 1,
      };
    }

    case VoiceActionTypes.RESET_CALLTIMER: {
      return {
        ...state,
        elapsed: 0,
      };
    }
    case VoiceActionTypes.ON_RECEIVE_MESSAGE: {
      const { payload } = action;
      return {
        ...state,
        arrMess: [...state.arrMess, payload],
      };
    }
    case VoiceActionTypes.UPDATE_SESSION_LIST: {
      const { payload } = action;
      const { sessionList } = state;
      return {
        ...state,
        sessionList: { ...sessionList, ...payload },
      };
    }
    case VoiceActionTypes.UPDATE_LIST_INVITED_EXTERNAL_CALL: {
      const { payload } = action;

      return {
        ...state,
        currentInvitedExternalCall: payload,
      };
    }

    case VoiceActionTypes.SET_CALL_LOG: {
      const { payload } = action;
      const { currentCallLog } = state;
      return {
        ...state,
        currentCallLog: { ...currentCallLog, ...payload },
      };
    }
    case VoiceActionTypes.FORCE_HUNG_UP_CALL_CONVERSATION: {
      const { payload } = action;
      return {
        ...state,
        forceHungUpConversation: payload,
      };
    }
    case VoiceActionTypes.SET_IN_CALL_STATUS: {
      const { payload } = action;
      return {
        ...state,
        isInCall: payload,
      };
    }
    case VoiceActionTypes.SYNC_VOICE_DATA_TO_STORE: {
      const { payload } = action;
      const { session, callLog, userId } = payload;

      const filteredData = {
        sessions: Object.values(session).filter((s) => s.userId !== userId),
        callLogs: Object.values(callLog).filter((c) => c?.callerUserId !== userId || !c?.userIds.includes(userId)),
      };

      const finalSessions = Object.fromEntries(filteredData.sessions.map((s) => [s.sessionId, s]));
      const finalCallLogs = Object.fromEntries(filteredData.callLogs.map((cl) => [cl.confUniqueName, cl]));
      const { sessionList, currentCallLog } = state;
      return {
        ...state,
        currentCallLog: { ...currentCallLog, ...finalCallLogs },
        sessionList: { ...sessionList, ...finalSessions },
      };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default voiceReducer;
