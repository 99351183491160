export default class ConversationAction {
  static ConversationActionTypes = {
    // fetch  chat
    FETCH_CHAT_START: 'Conversation/FETCH_CHAT_START',
    FETCH_CHAT_SUCCESS: 'Conversation/FETCH_CHAT_SUCCESS',
    FETCH_CHAT_FAILURE: 'Conversation/FETCH_CHAT_FAILURE',

    FETCH_CHAT_BY_ID_START: 'Conversation/FETCH_CHAT_BY_ID_START',
    FETCH_CHAT_BY_ID_SUCCESS: 'Conversation/FETCH_CHAT_BY_ID_SUCCESS',
    FETCH_CHAT_BY_ID_FAILURE: 'Conversation/FETCH_CHAT_BY_ID_FAILURE',

    // UPDATE_MESSAGE_UNREAD
    // UPDATE_MESSAGE_UNREAD: 'Conversation/UPDATE_MESSAGE_UNREAD',
    // UPDATE_SEEN_TO_TRUE
    UPDATE_SEEN_TO_TRUE: 'Conversation/UPDATE_SEEN_TO_TRUE',
    CHOOSE_CONVERSATION: 'Conversation/CHOOSE_CONVERSATION',
    // UPDATE_CLOSE_COVERSATION_STATUS
    UPDATE_CLOSE_COVERSATION_STATUS: 'Conversation/UPDATE_CLOSE_COVERSATION_STATUS',
    // UPDATE_UNREPLIED_STATUS
    UPDATE_UNREPLIED_STATUS: 'Conversation/UPDATE_UNREPLIED_STATUS',
    // SET_USERS
    SET_USERS_START: 'Conversation/SET_USERS_START',
    SET_USERS: 'Conversation/SET_USERS',
    // UPDATE_FILTER_GROUP
    UPDATE_FILTER_GROUP: 'Conversation/UPDATE_FILTER_GROUP',
    UPDATE_LAST_MESSAGE: 'Conversation/UPDATE_LAST_MESSAGE',
    // UPDATE_INDIVIDUAL_CONVERSATION
    UPDATE_INDIVIDUAL_CONVERSATION_START: 'Conversation/UPDATE_INDIVIDUAL_CONVERSATION_START',
    UPDATE_INDIVIDUAL_CONVERSATION: 'Conversation/UPDATE_INDIVIDUAL_CONVERSATION',
    UPDATE_INDIVIDUAL_CONVERSATION_FAILURE: 'Conversation/UPDATE_INDIVIDUAL_CONVERSATION_FAILURE',
    // HANDLE_CREATE_NEW_CONVERSATION
    HANDLE_CREATE_NEW_CONVERSATION_START: 'Conversation/HANDLE_CREATE_NEW_CONVERSATION',
    HANDLE_CREATE_NEW_CONVERSATION_FAILURE: 'Conversation/HANDLE_CREATE_NEW_CONVERSATION_FAILURE',
    // set seen
    HANDLE_SET_SEEN_START: 'Conversation/HANDLE_SET_SEEN_START',
    HANDLE_SET_SEEN_FAILURE: 'Conversation/HANDLE_SET_SEEN_FAILURE',
    UPDATE_VOICE_CONVERSATION: 'Conversation/UPDATE_VOICE_CONVERSATION',
    UPDATE_VOICE_LEGS_CONVERSATION: 'Conversation/UPDATE_VOICE_LEGS_CONVERSATION',
    // untag
    HANDLE_UNFOLLOW: 'Conversation/HANDLE_UNFOLLOW',
    HANDLE_FOLLOW: 'Conversation/HANDLE_FOLLOW',

    // HANDLE_UNTAG_FAILURE: 'Conversation/HANDLE_UNTAG_FAILURE',
    GET_LATEST_CONVERSATION: 'Conversation/GET_LATEST_CONVERSATION',
    GET_CONVERSATIONS_BY_CONTACT: 'Conversation/GET_CONVERSATIONS_BY_CONTACT',
    ADD_CALL_IN_SESSION: 'Conversation/ADD_CALL_IN_SESSION',
    REMOVE_CALL_FROM_SESSION: 'Conversation/REMOVE_CALL_FROM_SESSION',
    DISCONNECT_EVERYONE_FROM_GROUP: 'Conversation/DISCONNECT_EVERYONE_FROM_GROUP',
    SET_OPEN_CONTACT_SIDEBAR: 'Conversation/SET_OPEN_CONTACT_SIDEBAR',
    SEND_DTMF: 'Conversation/SEND_DTMF',
    ADD_CONVERSATION: 'Conversation/ADD_CONVERSATION',
    CHECK_IN_ACTIVE_CAMPAIGN: 'Conversation/CHECK_IN_ACTIVE_CAMPAIGN',
    START_RECORD_VOICE: 'Conversation/START_RECORD_VOICE',
    STOP_RECORD_VOICE: 'Conversation/STOP_RECORD_VOICE',
  };

  static checkInActiveCampaign = (conversationId, callback) => {
    return {
      type: this.ConversationActionTypes.CHECK_IN_ACTIVE_CAMPAIGN,
      payload: conversationId,
      callback,
    };
  };

  static addConversation = (conversation) => {
    return {
      type: this.ConversationActionTypes.ADD_CONVERSATION,
      payload: conversation,
    };
  };

  static sendDTMF = (sessionId, callId, dtmf) => {
    return {
      type: this.ConversationActionTypes.SEND_DTMF,
      payload: { sessionId, callId, dtmf },
    };
  };

  static setOpenContactSidebar(payload) {
    return {
      type: this.ConversationActionTypes.SET_OPEN_CONTACT_SIDEBAR,
      payload,
    };
  }

  static disconnectEveryOneFromGroupCall(sessionId, callback) {
    return {
      type: this.ConversationActionTypes.DISCONNECT_EVERYONE_FROM_GROUP,
      payload: { sessionId },
      callback,
    };
  }

  static addCallInSession(sessionId, callConfig, callback) {
    return {
      type: this.ConversationActionTypes.ADD_CALL_IN_SESSION,
      payload: { sessionId, callConfig },
      callback,
    };
  }

  static removeCallFromSession(sessionId, callId, callback) {
    return {
      type: this.ConversationActionTypes.REMOVE_CALL_FROM_SESSION,
      payload: { sessionId, callId },
      callback,
    };
  }

  static getLatestConversation(payload, callback) {
    return {
      type: this.ConversationActionTypes.GET_LATEST_CONVERSATION,
      payload,
      callback,
    };
  }

  static getConversationsByContact(payload, callback) {
    return {
      type: this.ConversationActionTypes.GET_CONVERSATIONS_BY_CONTACT,
      payload: { ...payload, callback },
    };
  }

  // create new conversation action
  static createNewConversationStart(payload, callback = () => {}) {
    return {
      type: this.ConversationActionTypes.HANDLE_CREATE_NEW_CONVERSATION_START,
      payload,
      callback,
    };
  }

  static createNewConversationFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.HANDLE_CREATE_NEW_CONVERSATION_FAILURE,
      payload: errorMessage,
    };
  }

  static chooseConversation(payload) {
    return {
      type: this.ConversationActionTypes.CHOOSE_CONVERSATION,
      payload,
    };
  }

  // fetch chat
  static fetchChatStart(callback) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_START,
      callback,
    };
  }

  static fetchChatSuccess(payload, metadata) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_SUCCESS,
      payload,
      metadata,
    };
  }

  static fetchChatFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_FAILURE,
      payload: errorMessage,
    };
  }

  static fetchConversationById(conversationId, callback) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_BY_ID_START,
      payload: conversationId,
      callback,
    };
  }

  static fetchConversationByIdSuccess(payload, metadata) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_BY_ID_SUCCESS,
      payload,
      metadata,
    };
  }

  static fetchConversationByIdFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.FETCH_CHAT_BY_ID_FAILURE,
      payload: errorMessage,
    };
  }

  // update individual conversation
  static updateConversationStart(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_INDIVIDUAL_CONVERSATION_START,
      payload,
    };
  }

  static updateConversationLastMessage(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_LAST_MESSAGE,
      payload,
    };
  }

  static updateIndividualConversation(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_INDIVIDUAL_CONVERSATION,
      payload,
    };
  }

  static updateConversationFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.UPDATE_INDIVIDUAL_CONVERSATION_FAILURE,
      payload: errorMessage,
    };
  }

  // set conversation user
  static setConversationUsersStart(payload) {
    return {
      type: this.ConversationActionTypes.SET_USERS_START,
      payload,
    };
  }

  static setConversationUsers(users) {
    return {
      type: this.ConversationActionTypes.SET_USERS,
      payload: users,
    };
  }

  static updateVoiceConversation(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_VOICE_CONVERSATION,
      payload,
    };
  }

  static updateVoiceLegsConversation(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_VOICE_LEGS_CONVERSATION,
      payload,
    };
  }

  // update messages unreadcount
  // static updateMessageUnreadCount(payload) {
  //   return {
  //     type: this.ConversationActionTypes.UPDATE_MESSAGE_UNREAD,
  //     payload,
  //   };
  // };

  // update unreplied status
  static updateUnRepliedStatus(payload) {
    return {
      type: this.ConversationActionTypes.UPDATE_UNREPLIED_STATUS,
      payload,
    };
  }

  // set seen
  static handleSetSeenStart(payload) {
    return {
      type: this.ConversationActionTypes.HANDLE_SET_SEEN_START,
      payload,
    };
  }

  static handleSetSeenFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.HANDLE_SET_SEEN_FAILURE,
      payload: errorMessage,
    };
  }

  // untag
  static handleUnfollow(payload, callback) {
    return {
      type: this.ConversationActionTypes.HANDLE_UNFOLLOW,
      payload,
      callback,
    };
  }

  static handleFollow(payload, callback) {
    return {
      type: this.ConversationActionTypes.HANDLE_FOLLOW,
      payload,
      callback,
    };
  }

  static handleUntagFailure(errorMessage) {
    return {
      type: this.ConversationActionTypes.HANDLE_UNTAG_FAILURE,
      payload: errorMessage,
    };
  }

  // action for untag and set seen when success handle
  static updateSeenToTrue(obj) {
    return {
      type: this.ConversationActionTypes.UPDATE_SEEN_TO_TRUE,
      payload: obj,
    };
  }

  // update filter group
  static updateFilterGroup(obj) {
    return {
      type: this.ConversationActionTypes.UPDATE_FILTER_GROUP,
      payload: obj,
    };
  }

  // update close conversation for socketio
  static updateCloseConversationStatus(obj) {
    return {
      type: this.ConversationActionTypes.UPDATE_CLOSE_COVERSATION_STATUS,
      payload: obj,
    };
  }

  static startVoiceRecord({ sessionId, fileName }, callback) {
    return {
      type: this.ConversationActionTypes.START_RECORD_VOICE,
      payload: {
        sessionId,
        fileName,
      },
      callback,
    };
  }

  static stopVoiceRecord({ sessionId }, callback) {
    return {
      type: this.ConversationActionTypes.STOP_RECORD_VOICE,
      payload: {
        sessionId,
      },
      callback,
    };
  }
}
