import { all, put, takeLatest } from 'redux-saga/effects';
import DncAction from '../dnc/dnc.action';
import SocketAction from '../socket/socket.action';
const { SOCKET_EVENT } = SocketAction;

export function* handleFetchDNCById({ payload: id }) {
  yield put(DncAction.fetchDNCById(id));
}

export function* handleDeleteDNC({ payload: id }) {
  yield put(DncAction.deleteDncSuccess(id));
}

export function* realtimeDNCFlow() {
  yield takeLatest(SOCKET_EVENT.DELETE_DNC, handleDeleteDNC);
  yield takeLatest(SOCKET_EVENT.FETCH_DNC_BY_ID, handleFetchDNCById);
}
