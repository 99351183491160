import { get, omit, uniq } from 'lodash';

import MessageUtil from './message.utils';
import MessageAction from './message.actions';
import UserAction from '../user/user.actions';

const { MessageActionTypes } = MessageAction;

const INITIAL_STATE = {
  fetching: false,
  data: {
    byId: {},
    allIds: [],
  },
  contact: {},
  messageErrorMessage: null,
  newMessage: false,
  conversationId: '',
  key: null,
  name: null,
  phone: null,
  did: null,
  _group: null,
  oldMessages: [],
  fetchingContactMessage: false,
  latestMessageByContact: {
    latestSentMessages: {},
    latestReceivedMessages: {},
  },
  fetchingLatestMessage: false,
  hasMore: false,
  isSearchingMessage: null,
  messageSearchResult: [],
  isSearchingForReport: false,
  messageForReportResult: [],
  fetchingSystemMessage: false,
  systemMessage: [],
  systemMessageForReport: [],
  forceFetchMessage: false,
};

const messageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_START:
      return {
        ...state,
        fetchingLatestMessage: true,
      };
    case MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_SUCCESS: {
      return {
        ...state,
        latestMessageByContact: action.payload,
        fetchingLatestMessage: false,
      };
    }
    case MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_FAILURE:
      return {
        ...state,
        fetchingLatestMessage: false,
      };

    case MessageActionTypes.FETCH_CONTACT_MESSAGE_START:
      return {
        ...state,
        fetchingContactMessage: true,
      };
    case MessageActionTypes.FETCH_CONTACT_MESSAGE_SUCCESS: {
      const messageData = MessageUtil.normalizeMessages(state, action.payload);
      return {
        ...state,
        data: messageData,
        fetchingContactMessage: false,
      };
    }
    case MessageActionTypes.FETCH_CONTACT_MESSAGE_FAILURE:
      return {
        ...state,
        fetchingContactMessage: false,
        messageErrorMessage: action.payload,
      };
    case MessageActionTypes.FETCH_MESSAGE_START:
      return {
        ...state,
        fetching: true,
      };
    case MessageActionTypes.FETCH_MESSAGE_SUCCESS: {
      const { data = [], key, contact, name, phone, did, _group, hasMore } = action.payload;
      const messageData = MessageUtil.normalizeMessages(state, data);
      return {
        ...state,
        fetching: false,
        data: messageData,
        key,
        hasMore,
        contact,
        name,
        phone,
        did,
        _group,
        oldMessages: [],
      };
    }
    case MessageActionTypes.FETCH_MESSAGE_FAILURE:
      return {
        ...state,
        fetching: false,
        messageErrorMessage: action.payload,
      };
    case MessageActionTypes.SELECT_CONVERSATION:
      return {
        ...state,
        key: action.payload,
      };

    case MessageActionTypes.ADD_NEW_CHAT_TEMP: {
      // const { payload } = action;
      const { data = [] } = MessageUtil.addMessageToChat(state, action.payload);

      return {
        ...state,
        data,
      };
    }

    case MessageActionTypes.REMOVE_NEW_CHAT_TEMP: {
      const { tempId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          allIds: state.data.allIds.filter((id) => id !== tempId),
          byId: omit(state.data.byId, tempId),
        },
      };
    }

    case MessageActionTypes.ADD_NEW_CHAT: {
      const { tmpId } = action.payload;
      const { data } = MessageUtil.addMessageToChat(state, action.payload);
      if (state.data.byId[tmpId]) {
        // delete state.data.byId[tmpId];

        return {
          ...state,
          data: {
            byId: {
              ...data.byId,
              [tmpId]: undefined,
            },
            allIds: data.allIds.filter((i) => i !== tmpId),
          },
        };
      }
      return {
        ...state,
        data,
      };
    }
    // case MessageActionTypes.RECEIVE_NEW_CHAT: {
    //     const { data = [] } = MessageUtil.addReceviedMessageToChat(state, action.payload)
    //     return {
    //         ...state,
    //         data
    //     };
    // };
    case MessageActionTypes.UPDATE_NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.payload,
      };
    // case MessageActionTypes.UPDATE_MESSAGE_STATUS:
    //     const { data = [] } = MessageUtil.updateMessageStatus(state, action.payload)
    //     return {
    //         ...state,
    //         data
    //     };

    case MessageActionTypes.ADD_MESSAGE_LIST:
      return {
        ...state,
        data: MessageUtil.normalizeMessages(state, action.payload),
      };
    case MessageActionTypes.RECEIVE_NEW_MESSAGE: {
      // const conversation = get(action.payload, 'conversation');
      // if (!conversation) return state;
      // const message = get(action.payload, 'message');
      const message = action.payload;
      const messageId = get(message, 'id');
      if (!messageId) return state;
      // remove
      const messageTempId = get(message, 'tmpId');
      const allIds = [...state.data.allIds.filter((i) => i !== messageTempId), messageId];
      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [messageTempId]: undefined,
            [messageId]: {
              ...state.data.byId[messageId],
              ...message,
            },
          },
          allIds: uniq(allIds),
        },
      };
    }
    case MessageActionTypes.UPDATE_MESSAGE_STATUS: {
      // const message = get(action.payload, 'message');
      const { status, message } = action.payload;
      const messageId = get(message, 'id');
      if (!messageId || !state.data.byId[messageId]) return state;
      const allIds = [...state.data.allIds, messageId];

      return {
        ...state,
        data: {
          ...state.data,
          byId: {
            ...state.data.byId,
            [messageId]: {
              ...state.data.byId[messageId],
              sent: status,
              failed: !status,
            },
          },
          allIds: uniq(allIds),
        },
      };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    case MessageActionTypes.SEARCH_MESSAGE:
      return {
        ...state,
        isSearchingMessage: true,
        messageForReportResult: [],
      };
    case MessageActionTypes.SEARCH_MESSAGE_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        isSearchingMessage: false,
        messageSearchResult: data,
      };
    case MessageActionTypes.SEARCH_MESSAGE_FAILURE:
      return {
        ...state,
        isSearchingMessage: false,
      };
    case MessageActionTypes.FETCH_MESSAGE_REPORT:
      return {
        ...state,
        isSearchingForReport: true,
      };
    case MessageActionTypes.FETCH_MESSAGE_REPORT_SUCCESS:
      return {
        ...state,
        isSearchingForReport: false,
        messageForReportResult: action.payload.data.messages.reverse(),
      };
    case MessageActionTypes.FETCH_MESSAGE_REPORT_FAILURE:
      return {
        ...state,
        isSearchingForReport: false,
      };
    case MessageActionTypes.FETCH_SYSTEM_MESSAGE:
      return {
        ...state,
        fetchingSystemMessage: true,
      };
    case MessageActionTypes.FETCH_SYSTEM_MESSAGE_FAILURE:
      return {
        ...state,
        fetchingSystemMessage: true,
      };
    case MessageActionTypes.FETCH_SYSTEM_MESSAGE_SUCCESS: {
      return {
        ...state,
        systemMessage: action.payload.data,
        fetchingSystemMessage: false,
      };
    }
    case MessageActionTypes.FETCH_SYSTEM_MESSAGE_FOR_REPORT_SUCCESS: {
      const result = action.payload.map((item) => ({
        id: item.id,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        system_type: item.system_type,
        type: item.type,
        _conversation: item?._conversation,
        conversationClosed: item?.conversation?.is_close,
        conversationIsActive: item?.conversation?.isActive,
        conversation: item?.conversation,
        conversationGroup: item?.conversation?._group,
        conversationIdentifyType: item?.conversation?.field_value_data_type,
        conversationAssignedUser: item?.conversation?.assignee,
        conversationIdentifier: item?.conversation?._identifier,
        conversationStatus: item?.conversation?.is_close && item?.conversation?.isActive,
      }));
      return {
        ...state,
        systemMessageForReport: result,
        fetchingSystemMessage: false,
      };
    }

    case MessageActionTypes.FORCE_FETCH_MESSAGE: {
      return {
        ...state,
        forceFetchMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default messageReducer;
