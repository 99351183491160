import SocketAction from '../socket/socket.action';
import CdrAction from './cdr.actions';
import CdrUtil from './cdr.utils';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  errorMessage: null,
};

const cdrReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CdrAction.ADD_CDR:
      return {
        ...state,
        data: CdrUtil.normalizeCdr(state, action.payload),
      };
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default cdrReducer;
