import { createSelector } from 'reselect';
import { flatten, get, groupBy, includes, uniq } from 'lodash';

export default class MemberSelector {
  static selectMember = (state) => state.members;

  static selectGroup = (state) => state.groups;

  static selectUser = (state) => state.user;

  static selectMemberGroups = createSelector([this.selectMember], (member) => member.data);

  static selectMemberById = createSelector([this.selectMember], (member) => member.data.byId);
  static selectGroupByIds = createSelector([this.selectGroup], (gr) => gr.data.byId);
  static selectUsersByIds = createSelector([this.selectUser], (u) => u.users.byId);

  static selectConversationAssigneeRoles = createSelector(
    this.selectMember,
    this.selectUser,
    (state) => state.conversations,
    (state) => state.messages,
    (members, user, conversations, messages) => {
      const conversationId = messages.key;
      if (!conversationId) return [];
      const conversation = conversations.data.byId[conversationId];
      if (!conversation) return [];
      const assigneeId = conversation.assignee;
      const groupId = conversation._group;
      const userInfo = get(user, `users.byId.${assigneeId}`);
      const memberId = members.data.allIds.find(
        (mId) => members.data.byId[mId]?._user_email === userInfo?.email && groupId === members.data.byId[mId]?._group,
      );

      if (!memberId) return [];

      return get(members.data.byId[memberId], 'roles') || [];
    },
  );

  static selectUsersInGroup = (groupId) =>
    createSelector(this.selectGroup, this.selectUser, this.selectMember, (groups, user, members) => {
      const group = get(groups, `data.byId.${groupId}`);
      if (!group) return [];
      const userById = get(user, 'users.byId');
      const memberList = Object.values(members.data.byId);

      // Member of group was accepted
      const listUserEmails = memberList
        .filter((member) => member._group === groupId && member?.status === 1)
        .map((member) => member?._user_email);

      const userIds = get(user, 'users.allIds').filter((userId) => {
        const memberData = userById[userId];
        return memberData.email && listUserEmails.includes(memberData.email);
      });

      return uniq(userIds).map((id) => {
        const userData = userById[id];
        const memData = memberList.find((m) => m._user_email == userData.email);
        return { ...userData, member: memData };
      });
    });

  static selectUserIdInGroups = (groups) =>
    createSelector(this.selectUser, this.selectMember, (user, members) => {
      const ownerIds = groups.map((item) => item._user);
      const listInfoMembers = flatten(
        groups.map((item) => item.members.map((memberId) => members.data.byId[memberId])),
      );
      const listUserEmails = listInfoMembers.map((i) => i?._user_email);
      const userIds = user.users.allIds.filter((userId) => includes(listUserEmails, user.users.byId[userId]?.email));
      return uniq([...userIds, ...ownerIds]);
    });

  static selectUserIdByGroupIds = (groupIds) =>
    createSelector(this.selectGroup, this.selectUser, this.selectMember, (group, user, members) => {
      const groups = Object.values(group.data.byId).filter((item) => groupIds.includes(item.id));
      const ownerIds = groups.map((item) => item._user);
      const listInfoMembers = flatten(
        groups.map((item) => item.members.map((memberId) => members.data.byId[memberId])),
      );
      const listUserEmails = listInfoMembers.map((i) => i?._user_email);
      const userIds = user.users.allIds.filter((userId) => includes(listUserEmails, user.users.byId[userId]?.email));
      return uniq([...userIds, ...ownerIds]);
    });

  static selectMemberByEmail = (memberEmail, groupId) =>
    createSelector(this.selectMember, (members) => {
      const memberId = members.data.allIds.find(
        (id) => members.data.byId[id]?._user_email === memberEmail && members.data.byId[id]?._group === groupId,
      );

      return members.data.byId[memberId];
    });

  static selectMemberRequesting = createSelector([this.selectUser, this.selectMember], (user, members) => {
    const currentUserEmail = user?.currentUser?.email;
    const result = Object.values(members.data.byId).filter(
      (mem) => mem._user_email === currentUserEmail && mem?.status === 0,
    );
    return result;
  });

  static selectCurrentUserIsMemberInGroup = (groupId) =>
    createSelector([this.selectMember, this.selectUser], (members, user) => {
      const { currentUser } = user;
      if (!currentUser) return null;
      const { email } = currentUser;

      const mem = Object.values(members.data.byId).find((m) => {
        return m?._user_email === email && groupId === m._group;
      });

      return mem;
    });

  static selectGroupByMemberByGroupId = createSelector(this.selectMember, (members) => {
    return groupBy(Object.values(members.data.byId), '_group');
  });

  static selectCurrentUserIsMemberNotIsOwner = createSelector(
    [this.selectMember, this.selectUser, this.selectGroup],
    (members, user, groups) => {
      const { currentUser } = user;
      if (!currentUser) return null;
      const { email } = currentUser;

      const groupsOwned = groups.data.allIds.filter((id) => groups.data.byId[id]._user === currentUser.id);

      const mem = Object.values(members.data.byId).find((m) => {
        return m?._user_email === email && !groupsOwned.includes(m._group);
      });

      return mem;
    },
  );
  static selectCurrentUserIsOwner = createSelector([this.selectUser, this.selectGroup], (user, groups) => {
    const { currentUser } = user;
    if (!currentUser) return null;
    const allGroupUserHave = groups.data.allIds.filter((id) => groups.data.byId[id]._user === currentUser.id);
    return allGroupUserHave && allGroupUserHave.length > 0;
  });

  static selectAllGroupUserOwnerOrMember = createSelector(
    [this.selectUser, this.selectGroup, this.selectMemberById],
    (user, groups, members) => {
      const { currentUser } = user;
      if (!currentUser) return null;
      const owner = Object.values(groups.data.byId)
        .filter((item) => item._user === currentUser.id)
        .map((item) => item.id);
      const member = Object.values(members)
        .filter((member) => {
          return member._user_email === currentUser.email;
        })
        .map((item) => item._group);
      return uniq([...owner, ...member]);
    },
  );

  static selectAllGroupDataUserOwnerOrMember = createSelector(
    [this.selectUser, this.selectGroupByIds, this.selectMemberById, this.selectUsersByIds],
    (user, groups, members, userById) => {
      if (Object.keys(groups).length > 0) {
        const { currentUser } = user;
        if (!currentUser) return null;
        const owner = Object.values(groups).filter((item) => item._user === currentUser.id);
        const member = Object.values(members)
          .filter((member) => {
            let userData = {};
            Object.values(userById).forEach((userItem) => {
              if (userItem.email === member._user_email) {
                userData = userItem;
              }
            });
            return member?._user_email === currentUser.email || groups[member._group]?.members?.includes(userData?.id);
          })
          .map((item) => {
            return groups[item._group];
          });
        return uniq([...owner, ...member]);
      }
      return [];
    },
  );
}
