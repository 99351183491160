import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import SIP from 'sip.js';
import publicIP from 'public-ip';
import App from './App';
import store, { persistor } from './redux/rootStore';
import { appInfo } from './enums/app';
import { SIPProvider } from 'react-sipjs';

if (!import.meta.env.VITE_APP_REACT_APP_LOGGER_FACTORY) {
  SIP.LoggerFactory = null;
}

const onBeforeLift = async () => {
  appInfo.publicIP = (await publicIP.v4()) || (await publicIP.v6());
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate onBeforeLift={onBeforeLift} loading={null} persistor={persistor}>
      <SIPProvider
        options={{
          domain: import.meta.env.VITE_APP_NEW_VOICE_DOMAIN,
          webSocketServer: import.meta.env.VITE_APP_NEW_VOICE_SOCKET_DOMAIN,
        }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SIPProvider>
    </PersistGate>
  </Provider>,
);
