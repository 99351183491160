export default class DncAction {
  static DNC_ACTION_TYPE = {
    // Get all
    HANDLE_FETCH_ALL_DNC_START: 'Dnc/HANDLE_FETCH_ALL_DNC_START',
    HANDLE_FETCH_ALL_DNC_SUCCESS: 'Dnc/HANDLE_FETCH_ALL_DNC_SUCCESS',
    HANDLE_FETCH_ALL_DNC_FAILURE: 'Dnc/HANDLE_FETCH_ALL_DNC_FAILURE',
    // Delete
    HANDLE_DELETE_DNC_START: 'Dnc/HANDLE_DELETE_DNC_START',
    HANDLE_DELETE_DNC_SUCCESS: 'Dnc/HANDLE_DELETE_DNC_SUCCESS',
    HANDLE_DELETE_DNC_FAILURE: 'Dnc/HANDLE_DELETE_DNC_FAILURE',
    // Add
    HANDLE_ADD_DNC_START: 'Dnc/HANDLE_ADD_DNC_START',
    HANDLE_ADD_DNC_SUCCESS: 'Dnc/HANDLE_ADD_DNC_SUCCESS',
    HANDLE_ADD_DNC_FAILURE: 'Dnc/HANDLE_ADD_DNC_FAILURE',
    // Fetch DNC by Id
    FETCH_DNC_BY_ID_START: 'Dnc/FETCH_DNC_BY_ID_START',
    FETCH_DNC_BY_ID_SUCCESS: 'Dnc/FETCH_DNC_BY_ID_SUCCESS',
    FETCH_DNC_BY_ID_FAILURE: 'Dnc/FETCH_DNC_BY_ID_FAILURE',
  };

  static fetchDNCById(id) {
    return {
      type: DncAction.DNC_ACTION_TYPE.FETCH_DNC_BY_ID_START,
      payload: id,
    };
  }

  static fetchDNCByIdSuccess(dnc) {
    return {
      type: DncAction.DNC_ACTION_TYPE.FETCH_DNC_BY_ID_SUCCESS,
      payload: dnc,
    };
  }

  static fetchDNCByIdFailure(errorMessage) {
    return {
      type: DncAction.DNC_ACTION_TYPE.FETCH_DNC_BY_ID_FAILURE,
      payload: errorMessage,
    };
  }

  // get all
  static fetchAllDncStart() {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_START,
    };
  }

  static fetchAllDncSuccess(dnc) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_SUCCESS,
      payload: dnc,
    };
  }

  static fetchAllDncFailure(errorMessage) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_FETCH_ALL_DNC_FAILURE,
      payload: errorMessage,
    };
  }

  // delete
  static deleteDncStart(dncId, callback) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_DELETE_DNC_START,
      payload: dncId,
      callback,
    };
  }

  static deleteDncSuccess(dncId) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_DELETE_DNC_SUCCESS,
      payload: dncId,
    };
  }

  static deleteDncFailure(errorMessage) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_DELETE_DNC_FAILURE,
      payload: errorMessage,
    };
  }

  static addDncStart(_contact, _group, callback) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_ADD_DNC_START,
      payload: { _contact, _group },
      callback,
    };
  }

  static addDncSuccess(dnc) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_ADD_DNC_SUCCESS,
      payload: dnc,
    };
  }

  static addDncFailure(errorMessage) {
    return {
      type: DncAction.DNC_ACTION_TYPE.HANDLE_ADD_DNC_FAILURE,
      payload: errorMessage,
    };
  }
}
