import CampaignAction from './campaign.actions';
import CampaignUtil from './campaign.utils';
import UserAction from '../user/user.actions';
import { CampaignSession } from '../../enums/campaign';
const {
  normalizeContactList,
  normalizeContact,
  normalizeDeleteContact,
  normalizeDeleteContactByOwner,
  normalizeAddContactList,
  getUpdateStateOfCreatingSupportingDataForDefaultCampaign,
} = CampaignUtil;

const { CAMPAIGN_ACTION } = CampaignAction;

const INITIAL_STATE = {
  fetching: false,
  data: {
    byId: {},
    allIds: [],
  },
  errorMessage: undefined,
  // creatingSupportingData: {
  //   description: false,
  //   message_flow: false,
  //   message_sample: false,
  // },
  supportingData: {
    description: '',
    message_flow: '',
    message_sample1: '',
    message_sample2: '',
    message_sample3: '',
    message_sample4: '',
    message_sample5: '',
  },
  isCreatingSupportDataDescription: false,
  isCreatingSupportDataMessageFlow: false,
  isCreatingSupportDataMessageSample: false,
};

const campaignReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_BY_OWNER: {
      return {
        ...state,
        data: normalizeDeleteContactByOwner(state.data, action.payload),
      };
    }
    case CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        data: normalizeDeleteContact(state.data, action.payload),
      };
    }
    case CAMPAIGN_ACTION.HANDLE_UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        data: normalizeAddContactList(state.data, action.payload),
      };
    }
    case CAMPAIGN_ACTION.HANDLE_CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        data: normalizeContact(state.data, action.payload),
      };
    }
    case CAMPAIGN_ACTION.FETCH_CAMPAIGN_START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case CAMPAIGN_ACTION.FETCH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        data: normalizeContactList(action.payload),
        fetching: false,
      };
    }
    case CAMPAIGN_ACTION.FETCH_CAMPAIGN_FAILURE: {
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    }
    case CAMPAIGN_ACTION.CREATE_SUPPORTING_DESCRIPTION_FOR_DEFAULT_CAMPAIGN_START: {
      return {
        ...state,
        isCreatingSupportDataDescription: true,
      };
    }
    case CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_FLOW_FOR_DEFAULT_CAMPAIGN_START: {
      return {
        ...state,
        isCreatingSupportDataMessageFlow: true,
      };
    }
    case CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_SAMPLE_FOR_DEFAULT_CAMPAIGN_START: {
      return {
        ...state,
        isCreatingSupportDataMessageSample: true,
      };
    }
    case CAMPAIGN_ACTION.CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_SUCCESS: {
      const { data } = action.payload;
      const { section } = action.payload;
      const isDescription = section === CampaignSession.description;
      const isMessageFlow = section === CampaignSession.message_flow;
      const isMessageSample = section === CampaignSession.message_sample;
      return {
        ...state,
        isCreatingSupportDataDescription: isDescription ? false : state.isCreatingSupportDataDescription,
        isCreatingSupportDataMessageFlow: isMessageFlow ? false : state.isCreatingSupportDataMessageFlow,
        isCreatingSupportDataMessageSample: isMessageSample ? false : state.isCreatingSupportDataMessageSample,
        supportingData: { ...state.supportingData, ...data },
      };
    }
    case CAMPAIGN_ACTION.CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_FAILURE: {
      const { section } = action.payload;
      const isDescription = section === CampaignSession.description;
      const isMessageFlow = section === CampaignSession.message_flow;
      const isMessageSample = section === CampaignSession.message_sample;
      return {
        ...state,
        isCreatingSupportDataDescription: isDescription ? false : state.isCreatingSupportDataDescription,
        isCreatingSupportDataMessageFlow: isMessageFlow ? false : state.isCreatingSupportDataMessageFlow,
        isCreatingSupportDataMessageSample: isMessageSample ? false : state.isCreatingSupportDataMessageSample,
      };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default campaignReducer;
