import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { IDENTIFY_TYPE } from '../../enums/identify';

export default class IdentifySelector {
  static selectIdentifyData = (state) => state.identify;

  static isFetchingNewIdentify = createSelector([this.selectIdentifyData], (identify) => identify.fetching);
  static isRemovingContactId = createSelector([this.selectIdentifyData], (identify) => identify.removingContactId);
  static isAddingNewIdentify = createSelector([this.selectIdentifyData], (identify) => identify.addingNewIdentify);
  static allIdentify = createSelector([this.selectIdentifyData], (identify) => identify.data.byId);

  static selectIdentifyById = (identifier) =>
    createSelector([this.selectIdentifyData], (identify) => {
      const byId = get(identify, 'data.byId');
      const data = byId[identifier];
      return data;
    });

  static getIdentifyByContactId = (contactId, type = null) =>
    createSelector([this.selectIdentifyData], (identify) => {
      let identifyType = type;
      const isFetchingAllIdentify = get(identify, 'fetchAll');
      const byId = get(identify, 'data.byId');
      const allIds = get(identify, 'data.allIds');
      if (byId && allIds) {
        let data = {};
        if (!type) {
          data =
            allIds &&
            allIds.reduce((pre, cur) => {
              if (byId[cur]._contact === contactId) return { ...pre, [cur]: byId[cur] };
              return pre;
            }, {});
          return data;
        } else {
          data =
            allIds &&
            allIds.reduce((pre, cur) => {
              if (byId[cur]._contact === contactId && byId[cur]?.type === identifyType)
                return { ...pre, [cur]: byId[cur] };
              return pre;
            }, {});
          return data;
        }
      }
      return {};
    });
}
