export const PATH_APP = {
  LANDING_PATH: '/',
  REQUEST_PATH: '/requestdemo',
  PRIVACY_PATH: '/privacy',
  TERM_PATH: '/terms',
  REGISTER_PATH: '/register',
  VERIFY_PATH: '/verify',
  FORGOT_PASSWORD_PATH: '/forgot-password',
  RESET_PASSWORD_PATH: '/reset-password',
  DASHBOARD_PATH: '/app',
  LOGIN_PATH: '/login',
  FORBIDDEN_PATH: '/403',
  UNAUTHORIZED_PATH: '/401',
  NOTFOUND_PATH: '*',
};

export const SIGNED_URL = '/files/getSignedUrl';
