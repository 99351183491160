import { omit } from 'lodash';
import ListAction from './list.actions';
import ListUtils from './list.utils';
import UserAction from '../user/user.actions';
import { normalizeUpdatedData } from '../../util';
import { LIST_ITEM_TYPE } from '../../enums/list';

const INITIAL_STATE = {
  fetching: true,
  creating: false,
  updating: false,
  deleting: false,
  executeCampaignLoading: false,
  uploading: false,
  replacing: false,
  data: {
    byId: {},
    allIds: [],
  },
  selectedData: {
    type: LIST_ITEM_TYPE.ALL_CONTACTS,
    id: null,
  },
  contactIdList: [],
  selectedContactIdList: [],
  selectedPage: 0,
};

const listReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ListAction.SET_SELECTED_CONTACT_ID_LIST:
      return {
        ...state,
        selectedContactIdList: action.payload,
      };

    case ListAction.SET_CONTACT_ID_LIST:
      return {
        ...state,
        contactIdList: action.payload,
      };

    case ListAction.SET_SELECTED_DATA:
      return {
        ...state,
        selectedData: action.payload,
      };

    case ListAction.REPLACE_LIST.START:
      return {
        ...state,
        replacing: true,
      };
    case ListAction.REPLACE_LIST.SUCCESS:
      return {
        ...state,
        replacing: false,
      };
    case ListAction.REPLACE_LIST.FAILURE:
      return {
        ...state,
        replacing: false,
        errorMessage: action.payload,
      };

    case ListAction.UPLOAD_LIST.START:
      return {
        ...state,
        uploading: true,
      };
    case ListAction.UPLOAD_LIST.SUCCESS:
      return {
        ...state,
        uploading: false,
      };
    case ListAction.UPLOAD_LIST.FAILURE:
      return {
        ...state,
        uploading: false,
        errorMessage: action.payload,
      };

    case ListAction.FETCH_LISTS.START:
      return {
        ...state,
        fetching: true,
      };
    case ListAction.FETCH_LISTS.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: normalizeUpdatedData(state.data, action.payload),
      };
    case ListAction.FETCH_LISTS.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case ListAction.EXECUTE_CAMPAIGN_ON_LIST.START:
    case ListAction.STOP_CAMPAIGN_ON_LIST.START:
      return {
        ...state,
        executeCampaignLoading: true,
      };
    case ListAction.EXECUTE_CAMPAIGN_ON_LIST.SUCCESS:
    case ListAction.STOP_CAMPAIGN_ON_LIST.SUCCESS:
      return {
        ...state,
        executeCampaignLoading: false,
      };
    case ListAction.EXECUTE_CAMPAIGN_ON_LIST.FAILURE:
    case ListAction.STOP_CAMPAIGN_ON_LIST.FAILURE:
      return {
        ...state,
        executeCampaignLoading: false,
        errorMessage: action.payload,
      };

    case ListAction.CREATE_NEW_LIST.START:
      return {
        ...state,
        creating: true,
      };
    case ListAction.CREATE_NEW_LIST.SUCCESS:
      return {
        ...state,
        creating: false,
        data: normalizeUpdatedData(state.data, action.payload),
      };
    case ListAction.CREATE_NEW_LIST.FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    case ListAction.UPDATE_LIST.START:
      return {
        ...state,
        updating: true,
      };
    case ListAction.UPDATE_LIST.SUCCESS:
      return {
        ...state,
        updating: false,
        data: normalizeUpdatedData(state.data, action.payload),
      };
    case ListAction.UPDATE_LIST.FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
      };

    case ListAction.DELETE_LIST.START:
      return {
        ...state,
        deleting: true,
      };
    case ListAction.DELETE_LIST.SUCCESS:
      const byId = omit(state.data.byId, action.payload);
      return {
        ...state,
        deleting: false,
        data: {
          byId,
          allIds: Object.keys(byId),
        },
      };
    case ListAction.DELETE_LIST.FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };
    case ListAction.SET_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default listReducer;
