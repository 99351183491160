import { GROUP_CALLER_TYPE_FIELD, GROUP_FIELDS_CALL_SETTING } from '../enums/modals/groups';

export const initForCallSettingIvr = {
  type: null,
  member_id: null,
  group_id: null,
  other_group_id: null,
  external_number: '',
  playfile: null,
};

export const initForCallSetting = {
  call_setting: GROUP_FIELDS_CALL_SETTING.HANG_UP,
  call_setting_member_id: null,
  call_setting_group_id: null,
  call_setting_other_group_id: null,
  call_setting_external_number: '',
  call_setting_welcome_media: null,
  call_setting_role: null,
  call_setting_forward_phone: '',
  // failover
  call_setting_failover_action: null,
  call_setting_failover_media: null,
  call_setting_failover_external_number: '',
  call_setting_failover_member_id: null,
  call_setting_failover_other_group_id: null,
  // timeout
  call_setting_timeout_action: null,
  call_setting_timeout_media: null,
  call_setting_timeout_external_number: '',
  call_setting_caller_type: GROUP_CALLER_TYPE_FIELD.DID_ANI,
  call_setting_timeout_member_id: null,
  call_setting_timeout_other_group_id: null,
  // queue_settings
  call_setting_queue_settings_queue_timeout: null,
  call_setting_queue_settings_queue_media: null,
  call_setting_queue_settings_timeout_media: null,
  // voicemail
  call_setting_queue_settings_voicemail_timeout: null,
  call_setting_queue_settings_voicemail_media: null,
  call_setting_queue_settings_voicemail_enable: null,
  // ivr
  call_setting_ivr_dtmf_arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => ({
    ...initForCallSettingIvr,
    id: String(number),
  })),
  call_setting_auto_record: false,
};

export const allFieldCallSeting = Object.keys(initForCallSetting);
