export default class MergeLogAction {
  static FETCH_MERGE_LOGS = {
    START: 'MergeLogs/FETCH_MERGE_LOGS_START',
    SUCCESS: 'MergeLogs/FETCH_MERGE_LOGS_SUCCESS',
    FAILURE: 'MergeLogs/FETCH_MERGE_LOGS_FAILURE',
  };

  static FETCH_MERGE_LOGS_BY_IDS = {
    START: 'MergeLogs/FETCH_MERGE_LOGS_BY_IDS_START',
    SUCCESS: 'MergeLogs/FETCH_MERGE_LOGS_BY_IDS_SUCCESS',
    FAILURE: 'MergeLogs/FETCH_MERGE_LOGS_BY_IDS_FAILURE',
  };

  static fetchMergeLogsByIds(contactId) {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.START,
      payload: contactId,
    };
  }

  static fetchMergeLogsByIdsSuccess(data) {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.SUCCESS,
      payload: data,
    };
  }

  static fetchMergeLogsByIdsFailure(errorMessage) {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.FAILURE,
      payload: errorMessage,
    };
  }

  static fetchMergeLogs() {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS.START,
    };
  }

  static fetchMergeLogSuccess(data) {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS.SUCCESS,
      payload: data,
    };
  }

  static fetchMergeLogFailure(errorMessage) {
    return {
      type: MergeLogAction.FETCH_MERGE_LOGS.FAILURE,
      payload: errorMessage,
    };
  }
}
