import React from 'react';
import { put, all, takeEvery, select } from 'redux-saga/effects';
import { confirmAlert } from 'react-confirm-alert';
import SocketAction from '../socket/socket.action';
import UserSelector from '../user/user.selectors';
import UserAction from '../user/user.actions';
import { USER_LOGIN_PLATFORM } from '../../enums/user';
import VoiceAction from '../voice/voice.actions';

function* updateCurrentUser({ payload }) {
  try {
    const currentuser = yield select(UserSelector.selectCurrentUser);

    if (!currentuser) return;

    yield put(UserAction.updateUserSuccess(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* updateUser({ payload: user }) {
  try {
    if (!user) throw new Error('User not found.');

    yield put(UserAction.fetchUserListSuccess([user]));
  } catch (error) {
    console.log({ error });
  }
}

function* updateUserField({ payload }) {
  const { id, key, value } = payload;

  try {
    yield put(UserAction.updateUserField(id, key, value));
  } catch (error) {
    console.log({ error });
  }
}

function* updateOnlineUser({ payload }) {
  try {
    yield put(UserAction.updateOnlineUser(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* deleteOnlineUser({ payload }) {
  try {
    yield put(UserAction.deleteOnlineUser(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* logOutUser({ payload = {} }) {
  try {
    const { message, platform } = JSON.parse(payload);
    if (platform === USER_LOGIN_PLATFORM.WEB) {
      confirmAlert({
        title: 'Notification',
        message: message,
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
      yield put(UserAction.userLogoutStart());
    }
  } catch (error) {
    console.log({ error });
  }
}
function* callUpdateListSessionList({ payload }) {
  try {
    yield put(VoiceAction.updateSessionList(payload));
  } catch (error) {
    console.log({ error });
  }
}
function* callUpdateListInvitedExternalCall({ payload }) {
  try {
    yield put(VoiceAction.updateListInvitedExternalCall(payload));
  } catch (error) {
    console.log({ error });
  }
}
function* callGetVoiceLogOfConference({ payload }) {
  try {
    yield put(VoiceAction.callSetCallLogFromVoiceServer(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* forceHungUpConversationCall({ payload }) {
  try {
    yield put(VoiceAction.setForceHungUpCallConversation(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* getRequestAndSyncVoiceData({ payload }) {
  try {
    yield put(VoiceAction.getAndSyncVoiceData(payload));
  } catch (error) {
    console.log({ error });
  }
}
function* syncVoiceData({ payload }) {
  try {
    yield put(VoiceAction.syncVoiceData(payload));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeUserFlow() {
  yield all([
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_CURRENT_USER, updateCurrentUser),
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_USER, updateUser),
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_USER_FIELD, updateUserField),
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_ONLINE_USER, updateOnlineUser),
    takeEvery(SocketAction.SOCKET_EVENT.DELETE_ONLINE_USER, deleteOnlineUser),
    takeEvery(SocketAction.SOCKET_EVENT.LOG_OUT, logOutUser),
    takeEvery(SocketAction.SOCKET_EVENT.CALL_UPDATE_SESSION_LIST_GROUP, callUpdateListSessionList),
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_LIST_INVITED_EXTERNAL_CALL, callUpdateListInvitedExternalCall),
    takeEvery(SocketAction.SOCKET_EVENT.UPDATE_CALL_LOG_CONFERENCE, callGetVoiceLogOfConference),
    takeEvery(SocketAction.SOCKET_EVENT.FORCE_HUNG_UP_CALL_CONVERSATION, forceHungUpConversationCall),
    takeEvery(SocketAction.SOCKET_EVENT.REQUEST_SYNC_VOICE_DATA, getRequestAndSyncVoiceData),
    takeEvery(SocketAction.SOCKET_EVENT.SYNC_VOICE_DATA, syncVoiceData),
  ]);
}
