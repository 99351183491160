import { takeLatest, put, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';
import BrandAction from './brand.actions';

const { BrandActionTypes } = BrandAction;

export function* fetchBrandNeedUpdateById({ payload }) {
  try {
    const response = yield call(axios.get, `/brands/needUpdate/${payload}`);
    yield put(BrandAction.fetchBrandSuccess(response.data));
  } catch (error) {
    console.log({ error });
  }
}

export function* UpdateBrand({ payload }) {
  try {
    const response = yield call(axios.put, `/brands`, payload);
    yield put(BrandAction.updateBrandSuccess(response.data));
  } catch (error) {
    toast.error(error.message);
    yield put(BrandAction.updateBrandFailure(error));
  }
}

export function* brandCastFlow() {
  yield all([
    takeLatest(BrandActionTypes.FETCH_BRAND_NEED_UPDATE.START, fetchBrandNeedUpdateById),
    takeLatest(BrandActionTypes.UPDATE_BRAND.START, UpdateBrand),
  ]);
}
