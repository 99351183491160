import { createAsyncAction } from '..';

export default class PlanAction {
  static FETCH_DEFAULT_PLANS = {
    START: 'PlanAction/FETCH_DEFAULT_PLANS_START',
    SUCCESS: 'PlanAction/FETCH_DEFAULT_PLANS_SUCCESS',
    FAILURE: 'PlanAction/FETCH_DEFAULT_PLANS_FAILURE',
  };

  static ADD_PAYMENT_METHOD = {
    START: 'PlanAction/ADD_PAYMENT_METHOD_START',
    SUCCESS: 'PlanAction/ADD_PAYMENT_METHOD_SUCCESS',
    FAILURE: 'PlanAction/ADD_PAYMENT_METHOD_FAILURE',
  };

  static FETCH_PLAN_BY_DATERANGE = {
    START: 'PlanAction/FETCH_PLAN_BY_DATERANGE_START',
    SUCCESS: 'PlanAction/FETCH_PLAN_BY_DATERANGE_SUCCESS',
    FAILURE: 'PlanAction/FETCH_PLAN_BY_DATERANGE_FAILURE',
  };

  static SET_PLAN_VIEW = 'PlanAction/SET_PLAN_VIEW';

  static SET_ADDTIONAL_USER_SEATS = 'PlanAction/SET_ADDTIONAL_USER_SEATS';

  static SET_PLAN_CYCLE = 'PlanAction/SET_PLAN_CYCLE';

  static SET_SELECTED_PLAN = 'PlanAction/SET_SELECTED_PLAN';

  static FETCH_USER_PLAN = 'PlanAction/FETCH_USER_PLAN';

  static fetchUserPlan = createAsyncAction(PlanAction.FETCH_USER_PLAN);

  static setSelectedPlan = (selectedPlan) => ({
    type: this.SET_SELECTED_PLAN,
    payload: selectedPlan,
  });

  static setPlanCycle = (planCycle) => ({
    type: this.SET_PLAN_CYCLE,
    payload: planCycle,
  });

  static setAdditionalUserSeats = (userSeats) => ({
    type: this.SET_ADDTIONAL_USER_SEATS,
    payload: userSeats,
  });

  static setPlanView = (planView) => ({
    type: this.SET_PLAN_VIEW,
    payload: planView,
  });

  static fetchPlanCountByDateRange = (startDate, endDate, userIds) => ({
    type: this.FETCH_PLAN_BY_DATERANGE.START,
    payload: { startDate, endDate, userIds },
  });

  static fetchPlanCountByDateRangeSuccess = (payload) => ({
    type: this.FETCH_PLAN_BY_DATERANGE.SUCCESS,
    payload,
  });

  static fetchPlanCountByDateRangeFailure = (errorMessage) => ({
    type: this.FETCH_PLAN_BY_DATERANGE.FAILURE,
    payload: errorMessage,
  });

  static fetchDefaultPlans = () => ({
    type: this.FETCH_DEFAULT_PLANS.START,
  });

  static fetchDefaultPlansSuccess = (payload) => ({
    type: this.FETCH_DEFAULT_PLANS.SUCCESS,
    payload,
  });

  static fetchDefaultPlansFailure = (errorMessage) => ({
    type: this.FETCH_DEFAULT_PLANS.FAILURE,
    payload: errorMessage,
  });

  static addPaymentMethod = (planId, paymentMethod, purchased_seat, brandData, a2pCampaignData, callback) => ({
    type: this.ADD_PAYMENT_METHOD.START,
    payload: {
      _plan: planId,
      paymentMethod,
      purchased_seat,
      brandData,
      a2pCampaignData,
    },
    callback,
  });

  static addPaymentMethodSuccess = (payload) => ({
    type: this.ADD_PAYMENT_METHOD.SUCCESS,
    payload,
  });

  static addPaymentMethodFailure = (error) => ({
    type: this.ADD_PAYMENT_METHOD.FAILURE,
    payload: error,
  });
}
