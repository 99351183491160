import { get, isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { CALL_STATUS, HAVE_CALL_CALL_STATUSES } from '../../enums/socket/voice';
import { SessionState } from '../../enums/voice/index';

export default class VoiceSelector {
  static selectVoice = (state) => state.voice;

  static selectUser = (state) => state.user;

  static selectCurrentUser = (state) => state.user.currentUser;

  static selectIncall = createSelector([this.selectVoice], (voice) => voice.incall);

  static selectWsState = createSelector([this.selectVoice], (voice) => voice.wsState);

  static selectCalltrying = createSelector([this.selectVoice], (voice) => voice.calltrying);

  static selectCallringing = createSelector([this.selectVoice], (voice) => voice.callringing);

  static selectCalltalking = createSelector([this.selectVoice], (voice) => voice.calltalking);

  static selectRegState = createSelector([this.selectVoice], (voice) => voice.regState);

  static selectConnectState = createSelector([this.selectVoice], (voice) => voice.wsState);

  static selectDialpad = createSelector([this.selectVoice], (voice) => voice.dialpad);

  static selectKeypad = createSelector([this.selectVoice], (voice) => voice.keypad);

  static selectElapsed = createSelector([this.selectVoice], (voice) => voice.elapsed);

  static selectVoiceData = createSelector([this.selectVoice], (voice) => voice.data.byId);

  static getSessionList = createSelector([this.selectVoice], (voice) => voice.sessionList);

  static getSessionListFilterByConversationId = (conversationId) =>
    createSelector([this.getSessionList], (sessionList) => {
      const sessionListArr = Object.values(sessionList);
      if (sessionListArr.length === 0) return [];
      return sessionListArr.filter(
        (session) =>
          session.state !== SessionState.Terminated &&
          session.state !== SessionState.Terminating &&
          session.conversationId === conversationId,
      );
    });

  static getCurrentCallLog = createSelector([this.selectVoice], (voice) => voice.currentCallLog);

  static forceHungUpConversation = createSelector([this.selectVoice], (voice) => voice.forceHungUpConversation);

  static getInCallStatus = createSelector([this.selectVoice], (voice) => voice.isInCall);

  static getIsAcceptCallFromPopup = createSelector([this.selectVoice], (voice) => voice.acceptCallFromPopup);
  static getCurrentCallStatus = createSelector([this.selectVoice], (voice) => voice.currentCallStatus);

  static getSelectedSession = (sessionId) =>
    createSelector([this.getSessionList], (sessionList) => {
      return get(sessionList, sessionId, {});
    });

  static getSessionIds = createSelector([this.getSessionList, this.selectCurrentUser], (sessions, currentUser) => {
    const sessionList = Object.values(sessions).filter(
      (session) => session.state !== SessionState.Terminated && session.state !== SessionState.Terminating,
    );
    const ringingCall = [];
    const pickupCall = [];
    const requestJoinRingingCall = [];
    const otherMemberCall = [];
    const sessionIds = [];
    const ringingIds = [];
    const onCallIds = [];
    const requestJoinCallInCall = [];

    if (sessionList.length === 0)
      return { sessionIds: [], otherMemberCall: [], ringingIds: [], requestJoinCallInCall: [], onCallIds: [] };

    sessionList.forEach((session) => {
      if (session.state === SessionState.Initial) {
        if (session.isRequestJoinCall) {
          requestJoinRingingCall.push(session);
        } else ringingCall.push(session);
      }
      if (session.state === SessionState.Established || session.state === SessionState.Establishing) {
        pickupCall.push(session);
      }
    });

    if (ringingCall.length > 0 && requestJoinRingingCall.length === 0) {
      ringingCall.forEach((session) => {
        if (
          session.userId === currentUser.id ||
          (session.fromLiveChat && session.liveChatCallToUser === currentUser.id)
        ) {
          sessionIds.push(session);
          ringingIds.push(session);
        }
      });
    }
    // pickup
    if (pickupCall.length > 0) {
      pickupCall.forEach((session) => {
        if (session.userId === currentUser.id) {
          sessionIds.push(session);
          onCallIds.push(session);
        } else {
          otherMemberCall.push(session);
        }
      });
    }

    if (pickupCall.length > 0 && requestJoinRingingCall.length > 0) {
      requestJoinRingingCall.forEach((session) => {
        if (session.userId === currentUser.id) {
          sessionIds.push(session);
          requestJoinCallInCall.push(session);
        }
      });
    }

    return { sessionIds, otherMemberCall, ringingIds, requestJoinCallInCall, onCallIds };
  });

  static getCurrentInvitedExternalCall = createSelector(
    [this.selectVoice],
    (voice) => voice.currentInvitedExternalCall,
  );

  static selectSessionIdByRTCSessionId = (rtcSessionId) =>
    createSelector([this.selectVoice], (voice) => {
      const session = Object.values(voice.data.byId).find((item) => isEqual(item.RTCSessionId, rtcSessionId));
      if (session) {
        return session.id;
      }
      return null;
    });

  static selectJoinCallBySessionId = (sessionId) =>
    createSelector([this.selectVoice], (voice) => {
      const session = voice.data.byId[sessionId];
      if (session) {
        return !!session.isJoinCall;
      }
      return false;
    });

  static selectVoiceDataByConversationIds = createSelector([this.selectVoice], (voice) => {
    const conversations = {};
    Object.values(voice.data.byId).forEach((voice) => {
      if (voice.eventName !== CALL_STATUS.HUNGUP) {
        conversations[voice.sessionId] = voice;
      }
    });
    return conversations;
  });

  static selectVoiceList = createSelector([this.selectVoice], (voice) => Object.values(voice.data.byId));

  static selectVoiceDataByConversationId = (conversationId) =>
    createSelector([this.selectVoice], (voice) => {
      let maxTimestamp = 0;
      const session = Object.values(voice.data.byId).reduce((pre, cur) => {
        if (isEqual(cur.conversationId, conversationId) && cur.firstPickUpTimeStamp >= maxTimestamp) {
          maxTimestamp = cur.firstPickUpTimeStamp;
          return cur;
        }
        return pre;
      }, null);
      return session; // Object.values(voice.data.byId).find((item) => isEqual(item.conversationId, conversationId));
    });

  static selectVoiceCurrentUserByConversationId = (conversationId) =>
    createSelector([this.selectVoice, this.selectUser], (voice, user) => {
      const { currentUser } = user;
      let maxTimestamp = 0;
      // const session = Object.values(voice.data.byId).find((item) => isEqual(item.conversationId, conversationId));
      const session = Object.values(voice.data.byId).reduce((pre, cur) => {
        if (isEqual(cur.conversationId, conversationId) && cur.firstPickUpTimeStamp >= maxTimestamp) {
          maxTimestamp = cur.firstPickUpTimeStamp;
          return cur;
        }
        return pre;
      }, null);

      const foundCurrentUserList = session?.participantList?.filter((item) => isEqual(item.userId, currentUser.id));

      let foundCurrentUser;

      if (foundCurrentUserList?.length === 1) {
        [foundCurrentUser] = foundCurrentUserList;
      } else {
        foundCurrentUser = foundCurrentUserList?.find((participant) => participant.state !== CALL_STATUS.HUNGUP);
      }

      return foundCurrentUser;
    });

  static selectVoiceStateCurrentUser = createSelector([this.selectVoice, this.selectUser], (voice, user) => {
    const { currentUser } = user;
    const sessions = {};
    Object.values(voice.data.byId).forEach((item) => {
      if (item.eventName !== CALL_STATUS.HUNGUP) {
        const participant = item.participantList.find((p) => isEqual(p.userId, currentUser.id));
        if (participant) {
          sessions[item.sessionId] = participant.state;
        }
      }
    });
    return sessions;
  });

  static selectVoiceState = createSelector([this.selectVoice], (voice) => {
    const sessions = {};
    Object.values(voice.data.byId).forEach((item) => {
      if (item.eventName !== CALL_STATUS.HUNGUP) {
        item.participantList.forEach((p) => {
          const states = sessions[item.sessionId];
          sessions[item.sessionId] = states ? [...states, p.state] : [p.state];
        });
      }
    });
    return sessions;
  });

  static selectGetUserHaveCallOrNot = createSelector([this.selectVoice, this.selectUser], (voice, user) => {
    const { currentUser } = user;
    const values = Object.values(voice.data.byId);
    for (let i = 0; i < values.length; i += 1) {
      const item = values[i];
      const participant = item.participantList.find((p) => isEqual(p.userId, currentUser.id));
      if (participant) {
        if (HAVE_CALL_CALL_STATUSES.includes(participant.state)) {
          return true;
        }
      } else if (
        item?.type === 'outbound' &&
        item.currentUserId === currentUser.id &&
        item.participantList?.some(
          (participant) =>
            HAVE_CALL_CALL_STATUSES.includes(participant.state) && !participant.userId && participant.meta.isContact,
        )
      ) {
        return true;
      }
    }

    return false;
  });
}
