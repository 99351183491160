import { isArray, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { CUSTOM_FIELDS_NAME } from '../../enums/modals/groups';
import GroupSelector from '../group/group.selectors';
import UserSelector from '../user/user.selectors';

export default class FieldSelector {
  static selectFields = (state) => state.fields;

  static getFieldForAddContactModal = (state) => state.fields.data.byId;

  static selectFieldssData = createSelector([this.selectFields], (fields) => Object.values(fields.data.byId));

  static selectAllFieldOfOwner = createSelector(
    [this.selectFields, GroupSelector.selectListGroup, UserSelector.selectCurrentUser],
    (fields, groupList, currentUser) => {
      const ownerId = currentUser.id;
      if (ownerId) {
        const groupIds = groupList.filter((group) => group._user === ownerId).map((group) => group.id);
        return Object.values(fields.data.byId).filter((item) => groupIds.includes(item?._group));
      }
      return [];
    },
  );

  static selectFieldsByGroupId = (groupIds) =>
    createSelector([this.selectFields], (fields) =>
      Object.values(fields.data.byId).filter((item) =>
        isArray(groupIds) ? groupIds.includes(item?._group) : groupIds === item?._group,
      ),
    );

  static selectDefaultFields = createSelector([this.selectFields], (fields) =>
    Object.values(fields.data.byId).filter((field) => field?.isDefault),
  );

  static selectAllGroupCustomFields = createSelector(
    [this.selectFields, GroupSelector.selectListGroup],
    (fields, groupList) => {
      const groupIds = groupList.map((group) => group.id);
      const fieldById = fields.data.byId;

      return Object.values(fieldById)
        .filter((field) => groupIds.includes(field?._group))
        .filter(Boolean);
    },
  );

  static selectAllGroupCustomFieldByOwnerId = (ownerId) =>
    createSelector([this.selectFields, GroupSelector.selectListGroup], (fields, groupList) => {
      const groupOwnerIds = groupList.filter((group) => group._user === ownerId).map((group) => group.id);
      return Object.values(fields.data.byId).filter((field) => field?._group && groupOwnerIds.includes(field?._group));
    });

  static selectDefaultFieldsEmailAndPhone = createSelector([this.selectFields], (fields) =>
    Object.values(fields.data.byId).filter(
      (field) =>
        field?.isDefault &&
        (field?.custom_field_name === CUSTOM_FIELDS_NAME.email ||
          field?.custom_field_name === CUSTOM_FIELDS_NAME.phone),
    ),
  );

  static selectFieldsByIds = createSelector([this.selectFields], (fields) => fields.data.byId);

  static selectFieldssFetching = createSelector([this.selectFields], (fields) => fields.fetching);

  static selectFieldsUpdating = createSelector([this.selectFields], (fields) => fields.updating);

  static selectFieldsDeleting = createSelector([this.selectFields], (fields) => fields.deleting);
}
