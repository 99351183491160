import axios from 'axios';
import _, { compact, get, isArray, isEmpty, omitBy, uniq, uniqBy } from 'lodash';
import { takeLatest, put, call, all, select, takeEvery, debounce } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import SocketAction from '../socket/socket.action';
// import { fetchMessageStartAsync } from '../message/message.saga';
// import ConversationUtils from './conversation.utils';
import ConversationAction from './conversation.actions';
import ConversationSelector from './conversation.selectors';
import MessageAction from '../message/message.actions';

import UserAction from '../user/user.actions';

import { handleGetErrorMessage, handleGetErrorMessageNew } from '../../util';
import ContactAction from '../contact/contact.actions';

const ENUM_METADATA_CONVERSATION = {
  contact: {
    path: 'identify.contact',
    defaultValues: {},
  },
  identifies: {
    path: 'identify.contact.identifies',
    defaultValues: [],
  },
  field_values: {
    path: 'identify.contact.field_values',
    defaultValues: [],
  },
};
const ENUM_METADATA_CONVERSATION_LABEL = {
  contact: 'contact',
  identifies: 'identifies',
  field_values: 'field_values',
};

const createMetadata = (dataItem) => {
  const contact = [];
  const identifies = [];
  const field_values = [];

  dataItem.forEach((cvs) => {
    Object.keys(ENUM_METADATA_CONVERSATION).forEach((meta) => {
      const rawData = _.get(cvs, ENUM_METADATA_CONVERSATION[meta].path, ENUM_METADATA_CONVERSATION[meta].defaultValues);
      if (rawData)
        if (meta === ENUM_METADATA_CONVERSATION_LABEL.contact) {
          contact.push(rawData);
        } else {
          meta === ENUM_METADATA_CONVERSATION_LABEL.identifies
            ? rawData.forEach((item) => {
                identifies.push(item);
              })
            : rawData.forEach((item) => {
                field_values.push(item);
              });
        }
    });
  });
  return {
    contact: _.uniqBy(contact, 'id'),
    identifies: _.uniqBy(identifies, 'id'),
    field_values: _.uniqBy(field_values, 'id'),
  };
};
// fetch chat
export function* fetchChatStartAsync(payload) {
  const callback = payload?.callback;

  try {
    const response = yield call(axios.get, '/conversation');
    if (response && response.data) {
      const conversations = response.data;
      const metadata = createMetadata(response.data);
      yield put(ConversationAction.fetchChatSuccess(conversations, metadata));
      const userIds = uniq(
        conversations
          .map(({ assignee, mentions }) => (mentions && isArray(mentions) ? [...mentions, assignee] : assignee))
          .flat()
          .filter(Boolean),
      );
      if (!isEmpty(userIds)) {
        const usersData = yield call(axios.get, '/user/user_ids', { params: { userIds } });
        yield put(UserAction.fetchUserListSuccess(usersData.data));
      }

      if (callback) {
        callback(null, conversations);
      }
    }
  } catch (error) {
    yield put(ConversationAction.fetchChatFailure(handleGetErrorMessage(error)));

    if (callback) {
      callback(error);
    }
  }
}

export function* fetchChatById({ payload: conversationId, callback = () => {} }) {
  try {
    const response = yield call(axios.get, `/conversation/${conversationId}`);
    if (response && response.data) {
      const conversation = response.data;
      const metadata = createMetadata([conversation]);
      yield put(ConversationAction.fetchConversationByIdSuccess(conversation, metadata));

      const { assignee, mentions } = conversation;
      const userIds = Array.isArray(mentions) ? compact(uniq([...mentions, assignee])) : assignee && [assignee];

      if (!isEmpty(userIds)) {
        const usersData = yield call(axios.get, '/user/user_ids', { params: { userIds } });
        yield put(UserAction.fetchUserListSuccess(usersData.data));
      }
      callback(null, conversation);
    }
  } catch (error) {
    callback(error);
    yield put(ConversationAction.fetchConversationByIdFailure(handleGetErrorMessage(error)));
    console.log({ error });
  }
}

// update individual conversation
export function* updateConversation({ payload }) {
  try {
    const { conversationId, body } = payload;
    const dataBody = body || { _did: get(payload, '_did') };
    const dataConversationID = conversationId || get(payload, 'payload.id');
    const response = yield call(axios.put, `/conversation/${dataConversationID}`, dataBody);
    yield put(ConversationAction.updateIndividualConversation(response.data));
    yield put(ConversationAction.fetchChatStart());
  } catch (error) {
    yield put(ConversationAction.updateConversationFailure(handleGetErrorMessage(error)));
    console.log({ error });
  }
}

// set conversation user
export function* getUsersForConversation({ payload }) {
  const { conversationId } = payload;
  try {
    if (!conversationId) return null;
    const value = yield call(axios.get, `/conversation/users?conversationId=${conversationId}`);
    yield put(ConversationAction.setConversationUsers(value.data.users));
  } catch (error) {
    console.log({ error });
  }
}

// set seen
// export function* setSeen(payload) {
//   const { id, conversationId } = payload.payload;
//   try {
//     yield call(axios.post, '/conversation/userUnfollow', { id, conversationId });
//     yield put(ConversationAction.updateSeenToTrue({ id, conversationId }));
//   } catch (error) {
//     yield put(ConversationAction.handleSetSeenFailure(handleGetErrorMessage(error)));
//     console.log({ error });
//   }
// }

// untag
export function* handleUnfollow({ payload, callback = () => {} }) {
  const { user, conversationId } = payload;
  try {
    const conversation = yield select(ConversationSelector.selectByConversationId(conversationId));
    if (conversation.assignee === user.id) {
      return callback('You are an assignee of the conversation');
    }
    const response = yield call(axios.post, '/conversation/userUnfollow', { conversationId });
    callback(null, response.data);
    // yield put(ConversationAction.updateSeenToTrue({ id, conversationId, purge: true }));
  } catch (error) {
    callback(error);
    // yield put(ConversationAction.handleUntagFailure(handleGetErrorMessage(error)));
    console.log({ error });
  }
}

export function* handleFollow({ payload, callback = () => {} }) {
  const { conversationId } = payload;
  try {
    const response = yield call(axios.post, '/conversation/userFollow', { conversationId });
    callback(null, response.data);
    // yield put(ConversationAction.updateSeenToTrue({ id, conversationId, purge: true }));
  } catch (error) {
    callback(error);
    // yield put(ConversationAction.handleUntagFailure(handleGetErrorMessage(error)));
    console.log({ error });
  }
}

// all conversation flow
// create new conversation
export function* createNewConversation({ payload, callback }) {
  try {
    const response = yield call(axios.post, '/conversation', payload);
    // Existed conversation
    if (!response.data) throw new Error('Something wrong.');

    const conversation = response.data;
    yield call(fetchChatStartAsync, { callback: () => {} });
    yield put(
      MessageAction.fetchMessageStart({
        key: conversation.id,
        name: conversation.name,
        phone_number: payload.phone_number,
        region_number: payload.region_number,
        did: conversation._did,
        _group: conversation._group,
      }),
    );
    yield put(ConversationAction.setConversationUsersStart({ conversationId: conversation.id }));
    yield put(ConversationAction.chooseConversation(conversation.id));

    if (callback) {
      callback(null, conversation);
    }
  } catch (e) {
    if (callback) {
      if (get(e, 'response.data.message')) callback(get(e, 'response.data.message'));
      else callback(e, 'exception');
    }
  }
}

// remove unread messages in conversation
export function* removeUnreadMessage({ payload }) {
  const conversationId = payload;
  if (!conversationId) return;
  try {
    yield call(axios.post, '/conversation/removeUnReadInternalMessageInConversation', { conversationId });
    yield put(
      ConversationAction.updateIndividualConversation({
        id: conversationId,
        unreadCount: 0,
        mentionUnreadCount: 0,
      }),
    );
  } catch (error) {
    console.log({ error });
  }
}

// chooseConversationAsync
export function* chooseConversationAsync({ payload }) {
  const conversationId = payload;
  if (conversationId) {
    yield put(MessageAction.selectConversation(conversationId));
    yield put(ContactAction.fetchContactByConversationId(conversationId));
  }
}

export function* getLatestConversation({ payload, callback = () => {} }) {
  const { contactId, groupId } = payload;

  try {
    if (contactId && groupId) {
      const response = yield call(axios.get, '/conversation/lastConversation', { params: payload });

      if (response.data) {
        const { conversation: conversationResponse } = response.data;
        const { id } = conversationResponse;
        const conversation = yield select(ConversationSelector.selectByConversationId(id));

        if (!get(conversation, 'isFetched')) {
          yield put(MessageAction.fetchContactMessageStart(id));
        }

        callback(null, response.data.conversation);
        return;
      }
    }

    callback(new Error('ContactId or GroupId is not found.'));
  } catch (error) {
    callback(error);
  }
}

export function* getConversationsByContact({ payload }) {
  const { contactId, callback } = payload;

  try {
    const response = yield call(axios.get, '/conversation/customs', { params: { contact: contactId } });
    const { conversations } = response.data;
    let conversationIds = conversations.map((conv) => conv.id);
    const conversationsFromRedux = yield select(ConversationSelector.selectByConversationIds(conversationIds));
    const unFetchedConversations = conversationsFromRedux.filter((conv) => {
      const isUnfetched = conv && !conv.isFetched;

      if (!isUnfetched) {
        conversationIds = conversationIds.filter((id) => conv.id !== id);
      }

      return isUnfetched;
    });

    if (unFetchedConversations.length > 0) {
      yield put(MessageAction.fetchContactMessageStart(conversationIds));

      if (callback) {
        callback();
      }
    }
  } catch (error) {
    if (callback) {
      callback(error);
    }
  }
}

export function* disconnectEveryOneFromGroupCall({ payload, callback = () => {} }) {
  const { sessionId } = payload;

  try {
    const response = yield call(axios.post, '/conversation/voice/disconnectEveryOneFromGroupCall', { sessionId });
    callback(null, response.data);
  } catch (error) {
    callback(error);
    console.error(error);
  }
}

export function* addCallInSession({ payload, callback = () => {} }) {
  const { callConfig, sessionId, isJoinCall } = payload;

  try {
    const response = yield call(axios.post, '/conversation/voice/addCallInSession', { callConfig, sessionId });
    callback(null, response.data);
  } catch (error) {
    callback(error);
    console.error(error);
  }
}

export function* removeCallFromSession({ payload, callback = () => {} }) {
  const { sessionId, callId } = payload;

  try {
    const response = yield call(axios.post, '/conversation/voice/removeCallFromSession', { callId, sessionId });
    callback(null, response.data);
  } catch (error) {
    callback(error);
    console.error(error);
  }
}

export function* sendDTMF({ payload, callback = () => {} }) {
  const { sessionId, callId, dtmf } = payload;

  try {
    if (callId && sessionId && dtmf) {
      const response = yield call(axios.post, '/conversation/voice/sendDTMF', { callId, sessionId, dtmf });
      callback(null, response.data);
    }
  } catch (error) {
    callback(error);
    console.error(error);
  }
}

export function* startRecordVoiceSaga({ payload, callback = () => {} }) {
  const { sessionId, fileName } = payload;

  try {
    if (sessionId && fileName) {
      const response = yield call(axios.post, '/conversation/voice/startRecordCall', { fileName, sessionId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}

export function* stopRecordVoiceSaga({ payload, callback = () => {} }) {
  const { sessionId } = payload;

  try {
    if (sessionId) {
      const response = yield call(axios.post, '/conversation/voice/stopRecordCall', { sessionId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}

export function* checkInActiveCampaign({ payload: conversationId, callback }) {
  try {
    if (conversationId) {
      const response = yield call(axios.get, `/conversation/checkInActiveCampaign?conversationId=${conversationId}`);
      if (callback) {
        callback(null, response.data);
      }
    } else {
      throw new Error('ConversationID is undefined');
    }
  } catch (error) {
    toast.error(error.message);
    if (callback) {
      callback(error);
    }
  }
}
export function* conversationFlow() {
  const { ConversationActionTypes } = ConversationAction;
  yield all([
    takeLatest(ConversationActionTypes.FETCH_CHAT_START, fetchChatStartAsync),
    takeEvery(ConversationActionTypes.FETCH_CHAT_BY_ID_START, fetchChatById),
    takeLatest(ConversationActionTypes.SET_USERS_START, getUsersForConversation),
    takeLatest(ConversationActionTypes.HANDLE_CREATE_NEW_CONVERSATION_START, createNewConversation),
    takeLatest(ConversationActionTypes.UPDATE_INDIVIDUAL_CONVERSATION_START, updateConversation),
    takeLatest(ConversationActionTypes.CHOOSE_CONVERSATION, chooseConversationAsync),
    takeLatest(ConversationActionTypes.CHOOSE_CONVERSATION, removeUnreadMessage),
    takeLatest(ConversationActionTypes.HANDLE_UNFOLLOW, handleUnfollow),
    takeLatest(ConversationActionTypes.HANDLE_FOLLOW, handleFollow),
    takeLatest(ConversationActionTypes.GET_LATEST_CONVERSATION, getLatestConversation),
    takeLatest(ConversationActionTypes.GET_CONVERSATIONS_BY_CONTACT, getConversationsByContact),
    // Voice
    takeLatest(ConversationActionTypes.ADD_CALL_IN_SESSION, addCallInSession),
    takeLatest(ConversationActionTypes.REMOVE_CALL_FROM_SESSION, removeCallFromSession),
    takeLatest(ConversationActionTypes.DISCONNECT_EVERYONE_FROM_GROUP, disconnectEveryOneFromGroupCall),
    takeEvery(ConversationActionTypes.SEND_DTMF, sendDTMF),
    takeLatest(ConversationActionTypes.CHECK_IN_ACTIVE_CAMPAIGN, checkInActiveCampaign),
    takeLatest(ConversationActionTypes.START_RECORD_VOICE, startRecordVoiceSaga),
    takeLatest(ConversationActionTypes.STOP_RECORD_VOICE, stopRecordVoiceSaga),

    // takeLatest(ConversationActionTypes.HANDLE_SET_SEEN_START, setSeen),
  ]);
}
