import { all, put, takeEvery } from 'redux-saga/effects';
import DidAction from '../did/did.actions';
import GroupAction from '../group/group.actions';

import SocketAction from '../socket/socket.action';

const { SOCKET_EVENT } = SocketAction;

function* fetchDid({ payload }) {
  if (payload) {
    const { id } = payload;
    yield put(DidAction.fetchDids({ ids: [id] }));
  } else {
    yield put(DidAction.fetchDids());
  }
}

function* deleteDid({ payload }) {
  yield put(DidAction.deleteDid(payload));
}

export function* realtimeDidFlow() {
  yield all([takeEvery(SOCKET_EVENT.FETCH_DID, fetchDid), takeEvery(SOCKET_EVENT.DELETE_DID, deleteDid)]);
}
