import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';

import SocketAction from '../socket/socket.action';
import CampaignAction from '../campaign/campaign.actions';
import { handleGetErrorMessage } from '../../util';

const { SOCKET_EVENT } = SocketAction;

// remove deleted campaign
export function* removeDeletedCampaign(payload) {
  try {
    const campaignId = get(payload, ['payload', 'campaign', 'id']);
    yield put(CampaignAction.deleteCampaignSuccess(campaignId));
  } catch (error) {
    yield put(CampaignAction.deleteCampaignFailure(handleGetErrorMessage(error)));
    console.log(error);
  }
}

// fetch one campaign after socket emit from server
export function* fetchUpdatedCampaign({ payload }) {
  try {
    const id = get(payload, ['campaign', 'id']);
    const response = yield call(axios.get, `/campaign/${id}`);
    yield put(CampaignAction.updateCampaignSuccess(response.data));
  } catch (error) {
    yield put(CampaignAction.updateCampaignFailure(handleGetErrorMessage(error)));
  }
}
export function* removeCampaignByOwner({ payload }) {
  yield put(CampaignAction.deleteCampaignByOwner(payload.owner));
}

export function* fetchCampaignByOwner({ payload }) {
  try {
    const owner = get(payload, ['owner']);
    const response = yield call(axios.get, `/campaign/owner/${owner}`);
    yield put(CampaignAction.updateCampaignSuccess(response.data));
  } catch (error) {
    yield put(CampaignAction.updateCampaignFailure(handleGetErrorMessage(error)));
  }
}

export function* realtimeCampaignFlow() {
  yield takeLatest(SOCKET_EVENT.DELETE_CAMPAIGN, removeDeletedCampaign);
  yield takeLatest(SOCKET_EVENT.UPDATE_CAMPAIGN, fetchUpdatedCampaign);
  yield takeLatest(SOCKET_EVENT.REMOVE_CAMPAIGN_BY_OWNER, removeCampaignByOwner);
  yield takeLatest(SOCKET_EVENT.FETCH_CAMPAIGN_BY_OWNER, fetchCampaignByOwner);
}
