import PlanAction from './plan.actions';
import PlanUtils from './plan.utils';
import UserAction from '../user/user.actions';
import { PLAN_CYCLE, PLAN_VIEW } from '../../enums/plan/plan';
import { normalizeUpdatedData } from '../../util';

const { fetchUserPlan } = PlanAction;

const INITIAL_STATE = {
  tax: {
    percentage: 0,
    display_name: '',
    inclusive: false,
    country: 'US',
    state: 'TX',
    jurisdiction: 'US-TX',
    description: 'TX SASS Tax',
  },
  fetching: true,
  planCountFetching: false,
  errorMessage: undefined,
  data: {
    byId: {},
    allIds: [],
  },
  planType: {
    byId: {},
    allIds: [],
  },
  planFeature: {
    byId: {},
    allIds: [],
  },
  userPlans: {
    byId: {},
    allIds: [],
  },
  planView: PLAN_VIEW.SELECTION,
  planCycle: PLAN_CYCLE.MONTHLY,
  additionalUserSeats: 1,
  selectedPlan: null,
  paymentLoading: false,
  planCount: {
    byId: {},
    allIds: [],
  },
};

const planReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fetchUserPlan.ACTION_TYPE.START:
      return {
        ...state,
        fetching: true,
      };

    case fetchUserPlan.ACTION_TYPE.SUCCESS:
      return {
        ...state,
        fetching: false,
        userPlans: normalizeUpdatedData(state.userPlans, action.payload),
      };

    case fetchUserPlan.ACTION_TYPE.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.error,
      };

    case PlanAction.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };

    case PlanAction.SET_PLAN_CYCLE:
      return {
        ...state,
        planCycle: action.payload,
      };

    case PlanAction.FETCH_DEFAULT_PLANS.START:
      return {
        ...state,
        fetching: false,
      };

    case PlanAction.FETCH_DEFAULT_PLANS.SUCCESS:
      const { plans, tax } = action.payload;
      const planState = PlanUtils.normalizePlans({plans});
      return {
        ...state,
        ...planState,
        fetching: false,
        tax,
      };

    case PlanAction.FETCH_DEFAULT_PLANS.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case PlanAction.FETCH_PLAN_BY_DATERANGE.START:
      return {
        ...state,
        planCountFetching: true,
      };

    case PlanAction.FETCH_PLAN_BY_DATERANGE.SUCCESS:
      return {
        ...state,
        planCountFetching: false,
        planCount: PlanUtils.normalizeUpdatedData(state.planCount, action.payload),
      };

    case PlanAction.FETCH_PLAN_BY_DATERANGE.FAILURE:
      return {
        ...state,
        planCountFetching: false,
        errorMessage: action.payload,
      };

    case PlanAction.ADD_PAYMENT_METHOD.START:
      return {
        ...state,
        paymentLoading: true,
      };

    case PlanAction.ADD_PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        paymentLoading: false,
      };

    case PlanAction.ADD_PAYMENT_METHOD.FAILURE:
      return {
        ...state,
        paymentLoading: false,
        errorMessage: action.payload,
      };

    case PlanAction.SET_PLAN_VIEW: {
      return {
        ...state,
        planView: action.payload,
      };
    }

    case PlanAction.SET_ADDTIONAL_USER_SEATS: {
      return {
        ...state,
        additionalUserSeats: action.payload,
      };
    }
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default planReducer;
