export default class BrandAction {
  // Action Types
  static BrandActionTypes = {
    FETCH_BRAND_NEED_UPDATE: {
      START: 'BrandAction/FETCH_BRAND_NEED_UPDATE_START',
      FAILURE: 'BrandAction/FETCH_BRAND_NEED_UPDATE_FAILURE',
      SUCCESS: 'BrandAction/FETCH_BRAND_NEED_UPDATE_SUCCESS',
    },
    SET_NEED_TO_UPDATE: 'BrandAction/SET_NEED_TO_UPDATE',
    UPDATE_BRAND: {
      START: 'BrandAction/UPDATE_BRAND_START',
      FAILURE: 'BrandAction/UPDATE_BRAND_FAILURE',
      SUCCESS: 'BrandAction/UPDATE_BRAND_SUCCESS',
    },
  };

  // fetch brand
  static fetchBrandStart = (payload) => ({
    type: this.BrandActionTypes.FETCH_BRAND_NEED_UPDATE.START,
    payload,
  });

  static fetchBrandSuccess = (payload) => ({
    type: this.BrandActionTypes.FETCH_BRAND_NEED_UPDATE.SUCCESS,
    payload,
  });

  static fetchBrandFailure = (errorMessage) => ({
    type: this.BrandActionTypes.FETCH_BRAND_NEED_UPDATE.FAILURE,
    payload: errorMessage,
  });

  static setNeedUpdateBrand = (payload) => ({
    type: this.BrandActionTypes.SET_NEED_TO_UPDATE,
    payload,
  });

  static updateBrandStart = (payload) => ({
    type: this.BrandActionTypes.UPDATE_BRAND.START,
    payload,
  });

  static updateBrandSuccess = (payload) => ({
    type: this.BrandActionTypes.UPDATE_BRAND.SUCCESS,
    payload,
  });

  static updateBrandFailure = (errorMessage) => ({
    type: this.BrandActionTypes.UPDATE_BRAND.FAILURE,
    payload: errorMessage,
  });
}
