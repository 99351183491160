import { groupBy } from 'lodash';

export default class DidUtil {
  static normalizeDid(dids) {
    const byId = groupBy(dids, 'id');
    const allIds = Object.keys(byId);

    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });

    return { byId, allIds };
  }
}
