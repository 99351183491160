import { get, groupBy, includes, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { CONVERSATION_TYPES } from '../../enums/conversation';
import { CUSTOM_FIELDS_NAME, GROUP_FIELDS_CALL_SETTING } from '../../enums/modals/groups';
import { decodePhone } from '../../util';

export default class ConversationSelector {
  static selectConversation = (state) => state.conversations;

  static selectIdentifyById = (state) => state.identify.data.byId;

  static selectUser = (state) => state.user;

  static selectCurrentUser = (state) => state.user.currentUser;

  static selectContacts = (state) => state.contacts;

  static selectMessages = (state) => state.messages;

  static selectConversationData = createSelector(
    [this.selectConversation, this.selectUser, this.selectIdentifyById],
    (conversationSelector, userSelector, identifies) => {
      const { byId, allIds } = conversationSelector.data;
      const { users, currentUser } = userSelector;
      if (allIds && byId) {
        const data = allIds
          .map((id) => {
            const conversation = byId[id];
            const identify = identifies[conversation?._identifier];
            if (!conversation) return undefined;
            const {
              mentions = [],
              unfollow_users = [],
              assignee,
              field_value_data,
              field_value_data_type,
            } = conversation;
            const phone = field_value_data_type === CUSTOM_FIELDS_NAME.phone ? decodePhone(field_value_data) : null;
            const isFollowing =
              includes(mentions, currentUser.id) &&
              !includes(unfollow_users, currentUser.id) &&
              assignee !== currentUser.id;
            const hasMentionForYou = includes(mentions, currentUser.id);
            const closedByUser = users.byId[conversation.closedBy];

            const _contact = identify?._contact;

            return {
              ...conversation,
              closedBy: closedByUser && `${closedByUser.given_name} ${closedByUser.family_name}`,
              phone_number: phone?.phone_number,
              region_number: phone?.region_number,
              user: users.byId && users.byId[conversation.assignee],
              isFollowing,
              hasMentionForYou,
              isAssignToYou: assignee === currentUser.id,
              _contact,
            };
          })
          .filter(Boolean)
          .filter((c) => c.show_in_member_queue);

        return {
          ...conversationSelector,
          data,
        };
      }
      return conversationSelector;
    },
  );

  static selectGroupsByIds = (state) => state.groups.data.byId;
  static selectIdentifyByIds = (state) => state.identify.data.byId;
  static selectFieldByIds = (state) => state.fields.data.byId;
  static selectFieldValuesByIds = (state) => state.fieldValues.data.byId;

  static selectGroupByMemberByGroupId = (state) => {
    return groupBy(Object.values(state.members.data.byId), '_group');
  };

  static selectConversationDataSidebar = createSelector(
    this.selectCurrentUser,
    this.selectConversationData,
    this.selectGroupByMemberByGroupId,
    this.selectGroupsByIds,
    this.selectIdentifyByIds,
    this.selectFieldByIds,
    this.selectFieldValuesByIds,
    (currentUser, conversations, groupByMembersByGroupId, groupById, identifyList, fieldList, fieldValuesList) => {
      let firstNameField = '';
      let lastNameField = '';
      Object.values(fieldList).forEach((field) => {
        if (field?.custom_field_name === CUSTOM_FIELDS_NAME.firstName) {
          firstNameField = field?.id;
        }
        if (field?.custom_field_name === CUSTOM_FIELDS_NAME.lastName) {
          lastNameField = field?.id;
        }
      });
      const conversationData = conversations.data.filter((conversation) => {
        const currentUserMember = get(groupByMembersByGroupId, conversation._group, []).find(
          (mem) => mem._user_email === currentUser?.email,
        );
        const roles = currentUserMember?.roles || [];
        // const callSetting = get(groupById, `${conversation._group}.call_setting`);
        // if (callSetting === GROUP_FIELDS_CALL_SETTING.A_ROLE_IN_GROUP) {
        //   const call_setting_role = get(groupById, `${conversation._group}.call_setting_role`);
        //   return roles.includes(call_setting_role);
        // } // remove logic

        return (
          !conversation.is_close &&
          !conversation.is_broadcast_conversation &&
          conversation.isActive &&
          ((conversation.assignee_role ? roles.includes(conversation.assignee_role) : true) ||
            conversation.assignee === currentUser?.id ||
            conversation.isFollowing)
          //  || (conversation.type === CONVERSATION_TYPES.LIVE_CHAT && conversation.is_close) //  The conversations should only appear in the Sidebar (My Conversations, Mentions, Assigned to Others, Unassigned) if that agent is part of that role. (The exception is when the agent is tagged/following a conversation, then the agent can still see that conversation in the Mentions section even if the agent is not part of the role. Until the agent stops following that conversation);
        );
      });
      const conversationDataResult = conversationData.map((cvs) => {
        let contactId = '';
        let firstName = '';
        let lastName = '';
        Object.values(identifyList).forEach((identify) => {
          if (cvs?._identifier === identify?.id) {
            contactId = identify?._contact;
          }
        });
        Object.values(fieldValuesList).forEach((fieldValues) => {
          if (fieldValues?._contact === contactId && fieldValues?._field === firstNameField) {
            firstName = fieldValues?.value?.toString().trim();
          }
          if (fieldValues?._contact === contactId && fieldValues?._field === lastNameField) {
            lastName = fieldValues?.value?.toString().trim();
          }
        });
        if (cvs?.type === CONVERSATION_TYPES.LIVE_CHAT && !cvs?._identifier) {
          return {
            ...cvs,
            contactName: cvs?.live_chat_anonymous_name,
          };
        } else {
          return {
            ...cvs,
            contactName: `${firstName} ${lastName}`,
          };
        }
      });
      return {
        ...conversations,
        data: conversationDataResult,
      };
    },
  );

  static selectIsFollowingConversation = (conversationId) =>
    createSelector([this.selectConversation, this.selectCurrentUser], (conversations, currentUser) => {
      const conversation = conversations.data.byId[conversationId];
      const mentions = conversation?.mentions || [];
      const isFollowing = includes(mentions, currentUser.id) && !includes(conversation.unfollow_users, currentUser.id);
      return isFollowing;
    });

  static selectConversationFetching = createSelector(
    [this.selectConversation],
    (conversations) => conversations.fetching,
  );

  static selectConversations = createSelector(this.selectConversation, (conversations) => conversations.data.byId);

  static selectIsOpenContactSidebar = createSelector(
    [this.selectConversation],
    (conversations) => conversations.isOpenContactDetail,
  );

  static selectConversationList = createSelector([this.selectConversation], (conversations) =>
    Object.values(conversations.data.byId),
  );

  static selectByConversationId = (conversationId) =>
    createSelector([this.selectConversation, this.selectMessages], (conversations, message) => {
      let cvs = conversations.data.byId[conversationId];
      if (!cvs) {
        const messageList = Object.values(message.data.byId);
        const messageWithConversationId = messageList.find(
          (messageItem) => messageItem?._conversation === conversationId,
        );
        cvs = messageWithConversationId?.conversation;
      }
      return cvs || {};
    });

  static selectByConversationIds = (conversationIds) =>
    createSelector(this.selectConversation, (conversations) =>
      conversationIds.map((conversationId) => conversations.data.byId[conversationId]),
    );

  static selectConversationListByContact = (contactId) =>
    createSelector(
      [this.selectConversationList, this.selectIdentifyById, this.selectMessages],
      (conversationList, identify, messages) => {
        const identifyData = Object.values(identify);
        const identifyList = identifyData
          .filter((identifyItem) => identifyItem?._contact === contactId)
          .map((item) => item?.id);
        const result = conversationList.filter((cvsItem) => {
          return identifyList.includes(cvsItem?._identifier);
        });
        Object.values(messages.data.byId).forEach((messageItem) => {
          if (identifyList.includes(messageItem?.conversation?._identifier)) {
            result.push(messageItem?.conversation);
          }
        });
        return uniqBy(result, 'id');
      },
    );

  static selectGroupIdByConversationId = (conversationId) =>
    createSelector([this.selectConversation], (conversation) => {
      return conversation.data.byId[conversationId]?._group;
    });

  static selectIsCloseByConversationId = (conversationId) =>
    createSelector([this.selectConversation], (conversation) => {
      return conversation.data.byId[conversationId]?.is_close;
    });

  static selectContactByConversationId = (conversationId) =>
    createSelector([this.selectConversation], (conversation) => {
      return conversation.data.byId[conversationId]?._contact;
    });

  static selectConversationsData = createSelector([this.selectConversation], (conversations) => conversations.data);

  static selectConversationsDataWithContact = createSelector(
    [this.selectConversation, this.selectIdentifyById],
    (conversations, identifies) => {
      return Object.values(conversations.data.byId).reduce((final, currentConversation) => {
        if (currentConversation.id) {
          const _contact = identifies[currentConversation?.identify]?._contact;
          final[currentConversation.id] = {
            ...currentConversation,
            _contact,
          };
        }
        return final;
      }, {});
    },
  );

  static selectUsers = createSelector([this.selectConversation], (conversations) => conversations.users);

  static selectFilterGroups = createSelector([this.selectConversation], (conversations) => conversations.filterGroups);

  static selectVoiceConversation = (conversationId) =>
    createSelector(this.selectConversation, (conversations) => get(conversations, `data.byId.${conversationId}.voice`));

  static selectCurrentUserVoiceConversation = (conversationId) =>
    createSelector(this.selectConversation, this.selectCurrentUser, (conversations, currentUser) =>
      get(conversations, `data.byId.${conversationId}.voice.legs.${currentUser.id}`),
    );
  static selectConversationByIds = (state) => state.conversations.data.byId;
}
