export default class CdrAction {
  static ADD_CDR = 'CDRAction/ADD_CDR';

  static FETCH_CDR_BY_IDS = 'CDRAction/FETCH_CDR_BY_IDS';

  static FETCH_CDR_BY_DATE_RANGE = 'CDRAction/FETCH_CDR_BY_DATE_RANGE';

  static fetchCdrByDateRange = (dateRange, ownerId) => ({
    type: this.FETCH_CDR_BY_DATE_RANGE,
    payload: { dateRange, ownerId },
  });

  static addCdr = (payload) => ({
    type: this.ADD_CDR,
    payload,
  });

  static fetchCdrByIds = (payload) => ({
    type: this.FETCH_CDR_BY_IDS,
    payload,
  });
}
