export const CAMPAIGN_STATUS = {
  END: 'END',
  RUNNING: 'RUNNING',
};

export const CAMPAIGN_TYPE = {
  TEMPLATE: 'TEMPLATE',
  INSTANCE: 'INSTANCE',
};

export const EVENT_TYPE = {
  FOLLOW_UP_MESSAGE: 'FOLLOW_UP_MESSAGE',
  RESPONSE_DRIVEN_MESSAGE: 'RESPONSE_DRIVEN_MESSAGE',
  API_CALL: 'API_CALL',
  CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
};

export const TYPE_CAMPAIGN = {
  INSTANCE: 'INSTANCE',
  TEMPLATE: 'TEMPLATE',
};

export const END_CAMPAIGN_CONDITION = {
  OPTS_OUT: 'OPTS_OUT',
  NEXT_RESPONSE_DOES_NOT_MATCH_TYPE: 'NEXT_RESPONSE_DOES_NOT_MATCH',
  NEXT_RESPONSE_DOES_NOT_MATCH: {
    CHARACTER_COUNT: 'CHARACTER_COUNT',
    NUMBERS_ONLY: 'NUMBERS_ONLY',
    ALPHABETS_ONLY: 'ALPHABETS_ONLY',
    EXACT_PHRASE: 'EXACT_PHRASE',
  },
  RESPONSE_CODE: 'RESPONSE_CODE',
  RESPONSE_PATH_NOT_FOUND: 'RESPONSE_PATH_NOT_FOUND',
  TIME_OUT_SECONDS: 'TIME_OUT_SECONDS',
  RESPONDS: 'RESPONDS',
};

export const SEND_AFTER_KEY = {
  IMMEDIATELY: 'IMMEDIATELY',
  ONE_HOUR: 'ONE_HOUR',
  TWO_HOUR: 'TWO_HOUR',
  FOUR_HOUR: 'FOUR_HOUR',
  EIGHT_HOUR: 'EIGHT_HOUR',
  ONE_DAY: 'ONE_DAY',
  TWO_DAY: 'TWO_DAY',
  ONE_WEEK: 'ONE_WEEK',
  TWO_WEEK: 'TWO_WEEK',
  ONE_MONTH: 'ONE_MONTH',
  TWO_MONTH: 'TWO_MONTH',
  SIX_MONTH: 'SIX_MONTH',
};

export const SEND_AFTER = {
  [SEND_AFTER_KEY.IMMEDIATELY]: 'Immediately',
  [SEND_AFTER_KEY.ONE_HOUR]: '1 Hour',
  [SEND_AFTER_KEY.TWO_HOUR]: '2 Hour',
  [SEND_AFTER_KEY.FOUR_HOUR]: '4 Hour',
  [SEND_AFTER_KEY.EIGHT_HOUR]: '8 Hour',
  [SEND_AFTER_KEY.ONE_DAY]: '1 Day',
  [SEND_AFTER_KEY.TWO_DAY]: '2 Day',
  [SEND_AFTER_KEY.ONE_WEEK]: '1 Week',
  [SEND_AFTER_KEY.TWO_WEEK]: '2 Week',
  [SEND_AFTER_KEY.ONE_MONTH]: '1 Month',
  [SEND_AFTER_KEY.TWO_MONTH]: '2 Month',
  [SEND_AFTER_KEY.SIX_MONTH]: '6 Month',
};

export const HTTP_REQUEST = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const HTTP_BODY_TYPE = {
  FORM_DATA: 'FORM_DATA',
  X_WWW_FORM_URLENCODED: 'X_WWW_FORM_URLENCODED',
  RAW: 'RAW',
};

export const NAME_CAMPAIGN = {
  ORDER: 'order',
  EVENT_TYPE: 'event_type',
  VARIABLE_NAME: 'variable_name', // sidebar
  CONCEALED_MESSAGE: 'concealed_message',
  MESSAGE: 'message',
  RESPONSE_MESSAGE: 'response_message', //  main
  SEND_AFTER: 'send_after',
  HTTP_TYPE: 'http_type',
  HTTP_TIME_OUT: 'http_timeout',
  HTTP_PARAMS: 'http_params',
  HTTP_URL: 'http_url',
  HTTP_BODY_TYPE: 'http_body_type',
  HTTP_HEADER: 'http_header',
  HTTP_BODY: 'http_body',
  HTTP_BODY_FORM: 'http_body_form',
  HTTP_BODY_FORM_KEY: 'http_body_form_key',
  HTTP_BODY_FORM_VALUE: 'http_body_form_value',
  RESPONSE_VARIABLES: 'response_variables', // main
  RESPONSE_VARIABLES_NAME: 'response_variable_name', // belong to RESPONSE_VARIABLES
  RESPONSE_VARIABLES_PATH: 'response_variable_path', // belong to RESPONSE_VARIABLES
  END_CAMPAIGN_IF_TYPE: 'end_campaign_if_type',
  END_CAMPAIGN_RESPONSE_CODE: 'end_campaign_response_code',
  END_CAMPAIGN_TIME_OUT_SECONDS: 'end_campaign_time_out_seconds',
  END_CAMPAIGN_IF_NM_TYPE: 'end_campaign_if_nm_type',
  END_CAMPAIGN_IF_NM_CHARACTER_COUNT: 'end_campaign_if_nm_character_count',
  END_CAMPAIGN_IF_NM_EXACT_PHRASE: 'end_campaign_if_nm_exact_phrase',
  SEND_TO_QUEUE: 'send_to_queue',
};
export const CampaignSession = {
  description: 'description',
  message_flow: 'message_flow',
  message_sample: 'message_sample',
  setting: 'setting',
};
