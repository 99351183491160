export default class FieldValueAction {
  static FETCH_FIELD_VALUES_BY_IDS = {
    START: 'FieldValuesAction/FETCH_FIELD_VALUES_BY_IDS',
    SUCCESS: 'FieldValuesAction/FETCH_FIELD_VALUES_BY_IDS_SUCCESS',
    FAILURE: 'FieldValuesAction/FETCH_FIELD_VALUES_BY_IDS_FAILURE',
  };

  static SET_FIELD_VALUES = 'FieldValuesAction/SET_FIELD_VALUES';

  static UPDATE_FIELD_VALUES = 'FieldValuesAction/UPDATE_FIELD_VALUES';

  static UPDATE_FIELD_VALUES_FAILURE = 'FieldValuesAction/UPDATE_FIELD_VALUES_FAILURE';

  static DELETE_FIELD_VALUES = 'FieldValuesAction/DELETE_FIELD_VALUES';

  static SET_DUPLICATE_VALUE = 'FieldValuesAction/SET_DUPLICATE_VALUE';

  static setDuplicateValue = (value) => ({
    type: FieldValueAction.SET_DUPLICATE_VALUE,
    payload: value,
  });

  static fetchFieldValueByIds = (payload) => ({
    type: this.FETCH_FIELD_VALUES_BY_IDS.START,
    payload,
  });

  static fetchFieldValueByIdsSuccess = (payload) => ({
    type: this.FETCH_FIELD_VALUES_BY_IDS.SUCCESS,
    payload,
  });

  static fetchFieldValueByIdsFailure = (error) => ({
    type: this.FETCH_FIELD_VALUES_BY_IDS.FAILURE,
    payload: error,
  });

  static setFieldValues = (params) => ({
    type: this.SET_FIELD_VALUES,
    payload: params,
  });

  static updateFieldValues = (data, contactId, callback) => ({
    type: this.UPDATE_FIELD_VALUES,
    payload: { ...data, contactId },
    callback,
  });

  static updateFieldValuesFailure = (errorMessage) => ({
    type: this.UPDATE_FIELD_VALUES_FAILURE,
    payload: errorMessage,
  });

  static deleteFieldValues = (params) => ({
    type: this.DELETE_FIELD_VALUES,
    payload: params,
  });
}
