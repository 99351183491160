export default class FileAction {
  static FETCH_FILES = {
    START: 'FileAction/FETCH_FILES_START',
    SUCCESS: 'FileAction/FETCH_FILES_SUCCESS',
    FAILURE: 'FileAction/FETCH_FILES_FAILURE',
  };

  static fetchFile = (_contact) => ({
    type: FileAction.FETCH_FILES.START,
    payload: _contact,
  });

  static fetchFileSuccess = (data, _contact) => ({
    type: FileAction.FETCH_FILES.SUCCESS,
    payload: { data, _contact },
  });

  static fetchFileFailure = (errorMessage) => ({
    type: FileAction.FETCH_FILES.FAILURE,
    payload: errorMessage,
  });
}
