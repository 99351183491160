import { put, takeLatest } from 'redux-saga/effects';
import MemberAction from '../member/member.actions';

import SocketAction from '../socket/socket.action';

function* handleDeleteMemberByIds({ payload: ids }) {
  console.log(ids);
  try {
    yield put(MemberAction.deletedMemberReduxByIds(ids));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeMemberFlow() {
  yield takeLatest(SocketAction.SOCKET_EVENT.DELETE_MEMBERS_BY_IDS, handleDeleteMemberByIds);
}
