import { BUSINESS_INDUSTRY } from '../twillio/twillio-enum';

export const GROUP_KEY_TABS = {
  GroupSettings: { value: 'group_settings', label: 'Group Settings' },
  Dids: { value: 'dids', label: 'DID' },
  // Roles: { value: 'roles', label: 'Roles' },
  Members: { value: 'members', label: 'Members' },
  CallSettings: { value: 'call_settings', label: 'Call Settings' },
  // Webhook: { value: 'webhook', label: 'Webhooks' },
  WorkFlow: { value: 'workflow', label: 'Actions' },
  CustomFields: { value: 'custom_fields', label: 'Custom Fields' },
  Template: { value: 'template', label: 'Templates' },
  LiveChat: { value: 'live_chat', label: 'Live Chat' },
};

export const GROUP_FIELDS_CALL_SETTING = {
  MEMBER_AUTO_ASSIGN: 'MEMBER_AUTO_ASSIGN',
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
  GROUP: 'GROUP',
  OTHER_GROUP: 'OTHER_GROUP',
  EXTERNAL_NUMBER: 'EXTERNAL_NUMBER',
  IVR: 'IVR',
  HANG_UP: 'HANG_UP',
  A_ROLE_IN_GROUP: 'A_ROLE_IN_GROUP',
  CALL_FORWARDING: 'CALL_FORWARDING',
};

export const TWILIO_CAMPAIGN_TYPES = [
  {
    value: 'CUSTOMER_CARE',
    label: 'Customer Care',
    description: 'All customer care messaging, including account management and support',
    post_approval_required: false,
  },
  // {
  //   value: 'AGENTS_FRANCHISES',
  //   label: 'Agents and Franchises',
  //   description:
  //     'For brands that have multiple agents, franchises or offices in the same brand vertical, but require individual localized numbers per agent/location/office.',
  //   post_approval_required: true,
  // },
  // {
  //   value: 'LOW_VOLUME',
  //   label: 'Low Volume Mixed',
  //   description: 'Low throughput, any combination of use-cases. Examples include:  test, demo accounts',
  //   post_approval_required: false,
  // },
  // {
  //   value: 'MIXED',
  //   label: 'Mixed',
  //   description: 'Mixed messaging reserved for specific consumer service industry',
  //   post_approval_required: false,
  // },
  // {
  //   value: "SECURITY_ALERT",
  //   label: "Security Alert",
  //   description: "A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take",
  //   post_approval_required: false
  // },
  // {
  //   value: "PUBLIC_SERVICE_ANNOUNCEMENT",
  //   label: "Public Service Announcement",
  //   description: "An informational message that is meant to raise the audience awareness about an important issue",
  //   post_approval_required: false
  // },
  // {
  //   value: "MARKETING",
  //   label: "Marketing",
  //   description: "Any communication with marketing and/or promotional content",
  //   post_approval_required: false
  // },
  // {
  //   value: "DELIVERY_NOTIFICATION",
  //   label: "Delivery Notification",
  //   description: "Information about the status of the delivery of a product or service",
  //   post_approval_required: false
  // },
  // {
  //   value: "FRAUD_ALERT",
  //   label: "Fraud Alert Messaging",
  //   description: "Fraud alert notification",
  //   post_approval_required: false
  // },
  // {
  //   value: "2FA",
  //   label: "Two-Factor authentication (2FA)",
  //   description: "Two-Factor authentication, one-time use password, password reset",
  //   post_approval_required: false
  // },
  // {
  //   value: "ACCOUNT_NOTIFICATION",
  //   label: "Account Notification",
  //   description: "All reminders, alerts, and notifications. (Examples include: flight delayed, hotel booked, appointment reminders.)",
  //   post_approval_required: false
  // }
];
export const TwilioA2pCampaignOptions = [
  {
    value: 'CUSTOMER_CARE',
    label: 'Customer Care',
  },
];

export const GROUP_LABEL_CALL_SETTING = {
  [GROUP_FIELDS_CALL_SETTING.OWNER]: 'The converation owner (If no owner, will ring group)',
  [GROUP_FIELDS_CALL_SETTING.MEMBER_AUTO_ASSIGN]: 'Member auto assign',
  [GROUP_FIELDS_CALL_SETTING.GROUP]: 'Everyone in the group',
  [GROUP_FIELDS_CALL_SETTING.OTHER_GROUP]: 'Everyone in the another group',
  [GROUP_FIELDS_CALL_SETTING.MEMBER]: 'A group member',
  [GROUP_FIELDS_CALL_SETTING.EXTERNAL_NUMBER]: 'An external number',
  [GROUP_FIELDS_CALL_SETTING.IVR]: 'IVR',
  [GROUP_FIELDS_CALL_SETTING.HANG_UP]: 'Hang up',
  [GROUP_FIELDS_CALL_SETTING.A_ROLE_IN_GROUP]: 'A role in group',
  [GROUP_FIELDS_CALL_SETTING.CALL_FORWARDING]: 'Call Forwarding',
};

export const GROUP_FIELDS_CALL_SETTING_TEXT = {
  [GROUP_FIELDS_CALL_SETTING.OWNER]: 'Owner',
  [GROUP_FIELDS_CALL_SETTING.GROUP]: 'Group',
  [GROUP_FIELDS_CALL_SETTING.OTHER_GROUP]: 'Other Group',
  [GROUP_FIELDS_CALL_SETTING.EXTERNAL_NUMBER]: 'External Number',
  [GROUP_FIELDS_CALL_SETTING.MEMBER]: 'Member',
  [GROUP_FIELDS_CALL_SETTING.HANG_UP]: 'Hang up',
};

export const GROUP_TYPE_INPUT = {
  EMAIL: 'email',
  SELECT: 'select',
  SELECT_MULTIPLE: 'multiple',
  TEXT_AREA: 'textarea',
  TEXT: 'text',
  NUMBER: 'number',
};

export const GROUP_NAME_INPUT_CALL_SETTING = {
  CALL_SETTING: 'call_setting',
  CALL_SETTING_OTHER_GROUP_ID: 'call_setting_other_group_id',
  CALL_SETTING_EXTERNAL_NUMBER: 'call_setting_external_number',
  CALL_SETTING_MEMBER_ID: 'call_setting_member_id',
  CALL_SETTING_ROLE: 'call_setting_role',
  CALL_SETTING_IVR_DTMF_ARR: 'call_setting_ivr_dtmf_arr',
  CALL_SETTING_GROUP_ID: 'call_setting_group_id',
  CALL_SETTING_WELCOME_MEDIA: 'call_setting_welcome_media',
  CALL_SETTING_CALLER_TYPE: 'call_setting_caller_type',
  CALL_SETTING_FORWARD_PHONE: 'call_setting_forward_phone',
  CALL_SETTING_TRANSFER_DID: 'call_setting_transfer_did',

  // FAILOVER
  CALL_SETTING_FAILOVER_ACTION: 'call_setting_failover_action',
  CALL_SETTING_FAILOVER_MEDIA: 'call_setting_failover_media',
  CALL_SETTING_FAILOVER_EXTERNAL_NUMBER: 'call_setting_failover_external_number',
  CALL_SETTING_FAILOVER_MEMBER_ID: 'call_setting_failover_member_id',
  CALL_SETTING_FAILOVER_OTHER_GROUP_ID: 'call_setting_failover_other_group_id',
  CALL_SETTING_FAILOVER_TRANSFER_DID: 'call_setting_failover_transfer_did',

  // TIMEOUT
  CALL_SETTING_TIMEOUT_ACTION: 'call_setting_timeout_action',
  CALL_SETTING_TIMEOUT_MEDIA: 'call_setting_timeout_media',
  CALL_SETTING_TIMEOUT_EXTERNAL_NUMBER: 'call_setting_timeout_external_number',
  CALL_SETTING_TIMEOUT_MEMBER_ID: 'call_setting_timeout_member_id',
  CALL_SETTING_TIMEOUT_OTHER_GROUP_ID: 'call_setting_timeout_other_group_id',
  CALL_SETTING_TIMEOUT_TRANSFER_DID: 'call_setting_timeout_transfer_did',
  // QUEUE SETTINGS
  CALL_SETTING_QUEUE_SETTINGS_QUEUE_TIMEOUT: 'call_setting_queue_settings_queue_timeout',
  CALL_SETTING_QUEUE_SETTINGS_QUEUE_MEDIA: 'call_setting_queue_settings_queue_media',
  CALL_SETTING_QUEUE_SETTINGS_TIMEOUT_MEDIA: 'call_setting_queue_settings_timeout_media',
  // VOICEMAIL
  CALL_SETTING_QUEUE_SETTINGS_VOICEMAIL_TIMEOUT: 'call_setting_queue_settings_voicemail_timeout',
  CALL_SETTING_QUEUE_SETTINGS_VOICEMAIL_MEDIA: 'call_setting_queue_settings_voicemail_media',
  CALL_SETTING_QUEUE_SETTINGS_VOICEMAIL_ENABLE: 'call_setting_queue_settings_voicemail_enable',

  // IVR
  MEMBER: 'member_id',
  EXTERNAL_NUMBER: 'external_number',
  OTHER_GROUP: 'other_group_id',
  PLAYFILE: 'playfile',

  AUTO_RECORD: 'call_setting_auto_record',
};

export const TWILIO_NEW_CAMPAIGN_SETTINGS = {
  default: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
};

export const TWILIO_NEW_CAMPAIGN_PARAMETERS = {
  CAMPAIGN_REGISTRATION_DESCRIPTION: 'description',
  CAMPAIGN_REGISTRATION_MESSAGE_FLOW: 'message_flow',
  CAMPAIGN_REGISTRATION_MESSAGE_SAMPLES_1: 'message_samples_1',
  CAMPAIGN_REGISTRATION_MESSAGE_SAMPLES_2: 'message_samples_2',
  CAMPAIGN_REGISTRATION_MESSAGE_SAMPLES_3: 'message_samples_3',
  CAMPAIGN_REGISTRATION_MESSAGE_SAMPLES_4: 'message_samples_4',
  CAMPAIGN_REGISTRATION_MESSAGE_SAMPLES_5: 'message_samples_5',
  CAMPAIGN_REGISTRATION_US_APP_TO_PERSON_USECASE: 'us_app_to_person_usecase',
  CAMPAIGN_REGISTRATION_HAS_EMBEDDED_LINKS: 'has_embedded_links',
  CAMPAIGN_REGISTRATION_HAS_EMBEDDED_PHONE: 'has_embedded_phone',
  CAMPAIGN_REGISTRATION_OPT_IN_MESSAGE: 'opt_in_message',
  CAMPAIGN_REGISTRATION_OPT_OUT_MESSAGE: 'opt_out_message',
  CAMPAIGN_REGISTRATION_HELP_MESSAGE: 'help_message',
  CAMPAIGN_REGISTRATION_OPT_IN_KEYWORDS: 'opt_in_keywords',
  CAMPAIGN_REGISTRATION_OUT_OUT_KEYWORDS: 'opt_out_keywords',
  CAMPAIGN_REGISTRATION_HELP_KEYWORDS: 'help_keywords',
};

export const GROUP_NAME_INPUT_GROUP_SETTING = {
  NEW_GROUP_NAME: 'newGroupName',
  NEW_GROUP_DESCRIPTION: 'newGroupDescription',
  NEW_MEMBER: 'newMember',
  USING_SYSTEM_WIDE_DNC: 'using_system_wide_dnc',
  SHARE_MESSAGE_HISTORY: 'shared_message_history_with',
  GROUP_COLOR: 'color',
  SHARE_DATA_REPORT: 'shareDataReport',
};

export const GROUP_NAME_FIELDS_SHOULD_BE_RESET = {
  [GROUP_FIELDS_CALL_SETTING.OWNER]: null,
  [GROUP_FIELDS_CALL_SETTING.GROUP]: null,
  [GROUP_FIELDS_CALL_SETTING.OTHER_GROUP]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_OTHER_GROUP_ID,
  [GROUP_FIELDS_CALL_SETTING.EXTERNAL_NUMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_EXTERNAL_NUMBER,
  [GROUP_FIELDS_CALL_SETTING.MEMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_MEMBER_ID,
  [GROUP_FIELDS_CALL_SETTING.IVR]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_IVR_DTMF_ARR,
};

export const GROUP_NAME_FIELDS_REQUIRED_FAILOVER = {
  [GROUP_FIELDS_CALL_SETTING.OWNER]: null,
  [GROUP_FIELDS_CALL_SETTING.GROUP]: null,
  [GROUP_FIELDS_CALL_SETTING.OTHER_GROUP]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_FAILOVER_OTHER_GROUP_ID,
  [GROUP_FIELDS_CALL_SETTING.EXTERNAL_NUMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_FAILOVER_EXTERNAL_NUMBER,
  [GROUP_FIELDS_CALL_SETTING.MEMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_FAILOVER_MEMBER_ID,
  [GROUP_FIELDS_CALL_SETTING.HANG_UP]: null,
};

export const GROUP_NAME_FIELDS_REQUIRED_TIMEOUT = {
  [GROUP_FIELDS_CALL_SETTING.OWNER]: null,
  [GROUP_FIELDS_CALL_SETTING.GROUP]: null,
  [GROUP_FIELDS_CALL_SETTING.OTHER_GROUP]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_TIMEOUT_OTHER_GROUP_ID,
  [GROUP_FIELDS_CALL_SETTING.EXTERNAL_NUMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_TIMEOUT_EXTERNAL_NUMBER,
  [GROUP_FIELDS_CALL_SETTING.MEMBER]: GROUP_NAME_INPUT_CALL_SETTING.CALL_SETTING_TIMEOUT_MEMBER_ID,
  [GROUP_FIELDS_CALL_SETTING.HANG_UP]: null,
};

export const GROUP_CALLER_TYPE_FIELD = {
  DID_ANI: 'DID_ANI',
  CUSTOMER_ANI: 'CUSTOMER_ANI',
};

export const GROUP_CALLER_TYPE = {
  DID_ANI: 'DID',
  CUSTOMER_ANI: "Customer's number",
};

// Workflow
export const WORKFLOW_TYPE = {
  NEW_INBOUND_CONVERSATIONS: 'NEW_INBOUND_CONVERSATIONS',
  ANY_INBOUND_MESSAGES: 'ANY_INBOUND_MESSAGES',
  CONVERSATION_CLOSING: 'CONVERSATION_CLOSING',
};

export const WORKFLOW_LISTEN_FOR = {
  ALL_MESSAGE: 'ALL_MESSAGE',
  KEY_WORDS: 'KEY_WORDS',
};

export const WORKFLOW_STRATEGY = {
  MESSAGE: 'MESSAGE',
  CAMPAIGN: 'CAMPAIGN',
};

export const WORKFLOW_ACTIVE_DURING = {
  ALL_TIMES: 'ALL_TIMES',
  SET_TIME_FRAMES: 'SET_TIME_FRAME',
};

export const DAY_OF_WEEK = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

export const WORKFLOW_FIELD = {
  ID: 'id',
  TYPE: 'type',
  LISTEN_FOR: 'listen_for',
  KEYWORDS: 'keywords',
  SHOW_MESSAGE_IN_QUEUE: 'show_messages_in_queue',
  STRATEGY: 'strategy',
  CAMPAIGN: '_campaign',
  MESSAGE: 'message',
  GROUP: '_group',
  ACTIVE_DURING: 'active_during',
  ACTIVE_DURING_TIME: 'active_during_time',
  SEND_INACTIVE_MESSAGE: 'send_inactive_message',
  INACTIVE_MESSAGE: 'inactive_message',
};

export const CUSTOM_FIELDS_TYPE = {
  String: 'STRING',
  TextArea: 'TEXT_AREA',
  Number: 'NUMBER',
  Selectors: 'SELECTORS',
  Date: 'DATE',
  Email: 'EMAIL',
  Phone: 'PHONE',
  Region: 'REGION',
  FingerPrint: 'FINGERPRINT',
};

export const CUSTOM_FIELDS_NAME = {
  firstName: 'First Name',
  lastName: 'Last Name',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  postalCode: 'Zip Code',
  ipAddress: 'IP Address',
  email: 'Email',
  phone: 'Phone',
  fingerPrint: 'FingerPrint',
  dnc: 'DNC',
};

export const LIVECHAT_FIELDS = {
  position: {
    label: 'Chat bubble positioning',
    value: 'position',
    type: 1,
    tips: 'Customize the positioning of your chat widget on your website.',
  },
  headerText: {
    label: 'Chat title ( on the header )',
    value: 'headerText',
    type: 1,
    placeholder: 'Hi, how can I help you?',
    tips: 'Customize the text in you chat widget header. Max 20 characters.',
  },
  themeColor: {
    label: 'Theme color',
    value: 'themeColor',
    type: 1,
  },
  introduce: {
    label: 'Show introductory text',
    value: 'introduce',
    type: 1,
    toggleable: true,
    placeholder: 'Tell us a little about yourself so we can better help you.',
  },
  terms: {
    label: 'Show Terms and Conditions',
    value: 'terms',
    type: 1,
    toggleable: true,
    placeholder:
      'by starting this chat, you agree that you would like to receive communications about ... services, promotions, events by SMS and/or email.',
  },
  autoIntro: {
    label: 'Send automated introductory chat message',
    value: 'autoIntro',
    type: 2,
    toggleable: true,
    placeholder: 'An agent will be with you shortly',
  },
  callSupport: {
    label: 'Browser Call Support',
    value: 'callSupport',
    type: 2,
  },
  liveChat_auto_hide: {
    label: 'Auto hide bubble',
    value: 'liveChat_auto_hide',
    type: 2,
  },
  sendTranscript: {
    label: 'Send user chat transcript (Requires Email as Required Fields to initiate chat)',
    value: 'sendTranscript',
    type: 2,
  },
  cdn: {
    label: 'CDN script',
    value: 'cdn',
    type: 2,
    tips: 'Copy and paste this script to your website’s public HTML file.',
  },
};

export const LIVE_CHAT_POSITION = {
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  TOP_LEFT: 'TOP_LEFT',
};

export const LIVE_CHAT_MATCH_CONTACT_FIELD = {
  EMAIL: {
    label: 'Email',
    value: 'EMAIL',
  },
  PHONE: {
    label: 'Phone number',
    value: 'PHONE',
  },
};

export const LIVECHAT_VALUES = {
  position: [
    { value: LIVE_CHAT_POSITION.BOTTOM_RIGHT, label: 'Bottom Right' },
    { value: LIVE_CHAT_POSITION.BOTTOM_LEFT, label: 'Bottom Left' },
    { value: LIVE_CHAT_POSITION.TOP_RIGHT, label: 'Top Right' },
    { value: LIVE_CHAT_POSITION.TOP_LEFT, label: 'Top Left' },
  ],
  default: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
};

export const TEMPLATE_TYPE = {
  TEXT: 'Text',
  CAMPAIGN: 'Campaign',
};

export const A2pCampaignField = {
  BUSINESS_NAME: 'businessName',
  BUSINESS_WEBSITE: 'businessWebsite',
  BUSINESS_INDUSTRY: 'businessIndustry',
  BUSINESS_SUPPORT_EMAIL: 'businessSupportEmail',
  US_APP_TO_PERSON_USECASE: 'us_app_to_person_usecase',
  DESCRIPTION: 'description',
  MESSAGE_FLOW: 'message_flow',
  MESSAGE_SAMPLE_1: 'message_samples_1',
  MESSAGE_SAMPLE_2: 'message_samples_2',
  MESSAGE_SAMPLE_3: 'message_samples_3',
  MESSAGE_SAMPLE_4: 'message_samples_4',
  MESSAGE_SAMPLE_5: 'message_samples_5',
  HAS_EMBEDDED_LINK: 'has_embedded_links',
  HAS_EMBEDDED_PHONE: 'has_embedded_phone',
  OPT_OUT_MESSAGE: 'opt_out_message',
  OPT_IN_MESSAGE: 'opt_in_message',
};

export const A2P_CAMPAIGN_STATUS = {
  FAILED: 'FAILED',
  VERIFIED: 'VERIFIED',
  IN_PROGRESS: 'IN_PROGRESS',
};
