import FieldValueUtils from './field-value.utils';
import FieldValueAction from './field-value.actions';
import UserAction from '../user/user.actions';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  error: null,
  loading: false,
  duplicateValue: null,
};

const fieldValueReducer = (state = INITIAL_STATE, action) => {
  if (action?.metadata?.field_values) {
    return {
      ...state,
      loading: false,
      data: FieldValueUtils.normalizeFieldValues(state.data, action?.metadata?.field_values),
      error: null,
    };
  }
  switch (action.type) {
    case FieldValueAction.SET_DUPLICATE_VALUE:
      return {
        ...state,
        duplicateValue: action.payload,
      };
    case FieldValueAction.UPDATE_FIELD_VALUES:
      return {
        ...state,
        loading: true,
      };
    case FieldValueAction.SET_FIELD_VALUES:
      return {
        ...state,
        loading: false,
        data: FieldValueUtils.normalizeFieldValues(state.data, action.payload),
      };
    case FieldValueAction.UPDATE_FIELD_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FieldValueAction.DELETE_FIELD_VALUES:
      return {
        ...state,
        data: FieldValueUtils.deleteFieldValues(state.data, action.payload),
      };
    case FieldValueAction.FETCH_FIELD_VALUES_BY_IDS.START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FieldValueAction.FETCH_FIELD_VALUES_BY_IDS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: FieldValueUtils.normalizeFieldValues(state.data, action.payload),
        error: null,
      };
    case FieldValueAction.FETCH_FIELD_VALUES_BY_IDS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default fieldValueReducer;
