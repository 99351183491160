import { every, get, isEmpty, uniq } from 'lodash';
import SIP from 'sip.js';
import { takeLatest, call, put, select, all, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import VoiceAction from './voice.actions';
import VoiceService from './voice.service';
import VoiceSelector from './voice.selectors';
import MessageSelector from '../message/message.selectors';
import ConversationAction from '../conversation/conversation.actions';
import UserSelector from '../user/user.selectors';
import axios from 'axios';
import { getNameOfEmail, handleGetErrorMessage, handleGetErrorMessageNew } from '../../util';
import { RegisterStatus, CONNECT_STATUS, useSIPProvider } from 'react-sipjs';
import SocketAction from '../socket/socket.action';
import { SessionState } from '../../enums/voice';
import SocketService, { ROOM_NAME } from '../socket/SocketIO';

const { VoiceActionTypes } = VoiceAction;
const defaultCallback = () => {};

export function* startConnectVoiceWithCurrentUser(currentUser) {
  return;
  const emailName = getNameOfEmail(currentUser?.email);
  const config = {
    username: emailName,
    password: 'test123',
  };
  yield put(VoiceAction.apConnect(config));
}

export function* handleConnected({ payload: status }) {
  if (status === 'CONNECTED') {
    yield put(VoiceAction.apRegister());
  }
}

// local connect
export function* connect({ payload, callback = defaultCallback }) {
  try {
    yield call(VoiceService.doConnect, payload);
  } catch (error) {
    console.log({ error });
    // callback(error, null);
  }
}

export function* register({ payload, callback = defaultCallback }) {
  try {
    yield call(VoiceService.doRegister, payload);
  } catch (error) {
    console.log({ doRegister: 'doRegister', error });
  }
}

export function* unregister({ payload }) {
  try {
    yield call(VoiceService.doUnRegister, payload);
  } catch (error) {
    console.log({ error });
    // callback(error, null);
  }
}

export function* dialing({ payload }) {
  const { uri, options, type } = payload;
  try {
    yield call(VoiceService.doCall, uri, options);
    yield put(VoiceAction.appDailingState(type));
  } catch (error) {
    console.log({ error });
  }
}

// ============ SESSION ============
export function* hangup({ payload }) {
  const { sessionId } = payload;
  try {
    yield put(VoiceAction.resetCalltimer());
    yield call(VoiceService.endCall, sessionId);
    yield put(VoiceAction.apDialerStatus('CONNECTED'));
  } catch (error) {
    console.log({ error });
  }
}

export function* handleSetLatestRTCSession({ payload }) {
  const voices = yield select(VoiceSelector.selectVoiceList);
  for (const item of voices) {
    if (item.RTCSessionId === payload) {
      break;
    }
    if (!item.RTCSessionId) {
      yield put(VoiceAction.updateVoiceData({ ...item, RTCSessionId: payload }));
      yield call(VoiceService.setSessionFromLog, payload, item.sessionId);
      break;
    }
  }
}

export function* handleAcceptCall({ payload }) {
  const { sessionId, config } = payload;
  try {
    if (sessionId) {
      yield call(VoiceService.acceptCall, sessionId);
    } else {
      toast.warn('You are on another call.');
    }
  } catch (error) {
    console.log(error);
  }
}

export function* mute({ payload }) {
  const { sessionId } = payload;
  try {
    yield call(VoiceService.muteCall, sessionId);
  } catch (error) {
    console.log({ error });
  }
}

export function* unmute({ payload }) {
  const { sessionId } = payload;

  try {
    yield call(VoiceService.unmuteCall, sessionId);
  } catch (error) {
    console.log({ error });
  }
}

export function* cancel({ payload }) {
  const { sessionId } = payload;

  try {
    yield call(VoiceService.cancelCall, sessionId);
  } catch (error) {
    console.log({ error });
  }
}

// export function* handleReceiveMessage({ payload }) {
//   try {
//     yield put(VoiceAction.receiveMessageFromSocketServer(payload));

//   } catch (error) {
//     console.log({ error });
//   }
// }

export function* fetchLogs({ payload }) {
  try {
    const { userId } = payload;
    const jsonLogs = yield call(VoiceService.fetchLogs, userId);
    const logList = jsonLogs.data.logs;

    yield put(VoiceAction.updateVoiceData(logList));
  } catch (error) {
    yield put(VoiceAction.fetchLogsFailure(error.message));
    console.error(error);
  }
}

export function* handleSetSession() {
  try {
    const voices = yield select(VoiceSelector.selectVoiceList);
    for (const session of voices) {
      const { participantList, sessionId, parentCallId } = session;
      yield call(VoiceService.setSession, parentCallId, sessionId);
      for (const participant of participantList) {
        yield call(VoiceService.setSession, participant.callId, sessionId);
      }
    }
  } catch (error) {
    yield put(VoiceAction.fetchLogsFailure(error.message));
    console.error(error);
  }
}

function* leaveCallSaga() {
  try {
    const conversationId = yield select(MessageSelector.selectMessageConversationId);
    if (conversationId) {
      const currentVoiceConversation = yield select(VoiceSelector.selectVoiceDataByConversationId(conversationId)); // Current Session Voice Data
      const currentUserVoice = yield select(VoiceSelector.selectVoiceCurrentUserByConversationId(conversationId)); // Current User Voice Data
      const currentUser = yield select(UserSelector.selectCurrentUser);
      if (currentVoiceConversation) {
        const isOutbound = currentVoiceConversation?.type === 'outbound';
        const isCurrentUser = currentVoiceConversation?.currentUserId === currentUser?.id;
        console.log('currentVoiceConversation', { currentVoiceConversation, currentUserVoice, currentUser });
        yield put(
          ConversationAction.removeCallFromSession(
            currentVoiceConversation.sessionId,
            isOutbound && isCurrentUser ? currentVoiceConversation?.parentCallId : currentUserVoice?.callId,
          ),
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
}
export function* muteUser({ payload, callback = () => {} }) {
  const { sessionId, callId } = payload;

  try {
    if (sessionId && callId) {
      const response = yield call(axios.post, '/conversation/voice/muteParticipant', { sessionId, callId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}
export function* unMuteUser({ payload, callback = () => {} }) {
  const { sessionId, callId } = payload;

  try {
    if (sessionId && callId) {
      const response = yield call(axios.post, '/conversation/voice/unMuteParticipant', { sessionId, callId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}
export function* holdUser({ payload, callback = () => {} }) {
  const { sessionId, callId } = payload;

  try {
    if (sessionId && callId) {
      const response = yield call(axios.post, '/conversation/voice/holdParticipant', { sessionId, callId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}
export function* unHoldUser({ payload, callback = () => {} }) {
  const { sessionId, callId } = payload;

  try {
    if (sessionId && callId) {
      const response = yield call(axios.post, '/conversation/voice/unHoldParticipant', { sessionId, callId });
      callback(null, response.data);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
    console.error(error);
  }
}

export function* inviteExternalCall({ payload, callback = () => {} }) {
  // const invitePayload = {
  //   from: '16164413854',
  //   uniqNameConference: 'conference-hx6lpi',
  //   to: { type: 'phone', number: '17147520454' },
  // };
  try {
    if (!!payload) {
      const response = yield call(VoiceService.inviteExternalCall, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('TCL - file: voice.saga.js:268 - function*inviteExternalCall - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* hangupExternalCall({ payload, callback = () => {} }) {
  try {
    if (!isEmpty(payload)) {
      const response = yield call(VoiceService.hangupExternalCall, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('TCL - file: voice.saga.js:280 - function*hangupExternalCall - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* requestJoinCall({ payload, callback = () => {} }) {
  try {
    if (!!payload) {
      const response = yield call(VoiceService.requestToJoinCall, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('TCL - file: voice.saga.js:292 - function*requestJoinCall - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* voiceGetCallLog({ payload, callback = () => {} }) {
  try {
    if (!!payload) {
      const response = yield call(VoiceService.getCallLog, payload);
      callback(null, response);
    }
  } catch (error) {
    callback(handleGetErrorMessageNew(error));
  }
}
export function* handleMuteParticipants({ payload, callback = () => {} }) {
  try {
    if (!!payload) {
      const response = yield call(VoiceService.handleControlMuteCall, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('TCL - file: voice.saga.js:314 - function*handleMuteParticipants - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* handleHoldParticipants({ payload, callback = () => {} }) {
  try {
    if (!!payload) {
      const response = yield call(VoiceService.holdParticipant, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('TCL - file: voice.saga.js:326 - function*handleHoldParticipants - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* handleRecord({ payload, callback = () => {} }) {
  try {
    if (!!payload) {
      const response = yield call(VoiceService.recordConference, payload);
      callback(null, response);
    }
  } catch (error) {
    console.log('🚀 ~ file: voice.saga.js:315 ~ function*handleMuteParticipants ~ error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export function* getAndRealtimeSessionData({ payload, callback = () => {} }) {
  try {
    if (!isEmpty(payload)) {
      const { sessions, currentUser } = payload;
      let isUserInCall = false;
      let isRequestJoinCall = false;
      const sessionList = Object.keys(sessions).map((sessionId) => {
        const sessionState = sessions[sessionId]?._state;
        if (
          sessionState === SessionState.Initial ||
          sessionState === SessionState.Established ||
          sessionState === SessionState.Establishing
        ) {
          isUserInCall = true;
        }

        if (sessionState === SessionState.Terminated) {
          isUserInCall = false;
        }
        const isInboundCall = !!sessions[sessionId]?.incomingInviteRequest;
        if (isInboundCall) {
          const { Userid = [] } = sessions[sessionId]?.incomingInviteRequest?.message?.headers;
          const { raw } = Userid[0];
          const userIdArray = raw.split(',');
          const cleanIdArray = uniq(userIdArray.map((id) => id.replace('mobile-', '').replace(/\s/g, '')));
          isRequestJoinCall =
            sessions[sessionId]?.incomingInviteRequest?.message?.headers?.Isrequestjoincall[0]?.raw === 'true';
          const isFromLiveChatHeader = sessions[sessionId]?.incomingInviteRequest?.message?.headers?.Fromlivechat;
          let isFromLiveChat = false;
          if (isFromLiveChatHeader && isFromLiveChatHeader.length) {
            if (isFromLiveChatHeader[0].raw === 'true') isFromLiveChat = true;
          }

          return {
            sessionId: sessionId,
            state: sessionState,
            callId: sessions[sessionId]?.incomingInviteRequest?.message?.callId,
            conversationId: sessions[sessionId]?.incomingInviteRequest?.message?.headers?.Conversationid[0]?.raw,
            conferenceName: sessions[sessionId]?.incomingInviteRequest?.message?.headers?.Conferencename[0]?.raw,
            groupId: sessions[sessionId]?.incomingInviteRequest?.message?.headers?.Groupid[0]?.raw,
            userId: cleanIdArray[0],
            isRequestJoinCall,
            isInboundCall,
            fromLiveChat: isFromLiveChat,
            liveChatCallToUser: currentUser.id,
          };
        } else {
          const { extraHeaders } = sessions[sessionId]?.outgoingRequestMessage;
          const conversationIdHeader = extraHeaders[0];
          const aniHeader = extraHeaders[1];
          const groupIdHeader = extraHeaders[2];
          const conferenceHeader = extraHeaders[3];
          const conversationPart = conversationIdHeader.split(': ');
          const aniPart = aniHeader.split(': ');
          const groupIdPart = groupIdHeader.split(': ');
          const conferencePart = conferenceHeader.split(': ');
          const conversationId = conversationPart[1].replace(/\s/g, '');
          const ani = aniPart[1].replace(/\s/g, '');
          const groupId = groupIdPart[1].replace(/\s/g, '');
          const conferenceName = conferencePart[1].replace(/\s/g, '');
          return {
            sessionId: sessionId,
            state: sessions[sessionId]?._state,
            callId: sessions[sessionId]?.outgoingRequestMessage?.callId,
            conversationId,
            conferenceName,
            groupId,
            userId: currentUser.id,
            isRequestJoinCall: false,
            isInboundCall,
            ani,
          };
        }
      });
      if (sessionList.length > 0) {
        const payload = {};
        sessionList.forEach((session) => {
          payload[session.sessionId] = session;
        });
        yield put(SocketAction.callUpdateListSessionInGroup(payload));
        yield put(VoiceAction.setInCallStatus(isUserInCall));
      }
    }
  } catch (error) {
    console.log('🚀 ~ file: voice.saga.js:326 ~ function*getAndRealtimeSessionData ~ error:', error);
  }
}

export function* getAndSyncVoiceData({ payload, callback = () => {} }) {
  const { groupId } = payload;
  try {
    const getSessionList = yield select(VoiceSelector.getSessionList);
    const getCurrentCallLog = yield select(VoiceSelector.getCurrentCallLog);
    SocketService.emit(ROOM_NAME.GROUP({ groupId }), SocketAction.SOCKET_EVENT.SYNC_VOICE_DATA, {
      session: getSessionList,
      callLog: getCurrentCallLog,
    });
  } catch (error) {
    console.log('TCL - file: voice.saga.js:441 - function*getAndSyncVoiceData - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}
export function* syncVoiceData({ payload, callback = () => {} }) {
  try {
    const currentUser = yield select(UserSelector.selectCurrentUser);
    const userId = currentUser?.id;
    yield put(VoiceAction.syncVoiceDataToStore({ ...payload, userId }));
  } catch (error) {
    console.log('TCL - file: voice.saga.js:449 - function*syncVoiceData - error:', error);
    callback(handleGetErrorMessageNew(error));
  }
}

export default function* voiceFlow() {
  yield takeLatest(VoiceActionTypes.CONNECT, connect);
  yield takeLatest(VoiceActionTypes.REGISTER, register);
  yield takeLatest(VoiceActionTypes.DIALING, dialing);
  yield takeLatest(VoiceActionTypes.HANGUP, hangup);
  yield takeLatest(VoiceActionTypes.DIALER_STATUS, handleConnected);
  yield takeLatest(VoiceActionTypes.ACCEPT_CALL, handleAcceptCall);
  yield takeLatest(VoiceActionTypes.MUTE, mute);
  yield takeLatest(VoiceActionTypes.UNMUTE, unmute);
  yield takeLatest(VoiceActionTypes.CANCEL, cancel);
  yield takeLatest(VoiceActionTypes.FETCH_LOGS, fetchLogs);
  yield takeLatest(VoiceActionTypes.SET_SESSION, handleSetSession);
  yield takeLatest(VoiceActionTypes.LEAVE_CURRENT_CALL, leaveCallSaga);
  yield takeLatest(VoiceActionTypes.MUTE_ID, muteUser);
  yield takeLatest(VoiceActionTypes.UNMUTE_ID, unMuteUser);
  yield takeLatest(VoiceActionTypes.HOLD_ID, holdUser);
  yield takeLatest(VoiceActionTypes.UN_HOLD_ID, unHoldUser);
  yield takeLatest(VoiceActionTypes.INVITE_EXTERNAL_CALL, inviteExternalCall);
  yield takeEvery(VoiceActionTypes.HANGUP_EXTERNAL_CALL, hangupExternalCall);
  yield takeLatest(VoiceActionTypes.REQUEST_JOIN_CALL, requestJoinCall);
  // yield takeLatest(VoiceActionTypes.ON_MESSAGE, handleReceiveMessage);
  yield takeLatest(VoiceActionTypes.GET_CALL_LOG, voiceGetCallLog);
  yield takeLatest(VoiceActionTypes.MUTE_PARTICIPANT, handleMuteParticipants);
  yield takeLatest(VoiceActionTypes.HOLD_PARTICIPANT, handleHoldParticipants);
  yield takeLatest(VoiceActionTypes.RECORD_CALL, handleRecord);
  yield takeLatest(VoiceActionTypes.GET_AND_REALTIME_SESSION_PAYLOAD, getAndRealtimeSessionData);
  yield takeEvery(VoiceActionTypes.GET_AND_SYNC_VOICE_DATA, getAndSyncVoiceData);
  yield takeEvery(VoiceActionTypes.SYNC_VOICE_DATA, syncVoiceData);
}
