import { groupBy, omit, uniq } from 'lodash';

export default class FieldValueUtils {
  static normalizeFieldValues(data, fieldValues) {
    const byId = groupBy(fieldValues, 'id');
    const allIds = Object.keys(byId);
    allIds.map((id) => {
      byId[id] = byId[id][0];
    });
    return {
      byId: { ...data.byId, ...byId },
      allIds: uniq([...data.allIds, ...allIds]),
    };
  }

  static deleteFieldValues(data, fieldValueIds) {
    let byId = data.byId;

    fieldValueIds.forEach((fieldValueId) => {
      byId = omit(byId, fieldValueId);
    });

    const allIds = uniq(Object.keys(byId));

    return { byId, allIds };
  }
}
