export const REPORT_ITEM = {
  DASHBOARD: 'DASHBOARD',
  AGENT: 'AGENT',
  CONVERSATION: 'CONVERSATION',
  CALL_SUMMARY: 'CALL_SUMMARY',
  CALL_LOG: 'CALL_LOG',
  FIELD_CHANGE_LOG: 'FIELD_CHANGE_LOG',
  MESSAGE_SEARCH: 'MESSAGE_SEARCH',
  BULK_UPLOAD_CONTACT: 'BULK_UPLOAD_CONTACT',
};

export const REPORT_ITEM_LABEL = {
  [REPORT_ITEM.DASHBOARD]: 'Daily Dashboard',
  [REPORT_ITEM.AGENT]: 'Agent Performance Summary',
  [REPORT_ITEM.CONVERSATION]: 'Conversation Summary',
  [REPORT_ITEM.CALL_SUMMARY]: 'Call Summary',
  [REPORT_ITEM.CALL_LOG]: 'Call Log',
  [REPORT_ITEM.FIELD_CHANGE_LOG]: 'Field Change Log',
  [REPORT_ITEM.MESSAGE_SEARCH]: 'Message Search',
  [REPORT_ITEM.BULK_UPLOAD_CONTACT]: 'Bulk Upload Contact Status',
};

export const FILTER_TYPE = {
  GROUP: 'GROUP',
  CALL_TYPE: 'CALL_TYPE',
  DIRECTION: 'DIRECTION',
  ADVANCED_CALL_LOG: 'ADVANCED_CALL_LOG',
  ADVANCED_FIELD_CHANGE_LOG: 'ADVANCED_FIELD_CHANGE_LOG',
  MESSAGE_SEARCH: 'MESSAGE_SEARCH',
};

export const ALL_OPTION_VALUE = 'all';
