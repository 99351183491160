import axios from 'axios';
import { compact } from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';
import CdrAction from './cdr.actions';
import MessageAction from '../message/message.actions';

export function* fetchCdrByIds({ payload }) {
  try {
    const response = yield call(axios.post, '/cdr/cdr_ids', { cdrIds: compact(payload) });
    if (response && response.data) {
      yield put(CdrAction.addCdr(response.data));
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchCdrByDateRange({ payload }) {
  const { dateRange, ownerId } = payload;
  try {
    const response = yield call(axios.post, '/cdr/byDateRange', { dateRange, ownerId });
    if (response && response.data) {
      const { cdrs, messages } = response.data;

      yield put(CdrAction.addCdr(cdrs));
      yield put(MessageAction.addMessageList(messages));
    }
  } catch (error) {
    console.log({ error });
  }
}

export default function* cdrSagaFlow() {
  yield takeLatest(CdrAction.FETCH_CDR_BY_IDS, fetchCdrByIds);
  yield takeLatest(CdrAction.FETCH_CDR_BY_DATE_RANGE, fetchCdrByDateRange);
}
