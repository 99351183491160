export const USER_LOGIN_PLATFORM = {
  WEB: 'web',
  MOBILE: 'mobile',
};
export const VERIFY_METHOD = {
  SMS: 'SMS',
};

export const AUTH_ERROR = {
  duplicate_email: 'DuplicateEmail',
};
