export default class UserUtils {
  static normalizeUsers(stateUser, users) {
    const byId = {};

    // Updated User
    Object.keys(stateUser.byId).forEach((userId) => {
      const user = stateUser.byId[userId];
      const updatedUser = users.find((item) => item.id === userId) || {};

      byId[userId] = { ...user, ...updatedUser };
    });

    // New User
    users
      .filter((item) => item.id && !Object.keys(byId).includes(item.id))
      .forEach((item) => {
        byId[item.id] = item;
      });

    const allIds = Object.keys(byId);

    return { byId, allIds };
  }
}
