import Immutable, { merge, Record, remove } from 'immutable';
import { uniq } from 'lodash';
import SocketAction from './socket.action';
import { ROOM_NAME } from './SocketIO';

const INITIAL_STATE = Record({
  connected: false,
  rooms: [],
  groupRooms: [],
  ownerRooms: [],
})({
  connected: false,
  rooms: [],
  groupRooms: [],
  ownerRooms: [],
});

function socketReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SocketAction.DISCONNECT:
      return state.set('connected', false);
    case SocketAction.SET_IS_CONNECTED:
      return state.set('connected', action.payload);
    case SocketAction.UPDATE_ROOM:
      const { groupRooms, ownerRooms } = action.payload;
      if (groupRooms && ownerRooms) {
        return state
          .set('rooms', uniq([...state.rooms, ...groupRooms, ...ownerRooms]))
          .set('groupRooms', groupRooms)
          .set('ownerRooms', ownerRooms);
      }
      return state;
    case SocketAction.JOIN_CONNECTION_ROOM:
      return state.update('rooms', (oldRooms) => [...oldRooms, ROOM_NAME.CONNECTION]);
    case SocketAction.LEAVE_CONNECTION_ROOM:
      return state.update('rooms', (oldRooms) => oldRooms.filter((item) => item !== ROOM_NAME.CONNECTION));
    case SocketAction.JOIN_ROOM:
      return state.set('rooms', uniq([...state.rooms, action.payload]));
    case SocketAction.LEAVE_ROOM:
      return state
        .set(
          'rooms',
          state.rooms.filter((item) => item !== action.payload),
        )
        .set(
          'groupRooms',
          state.groupRooms.filter((item) => item !== action.payload),
        )
        .set(
          'ownerRooms',
          state.ownerRooms.filter((item) => item !== action.payload),
        );
    default:
      return state;
  }
}

export default socketReducer;
