import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import MemberSelector from '../member/member.selectors';

export default class UserSelector {
  static selectUser = (state) => state.user;

  static selectCurrentUser = createSelector(this.selectUser, (user) => user.currentUser);

  static checkSubscribedUser = createSelector(
    [this.selectUser, MemberSelector.selectCurrentUserIsMemberNotIsOwner],
    (user, member) => {
      const { user_plan } = user.currentUser;
      return !!user_plan || !isEmpty(member);
    },
  );

  static getCurrentAutoAssign = createSelector(this.selectUser, (user) => !!user.currentUser?.auto_assign);

  static selectSameDomainUsers = createSelector([this.selectUser], (user) => user.sameDomainUsers);

  static selectUserPlan = createSelector([this.selectUser], (user) => user.plan);

  static selectAutoAssignUser = createSelector([this.selectUser], (user) => get(user, 'currentUser.auto_assign', null));

  static selectVoiceState = createSelector([this.selectUser], (user) => get(user, 'currentUser.voiceState', null));

  static selectUsers = createSelector([this.selectUser], (user) => get(user, 'users'));

  static selectUsersByIds = createSelector([this.selectUser], (user) => get(user, 'users.byId'));

  static selectOnlineUser = createSelector([this.selectUser], (user) => get(user, 'onlineList'));

  static selectOnlineUserData = createSelector([this.selectUser], (user) => {
    const _userList = get(user, 'onlineList');
    return _userList.map((id) => get(user, `users.byId.${id}`));
  });

  static selectUserByUserId = (userId) => createSelector(this.selectUser, (user) => get(user, `users.byId.${userId}`));

  static getAccessToken = createSelector([this.selectUser], (user) => get(user, 'token'));

  static getSessionId = createSelector([this.selectUser], (user) => get(user, 'sessionId'));

  static getUpdatingUser = createSelector([this.selectUser], (user) => get(user, 'isUpdatingUser'));

  static selectUserByEmail = createSelector([this.selectUser], (user) => {
    const { currentUser } = user;
    const userById = { ...user.users.byId, [currentUser.id]: currentUser };

    return Object.values(userById).reduce((initValue, value) => {
      return {
        ...initValue,
        [value.email]: value,
      };
    }, {});
  });
}
