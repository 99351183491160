import { groupBy, omit } from 'lodash';

export default class DncUtil {
  static normalizeDnc(state, dnc) {
    const byId = dnc.reduce(
      (initValue, value) => ({
        ...initValue,
        [value.id]: value,
      }),
      {},
    );

    const allIds = Object.keys(byId);
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: [...state.data.allIds, ...allIds],
    };
  }

  static normalizeDeleteDnc(state, idDnc) {
    const { byId, allIds } = state.data;
    return {
      byId: omit(byId, [idDnc]),
      allIds: allIds.filter((itemId) => itemId !== idDnc),
    };
  }
}
