export default class SessionLogAction {
  static FETCH_SESSION_LOG_BY_DATE_RANGE = {
    START: 'SessionLogAction/FETCH_SESSION_LOG_BY_DATE_RANGE_START',
    SUCCESS: 'SessionLogAction/FETCH_SESSION_LOG_BY_DATE_RANGE_SUCCESS',
    FAILURE: 'SessionLogAction/FETCH_SESSION_LOG_BY_DATE_RANGE_FAILURE',
  };

  static fetchSessionLogByDateRange = (dateRange, ownerId) => ({
    type: this.FETCH_SESSION_LOG_BY_DATE_RANGE.START,
    payload: { dateRange, ownerId },
  });

  static fetchSessionLogByDateRangeSuccess = (payload) => ({
    type: this.FETCH_SESSION_LOG_BY_DATE_RANGE.SUCCESS,
    payload,
  });

  static fetchSessionLogByDateRangeFailure = (error) => ({
    type: this.FETCH_SESSION_LOG_BY_DATE_RANGE.FAILURE,
    payload: error,
  });
}
