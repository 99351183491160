export default class ReportAction {
  static REPORT_FILTER = {
    SET_REPORT_GROUP_FILTER: 'ReportAction/SET_REPORT_GROUP_FILTER',
    SET_REPORT_DATE_RANGE_FILTER: 'ReportAction/SET_REPORT_DATE_RANGE_FILTER',
    SET_REPORT_CALL_TYPE_FILTER: 'ReportAction/SET_REPORT_CALL_TYPE_FILTER',
    SET_REPORT_DIRECTION_FILTER: 'ReportAction/SET_REPORT_DIRECTION_FILTER',
    SET_REPORT_ADVANCED_FILTER_CALL_LOG: 'ReportAction/SET_REPORT_ADVANCED_FILTER_CALL_LOG',
    SET_REPORT_ADVANCED_FILTER_OPTION_CALL_LOG: 'ReportAction/SET_REPORT_ADVANCED_FILTER_OPTION_CALL_LOG',

    SET_REPORT_ADVANCED_FILTER_FIELD_CHANGE_LOG: 'ReportAction/SET_REPORT_ADVANCED_FILTER_FIELD_CHANGE_LOG',
    SET_REPORT_ADVANCED_FILTER_OPTION_FIELD_CHANGE_LOG:
      'ReportAction/SET_REPORT_ADVANCED_FILTER_OPTION_FIELD_CHANGE_LOG',
    SET_MESSAGE_SEARCH: 'ReportAction/SET_MESSAGE_SEARCH',
  };

  static SET_REPORT_TAB = 'ReportAction/SET_REPORT_TAB';

  static SET_DEFAULT_DATE_RANGE = 'ReportAction/SET_DEFAULT_DATE_RANGE';

  static SET_REPORT_LOADING = 'ReportAction/SET_REPORT_LOADING';

  static FETCH_REPORT = {
    START: 'ReportAction/FETCH_REPORT_START',
    SUCCESS: 'ReportAction/FETCH_REPORT_SUCCESS',
    FAILED: 'ReportAction/FETCH_REPORT_FAILED',
  };

  static FETCH_REPORT_LOG_CONVERSATION = {
    START: 'ReportAction/FETCH_REPORT_START_LOG_CONVERSATION',
    SUCCESS: 'ReportAction/FETCH_REPORT_SUCCESS_LOG_CONVERSATION',
    FAILED: 'ReportAction/FETCH_REPORT_FAILED',
  };

  static FETCH_REPORT_LOG_BULK_UPLOAD_LOG = {
    START: 'ReportAction/FETCH_REPORT_LOG_BULK_UPLOAD_LOG',
    SUCCESS: 'ReportAction/FETCH_REPORT_LOG_BULK_UPLOAD_LOG_SUCCESS',
    FAILED: 'ReportAction/FETCH_REPORT_LOG_BULK_UPLOAD_LOG_FAILED',
  };

  static SET_RE_FETCH_REPORT = 'ReportAction/SET_RE_FETCH_REPORT';

  static setFetchReport = (payload) => ({
    type: ReportAction.SET_RE_FETCH_REPORT,
    payload,
  });

  static fetchReportStart = (groupFilter, start, end, callback) => ({
    type: ReportAction.FETCH_REPORT.START,
    payload: { groupFilter, start, end },
    callback,
  });

  static fetchReportSuccess = (payload) => ({
    type: ReportAction.FETCH_REPORT.SUCCESS,
    payload,
  });

  static fetchReportLogConversationStart = () => ({
    type: ReportAction.FETCH_REPORT_LOG_CONVERSATION.START,
  });

  static fetchReportLogConversationSuccess = (payload) => ({
    type: ReportAction.FETCH_REPORT_LOG_CONVERSATION.SUCCESS,
    payload,
  });

  static fetchReportLogBulkUploadContactStart = () => ({
    type: ReportAction.FETCH_REPORT_LOG_BULK_UPLOAD_LOG.START,
  });

  static fetchReportLogBulkUploadContactSuccess = (payload) => ({
    type: ReportAction.FETCH_REPORT_LOG_BULK_UPLOAD_LOG.SUCCESS,
    payload,
  });

  static fetchReportFail = (payload) => ({
    type: ReportAction.FETCH_REPORT.FAILED,
    payload,
  });

  static setReportAdvancedFilterOptionFieldChangeLog = (key, value) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_OPTION_FIELD_CHANGE_LOG,
    payload: { key, value },
  });

  static setReportAdvancedFilterFieldChangeLog = (advancedFilter) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_FIELD_CHANGE_LOG,
    payload: advancedFilter,
  });

  static setReportAdvancedFilterOptionCallLog = (key, value) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_OPTION_CALL_LOG,
    payload: { key, value },
  });

  static setReportAdvancedFilterCallLog = (advancedFilter) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_CALL_LOG,
    payload: advancedFilter,
  });

  static setReportMessageSearch = (advancedFilter) => ({
    type: ReportAction.REPORT_FILTER.SET_MESSAGE_SEARCH,
    payload: advancedFilter,
  });

  static setReportCallTypeFilter = (callType) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_CALL_TYPE_FILTER,
    payload: callType,
  });

  static setReportDirectionFilter = (direction) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_DIRECTION_FILTER,
    payload: direction,
  });

  static setReportDateRangeFilter = (dateRange) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_DATE_RANGE_FILTER,
    payload: dateRange,
  });

  static setReportGroupFilter = (groupId) => ({
    type: ReportAction.REPORT_FILTER.SET_REPORT_GROUP_FILTER,
    payload: groupId,
  });

  static setReportTab = (reportTab) => ({
    type: ReportAction.SET_REPORT_TAB,
    payload: reportTab,
  });

  static setReportLoading = (loading) => ({
    type: ReportAction.SET_REPORT_TAB,
    payload: loading,
  });

  static setReportDefaultDateRangeFilter = (dateRange) => ({
    type: ReportAction.SET_DEFAULT_DATE_RANGE,
    payload: dateRange,
  });
}
