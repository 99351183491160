export const VOICE_STATUS = ['INIT', 'ANSWER', 'JOIN_CALL', 'HUNGUP', 'NONE'];

export const CALL_STATUS = {
  RINGING: 'RINGING',
  INIT: 'INIT',
  INIT_ACCEPTED: 'INIT_ACCEPTED',
  HUNGUP: 'HUNGUP',
  FAILED: 'FAILED',
  ANSWER: 'ANSWER',
  BRIDGE: 'BRIDGE',
};

export const HAVE_CALL_CALL_STATUSES = [
  CALL_STATUS.ANSWER,
  CALL_STATUS.RINGING,
  CALL_STATUS.INIT,
  CALL_STATUS.INIT_ACCEPTED,
];
