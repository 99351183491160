import { uniq, get } from 'lodash';
import dayjs from 'dayjs';

export default class ReportUtil {
  static normalizeData(state, payload) {
    const smsCount = get(payload, 'smsCount', []);
    const liveChatCount = get(payload, 'liveChatCount', []);
    const callCount = get(payload, 'callCount', []);
    const onlineTime = get(payload, 'onlineTime', []);
    const closeSms = get(payload, 'smsClose', {});
    const { sms, live } = closeSms;
    const data = { smsCount, liveChatCount, callCount, onlineTime };
    let byId;
    let allIds;
    byId = smsCount.reduce((initValue, value) => {
      return {
        ...initValue,
        [value._user]: value,
      };
    }, {});

    allIds = Object.keys(byId);

    data.smsCount = {
      byId: { ...state.data.smsCount.byId, ...byId },
      allIds: uniq([...state.data.smsCount.allIds, ...allIds]),
    };

    byId = liveChatCount.reduce((initValue, value) => {
      return {
        ...initValue,
        [value._user]: value,
      };
    }, {});

    allIds = Object.keys(byId);

    data.liveChatCount = {
      byId: { ...state.data.liveChatCount.byId, ...byId },
      allIds: uniq([...state.data.liveChatCount.allIds, ...allIds]),
    };

    byId = callCount.reduce((initValue, value) => {
      return {
        ...initValue,
        [value._user]: value,
      };
    }, {});

    allIds = Object.keys(byId);

    data.callCount = {
      byId: { ...state.data.callCount.byId, ...byId },
      allIds: uniq([...state.data.callCount.allIds, ...allIds]),
    };

    byId = onlineTime.reduce((initValue, value) => {
      return {
        ...initValue,
        [value._user]: value,
      };
    }, {});

    allIds = Object.keys(byId);

    data.onlineTime = {
      byId: { ...state.data.onlineTime.byId, ...byId },
      allIds: uniq([...state.data.onlineTime.allIds, ...allIds]),
    };
    const closeSmsById = { ...state.data.closeSms.byId, ...sms };
    const closeSmsAllIds = Object.keys(closeSmsById);
    data.closeSms = {
      byId: closeSmsById,
      allIds: closeSmsAllIds,
    };
    const closeLiveById = { ...state.data.closeLive.byId, ...live };
    const closeLiveAllIds = Object.keys(closeLiveById);
    data.closeLive = {
      byId: closeLiveById,
      allIds: closeLiveAllIds,
    };
    return data;
  }

  static formatDate = (date) => {
    return dayjs(date).format('MM/DD/YYYY hh:MM:ssA z');
  };
}
