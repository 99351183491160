export default class GroupAction {
  static FETCH_GROUP = {
    START: 'GroupAction/FETCH_GROUP_START',
    SUCCESS: 'GroupAction/FETCH_GROUP_SUCCESS',
    FAILURE: 'GroupAction/FETCH_GROUP_FAILURE',
  };

  static FETCH_GROUP_BY_ID = {
    START: 'GroupAction/FETCH_GROUP_BY_ID_START',
    SUCCESS: 'GroupAction/FETCH_GROUP_BY_ID_SUCCESS',
    FAILURE: 'GroupAction/FETCH_GROUP_BY_ID_FAILURE',
  };

  static GET_CAMPAIGN_SUGGESTION = {
    START: 'GroupAction/GET_CAMPAIGN_SUGGESTION_START',
    SUCCESS: 'GroupAction/GET_CAMPAIGN_SUGGESTION_SUCCESS',
    FAILURE: 'GroupAction/GET_CAMPAIGN_SUGGESTION_FAILURE',
  };

  static DELETE_GROUP_MEMBER = 'GroupAction/DELETE_GROUP_MEMBER';

  static ADD_GROUP_MEMBER = 'GroupAction/ADD_GROUP_MEMBER';

  static CREATE_NEW_GROUP = 'GroupAction/CREATE_NEW_GROUP';

  static UPDATE_GROUP = 'GroupAction/UPDATE_GROUP';

  static DELETE_GROUP = 'GroupAction/DELETE_GROUP';

  static DELETE_GROUP_SUCCESS = 'GroupAction/DELETE_GROUP_SUCCESS';

  static REMOVE_GROUP_REDUX = 'GroupAction/REMOVE_GROUP_REDUX';

  static ACCEPT_GROUP_REQUEST = 'GroupAction/ACCEPT_GROUP_REQUEST';

  static REJECT_GROUP_REQUEST = 'GroupAction/REJECT_GROUP_REQUEST';

  static WEBHOOK_TOKEN_REQUEST = 'GroupAction/WEBHOOK_TOKEN_REQUEST';

  static createNewGroup = (state, callback) => ({
    type: this.CREATE_NEW_GROUP,
    payload: state,
    callback,
  });

  static updateGroup = (group_id, state, callback) => ({
    type: this.UPDATE_GROUP,
    payload: {
      group_id,
      state,
      callback,
    },
  });

  static acceptGroupRequest = (groupId) => ({
    type: this.ACCEPT_GROUP_REQUEST,
    payload: groupId,
  });

  static rejectGroupRequest = (groupId) => ({
    type: this.REJECT_GROUP_REQUEST,
    payload: groupId,
  });

  static tokenGroupRequest = (groupId) => ({
    type: this.WEBHOOK_TOKEN_REQUEST,
    payload: groupId,
  });

  static deleteGroup = (groupId, callback) => ({
    type: this.DELETE_GROUP,
    payload: groupId,
    callback,
  });

  static deleteGroupSuccess = (groupId, callback) => ({
    type: this.DELETE_GROUP_SUCCESS,
    payload: groupId,
  });

  static removeGroupRedux = (groupId, callback) => ({
    type: this.REMOVE_GROUP_REDUX,
    payload: groupId,
    callback,
  });

  static deleteGroupMember = (obj) => ({
    type: this.DELETE_GROUP_MEMBER,
    payload: obj,
  });

  static addGroupMember = (obj) => ({
    type: this.ADD_GROUP_MEMBER,
    payload: obj,
  });

  static fetchGroups = (status, callback) => ({
    type: this.FETCH_GROUP.START,
    payload: status,
    callback,
  });

  static fetchGroupSuccess = (smsData) => ({
    type: this.FETCH_GROUP.SUCCESS,
    payload: smsData,
  });

  static fetchGroupFailure = (errorMessage) => ({
    type: this.FETCH_GROUP.FAILURE,
    payload: errorMessage,
  });

  static fetchGroupById = (groupId, callback) => ({
    type: this.FETCH_GROUP_BY_ID.START,
    payload: groupId,
    callback,
  });

  static fetchGroupByIdSuccess = (payload) => ({
    type: this.FETCH_GROUP_BY_ID.SUCCESS,
    payload,
  });

  static fetchGroupByIdFailure = (payload) => ({
    type: this.FETCH_GROUP_BY_ID.FAILURE,
    payload,
  });

  static getSuggestionForCampaign = (payload, callback) => ({
    type: this.GET_CAMPAIGN_SUGGESTION.START,
    payload,
    callback,
  });
}
