import { put, all, takeEvery, select, delay, call } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import SocketAction from '../socket/socket.action';
import VoiceAction from '../voice/voice.actions';
import UserSelector from '../user/user.selectors';
import { CALL_STATUS } from '../../enums/socket/voice';
import VoiceService from '../voice/voice.service';
import VoiceSelector from '../voice/voice.selectors';

function* handleRingingCall({ payload }) {
  try {
    console.log('handleRingingCall', payload);
    const { participantList, eventName, currentUserId, parentCallId, sessionId, conversationId } = payload;
    if (!conversationId) {
      return;
    }
    // Map Session from logs to RTC Session
    yield call(VoiceService.setSession, parentCallId, sessionId);
    for (const participant of participantList) {
      yield call(VoiceService.setSession, participant.callId, sessionId);
    }

    const isJoinCall = yield select(VoiceSelector.selectJoinCallBySessionId(sessionId));
    const currentUser = yield select(UserSelector.selectCurrentUser);
    if (!currentUser) return;

    const isMemberAssigned = isEqual(eventName, 'MEMBER_AUTO_ASSIGN') && isEqual(currentUser.id, currentUserId);
    const currentParticipantRinging = participantList.some(
      (participant) => isEqual(participant.userId, currentUser.id) && isEqual(participant.state, CALL_STATUS.INIT),
    );

    const isOwnerJoined = participantList.some(
      (participant) =>
        currentUserId === currentUser.id &&
        participant.userId === currentUserId &&
        participant.state === CALL_STATUS.INIT &&
        isJoinCall,
    );

    const isJoinedCall =
      (isEqual(eventName, CALL_STATUS.INIT) ||
        isEqual(eventName, CALL_STATUS.INIT_ACCEPTED) ||
        isEqual(eventName, CALL_STATUS.BRIDGE)) &&
      currentParticipantRinging &&
      isJoinCall;

    yield put(VoiceAction.updateVoiceData(payload));

    if (isMemberAssigned || isJoinedCall || isOwnerJoined) {
      yield delay(700);
      yield put(VoiceAction.acceptCall(sessionId, null));
      yield put(VoiceAction.setJoinCall(sessionId, false));
    }
  } catch (error) {
    console.log({ error });
  }
}

function* handleInCall({ payload }) {
  try {
    // const { sessionId } = payload;
    // const latestRTCSessionId = VoiceService.getLatestSessionId();
    // VoiceService.setSessionFromLog(latestRTCSessionId, sessionId);

    yield put(VoiceAction.updateVoiceData(payload));
  } catch (error) {
    console.log({ error });
  }
}

function* handleHungup({ payload }) {
  try {
    yield put(VoiceAction.updateVoiceData(payload));
    yield put(VoiceAction.removeClosedSession());
  } catch (error) {
    console.log({ error });
  }
}

function* handleCallConversation({ payload }) {
  try {
    yield put(VoiceAction.updateVoiceData(payload));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeCallingFlow() {
  yield all([
    takeEvery(SocketAction.SOCKET_EVENT.VOICE_INIT, handleRingingCall), // INIT
    takeEvery(SocketAction.SOCKET_EVENT.VOICE_INCALL, handleInCall), // INCALL
    takeEvery(SocketAction.SOCKET_EVENT.VOICE_HUNGUP, handleHungup), // HUNGUP
  ]);
}
