import { difference, omit } from 'lodash';
import { REPORT_ITEM } from '../../enums/report';
import UserAction from '../user/user.actions';
import ReportAction from './report.action';
import ReportUtil from './report.utils';

const INITIAL_STATE = {
  data: {
    smsCount: {
      byId: {},
      allIds: [],
    },
    liveChatCount: {
      byId: {},
      allIds: [],
    },
    callCount: {
      byId: {},
      allIds: [],
    },
    onlineTime: {
      byId: {},
      allIds: [],
    },
    closeSms: {
      byId: {},
      allIds: [],
    },
    closeLive: {
      byId: {},
      allIds: [],
    },
  },
  groupFilter: [],
  dateRangeFilter: [new Date(), new Date()],
  defaultDateRangeFilter: [new Date(), new Date()],
  directionFilter: [],
  callTypeFilter: [],
  // Call Logs
  advancedFilterCallLog: [],
  advancedFilterOptionCallLog: {},
  // Field Change Logs
  advancedFilterFieldChangeLog: [],
  advancedFilterOptionFieldChangeLog: {},
  activeTab: REPORT_ITEM.DASHBOARD,
  loading: false,
  error: null,
  conversationLog: [],
  contactBulkUploadLog: [],
  reFetchReport: false,
};

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_OPTION_FIELD_CHANGE_LOG: {
      const { key, value } = action.payload;
      if (!value || value?.length === 0) {
        return {
          ...state,
          advancedFilterOptionFieldChangeLog: omit(state.advancedFilterOptionFieldChangeLog, key),
        };
      }

      return {
        ...state,
        advancedFilterOptionFieldChangeLog: {
          ...state.advancedFilterOptionFieldChangeLog,
          [key]: value,
        },
      };
    }

    case ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_FIELD_CHANGE_LOG: {
      const advancedFilterFieldChangeLog = action.payload;
      const { advancedFilterOptionFieldChangeLog } = state;

      const advancedFilterOptionFieldChangeLogKeys = Object.keys(advancedFilterOptionFieldChangeLog);
      const deletedFields = difference(advancedFilterOptionFieldChangeLogKeys, advancedFilterFieldChangeLog);

      return {
        ...state,
        advancedFilterFieldChangeLog,
        advancedFilterOptionFieldChangeLog: omit(advancedFilterOptionFieldChangeLog, deletedFields),
      };
    }

    case ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_OPTION_CALL_LOG:
      const { key, value } = action.payload;
      if (!value || value?.length === 0) {
        return {
          ...state,
          advancedFilterOptionCallLog: omit(state.advancedFilterOptionCallLog, key),
        };
      }

      return {
        ...state,
        advancedFilterOptionCallLog: {
          ...state.advancedFilterOptionCallLog,
          [key]: value,
        },
      };

    case ReportAction.REPORT_FILTER.SET_REPORT_ADVANCED_FILTER_CALL_LOG:
      const advancedFilterCallLog = action.payload;
      const { advancedFilterOptionCallLog } = state;

      const advancedFilterOptionCallLogKeys = Object.keys(advancedFilterOptionCallLog);
      const deletedFields = difference(advancedFilterOptionCallLogKeys, advancedFilterCallLog);

      return {
        ...state,
        advancedFilterCallLog,
        advancedFilterOptionCallLog: omit(advancedFilterOptionCallLog, deletedFields),
      };

    case ReportAction.REPORT_FILTER.SET_REPORT_CALL_TYPE_FILTER:
      return { ...state, callTypeFilter: action.payload };

    case ReportAction.REPORT_FILTER.SET_REPORT_DIRECTION_FILTER:
      return { ...state, directionFilter: action.payload };

    case ReportAction.REPORT_FILTER.SET_REPORT_DATE_RANGE_FILTER:
      return { ...state, dateRangeFilter: action.payload };
    case ReportAction.SET_DEFAULT_DATE_RANGE:
      return { ...state, defaultDateRangeFilter: action.payload };

    case ReportAction.REPORT_FILTER.SET_REPORT_GROUP_FILTER:
      return { ...state, groupFilter: action.payload };

    case ReportAction.SET_REPORT_TAB:
      return { ...state, activeTab: action.payload };

    case ReportAction.SET_REPORT_LOADING:
      return { ...state, loading: action.payload };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    case ReportAction.FETCH_REPORT.START:
      return { ...state, loading: true };

    case ReportAction.FETCH_REPORT.SUCCESS:
      const data = ReportUtil.normalizeData(state, action.payload);
      return { ...state, loading: false, data };

    case ReportAction.FETCH_REPORT.FAILED:
      return { ...state, loading: false, error: action.payload };

    case ReportAction.FETCH_REPORT_LOG_CONVERSATION.SUCCESS: {
      return { ...state, loading: false, conversationLog: action.payload };
    }

    case ReportAction.FETCH_REPORT_LOG_BULK_UPLOAD_LOG.SUCCESS: {
      return { ...state, loading: false, contactBulkUploadLog: action.payload };
    }
    case ReportAction.SET_RE_FETCH_REPORT: {
      return { ...state, loading: false, reFetchReport: action.payload };
    }
    default:
      return state;
  }
};

export default reportReducer;
