import { createSelector } from 'reselect';
import { isEqual, compact, get, isEmpty, uniq, uniqBy, orderBy, map } from 'lodash';
import UserSelector from '../user/user.selectors';
import MemberSelector from '../member/member.selectors';
import ConversationSelector from '../conversation/conversation.selectors';
import { MESSAGE_TYPES, SYSTEM_TYPES } from '../../enums/message/message';

const getFromTitle = (userInfo, isFromHookAPI) => {
  if (isFromHookAPI) {
    return 'API Message';
  }
  return userInfo.given_name || userInfo.family_name ? `${userInfo.given_name} ${userInfo.family_name}` : '';
};
const getMessageByConversation = (conversationId, messages, users) => {
  const idMessagesInConversation = messages.data.allIds.filter((id) => {
    const message = messages.data.byId[id];
    if (!message) return false;
    return message._conversation === conversationId;
  });
  const resultMessages = orderBy(
    map(idMessagesInConversation, (id) => {
      const message = messages.data.byId[id];
      const userInfo = message && message._from ? get(users, `byId.${message._from}`, {}) : {};
      return {
        ...message,
        from: getFromTitle(userInfo, message.is_from_hook_api),
        from_email: userInfo.email ? userInfo.email : '',
      };
    }),
    (con) => new Date(con.createdAt),
    ['desc'],
  );

  return resultMessages;
};
export default class GroupSelector {
  static selectGroups = (state) => state.groups;

  static selectMembers = (state) => state.members.data;

  static selectFields = (state) => state.fields.data;

  static selectUsers = (state) => state.user.users;

  static getAllGroup = (state) => state.groups.data.allIdsWithGroupName;

  static selectUser = (state) => state.user;

  static selectCurrentUser = (state) => state.user.currentUser;
  static selectUserByIds = (state) => state.user.users.byId;
  static selectMessages = (state) => state.messages;

  static selectGroupData = createSelector(
    [this.selectGroups, this.selectFields, this.selectMembers, this.selectUsers],
    (groupSelector, fieldSelector, memberSelector, userSelector) => {
      const groups = Object.values(groupSelector.data.byId)
        .map((item) => {
          const user = userSelector.byId[item._user];
          if (!user) return undefined;
          // const fieldData = compact(item.fields.map((idField) => fieldSelector.byId[idField]));
          const members = item.members
            .map((idMember) => {
              const member = memberSelector.byId[idMember];
              if (!member) return undefined;
              return {
                ...member,
                user: Object.values(userSelector.byId).find((u) => isEqual(u.email, member && member._user_email)),
              };
            })
            .filter(Boolean);
          // return { ...item, fieldData, members, user };
          return { ...item, members, user };
        })
        .filter(Boolean);
      const owned_groups = groups.filter((g) => g.role === 'owner');
      const member_groups = groups.filter((g) => g.role === 'member');
      const shared_groups = groups.filter((g) => g.role === 'shared');
      return {
        ...groupSelector,
        data: owned_groups,
        member_groups,
        shared_groups,
      };
    },
  );

  static selectAllGroupCurrentUser = createSelector(this.selectGroupData, (groups) => {
    return uniqBy([...groups.data, ...groups.member_groups, ...groups.shared_groups], 'id');
  });

  static selectGroupOwner = createSelector([this.selectGroups, this.selectUsers], (groups, users) => {
    const ownerByGroups = {};
    Object.values(groups.data.byId).forEach((item) => {
      ownerByGroups[item.id] = users.byId[item._user];
    });
    return ownerByGroups;
  });

  static selectGroupForCurrentUser = createSelector(
    [this.selectGroups, UserSelector.selectCurrentUser, MemberSelector.selectMemberById],
    (groups, currentUser, memberById) => {
      const memberList = Object.values(memberById);

      const { email } = currentUser;
      const members = memberList.filter((item) => item._user_email === email && item?.status === 1);
      const groupIds = uniq(members.map((member) => member._group));

      return Object.values(groups.data.byId).filter((group) => groupIds.includes(group.id));
    },
  );

  static selectGroupByOwnerId = (ownerId) =>
    createSelector([this.selectGroups], (groups) => {
      return Object.values(groups.data.byId).filter((item) => item._user === ownerId);
    });

  static selectGroupByIds = (groupIds) =>
    createSelector([this.selectGroups], (groups) => {
      return Object.values(groups.data.byId).filter((item) => groupIds.includes(item.id));
    });

  static selectListGroup = createSelector([this.selectGroups], (group) => Object.values(group.data.byId));

  static selectGroupsByIds = createSelector([this.selectGroups], (group) => group.data.byId);

  static fetchingGroup = createSelector([this.selectGroups], (group) => group.fetching);

  static selectGroupById = (id) => createSelector([this.selectGroups], (groups) => get(groups, `data.byId.${id}`));

  static getNumberOfConversationHaveNoti = createSelector(
    [
      this.selectGroupsByIds,
      ConversationSelector.selectConversationList,
      this.selectMessages,
      UserSelector.selectUsers,
    ],
    (groups, conversations, messages, users) => {
      const data = {};
      Object.keys(groups).forEach((group) => {
        let count = 0;
        Object.values(conversations).forEach((conversation) => {
          if (conversation._group === group && !conversation.is_close) {
            const selectMessageListByConversationId = getMessageByConversation(conversation?.id, messages, users);
            const { last_message } = conversation;
            const isSMS = isEqual(last_message?.type, MESSAGE_TYPES.SMS);
            const isLiveChat = isEqual(last_message?.type, MESSAGE_TYPES.LIVE_CHAT);
            const isCampaignWorkflowMessage =
              !!last_message?.created_by_campaign || !!last_message?.created_by_workflow;
            if (
              !last_message?._from &&
              !isCampaignWorkflowMessage &&
              (isSMS || isLiveChat) &&
              selectMessageListByConversationId[0]?.type !== MESSAGE_TYPES.SYSTEM &&
              selectMessageListByConversationId[0]?.system_type !== SYSTEM_TYPES.OPEN_CONVERSATION
            ) {
              count += 1;
            }
          }
        });
        data[group] = count;
      });
      return data;
    },
  );
  static selectGroupUserOwner = () =>
    createSelector([this.selectGroupsByIds, this.selectUser], (groups, user) => {
      const { currentUser } = user;
      return Object.keys(groups).filter((item) => groups[item]._user === currentUser.id);
    });

  static getIsFetchingAllGroup = (state) => state.groups.isFetchingAllGroup;

  static selectAllGroupCurrentUserIds = createSelector(this.selectGroupData, (groups) => {
    const group = uniqBy([...groups.data, ...groups.member_groups, ...groups.shared_groups], 'id') || [];
    return group.map((item) => item.id);
  });

  static selectOwnerData = createSelector(
    [this.selectGroupsByIds, this.selectUserByIds, this.selectCurrentUser],
    (groups, users, currentUser) => {
      let owner = {};
      let ownerId = '';
      const groupList = Object.values(groups);
      if (groupList.length === 0) return currentUser;
      groupList.forEach((group) => {
        const { _user } = group;
        if (_user === currentUser.id) ownerId = currentUser.id;
      });
      if (!ownerId) {
        owner = currentUser;
      }
      owner = users[ownerId];
      return owner;
    },
  );
}
