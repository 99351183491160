import axios from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import MergeLogAction from './merge-logs.actions';

export function* fetchMergeLogs() {
  try {
    const response = yield call(axios.get, `/merge_log`);
    if (response && response.data) {
      yield put(MergeLogAction.fetchMergeLogSuccess(response.data));
    }
  } catch (error) {
    yield put(MergeLogAction.fetchMergeLogFailure(error));
    console.error({ error });
  }
}

export function* fetchMergeLogsByIds({ payload: mergeLogIds }) {
  try {
    const response = yield call(axios.post, `/merge_log/byIds`, { mergeLogIds });
    if (response && response.data) {
      yield put(MergeLogAction.fetchMergeLogsByIdsSuccess(response.data));
    }
  } catch (error) {
    yield put(MergeLogAction.fetchMergeLogsByIdsFailure(error));
    console.error({ error });
  }
}

export default function* mergeLogSagaFlow() {
  yield takeLatest(MergeLogAction.FETCH_MERGE_LOGS.START, fetchMergeLogs);
  yield takeLatest(MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.START, fetchMergeLogsByIds);
}
