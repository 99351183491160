import BrandUtil from './brand.utils';
import BrandAction from './brand.actions';

const { BrandActionTypes } = BrandAction;
const INITIAL_STATE = {
  fetching: true,
  data: {
    byId: {},
    allIds: [],
  },
  needUpdate: { id: 'a05913e7-84e0-4f84-9e0e-665d7b719073' },
};

const brandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // fetch broadcasr
    case BrandActionTypes.FETCH_BRAND_NEED_UPDATE.START:
      return {
        ...state,
        fetching: true,
      };
    case BrandActionTypes.FETCH_BRAND_NEED_UPDATE.SUCCESS:
      return {
        ...state,
        fetching: false,
        needUpdate: action.payload,
      };
    case BrandActionTypes.FETCH_BRAND_NEED_UPDATE.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case BrandActionTypes.SET_NEED_TO_UPDATE:
      return {
        ...state,
        needUpdate: { id: action.payload },
      };
    case BrandActionTypes.UPDATE_BRAND.start:
      return {
        ...state,
        fetching: true,
      };
    case BrandActionTypes.UPDATE_BRAND.SUCCESS:
      return {
        ...state,
        needUpdate: { id: '' },
      };
    default:
      return state;
  }
};

export default brandReducer;
