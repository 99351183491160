import { createSelector } from 'reselect';
import { get } from 'lodash';
import { CUSTOM_FIELDS_NAME } from '../../enums/modals/groups';
import { decodePhone } from '../../util';
import FieldSelector from '../field/field.selectors';
import GroupSelector from '../group/group.selectors';

export default class FieldValueSelector {
  static selectFieldValue = (state) => state.fieldValues;

  static selectFieldValueData = createSelector([this.selectFieldValue], (fieldValue) => fieldValue.data);

  static selectFieldByIds = (state) => state.fields.data.byId;

  static selectFieldValueList = createSelector([this.selectFieldValue, this.selectFieldByIds], (fieldValue, field) => {
    const result = Object.values(fieldValue.data.byId).map((fieldValues) => {
      const fieldData = field[fieldValues?._field];
      const fieldValuesData = fieldValues;
      if (!!fieldData) {
        fieldValuesData.field = fieldData;
      }
      return fieldValuesData;
    });
    return result;
  });

  static selectFieldValueByField = (fieldId) =>
    createSelector([this.selectFieldValue], (fieldValue) => {
      return Object.values(fieldValue.data.byId).filter((fv) => get(fv, '_field') === fieldId);
    });

  static selectPhoneNumberByContactId = (contactId) =>
    createSelector([this.selectFieldValue, FieldSelector.selectDefaultFields], (fieldValue, defaultFields) => {
      const phoneField = defaultFields.find((item) => item?.custom_field_name === CUSTOM_FIELDS_NAME.phone);
      const fieldValueList = Object.values(fieldValue.data.byId).filter(
        (item) => item?._contact === contactId && item?._field === phoneField.id,
      );
      return fieldValueList.filter(Boolean).map((item) => decodePhone(item?.value));
    });

  static selectFieldValueOfAllGroups = createSelector(
    [this.selectFieldValue, GroupSelector.selectListGroup, FieldSelector.selectFieldsByIds],
    (fieldValue, groupList, fieldById) => {
      const groupIds = groupList.map((group) => group.id);

      return Object.values(fieldValue.data.byId).filter((item) => {
        const field = fieldById[item?._field];
        return field?.isDefault || groupIds.includes(field?._group);
      });
    },
  );

  static selectDuplicateValue = createSelector([this.selectFieldValue], (fieldValue) => fieldValue.duplicateValue);

  static selectFieldValueByContactId = (contactId) =>
    createSelector([this.selectFieldValue], (fieldValue) =>
      Object.values(fieldValue.data.byId).filter((item) => item?._contact === contactId),
    );

  static selectOnDNCByContactId = (contactId) =>
    createSelector([this.selectFieldValue, FieldSelector.selectFieldsByIds], (fieldValue, fieldById) => {
      const fieldValueByContactId = Object.values(fieldValue.data.byId).filter((item) => item?._contact === contactId);

      return fieldValueByContactId.some((item) => {
        const field = fieldById[item?._field];
        return field && field.custom_field_name === CUSTOM_FIELDS_NAME.dnc && item?.value === 'Yes';
      });
    });

  static selectFieldValueLoading = createSelector([this.selectFieldValue], (fieldValue) => fieldValue.loading);
}
