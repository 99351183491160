import { normalizeUpdatedData } from '../../util';
import MergeLogAction from './merge-logs.actions';
import MergeLogUtil from './merge-logs.utils';

const INITIAL_STATE = {
  data: {
    byId: {},
    allIds: [],
  },
  fetching: false,
  error: null,
};

const mergeLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.SUCCESS: {
      return {
        ...state,
        data: normalizeUpdatedData(state.data, action.payload),
        fetching: false,
      };
    }
    case MergeLogAction.FETCH_MERGE_LOGS_BY_IDS.FAILURE: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    }

    case MergeLogAction.FETCH_MERGE_LOGS.START: {
      return {
        ...state,
        fetching: true,
      };
    }
    case MergeLogAction.FETCH_MERGE_LOGS.SUCCESS: {
      return {
        ...state,
        data: MergeLogUtil.normalizeMergeLogs(state, action.payload),
        fetching: false,
      };
    }
    case MergeLogAction.FETCH_MERGE_LOGS.FAILURE: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mergeLogReducer;
