export default class CampaignAction {
  static CAMPAIGN_ACTION = {
    FETCH_CAMPAIGN_START: 'CampaignAction/FETCH_CAMPAIGN_START',
    FETCH_CAMPAIGN_SUCCESS: 'CampaignAction/FETCH_CAMPAIGN_SUCCESS',
    FETCH_CAMPAIGN_FAILURE: 'CampaignAction/FETCH_CAMPAIGN_FAILURE',
    // create
    HANDLE_CREATE_CAMPAIGN_START: 'CampaignAction/HANDLE_CREATE_CAMPAIGN_START',
    HANDLE_CREATE_CAMPAIGN_SUCCESS: 'CampaignAction/HANDLE_CREATE_CAMPAIGN_SUCCESS',
    HANDLE_CREATE_CAMPAIGN_FAILURE: 'CampaignAction/HANDLE_CREATE_CAMPAIGN_FAILURE',
    // update
    HANDLE_UPDATE_CAMPAIGN_START: 'CampaignAction/HANDLE_UPDATE_CAMPAIGN_START',
    HANDLE_UPDATE_CAMPAIGN_SUCCESS: 'CampaignAction/HANDLE_UPDATE_CAMPAIGN_SUCCESS',
    HANDLE_UPDATE_CAMPAIGN_FAILURE: 'CampaignAction/HANDLE_UPDATE_CAMPAIGN_FAILURE',
    // delete
    HANDLE_DELETE_CAMPAIGN_START: 'CampaignAction/HANDLE_DELETE_CAMPAIGN_START',
    HANDLE_DELETE_CAMPAIGN_SUCCESS: 'CampaignAction/HANDLE_DELETE_CAMPAIGN_SUCCESS',
    HANDLE_DELETE_CAMPAIGN_FAILURE: 'CampaignAction/HANDLE_DELETE_CAMPAIGN_FAILURE',
    HANDLE_DELETE_CAMPAIGN_BY_OWNER: 'CampaignAction/HANDLE_DELETE_CAMPAIGN_BY_OWNER',

    CREATE_DEFAULT_CAMPAIGN_START: 'CampaignAction/CREATE_DEFAULT_CAMPAIGN_START',
    CREATE_DEFAULT_CAMPAIGN_SUCCESS: 'CampaignAction/CREATE_DEFAULT_CAMPAIGN_SUCCESS',
    CREATE_DEFAULT_CAMPAIGN_FAILURE: 'CampaignAction/CREATE_DEFAULT_CAMPAIGN_FAILURE',

    CREATE_SUPPORTING_DESCRIPTION_FOR_DEFAULT_CAMPAIGN_START:
      'CampaignAction/CREATE_SUPPORTING_DESCRIPTION_FOR_DEFAULT_CAMPAIGN_START',
    CREATE_SUPPORTING_MESSAGE_FLOW_FOR_DEFAULT_CAMPAIGN_START:
      'CampaignAction/CREATE_SUPPORTING_MESSAGE_FLOW_FOR_DEFAULT_CAMPAIGN_START',
    CREATE_SUPPORTING_MESSAGE_SAMPLE_FOR_DEFAULT_CAMPAIGN_START:
      'CampaignAction/CREATE_SUPPORTING_MESSAGE_SAMPLE_FOR_DEFAULT_CAMPAIGN_START',
    CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_SUCCESS:
      'CampaignAction/CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_SUCCESS',
    CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_FAILURE:
      'CampaignAction/CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_FAILURE',
  };

  // fetch campaign
  static fetchCampaignStart() {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.FETCH_CAMPAIGN_START,
    };
  }

  static fetchCampaignSuccess(payload) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.FETCH_CAMPAIGN_SUCCESS,
      payload,
    };
  }

  static fetchCampaignFailure(errorMessage) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.FETCH_CAMPAIGN_FAILURE,
      payload: errorMessage,
    };
  }

  // create campaign
  static createCampaignStart(campaign, callback = () => {}) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_CREATE_CAMPAIGN_START,
      payload: campaign,
      callback,
    };
  }

  static createCampaignSuccess(campaign) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_CREATE_CAMPAIGN_SUCCESS,
      payload: campaign,
    };
  }

  static createCampaignFailure(errorMessage) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_CREATE_CAMPAIGN_FAILURE,
      payload: errorMessage,
    };
  }

  // update campaign
  static updateCampaignStart(campaign, callback = () => {}) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_UPDATE_CAMPAIGN_START,
      payload: campaign,
      callback,
    };
  }

  static updateCampaignSuccess(campaign) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_UPDATE_CAMPAIGN_SUCCESS,
      payload: campaign,
    };
  }

  static updateCampaignFailure(errorMessage) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_UPDATE_CAMPAIGN_FAILURE,
      payload: errorMessage,
    };
  }

  // delete campaign
  static deleteCampaignStart(campaignId, callback = () => {}) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_START,
      payload: campaignId,
      callback,
    };
  }

  static deleteCampaignSuccess(campaignId) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_SUCCESS,
      payload: campaignId,
    };
  }

  static deleteCampaignByOwner(ownerId) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_BY_OWNER,
      payload: ownerId,
    };
  }

  static deleteCampaignFailure(errorMessage) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.HANDLE_DELETE_CAMPAIGN_FAILURE,
      payload: errorMessage,
    };
  }

  static createDefaultCampaign(payload, callback) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_DEFAULT_CAMPAIGN_START,
      payload,
      callback,
    };
  }

  static createSupportingDescriptionDefaultCampaign(payload, callback) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_SUPPORTING_DESCRIPTION_FOR_DEFAULT_CAMPAIGN_START,
      payload,
      callback,
    };
  }
  static createSupportingMessageFlowDefaultCampaign(payload, callback) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_FLOW_FOR_DEFAULT_CAMPAIGN_START,
      payload,
      callback,
    };
  }
  static createSupportingMessageSampleDefaultCampaign(payload, callback) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_SUPPORTING_MESSAGE_SAMPLE_FOR_DEFAULT_CAMPAIGN_START,
      payload,
      callback,
    };
  }
  static createSupportingDataDefaultCampaignSuccess(payload) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_SUCCESS,
      payload,
    };
  }
  static createSupportingDataDefaultCampaignFailure(payload) {
    return {
      type: CampaignAction.CAMPAIGN_ACTION.CREATE_SUPPORTING_DATA_FOR_DEFAULT_CAMPAIGN_FAILURE,
      payload,
    };
  }
}
