import { put, takeLatest } from 'redux-saga/effects';
import FieldValueAction from '../field-value/field-value.actions';
import IdentifyAction from '../identify/identify.actions';

import SocketAction from '../socket/socket.action';

function* handleFetchFieldValueByIds({ payload: fieldValueIds }) {
  try {
    yield put(FieldValueAction.fetchFieldValueByIds(fieldValueIds));
  } catch (error) {
    console.log({ error });
  }
}

function* handleDeleteFieldValueByIds({ payload: fieldValueIds }) {
  try {
    yield put(FieldValueAction.deleteFieldValues(fieldValueIds));
  } catch (error) {
    console.log({ error });
  }
}

function* handleUpdateFieldValues({ payload: fieldValue }) {
  try {
    const fieldValueArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
    yield put(FieldValueAction.setFieldValues(fieldValueArray));
  } catch (error) {
    console.log({ error });
  }
}

export function* realtimeFieldValueFlow() {
  yield takeLatest(SocketAction.SOCKET_EVENT.FETCH_FIELD_VALUES_BY_IDS, handleFetchFieldValueByIds);
  yield takeLatest(SocketAction.SOCKET_EVENT.DELETE_FIELD_VALUES_BY_IDS, handleDeleteFieldValueByIds);
  yield takeLatest(SocketAction.SOCKET_EVENT.UPDATE_FIELD_VALUES, handleUpdateFieldValues);
}
