export default class FieldAction {
  static ADD_FIELD = {
    START: 'FieldAction/ADD_FIELD',
    SUCCESS: 'FieldAction/ADD_FIELD_SUCCESS',
    FAILURE: 'FieldAction/ADD_FIELD_FAILURE',
  };

  static DELETE_FIELD = {
    START: 'FieldAction/DELETE_FIELD_START',
    SUCCESS: 'FieldAction/DELETE_FIELD_SUCCESS',
    FAILURE: 'FieldAction/DELETE_FIELD_FAILURE',
  };

  static UPDATE_FIELD = {
    START: 'FieldAction/UPDATE_FIELD_START',
    SUCCESS: 'FieldAction/UPDATE_FIELD_SUCCESS',
    FAILURE: 'FieldAction/UPDATE_FIELD_FAILURE',
  };

  static FETCH_FIELDS = {
    START: 'FieldAction/FETCH_FIELDS_START',
    SUCCESS: 'FieldAction/FETCH_FIELDS_SUCCESS',
    FAILURE: 'FieldAction/FETCH_FIELDS_FAILURE',
  };

  static fetchFields = () => ({
    type: this.FETCH_FIELDS.START,
  });

  static fetchFieldsSuccess = (params) => ({
    type: this.FETCH_FIELDS.SUCCESS,
    payload: params,
  });

  static fetchFieldsFailure = (errorMessage) => ({
    type: this.FETCH_FIELDS.FAILURE,
    payload: errorMessage,
  });

  static addField = (field, callback) => ({
    type: this.ADD_FIELD.START,
    payload: field,
    callback,
  });

  static addFieldSuccess = (field) => ({
    type: this.ADD_FIELD.SUCCESS,
    payload: field,
  });

  static addFieldFailure = (errorMessage) => ({
    type: this.ADD_FIELD.FAILURE,
    payload: errorMessage,
  });

  static deleteField = (field_id) => ({
    type: this.DELETE_FIELD.START,
    payload: field_id,
  });

  static deleteFieldSuccess = (field_id) => ({
    type: this.DELETE_FIELD.SUCCESS,
    payload: field_id,
  });

  static deleteFieldFailure = (errorMessage) => ({
    type: this.DELETE_FIELD.FAILURE,
    payload: errorMessage,
  });

  static updateField = (body) => ({
    type: this.UPDATE_FIELD.START,
    payload: body,
  });

  static updateFieldSuccess = (body) => ({
    type: this.UPDATE_FIELD.SUCCESS,
    payload: body,
  });

  static updateFieldFailure = (errorMessage) => ({
    type: this.UPDATE_FIELD.FAILURE,
    payload: errorMessage,
  });
}
