export default class MergeLogUtil {
  static normalizeMergeLogs(state, mergeLogPayload) {
    const byId = mergeLogPayload.reduce(
      (initValue, value) => ({
        ...initValue,
        [value.id]: value,
      }),
      {},
    );

    const allIds = Object.keys(byId);
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: [...state.data.allIds, ...allIds],
    };
  }
}
