import { omit } from 'lodash';
import FilterAction from './filter.actions';
import UserAction from '../user/user.actions';
import { normalizeUpdatedData } from '../../util';

const INITIAL_STATE = {
  fetching: true,
  updating: false,
  deleting: false,
  data: {
    byId: {},
    allIds: [],
  },
  applyFilterLoading: false,
  selectedFilter: null,
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterAction.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };

    case FilterAction.APPLY_FILTER.START:
      return {
        ...state,
        applyFilterLoading: true,
      };
    case FilterAction.APPLY_FILTER.SUCCESS:
      return {
        ...state,
        applyFilterLoading: false,
      };
    case FilterAction.APPLY_FILTER.FAILURE:
      return {
        ...state,
        applyFilterLoading: false,
        errorMessage: action.payload,
      };

    case FilterAction.FETCH_FILTERS.START:
      return {
        ...state,
        fetching: true,
      };
    case FilterAction.FETCH_FILTERS.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: normalizeUpdatedData(state.data, action.payload),
      };
    case FilterAction.FETCH_FILTERS.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };

    case FilterAction.UPDATE_FILTER.START:
      return {
        ...state,
        updating: true,
      };
    case FilterAction.UPDATE_FILTER.SUCCESS:
      return {
        ...state,
        updating: false,
        data: normalizeUpdatedData(state.data, action.payload),
      };
    case FilterAction.UPDATE_FILTER.FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
      };

    case FilterAction.DELETE_FILTER.START:
      return {
        ...state,
        deleting: true,
      };
    case FilterAction.DELETE_FILTER.SUCCESS:
      const byId = omit(state.data.byId, action.payload);
      return {
        ...state,
        deleting: false,
        data: {
          byId,
          allIds: Object.keys(byId),
        },
      };
    case FilterAction.DELETE_FILTER.FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default filterReducer;
