import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import FilterAction from './filter.actions';
import ListAction from '../list/list.actions';

const defaultCallback = () => {};

export function* fetchFilters() {
  try {
    const response = yield call(axios.get, '/filter');
    if (response && response.data) {
      yield put(FilterAction.fetchFiltersSuccess(response.data));
    } else {
      yield put(FilterAction.fetchFiltersFailure('Fetch filters failed'));
    }
  } catch (error) {
    yield FilterAction.fetchFiltersFailure(error.message);
    console.log({ error });
  }
}

export function* deleteFilter({ payload }) {
  try {
    const response = yield call(axios.delete, `/filter/${payload.id}`);
    if (response.data) {
      yield put(FilterAction.deleteFilterSuccess(response.data));
    }
  } catch (error) {
    yield put(FilterAction.deleteFilterFailure(error.message));
    console.log({ error });
  }
}

export function* updateFilter({ payload, callback = defaultCallback }) {
  try {
    const response = yield call(axios.put, '/filter', payload);
    if (response.data) {
      yield put(FilterAction.updateFilterSuccess(response.data));
      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    yield put(FilterAction.updateFilterFailure(error.message));
    if (callback) {
      callback(error);
    }
  }
}

export function* applyFilter({ payload, callback = defaultCallback }) {
  try {
    const response = yield call(axios.post, '/filter/apply', payload);
    if (response.data) {
      const contactIds = response.data.map((contact) => contact.id);
      yield put(FilterAction.applyFiltersSuccess(response.data));
      yield put(ListAction.setContactIdList(contactIds));

      if (callback) {
        callback(null, response.data);
      }
    }
  } catch (error) {
    yield put(FilterAction.applyFiltersFailure(error.message));
    if (callback) {
      callback(error);
    }
  }
}

export default function* filterSagaFlow() {
  yield takeLatest(FilterAction.FETCH_FILTERS.START, fetchFilters);
  yield takeLatest(FilterAction.UPDATE_FILTER.START, updateFilter);
  yield takeLatest(FilterAction.DELETE_FILTER.START, deleteFilter);
  yield takeLatest(FilterAction.APPLY_FILTER.START, applyFilter);
}
