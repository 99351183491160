export default class MessageAction {
  static MessageActionTypes = {
    FETCH_CONTACT_MESSAGE_START: 'MessageActions/FETCH_CONTACT_MESSAGE_START',
    FETCH_CONTACT_MESSAGE_SUCCESS: 'MessageActions/FETCH_CONTACT_MESSAGE_SUCCESS',
    FETCH_CONTACT_MESSAGE_FAILURE: 'MessageActions/FETCH_CONTACT_MESSAGE_FAILURE',
    // fetch
    FETCH_MESSAGE_START: 'MessageActions/FETCH_MESSAGE_START',
    FETCH_MESSAGE_SUCCESS: 'MessageActions/FETCH_MESSAGE_SUCCESS',
    FETCH_MESSAGE_FAILURE: 'MessageActions/FETCH_MESSAGE_FAILURE',

    // add new chat
    ADD_NEW_CHAT_TEMP: 'MessageActions/ADD_NEW_CHAT_TEMP',
    REMOVE_NEW_CHAT_TEMP: 'MessageActions/REMOVE_NEW_CHAT_TEMP',
    ADD_NEW_CHAT_START: 'MessageActions/ADD_NEW_CHAT_START',
    ADD_NEW_CHAT: 'MessageActions/ADD_NEW_CHAT',
    // select conversation
    SELECT_CONVERSATION: 'MessageActions/SELECT_CONVERSATION',
    // upload file
    HANDLE_MESSAGE_UPLOAD_FILE_START: 'MessageActions/HANDLE_MESSAGE_UPLOAD_FILE_START',

    ADD_MESSAGE_LIST: 'MessageActions/ADD_MESSAGE_LIST',
    // update new message
    UPDATE_NEW_MESSAGE: 'MessageActions/UPDATE_NEW_MESSAGE',
    // recive new chat
    // RECEIVE_NEW_CHAT: 'MessageActions/RECEIVE_NEW_CHAT',
    RECEIVE_NEW_MESSAGE: 'MessageActions/RECEIVE_NEW_MESSAGE', // should clear
    // update message status
    // RECEIVE_MESSAGE_STATUS: 'MessageActions/RECEIVE_MESSAGE_STATUS',
    UPDATE_MESSAGE_STATUS: 'MessageActions/UPDATE_MESSAGE_STATUS', // should clear
    // close Mesasge
    CLOSE_MESSAGE_START: 'MessageActions/CLOSE_MESSAGE_START',

    FETCH_LATEST_MESSAGE_BY_CONTACT_START: 'MessageActions/FETCH_LATEST_MESSAGE_BY_CONTACT_START',
    FETCH_LATEST_MESSAGE_BY_CONTACT_SUCCESS: 'MessageActions/FETCH_LATEST_MESSAGE_BY_CONTACT_SUCCESS',
    FETCH_LATEST_MESSAGE_BY_CONTACT_FAILURE: 'MessageActions/FETCH_LATEST_MESSAGE_BY_CONTACT_FAILURE',

    SEARCH_MESSAGE: 'MessageActions/SEARCH_MESSAGE',
    SEARCH_MESSAGE_SUCCESS: 'MessageActions/SEARCH_MESSAGE_SUCCESS',
    SEARCH_MESSAGE_FAILURE: 'MessageActions/SEARCH_MESSAGE_FAILURE',

    FETCH_MESSAGE_REPORT: 'MessageActions/FETCH_MESSAGE_REPORT',
    FETCH_MESSAGE_REPORT_SUCCESS: 'MessageActions/FETCH_MESSAGE_REPORT_SUCCESS',
    FETCH_MESSAGE_REPORT_FAILURE: 'MessageActions/FETCH_MESSAGE_REPORT_FAILURE',

    FETCH_SYSTEM_MESSAGE: 'MessageActions/FETCH_SYSTEM_MESSAGE',
    FETCH_SYSTEM_MESSAGE_SUCCESS: 'MessageActions/FETCH_SYSTEM_MESSAGE_SUCCESS',
    FETCH_SYSTEM_MESSAGE_FAILURE: 'MessageActions/FETCH_SYSTEM_MESSAGE_FAILURE',

    FETCH_SYSTEM_MESSAGE_FOR_REPORT: 'MessageActions/FETCH_SYSTEM_MESSAGE_FOR_REPORT',
    FETCH_SYSTEM_MESSAGE_FOR_REPORT_SUCCESS: 'MessageActions/FETCH_SYSTEM_MESSAGE_FOR_REPORT_SUCCESS',
    FETCH_SYSTEM_MESSAGE_FOR_REPORT_FAILURE: 'MessageActions/FETCH_SYSTEM_MESSAGE_FOR_REPORT_FAILURE',

    FORCE_FETCH_MESSAGE: 'MessageActions/FORCE_FETCH_MESSAGE',
  };

  static fetchLatestMessageByContact(contactIds, callback) {
    return {
      type: this.MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_START,
      payload: contactIds,
      callback,
    };
  }

  static fetchAllSystemMessage(payload) {
    return {
      type: this.MessageActionTypes.FETCH_SYSTEM_MESSAGE,
      payload,
    };
  }

  static fetchAllSystemMessageSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_SYSTEM_MESSAGE_SUCCESS,
      payload,
    };
  }

  static fetchLatestMessageByContactSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_SUCCESS,
      payload,
    };
  }

  static fetchLatestMessageByContactFailure(errorMessage) {
    return {
      type: this.MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_FAILURE,
      payload: errorMessage,
    };
  }

  static fetchContactMessageStart(payload, callback) {
    return {
      type: this.MessageActionTypes.FETCH_CONTACT_MESSAGE_START,
      payload,
      callback,
    };
  }

  static fetchContactMessageSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_CONTACT_MESSAGE_SUCCESS,
      payload,
    };
  }

  static fetchContactMessageFailure(errorMessage) {
    return {
      type: this.MessageActionTypes.FETCH_CONTACT_MESSAGE_FAILURE,
      payload: errorMessage,
    };
  }

  // fetch message
  static fetchMessageStart(payload, callback) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_START,
      payload,
      callback,
    };
  }

  static fetchMessageSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_SUCCESS,
      payload,
    };
  }

  static fetchMessageFailure(errorMessage) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_FAILURE,
      payload: errorMessage,
    };
  }

  // update new message
  static updateNewMessage(status) {
    return {
      type: this.MessageActionTypes.UPDATE_NEW_MESSAGE,
      payload: status,
    };
  }

  // create new message temp
  static addMessageTemp(payload) {
    return {
      type: this.MessageActionTypes.ADD_NEW_CHAT_TEMP,
      payload,
    };
  }

  static removeMessageTemp(payload) {
    return {
      type: this.MessageActionTypes.REMOVE_NEW_CHAT_TEMP,
      payload,
    };
  }

  // create new message
  static createNewMessageStart(payload, callback) {
    return {
      type: this.MessageActionTypes.ADD_NEW_CHAT_START,
      payload,
      callback,
    };
  }

  // create media message
  static handleMessageUploadFileStart(payload) {
    return {
      type: this.MessageActionTypes.HANDLE_MESSAGE_UPLOAD_FILE_START,
      payload,
    };
  }

  static addNewChat(chat) {
    return {
      type: this.MessageActionTypes.ADD_NEW_CHAT,
      payload: chat,
    };
  }

  // close Message
  static closeMessageStart(payload) {
    return {
      type: this.MessageActionTypes.CLOSE_MESSAGE_START,
      payload,
    };
  }

  // update message status
  static updateMessageStatus(payload) {
    return {
      type: this.MessageActionTypes.UPDATE_MESSAGE_STATUS,
      payload,
    };
  }

  // received new message
  static receivedNewMessage(payload) {
    return {
      type: this.MessageActionTypes.RECEIVE_NEW_MESSAGE,
      payload,
    };
  }

  static addMessageList(payload) {
    return {
      type: this.MessageActionTypes.ADD_MESSAGE_LIST,
      payload,
    };
  }

  // received new message
  static selectConversation(payload) {
    return {
      type: this.MessageActionTypes.SELECT_CONVERSATION,
      payload,
    };
  }

  static searchMessage(payload) {
    return {
      type: this.MessageActionTypes.SEARCH_MESSAGE,
      payload,
    };
  }

  static searchMessageSuccess(payload) {
    return {
      type: this.MessageActionTypes.SEARCH_MESSAGE_SUCCESS,
      payload,
    };
  }

  static searchMessageFailure(payload) {
    return {
      type: this.MessageActionTypes.SEARCH_MESSAGE_FAILURE,
      payload,
    };
  }

  static fetchMessageReport(payload) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_REPORT,
      payload,
    };
  }

  static fetchMessageReportSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_REPORT_SUCCESS,
      payload,
    };
  }

  static fetchMessageReportFailure(payload) {
    return {
      type: this.MessageActionTypes.FETCH_MESSAGE_REPORT_FAILURE,
      payload,
    };
  }

  static fetchSystemMessageForReport(payload, callback) {
    return {
      type: this.MessageActionTypes.FETCH_SYSTEM_MESSAGE_FOR_REPORT,
      payload,
      callback,
    };
  }

  static fetchSystemMessageForReportSuccess(payload) {
    return {
      type: this.MessageActionTypes.FETCH_SYSTEM_MESSAGE_FOR_REPORT_SUCCESS,
      payload,
    };
  }

  static forceFetchMessage(payload) {
    return {
      type: this.MessageActionTypes.FORCE_FETCH_MESSAGE,
      payload,
    };
  }
}
