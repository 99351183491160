import { groupBy, get, omit, uniq } from 'lodash';
import { CampaignSession } from '../../enums/campaign';

export default class CampaignUtil {
  static normalizeContactList(campaigns) {
    const byId = groupBy(campaigns, 'id');
    const allIds = Object.keys(byId);
    allIds.map((id) => {
      byId[id] = byId[id][0];
    });
    return { byId, allIds };
  }

  static normalizeContact(data, campaign) {
    const { byId, allIds } = data;
    const keyId = get(campaign, 'id');
    const addById = { [keyId]: campaign };
    const newData = {
      byId: { ...byId, ...addById },
      allIds: [...allIds, keyId],
    };
    return newData;
  }

  static normalizeAddContactList(data, campaigns) {
    if (Array.isArray(campaigns)) {
      const addById = groupBy(campaigns, 'id');
      const keyId = Object.keys(addById);
      keyId.forEach((id) => {
        addById[id] = addById[id][0];
      });
      const { byId, allIds } = data;
      const newData = {
        byId: { ...byId, ...addById },
        allIds: uniq([...allIds, ...keyId]),
      };
      return newData;
    }
    return CampaignUtil.normalizeContact(data, campaigns);
  }

  static normalizeDeleteContact(data, campaignId) {
    const { byId, allIds } = data;

    const newAllIds = allIds.filter((id) => id !== campaignId);
    const { [campaignId]: foo, ...rest } = byId;
    const newData = {
      byId: rest,
      allIds: newAllIds,
    };
    return newData;
  }

  static normalizeDeleteContactByOwner(data, owner) {
    const { byId, allIds } = data;
    let removeIds = [];
    const removedCampaign = allIds.filter((id) => {
      if (get(byId, [id, '_user']) != owner) return true;
      else {
        removeIds.push(id);
        return false;
      }
    });
    const removedById = omit(byId, removeIds);
    const removedNormalize = { byId: removedById, allIds: removedCampaign };
    return removedNormalize;
  }

  static getUpdateStateOfCreatingSupportingDataForDefaultCampaign(payload, currentState, isCreating) {
    console.log(
      '🚀 ~ CampaignUtil ~ getUpdateStateOfCreatingSupportingDataForDefaultCampaign ~ isCreating:',
      isCreating,
    );
    const { section } = payload;
    const updatedCreatingSupportingData = currentState.creatingSupportingData;
    switch (section) {
      case CampaignSession.description: {
        updatedCreatingSupportingData.description = isCreating;
        break;
      }
      case CampaignSession.message_flow: {
        updatedCreatingSupportingData.message_flow = isCreating;
        break;
      }
      case CampaignSession.message_sample: {
        updatedCreatingSupportingData.message_sample = isCreating;
        break;
      }
      default:
        break;
    }
    return updatedCreatingSupportingData;
  }
}
