import { isArray, uniq } from 'lodash';
import UserAction from './user.actions';
import UserUtils from './user.utils';

const { UserActionTypes } = UserAction;

const INITIAL_STATE = {
  currentUser: {},
  sameDomainUsers: [],
  isFetching: false,
  onlineList: [],
  users: {
    byId: {},
    allIds: [],
  },
  isFetchingList: false,
  isUpdatingUser: false,
  errorMessage: null,
  token: undefined,
  sessionId: '',
  plan: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case UserActionTypes.SET_CURRENT_USER:
    //    return { ...state, currentUser: action.payload};

    case UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    case UserActionTypes.LOCAL_REGISTER:
      // if (!action.payload) return false;
      // return { ...state, ...action.payload };
      return state;

    case UserActionTypes.UPDATE_ONLINE_USER: {
      return {
        ...state,
        onlineList: isArray(action.payload)
          ? uniq([...state.onlineList, ...action.payload])
          : uniq([...state.onlineList, action.payload]),
      };
    }

    case UserActionTypes.DELETE_ONLINE_USER: {
      return {
        ...state,
        onlineList: state.onlineList.filter((onlineUserId) => {
          const userIds = action.payload;
          if (isArray(userIds)) {
            return userIds.every((id) => id !== onlineUserId);
          }
          return onlineUserId !== userIds;
        }),
      };
    }

    case UserActionTypes.FETCH_USER: {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    }
    case UserActionTypes.LOCAL_LOGIN: {
      const { currentUser, token, sessionId } = action.payload;
      return {
        ...state,
        isFetching: true,
        currentUser,
        token,
        sessionId,
      };
    }

    case UserActionTypes.UPDATE_USER_START: {
      return {
        ...state,
        isUpdatingUser: true,
      };
    }

    case UserActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isUpdatingUser: false,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    }

    case UserActionTypes.UPDATE_USER_FAILURE: {
      return {
        ...state,
        isUpdatingUser: false,
      };
    }

    case UserActionTypes.FETCH_USER_START:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionTypes.FETCH_USER_SUCCESS: {
      const currentUser = action.payload;
      return { ...state, currentUser };
    }
    case UserActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case UserActionTypes.UPDATE_PLAN: {
      const currentUser = { ...state.currentUser, ...action.payload };
      return {
        ...state,
        currentUser,
      };
    }
    case UserActionTypes.FETCH_SAME_DOMAIN_EMAILS:
      return {
        ...state,
        sameDomainUsers: action.payload,
      };
    case UserActionTypes.USER_AUTH_ERROR: {
      const currentUser = {
        authError: action.payload[0],
        email: action.payload[1],
        cell_phone: action.payload[2],
        id: action.payload[3],
      };
      return {
        ...state,
        currentUser,
      };
    }

    case UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_START:
      return {
        ...state,
        isFetchingList: true,
      };
    case UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        users: UserUtils.normalizeUsers(state.users, action.payload),
      };
    case UserActionTypes.UPDATE_USER_FIELD:
      const { id, key, value } = action.payload;
      const { byId, allIds } = state.users;
      const user = {
        ...byId[id],
        [key]: value,
      };

      return {
        ...state,
        users: {
          byId: { ...byId, [id]: user },
          allIds,
        },
      };
    case UserActionTypes.FETCH_USER_LIST_BY_MEMBERS_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errorMessage: action.payload,
      };

    case UserActionTypes.FETCH_USER_PLAN_START:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionTypes.FETCH_USER_PLAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        plan: action.payload,
      };
    case UserActionTypes.FETCH_USER_PLAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case UserActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        sessionId: action.payload.sessionId,
      };
    default:
      return state;
  }
};

export default userReducer;
