import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import ConversationAction from '../conversation/conversation.actions';
import MessageAction from './message.actions';
import { handleGetErrorMessage, handleDateFormat } from '../../util/index';
import CdrAction from '../cdr/cdr.actions';

const { MessageActionTypes } = MessageAction;

export function* fetchContactMessage({ payload, callback = () => {} }) {
  try {
    let response;

    if (Array.isArray(payload)) {
      response = yield call(axios.post, '/message/conversations/all', { conversations: payload });
    } else {
      if (!payload) return;
      response = yield call(axios.get, `/message/${payload}`);
    }

    const messages = get(response, 'data.messages') ? get(response, 'data.messages') : null;
    yield put(MessageAction.fetchContactMessageSuccess(messages));
    callback(null, messages);
  } catch (error) {
    yield put(MessageAction.fetchContactMessageFailure(handleGetErrorMessage(error)));
    callback(error);
    console.error({ error });
  }
}

export function* fetchLatestMessageByContactIds({ payload, callback = () => {} }) {
  try {
    const response = yield call(axios.post, '/message/lastMessageByContactId', { contactIds: payload });
    yield put(MessageAction.fetchLatestMessageByContactSuccess(response.data));
    if (callback) {
      callback(null, response.data);
    }
  } catch (error) {
    yield put(MessageAction.fetchLatestMessageByContactFailure());
    if (callback) {
      callback(error);
    }
    console.error({ error });
  }
}

export function* fetchMessageStartAsync({ payload, callback = () => {} }) {
  const { key, name, phone, did, _group, page, pageSize = 10 } = payload;
  try {
    if (!key) {
      yield put(MessageAction.fetchMessageFailure());
      return;
    }
    const response = yield call(axios.get, `/message/${key}`, { params: { page, pageSize } });

    if (response && response.data) {
      const { messages, hasMore } = response.data;
      const cdrIds = messages.filter((message) => message._cdr).map((message) => message._cdr);
      const messageObjArray = messages;
      // ? messages.map((obj) => {
      //     return {
      //       ...obj,
      //       createdAt: handleDateFormat(obj.createdAt),
      //     };
      //   })
      // : null;
      const messageObj = {
        // contact: response.data.contact,
        data: messageObjArray,
        key,
        name,
        phone,
        did,
        _group,
        hasMore,
      };
      yield put(CdrAction.fetchCdrByIds(cdrIds));
      yield put(MessageAction.fetchMessageSuccess(messageObj));
      callback(messages);
    }
  } catch (error) {
    yield put(MessageAction.fetchMessageFailure(handleGetErrorMessage(error)));
    // window.location.href = '/app/chats';
    callback(null, error);
  }
}

// upload file
export function* uploadFile({ payload }) {
  const { conversationId, file, type, callback } = payload;
  try {
    const requestBody = {
      file,
      conversation: conversationId,
      type,
      sent: false,
      failed: false,
    };
    const response = yield call(axios.post, '/message/file', requestBody);

    const chat = get(response, 'data.message');

    // SocketService.socketConnect().emit('SEND_MESSAGE', response.data);
    yield put(MessageAction.addNewChat(response.data));
    yield put(
      ConversationAction.updateUnRepliedStatus({
        conversation: conversationId,
        status: false,
        chat,
      }),
    );

    callback(undefined, chat);
  } catch (error) {
    callback(error, undefined);
    console.log({ error });
  }
}

// create message
export function* createNewMessage({ payload, callback = () => {} }) {
  const tmpId = uuidv4();
  const { conversationId, message, type, group, tags: mentions, is_received, is_close, created_by_template } = payload;

  try {
    const response = yield call(axios.post, '/message', {
      tmpId,
      chat: message,
      conversation: conversationId,
      type,
      sent: false,
      failed: false,
      mentions,
      is_received,
      created_by_template,
    });
    if (response?.data?.isReopen) {
      yield put(MessageAction.fetchAllSystemMessage());
    }
    if (
      is_close &&
      get(response, 'data.routeConversation') &&
      (get(response, 'data.newConversationCreated') || get(response, 'data.conversationRouted'))
    ) {
      const newConversation = get(response, 'data.routeConversation');
      if (get(response, 'data.newConversationCreated')) {
        yield put(ConversationAction.fetchChatStart());
      }
      yield put(
        MessageAction.fetchMessageStart({
          key: newConversation.id,
          name: newConversation.name,
          phone: newConversation.phone,
          did: newConversation._did,
          _group: newConversation._group,
        }),
      );
      yield put(ConversationAction.setConversationUsersStart({ conversationId: newConversation.id }));
      yield put(ConversationAction.chooseConversation(newConversation.id));
    }

    yield put(MessageAction.addNewChat({ ...response.data, tmpId }));

    callback(null, response.data);
  } catch (error) {
    yield put(MessageAction.removeMessageTemp({ tempId: tmpId }));

    if (get(error, 'response.data.message')) {
      callback(get(error, 'response.data.message'));
    }
  }
}

// close message
export function* closeMessage({ payload }) {
  const conversation = payload;
  try {
    yield call(axios.put, `/conversation/${conversation}`, {
      is_close: true,
    });
    yield put(ConversationAction.fetchChatStart());
    yield put(MessageAction.selectConversation(null));
    yield put(MessageAction.fetchAllSystemMessage());
  } catch (error) {
    console.log({ error });
  }
}

// untag
// export function* untag({ payload }) {
//   const conversation = payload;
//   try {
//     yield call(axios.put, `/conversation/untag${conversation}`, {});
//   } catch (error) {
//     console.log({ error });
//   }
// }
export function* searchMessage({ payload }) {
  try {
    const { messageSearchKeyword, userId } = payload;
    const response = yield call(axios.post, `/message/search_message`, { messageSearchKeyword, userId });
    yield put(MessageAction.searchMessageSuccess(response));
  } catch (error) {
    yield put(MessageAction.searchMessageFailure(error));
  }
}
export function* fetchMessageForConversation({ payload }) {
  try {
    const { conversationId, fromMessageId } = payload;

    const response = yield call(
      axios.get,
      `/message/fromMessageByDirection/${conversationId}?fromMessageId=${fromMessageId}&modeLoadDirection=0`,
    );
    yield put(MessageAction.fetchMessageReportSuccess(response));
  } catch (error) {
    yield put(MessageAction.fetchMessageReportFailure(error));
  }
}
export function* fetchSystemMessage({ payload }) {
  try {
    const data = yield call(axios.get, `/message/all_system`);
    yield put(MessageAction.fetchAllSystemMessageSuccess(data));
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchSystemMessageForReport({ payload, callback = () => {} }) {
  try {
    const response = yield call(axios.get, `/message/all_system_for_report`);
    if (response.status === 200) {
      yield put(MessageAction.fetchSystemMessageForReportSuccess(response.data));
      callback(null, response.data);
    }
  } catch (error) {
    console.log({ error });
    callback(error);
  }
}

export function* messageFlow() {
  yield all([
    yield takeLatest(MessageActionTypes.FETCH_CONTACT_MESSAGE_START, fetchContactMessage),
    yield takeLatest(MessageActionTypes.FETCH_MESSAGE_START, fetchMessageStartAsync),
    yield takeLatest(MessageActionTypes.HANDLE_MESSAGE_UPLOAD_FILE_START, uploadFile),
    yield takeLatest(MessageActionTypes.ADD_NEW_CHAT_START, createNewMessage),
    yield takeLatest(MessageActionTypes.CLOSE_MESSAGE_START, closeMessage),
    yield takeLatest(MessageActionTypes.FETCH_LATEST_MESSAGE_BY_CONTACT_START, fetchLatestMessageByContactIds),
    yield takeLatest(MessageActionTypes.SEARCH_MESSAGE, searchMessage),
    yield takeLatest(MessageActionTypes.FETCH_MESSAGE_REPORT, fetchMessageForConversation),
    yield takeLatest(MessageActionTypes.FETCH_SYSTEM_MESSAGE, fetchSystemMessage),
    // yield takeLatest(MessageActionTypes.UNTAG_START, untag),
  ]);
}
