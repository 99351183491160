import axios from 'axios';
import { isEqual } from 'lodash';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import ContactAction from '../contact/contact.actions';
import FieldValueAction from '../field-value/field-value.actions';
import FieldValueSelector from '../field-value/field-value.selectors';
import FieldAction from './field.actions';

export function* fetchFields() {
  try {
    const response = yield call(axios.get, '/field');
    if (response && response.data) {
      yield put(FieldAction.fetchFieldsSuccess(response.data));
    }
  } catch (error) {
    yield put(FieldAction.fetchFieldsFailure(error.message));
    console.log({ error });
  }
}

export function* createField({ payload, callback = () => {} }) {
  try {
    const response = yield call(axios.post, `/field`, payload);
    if (response.data) {
      yield put(FieldAction.addFieldSuccess(response.data));
      callback(null, response.data);
    } else {
      const error = new new Error('Create field failed.')();
      yield put(FieldAction.addFieldFailure(error.message));
      callback(error);
    }
  } catch (error) {
    yield put(FieldAction.addFieldFailure(error.message));
    callback(error);
    console.log({ error });
  }
}

export function* deleteField({ payload }) {
  try {
    yield call(axios.delete, `/field/${payload}`);
    const fieldValues = yield select(FieldValueSelector.selectFieldValueList);
    const deletedFieldValues = fieldValues
      .filter((fieldValue) => isEqual(fieldValue._field, payload))
      .map((fieldValue) => fieldValue.id);
    yield put(FieldAction.deleteFieldSuccess(payload));
    yield put(FieldValueAction.deleteFieldValues(deletedFieldValues));
    // yield put(ContactAction.fetchContacts());
  } catch (error) {
    yield put(FieldAction.deleteFieldFailure(error.message));
    console.log({ error });
  }
}

export function* updateField({ payload }) {
  try {
    const response = yield call(axios.put, `/field/${payload.id}`, payload);
    yield put(FieldAction.updateFieldSuccess(response.data));
  } catch (error) {
    yield put(FieldAction.updateFieldFailure(error.message));
    console.log({ error });
  }
}

export default function* fieldSagaFlow() {
  yield takeLatest(FieldAction.FETCH_FIELDS.START, fetchFields);
  yield takeLatest(FieldAction.UPDATE_FIELD.START, updateField);
  yield takeLatest(FieldAction.DELETE_FIELD.START, deleteField);
  yield takeLatest(FieldAction.ADD_FIELD.START, createField);
}
