import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import FileAction from './file.actions';

export function* fetchFile({ payload }) {
  const _contact = payload;
  try {
    const response = yield call(axios.get, `/files/byContact/${_contact}`);
    if (response && response.data) {
      yield put(FileAction.fetchFileSuccess(response.data, _contact));
    } else {
      yield put(FileAction.fetchFileFailure('Fetch Files failed'));
    }
  } catch (error) {
    yield FileAction.fetchFileFailure(error.message);
    console.log({ error });
  }
}

export default function* fileSagaFlow() {
  yield takeLatest(FileAction.FETCH_FILES.START, fetchFile);
}
