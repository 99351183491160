export default class DidAction {
  static FETCH_DID = {
    START: 'DidAction/FETCH_DID_START',
    SUCCESS: 'DidAction/FETCH_DID_SUCCESS',
    FAILURE: 'DidAction/FETCH_DID_FAILURE',
  };

  static SEARCH_NUMBER = {
    START: 'DidAction/SEARCH_NUMBER_START',
    SUCCESS: 'DidAction/SEARCH_NUMBER_SUCCESS',
    FAILURE: 'DidAction/SEARCH_NUMBER_FAILURE',
  };

  static UPDATE_DID = 'DidAction/UPDATE_DID';

  static SET_DID = 'DidAction/SET_DID';

  static DELETE_DID = 'DidAction/DELETE_DID';

  static RELEASE_DID = 'DidAction/RELEASE_DID';

  static CONFIRM_PURCHASE_DID = 'DidAction/CONFIRM_PURCHASE_DID';

  static fetchDids = (payload) => ({
    type: this.FETCH_DID.START,
    payload,
  });

  static fetchDidSuccess = (map) => ({
    type: this.FETCH_DID.SUCCESS,
    payload: map,
  });

  static fetchDidFailure = (errorMessage) => ({
    type: this.FETCH_DID.FAILURE,
    payload: errorMessage,
  });

  static searchDid = (state, callback) => ({
    type: this.SEARCH_NUMBER.START,
    payload: state,
    callback,
  });

  static searchDidSuccess = (payload) => ({
    type: this.SEARCH_NUMBER.SUCCESS,
    payload,
  });

  static searchDidFailure = (errorMessage) => ({
    type: this.SEARCH_NUMBER.FAILURE,
    payload: errorMessage,
  });

  static updateDid = (state, callback) => ({
    type: this.UPDATE_DID,
    payload: state,
    callback,
  });
  static setDid = (state, callback) => ({
    type: this.SET_DID,
    payload: state,
    callback,
  });

  static deleteDid = (payload) => ({
    type: this.DELETE_DID,
    payload,
  });

  static confirmPurchaseDids = (state, callback) => ({
    type: this.CONFIRM_PURCHASE_DID,
    payload: state,
    callback,
  });

  static releaseDid = (props) => ({
    type: this.RELEASE_DID,
    payload: props,
  });
}
