import UserAction from '../user/user.actions';
import SessionLogAction from './session-log.action';

const INITIAL_STATE = {
  fetching: false,
  sessionByUser: {},
  error: null,
};

const sessionLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SessionLogAction.FETCH_SESSION_LOG_BY_DATE_RANGE.START:
      return { ...state, fetching: true };

    case SessionLogAction.FETCH_SESSION_LOG_BY_DATE_RANGE.SUCCESS:
      return { ...state, fetching: false, sessionByUser: action.payload };

    case SessionLogAction.FETCH_SESSION_LOG_BY_DATE_RANGE.FAILURE:
      return { ...state, fetching: false, error: action.payload };

    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default sessionLogReducer;
