import { groupBy, omit } from 'lodash';
import FileAction from './file.actions';
import UserAction from '../user/user.actions';
import { normalizeUpdatedData } from '../../util';

const INITIAL_STATE = {
  fetching: true,
  updating: false,
  deleting: false,
  data: {
    byId: {},
    allIds: [],
  },
  byContact: {},
};

const fileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FileAction.FETCH_FILES.START:
      return {
        ...state,
        fetching: true,
      };
    case FileAction.FETCH_FILES.SUCCESS:
      const data = normalizeUpdatedData(state.data, action.payload.data);
      const groupByContact = groupBy(data.byId, 'message.conversation.identify._contact');
      const byContact = Object.keys(groupByContact).reduce(
        (pre, cur) => ({ ...pre, [cur]: groupByContact[cur].map((ls) => ls.id) }),
        {},
      );
      return {
        ...state,
        fetching: false,
        data,
        byContact,
      };
    case FileAction.FETCH_FILES.FAILURE:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case UserAction.UserActionTypes.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default fileReducer;
