import { groupBy } from 'lodash';

export default class FieldLogUtil {
  static normalize(state, fields) {
    const byId = groupBy(fields, 'id');
    const allIds = Object.keys(byId);
    allIds.forEach((id) => {
      byId[id] = byId[id][0];
    });
    return {
      byId: { ...state.data.byId, ...byId },
      allIds: [...state.data.allIds, ...allIds],
    };
  }
}
